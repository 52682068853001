import React from 'react';
import {Popup} from "./style-edit";
import EditForm from "../taskForm/TaskForm";


interface PopupProps {
    show: boolean;
    handleClosePopup: () => void;
    onClickClosest: () => void;
}


export const EditPopups = ({show, handleClosePopup, onClickClosest}: PopupProps) => {
    return (
        <Popup show={show} onClick={onClickClosest}>
            <EditForm handleClosePopup={handleClosePopup}/>
        </Popup>
    );
}

