import React from 'react';
import { useLocation } from 'react-router-dom';
import { HeaderTitle } from './HeaderTitle';
import {HeaderTitleDown} from "./HeaderTitleDown";

type PageTitles = Record<string, string>;

const pageTitles: PageTitles = {
    '/': 'Mój ślub',
    '/tasks': 'Lista zadań',
    '/budget': 'Budżet',
    '/guest': 'Lista gości',
    '/sharing': 'Lista gości',
    '/plan': 'Plan stołów',
    '/weddingqa': 'Ślubne Q&A',
    '/weddingqa/answers': 'Ślubne Q&A',
    '/weddingqa/questions': 'Ślubne Q&A',
    '/website': 'Strona ślubna',
    '/website-create': 'Strona ślubna',
    '/website-create/website': 'Strona ślubna',
    '/website-create/website/website-pages': 'Strona ślubna',
    '/ads': 'Moje ogłoszenia',
    '/favorites': 'Ulubione i inspiracje',
    '/favorites/favorite': 'Ulubione i inspiracje',
    '/favorites/inspirations': 'Ulubione i inspiracje',
    '/adsplace': 'Moje ogłoszenia',
};

export const PageHeader = () => {
    const location = useLocation();
    const path = location.pathname;

    const title = pageTitles[path] ? pageTitles[path] : 'Default Title';

    return <HeaderTitle title={title} />;
};

export const PageHeaderDown = () => {
    const location = useLocation();
    const path = location.pathname;

    const title = pageTitles[path] ? pageTitles[path] : 'Default Title';

    return <HeaderTitleDown title={title} />;
};
