import React from "react";
import {useAppSelector} from "../../../../../common/hook";
import {calcPlannedPrice} from "../../../../../common/utils/calcPlannedPrice";
import {PlannedContent, PlannedPrice, PlannedTitle} from "./style-planned";
import {categorySelector} from "../../../../../app/selectors/category";

export const Planned = () => {
    const { categories } = useAppSelector(categorySelector);
    const totalPrice = calcPlannedPrice(categories);

    return (
        <PlannedContent>
            <PlannedTitle>Planowana kwota</PlannedTitle>
            <PlannedPrice>{totalPrice} zł</PlannedPrice>
        </PlannedContent>
    );
};