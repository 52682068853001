import React from 'react';
import {Categories} from "../container/categories-container/Categories";
import {ListQuestions} from "./list-questions/ListQuestions";
import {QuestionsWrap} from "./style-questions";
import {NewQuestions} from "../container/new-questions/NewQuestions";

export const Questions = () => {
    return (
        <QuestionsWrap>
            <Categories/>
            <ListQuestions/>
            <NewQuestions/>
        </QuestionsWrap>
    );
};

