import styled from "styled-components";

export const ImageWrap = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%,-50%,0);
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  width: 100%;

  @media (max-width: 682.98px) {
    position: relative;
    width: 100%;
    height: 45vw;
    min-height: 120px;
    left: unset;
    top: unset;
    transform: none;
    min-width: unset;
  }
;
`

export const ImageContainer = styled.div`
  width: 50%;
  position: relative;
  overflow: hidden;
  border-radius: 0 5px 5px 0;
  @media (max-width: 682.98px) {
    width: 100%;
  }

`
