import React, {useEffect, useState} from 'react';
import {CloseOutlined} from '@ant-design/icons';
import type {UploadProps} from 'antd';
import {message, Upload} from 'antd';
import {ReactComponent as CameraIcon} from './../assets/sing/camera.svg';
import {
    ContactAvatar,
    ContactAvatarContainer,
    Loader,
    LoaderBlock,
    StyledAvatarFileContainer,
    StyledContactFileImg
} from "components/style-contact-person-form";
import {FileIconContainer, FileUploadIcon, FileUploadText} from "components/pages/ads/ads-place/style-ads-place";

type FileType = Parameters<NonNullable<UploadProps['beforeUpload']>>[0];

const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

interface MultimediaContactProps {
    data: any;
    onImageChange?: (file: File | string | null) => void;
}

export const MultimediaContact = ({data, onImageChange}: MultimediaContactProps) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string | any>();


    useEffect(() => {
        if (data?.wedding_image?.link) {
            setImageUrl(data.wedding_image?.link);
        }
    }, [data]);

    const handleChange: UploadProps['onChange'] = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            setLoading(false);
            const file = info.file.originFileObj as FileType;
            const fileUrl = URL.createObjectURL(file);
            setImageUrl(fileUrl);
            if (onImageChange) {
                onImageChange(file);
            }
        }
    };




    const handleDelete = () => {
        setImageUrl(undefined);
        if (onImageChange) {
            onImageChange('');
        }
    };

    return (
        <ContactAvatarContainer>
            {!imageUrl ? (
                <ContactAvatar>
                    <Upload
                        name="avatar"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        customRequest={({file, onSuccess}) => {
                            onSuccess?.({}, file as any);
                        }}
                    >
                        <FileIconContainer>
                            <FileUploadIcon>+</FileUploadIcon>
                            <FileUploadText>Dodaj zdjęcie</FileUploadText>
                        </FileIconContainer>
                        <CameraIcon/>
                    </Upload>
                </ContactAvatar>
            ) : (
                <StyledAvatarFileContainer>
                    {loading ? (
                        <LoaderBlock>
                            <Loader/>
                        </LoaderBlock>
                    ) : (
                        <>
                            <StyledContactFileImg imageUrl={imageUrl}/>
                            <CloseOutlined
                                onClick={handleDelete}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    zIndex: 3,
                                    cursor: 'pointer',
                                }}
                            />
                        </>
                    )}
                </StyledAvatarFileContainer>
            )}
        </ContactAvatarContainer>
    );
};
