import styled from "styled-components";


export const PlanContentWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PlanContentPrint = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  margin-bottom: 31px;
  @media (max-width: 577.98px) {
    gap: 20px;
  }

`;

export const PlanContentBlock = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 1440.98px) {
    width: 100%;
    flex-direction: column-reverse;
  }
`;

export const PrintPlanContent = styled.div`
  display: flex;
  gap: 42px;
`;

export const PlanContentCheckbox = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-right: 76px;
  @media (max-width: 1440.98px) {
    padding-right: 25px;
  }

  @media (max-width: 1000.98px) {
    padding-right: 0;
  }

`;