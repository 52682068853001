import styled from "styled-components";

export const PlannedWrap = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #D2DAE3;
  align-items: end;
  
`

export const PlannedContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  text-align: right;
  padding-top: 20px;
  padding-right: 15px;
`
export const PlannedTitle = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(12, 18, 21, 0.6);
`

export const PlannedPrice = styled.p`
  font-weight: 500;
  font-size: 22px;
  line-height: 16px;
  color: #0C1215;
`

export const PlannedZeroPrice = styled.p`
  color: #0C1215;
  font-family: Gilroy,sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
  
  svg {
    cursor: pointer;
  }
`