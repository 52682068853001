import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {v4 as uuid} from 'uuid';


export interface IVisitor {
    id: string,
    name: string,
    notes: string,
    isChild: boolean,
    isInvited: boolean,
    isConfirmed: boolean,
    isChurch: boolean,
    isRestaurant: boolean,
    isAccommodation: boolean,
    isTransport: boolean,
    isSpecialDiet: boolean,
}

export interface IGroups {
    id: string
    groupName: string
    visitors: IVisitor[]
}

interface INewlyweds {
    id: string,
    groups: IGroups[]
}

interface InitialStateType {
    newlyweds: INewlyweds[]
}

const initialState: InitialStateType = {
    newlyweds: [{id: uuid(), groups: []}, {id: uuid(), groups: []}],
};

const slice = createSlice({
    name: 'guest',
    initialState,
    reducers: {
        // addGroup: (
        //     state,
        //     action: PayloadAction<{ newlywedsId: string; values: IGroups }>
        // ) => {
        //     const {newlywedsId, values} = action.payload;
        //     state.newlyweds = [...state.newlyweds, state.newlyweds.find(n => n.id === newlywedsId)!.groups.concat(values)];
        // },
        addGroup: (state, action: PayloadAction<{ newlywedsId: string; values: IGroups }>) => {
            const { newlywedsId, values } = action.payload;
            const newlyweds = state.newlyweds.find(n => n.id === newlywedsId);
            if (newlyweds) {
                newlyweds.groups.push(values);
            }
        },
    },
});


export const guestReducer = slice.reducer;
export const {
    addGroup,
} = slice.actions;
