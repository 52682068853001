import { z } from 'zod';

export const loginSchema = z.object({
    email: z.string().email({ message: "Nieprawidłowy adres email" }),
    password: z
        .string()
        .min(3, { message: "Hasło jest za krótkie" })
        .max(30, { message: "Hasło jest zbyt długie" }),
});

export type FormValues = z.infer<typeof loginSchema>;
