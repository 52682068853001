import React, {FC} from 'react';
import {Form, Formik} from 'formik';


import {ReactComponent as Checkbox} from '../../../../../../../../assets/main/form/checkbox.svg';
import {ReactComponent as СrossIcon} from '../../../../../../../../assets/main/wedding/close.svg';


import {ButtonPlanBlock, ButtonPlanSubmit} from "../../../../../plan-tab/style-plan-tab";

import {
    FormBlock,
    FormContainer,
    FormWrapper,
    StyledInput,
    StyledLabel
} from "../../../../../../wedding/usersForm/style-usersform";
import {CloseButton, StyledTitle} from "../../../../../../wedding/popUp/style-popups";
import {QuestionSelect} from "../../../../../../weddingqa/answers/answer-question/question-select/QuestionSelect";
import {optionsAllStatuses} from "./options";
import {PlanNewlyweds} from "../../../../../plan-newlyweds/plan-newlyweds";


interface MyFormValues {
    id: string,
    tableName: string,
    numberOfSeats: string,
}

interface PropsType {
    handleClosePopup: () => void;
}


export const FormAddGuest: FC<PropsType> = ({handleClosePopup}) => {


        const handleSubmit = () => {
            // id: uuidv1(),
            // tableName: '',
            // numberOfSeats: '',
        };


        return (
            <Formik
                initialValues={{
                    id: '',
                    tableName: '',
                    numberOfSeats: '',
                }}
                enableReinitialize={true}
                onSubmit={handleSubmit}
            >
                {({values, handleSubmit, handleChange, errors}) => (
                    <FormWrapper onClick={(e) => e.stopPropagation()}>
                        <Form onSubmit={handleSubmit}>
                            <CloseButton type="button" onClick={handleClosePopup}>
                                <СrossIcon/>
                            </CloseButton>
                            <StyledTitle>Dodaj gościa</StyledTitle>
                            <FormContainer>
                                <FormBlock>
                                    <StyledLabel htmlFor="subject">Kategoria</StyledLabel>
                                    <QuestionSelect name="mySelectBox" options={optionsAllStatuses}/>
                                </FormBlock>
                                <PlanNewlyweds/>
                            </FormContainer>
                        </Form>
                    </FormWrapper>
                )}
            </Formik>
        );
    }
;
