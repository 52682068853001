import React, {FC, useState} from 'react';
import {Form, Formik} from 'formik';
import {ReactComponent as Checkbox} from '../../../../../../assets/main/form/checkbox.svg';
import {ReactComponent as CloseIcon} from '../../../../../../assets/main/form/close.svg';
import {ReactComponent as СrossIcon} from '../../../../../../assets/main/wedding/close.svg';
import {CloseButton, StyledTitle} from "../guest-popup-form/style-popups";
import {
    ButtonBlock,
    ButtonCloses,
    ButtonSubmit,
    FormBlock,
    FormContainer,
    FormWrapper,
    GuestFormBlock,
    GuestFormInput,
    GuestFormItem,
    RadioBlock,
    RadioInput,
    RadioInputBlock,
    StyledInput,
    StyledLabel
} from "../../../../wedding/usersForm/style-usersform";
import {AddInput} from "../../../../ads/ads-place/style-ads-place";


// import {schema} from "../../../../yup/yup";

interface MyFormValues {
    first: string;
    man: boolean;
    woman: boolean;
    gender: boolean;
    adult: boolean;
    child: boolean;
    age: boolean;
    side: boolean;
    bride: boolean;
    groom: boolean;
    email: string;
    additionalEmail: string;
    tel: string;
    additionalTel: string;
    address: string;
    note: string;
}

interface PropsType {
    handleClosePopup: () => void;
}

export const FormGuest: FC<PropsType> = ({handleClosePopup}) => {
        const [emailFields, setEmailFields] = useState(false);
        const [telFields, setTelFields] = useState(false);

        const onClickEmailHandler = () => {
            setEmailFields(!emailFields)
        }
        const onClickTellHandler = () => {
            setTelFields(!telFields)
        }
        const handleSubmit = (values: MyFormValues) => {
            // dispatchEvent()
        }

        return (
            <Formik
                initialValues={{
                    first: '',
                    man: false,
                    woman: false,
                    adult: false,
                    side: false,
                    gender: false,
                    child: false,
                    age: false,
                    bride: false,
                    groom: false,
                    email: '',
                    additionalEmail:'',
                    tel: '',
                    additionalTel: '',
                    address: '',
                    note: '',
                }}
                onSubmit={handleSubmit}
            >
                {({values, handleSubmit, handleChange, errors}) => (
                    <FormWrapper onClick={(e) => e.stopPropagation()}>
                        <Form onSubmit={handleSubmit}>
                            <CloseButton type="button" onClick={handleClosePopup}>
                                <СrossIcon/>
                            </CloseButton>
                            <StyledTitle>Dodaj gościa</StyledTitle>
                            <FormContainer>
                                <FormBlock>
                                    <StyledLabel htmlFor="name">Imię & Nazwisko</StyledLabel>
                                    <StyledInput
                                        type="text"
                                        name="name"
                                        value={values.first}
                                        onChange={handleChange}
                                    />
                                </FormBlock>
                                <GuestFormItem>
                                    <GuestFormBlock>
                                        <StyledLabel htmlFor="gender">Płeć</StyledLabel>
                                        <GuestFormInput>
                                            <RadioBlock>
                                                <RadioInput
                                                    type="radio"
                                                    id="gender"
                                                    name="gender"
                                                    value="gender"
                                                    checked={values.gender}
                                                    onChange={handleChange}
                                                />
                                                <span>Mężczyzna</span>
                                            </RadioBlock>
                                            <RadioBlock>
                                                <RadioInput
                                                    type="radio"
                                                    id="man"
                                                    value="man"
                                                    name="man"
                                                    checked={values.man}
                                                    onChange={handleChange}
                                                />
                                                <span>Kobieta</span>
                                            </RadioBlock>
                                        </GuestFormInput>
                                    </GuestFormBlock>
                                    <GuestFormBlock>
                                        <StyledLabel htmlFor="age">Wiek</StyledLabel>
                                        <GuestFormInput>
                                            <RadioBlock>
                                                <RadioInput
                                                    type="radio"
                                                    id="age"
                                                    value="age"
                                                    name="age"
                                                    checked={values.age}
                                                    onChange={handleChange}
                                                />
                                                <span>Dorosły</span>
                                            </RadioBlock>
                                            <RadioBlock>
                                                <RadioInput
                                                    type="radio"
                                                    id="adult"
                                                    value="adult"
                                                    name="adult"
                                                    checked={values.adult}
                                                    onChange={handleChange}
                                                />
                                                <span>Dziecko</span> </RadioBlock>
                                        </GuestFormInput>
                                    </GuestFormBlock>
                                </GuestFormItem>
                                <GuestFormBlock>
                                    <StyledLabel htmlFor="side">Która strona</StyledLabel>
                                    <RadioInputBlock>
                                        <RadioBlock>
                                            <RadioInput
                                                type="radio"
                                                id="side"
                                                value="side"
                                                name="side"
                                                checked={values.side}
                                                onChange={handleChange}
                                            />
                                            <span>Od Panny Młodej</span>
                                        </RadioBlock>
                                        <RadioBlock>
                                            <RadioInput
                                                type="radio"
                                                id="bride"
                                                value="bride"
                                                name="bride"
                                                checked={values.bride}
                                                onChange={handleChange}
                                            />
                                            <span>Od Pana Młodego</span>
                                        </RadioBlock>
                                    </RadioInputBlock>
                                </GuestFormBlock>


                                <FormBlock>
                                    <StyledLabel htmlFor="email">E-mail</StyledLabel>
                                    <StyledInput
                                        type="email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                    />
                                </FormBlock>

                                {emailFields && (
                                    <FormBlock>
                                        <StyledLabel htmlFor="additionalEmail">Additional E-mail</StyledLabel>
                                        <StyledInput
                                            type="additionalEmail"
                                            name="additionalEmail"
                                            value={values.additionalEmail}
                                            onChange={handleChange}
                                        />
                                    </FormBlock>
                                )
                                }
                                {!emailFields && (
                                    <AddInput onClick={onClickEmailHandler}>+ Dodaj kolejny</AddInput>
                                )}
                                <FormBlock>
                                    <StyledLabel htmlFor="tel">Telefon</StyledLabel>
                                    <StyledInput
                                        type="tel"
                                        name="tel"
                                        value={values.tel}
                                        onChange={handleChange}
                                    />
                                </FormBlock>
                                {telFields && (
                                    <FormBlock>
                                        <StyledLabel htmlFor="tel">Additional Telefon</StyledLabel>
                                        <StyledInput
                                            type="AdditionalTel"
                                            name="AdditionalTel"
                                            value={values.additionalTel}
                                            onChange={handleChange}
                                        />
                                    </FormBlock>
                                )}
                                {!telFields && (
                                    <AddInput onClick={onClickTellHandler}>+ Dodaj kolejny</AddInput>
                                )}
                                <FormBlock>
                                    <StyledLabel htmlFor="address">Adres</StyledLabel>
                                    <StyledInput
                                        type="text"
                                        name="address"
                                        value={values.address}
                                        onChange={handleChange}
                                    />
                                </FormBlock>
                                <FormBlock>
                                    <StyledLabel htmlFor="note">Dodaj notatkę</StyledLabel>
                                    <StyledInput
                                        type="text"
                                        name="note"
                                        value={values.note}
                                        onChange={handleChange}
                                    />
                                </FormBlock>
                                <ButtonBlock>
                                    <ButtonCloses>
                                        <CloseIcon/>
                                        <span>Anuluj</span>
                                    </ButtonCloses>
                                    <ButtonSubmit
                                        type="submit"
                                        disabled={Object.keys(errors).length !== 0}
                                    >
                                        <Checkbox/>
                                        <span>Zapisz</span>
                                    </ButtonSubmit>
                                </ButtonBlock>
                            </FormContainer>
                        </Form>
                    </FormWrapper>
                )}
            </Formik>
        )
            ;
    }
;