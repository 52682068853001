import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {v4 as uuid} from 'uuid';



interface InitialStateType {
    [key: string]: number;
}

const initialState: InitialStateType = {
    websiteFull: 0,
    websiteInformation: 0,
    websiteHoneymoon: 0,
};

const slice = createSlice({
    name: 'scroll',
    initialState,
    reducers: {
        setScrollToCoordinates: (state, action: PayloadAction<{computedProps: string, coordinates: number}>) => {
            state[action.payload.computedProps] = action.payload.coordinates;
        },
    },
});

export const scrollReducer = slice.reducer;
export const { setScrollToCoordinates } = slice.actions;