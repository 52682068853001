import styled, {css} from "styled-components";
import {Input} from "antd";
import {Field} from "formik";

interface SwitchButtonProps {
    isOn: boolean;
}

export interface ErrorProps {
    error?: any;
    errors?: string;
}



export const FormWrapper = styled.div`
    background: white;
    padding: 30px 62px 50px 62px;
    position: absolute;
    left: 50%;
    top: 5%;
    transform: translate(-50%);
    overflow: auto;


    @media screen and (max-width: 425.98px) {
        padding: 20px;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: none;
    }

    form {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
        grid-auto-flow: dense;
        justify-content: center;
        padding-top: 30px;
        position: relative;
    }
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    //align-items: center;
    gap: 18px;
`;

export const GuestFormItem = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 100px;
`;

export const GuestFormBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const GuestFormInput = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledLabel = styled.label<ErrorProps>`
    color: ${props => props.error ? 'red' : '#0C1215'};
    //color: #0C1215;
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: 0.5;
`;

export const RadioInputBlock = styled.div`
    display: flex;
    gap: 52px;
`;


export const PhotoLabel = styled.label`
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #1056b2;
    text-decoration-line: underline;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
        color: #0e408f;
        text-decoration-line: none;
    }
`;

export const HiddenInput = styled.input`
    display: none;
`;


export const StyledInput = styled.input<ErrorProps>`
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    height: 48px;
    padding: 0 20px;
    background: #fff;
    border: ${props => props.error ? '0.6px solid red' : '0.6px solid rgba(12, 18, 21, 0.2)'};
    border-radius: 5px;
    transition: border-color 0.3s ease-in-out;
    color: #0C1215;

    @media screen and (min-width: 1132px) {
        width: 416px;
    }
`;

export const StyledInputCalendar = styled.input<ErrorProps>`
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    height: 48px;
    padding: 0 20px;
    background: #fff;
    border: ${props => props.error ? '0.6px solid red' : '0.6px solid rgba(12, 18, 21, 0.2)'};
    border-radius: 5px;
    transition: border-color 0.3s ease-in-out;
    color: #0C1215;
    cursor: pointer;

    @media screen and (min-width: 1132px) {
        width: 416px;
    }
`;


export const FormBlockPassword = styled.div<ErrorProps>`
  padding: 0.5rem 1rem;
  justify-content: space-between;
  width: 100%;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: ${props => props.error ? '0.6px solid red' : '0.6px solid rgba(12, 18, 21, 0.2)'};
  border-radius: 5px;
  font-size: 16px;
  line-height: 46px;
  color: rgba(12, 18, 21, 0.8);
  height: 48px;
  transition: border-color 0.3s ease-in-out;
  //padding: 0 20px;

  input {
    outline: none;
    font-size: 16px;
    color: rgba(12, 18, 21, 0.8);
    background: #fff;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 100%;
    }

    @media screen and (min-width: 1024px) {
      width: 358px;
    }
  }

  svg {
    cursor: pointer;
  }
`;

export const StyledArea = styled.textarea`
    font-size: 16px;
    line-height: 46px;
    color: rgba(12, 18, 21, 0.8);
    height: 48px;
    padding: 0 20px;
    background: #fff;
    border: 1px solid rgba(12, 18, 21, 0.2);
    border-radius: 5px;

    @media screen and (max-width: 767px) {
        width: 100%;
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        width: 100%;
    }

    @media screen and (min-width: 1024px) {
        width: 416px;
    }

    //&:hover {
    //  border-color: #007aff;
    //}
    //
    //&:focus {
    //  border-color: #007aff;
    //  outline: none;
    //  box-shadow: 0 0 3px #007aff;
    //}
`;


export const StyledTextAreaForm = styled.textarea<ErrorProps>`
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  height: auto;
  min-height: 100px;
  max-height: 190px;
  padding: 10px 20px;
  background: #fff;
  border: ${props => props.error || props.errors ? '0.6px solid red' : '0.6px solid rgba(12, 18, 21, 0.2)'};
  border-radius: 5px;
  transition: border-color 0.3s ease-in-out, height 0.3s ease-in-out; 
  overflow-y: auto;
  resize: none;

  color:${props => props.error || props.errors ? 'red' : '#0C1215'};

  @media screen and (min-width: 1132px) {
    width: 416px;
  }
`;

export const StyledTextArea = styled(Input.TextArea)`
    font-size: 16px;
    line-height: 46px;
    color: rgba(12, 18, 21, 0.8);
    height: 48px;
    padding: 0 20px;
    background: #fff;
    border: 1px solid rgba(12, 18, 21, 0.2);
    border-radius: 5px;


    @media screen and (max-width: 767px) {
        width: 100%;
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        width: 100%;
    }

    @media screen and (min-width: 1024px) {
        width: 416px;
    }

`;


export const ButtonWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 18px;
`;

export const ButtonBlock = styled.div`
    width: 100%;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    padding-top: 12px;

    @media (max-width: 425.98px) {
        flex-direction: column-reverse;
    }
`;


export const ButtonUserSubmit = styled.button`
    margin-top: 10px;
    background: #1056B2;
    border-radius: 208px 100px 100px 0;
    padding: 12px 30px;
    gap: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    cursor: pointer;
    width: 416px;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
    text-transform: uppercase;

    &:hover {
        opacity: 0.9;
    }
`;

export const ButtonDelete = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: underline;
    color: #1056B2;
    cursor: pointer;
`;


export const ButtonCloses = styled.button`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
    padding: 12px 30px;
    background: #ffffff;
    opacity: 0.8;
    border: 1px solid #d2dae3;
    border-radius: 208px 100px 100px 0;

    span {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #0C1215;
    }

`;

export const ButtonSubmit = styled.button`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
    padding: 12px 30px;
    background: #1056B2;
    border-radius: 100px 208px 0 100px;

    span {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
    }

    &:hover,
    &:focus {
        background-color: #1976d2;
    }

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
`;

export const VisibilityBlock = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const RadioBlock = styled.div`
    display: flex;
    gap: 12px;
    padding-left: 17px;

    span {
        font-family: 'Gilroy', serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        align-items: center;
        color: #0C1215;
        opacity: 0.8;
    }
`

export const CheckBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding-left: 17px;
    div {
        display: flex;
        gap: 12px;
        align-items: center;
    }

    span {
        font-family: 'Gilroy', serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        align-items: center;
        color: #0C1215;
        opacity: 0.8;
    }
`

export const TimeBlock = styled.div`
    display: flex;
    gap: 17px;
`

export const TimeInput = styled.input`
    font-size: 16px;
    line-height: 46px;
    color: rgba(12, 18, 21, 0.8);
    height: 48px;
    padding: 0 20px;
    background: #fff;
    border: 1px solid rgba(12, 18, 21, 0.2);
    border-radius: 5px;

    @media screen and (max-width: 767px) {
        width: 100%;
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        width: 100%;
    }

    @media screen and (min-width: 1024px) {
        width: 200px;
    }

    //&:hover {
    //  border-color: #007aff;
    //}
    //
    //&:focus {
    //  border-color: #007aff;
    //  outline: none;
    //  box-shadow: 0 0 3px #007aff;
    //}

`
export const RadioInput = styled(Field)`
    cursor: pointer;
`


// export const FormBlock = styled.div`
//     position: relative;
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     gap: 8px;
//
//     .ant-input:hover,
//     .ant-input:focus {
//         border-color: rgba(12, 18, 21, 0.20);
//         box-shadow: none; /* Отменяем тень при фокусе */
//     }
//
//     /* Отменяем стили при hover и focus для компонента TextArea из Ant Design */
//
//     .ant-input-textarea:hover,
//     .ant-input-textarea:focus {
//         border-color: rgba(12, 18, 21, 0.20);
//         box-shadow: none; /* Отменяем тень при фокусе */
//     }
// `;


export const FormBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    .ant-picker {
        height: 48px;
        cursor: pointer;
    }

    .ant-picker .ant-picker-input {
        cursor: pointer;
    }

    .ant-picker .ant-picker-input > input {
        cursor: pointer;
    }

    .ant-picker-suffix .anticon-calendar svg {
        width: 20px; 
        height: 20px;
        fill: #0C1215;
        opacity: 0.8;
    }
    .ant-picker-clear .anticon-close-circle svg {
        width: 20px; 
        height: 20px;
    }




    .ant-picker .ant-picker-input > input {
        font-family: Gilroy, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        //height: 48px;
        padding: 0 10px;
        background: #fff;
        //border: 0.6px solid rgba(12, 18, 21, 0.2);
        //border-radius: 5px;
        //transition: border-color 0.3s ease-in-out;
        color: #0C1215;

    }


    .ant-picker .ant-picker-input > input:focus {

        border: none;
    }


    .ant-input {
        color: #0C1215;
        font-family: Gilroy, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;

        &:hover {
            border-color: rgba(12, 18, 21, 0.20);;
        }

        &:focus {
            border-color: rgba(12, 18, 21, 0.20);
        }


        svg {
            position: relative;
            left: -4px;
            top: 0;
            width: 24px;
            height: 24px;
            flex-shrink: 0;
            opacity: 0.6;
        }

        .ant-picker-suffix svg {
            display: none;
        }


        @media screen and (max-width: 1132px) {
            width: 100%;
        }
`;

export const FormBlockCalendar = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    cursor: pointer;

    .ant-input {
        color: #0C1215;
        font-family: Gilroy, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;

        &:hover {
            border-color: rgba(12, 18, 21, 0.20);;
        }

        &:focus {
            border-color: rgba(12, 18, 21, 0.20);
        }


        svg {
            position: relative;
            left: -4px;
            top: 0;
            width: 24px;
            height: 24px;
            flex-shrink: 0;
            opacity: 0.6;
        }

        .ant-picker-suffix svg {
            display: none;
        }


        @media screen and (max-width: 1132px) {
            width: 100%;
        }
`;

export const FormRadioBlock = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    label {
        display: flex;
        align-items: center;
        gap: 12px;

        p {
            color: #0C1215;
            font-family: Gilroy, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            opacity: 0.8;
        }
    }
`;

export const StyledUserTitle = styled.p`
    color: #0C1215;
    font-family: Gilroy, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
`

export const CalendarButton = styled.span`
    position: absolute;
    right: 15px;
    top: 37px;
    cursor: pointer;
    opacity: 0.7;

    svg {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        opacity: 0.6;
    }
`

export const ErrorMessage = styled.div`
    display: block;
    color: red;
`

export const StyledUserFileInputContainer = styled.label`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 188px;
    height: 116px;
    outline: none;
    cursor: pointer;
    text-align: center;
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid #D2DAE3;
    box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
    border-radius: 5px;
    color: #1056B2;
    transition: all 0.3s ease;

    &:hover {
        background: #999999;
        box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
        border-radius: 5px;
        color: #FFFFFF;

        svg {
            fill: #FFFFFF;
        }
    }

    input[type='file'] {
        display: none;
    }

    svg {
        position: absolute;
        top: 5px;
        left: 5px;
        fill: #1056B2;
        transition: fill 0.3s ease;
    }
`;


export const SwitchBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    max-width: 416px;
`;

export const SwitchText = styled.div`
    color: #0C1215;
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: 0.5;
`;

export const SwitchContainer = styled.div`
    text-align: center;
`;


export const SwitchButton = styled.div<SwitchButtonProps>`
    display: inline-block;
    width: 50px;
    height: 24px;
    border-radius: 12px;
    background: ${props => (props.isOn ? '#e7ecf3' : '#e7ecf3')};
    z-index: 0;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    position: relative;
    transition-duration: 300ms;

    &::after {
        content: '';
        height: 28px;
        width: 28px;
        border-radius: 18px;
        background: #D2DAE3;
        top: -2px;
        left: ${props => (props.isOn ? '28px' : '-6px')};
        transition-duration: 300ms;
        position: absolute;
        z-index: 1;
    }

    ${props =>
            props.isOn &&
            css`

                &::after {
                    background: #1056B2;
                }
            `}
`;

