import React from 'react';
import {ReactComponent as Plus} from '../../../../assets/main/wedding/plus.svg';
import {ReactComponent as ForAllIcon} from '../../../../assets/main/wedding/forAll.svg';
import {ReactComponent as ForMeIcon} from '../../../../assets/main/wedding/forMe.svg';
import {ReactComponent as Location} from '../../../../assets/main/wedding/location.svg';
import {ReactComponent as Edit} from '../../../../assets/main/plan/edit.svg';
import {
    PlanBlock,
    PlanContainer,
    PlanEdit,
    PlanEditNew,
    PlanFalseName,
    PlanItems,
    PlanJust,
    PlanLocation,
    PlanName,
    PlanText,
    PlanTime,
    PlanTitle,
    PlanWrap
} from "./style-Plan";
import {CalendarButton, CalendarText} from "../../../calendar-Info/styles";
import {NoText} from "../../budget/butget-expense/style-budgetExpense";
import usePopup from "../../../../common/hook/use-popup";
import {EditPopups} from "../popUpEdit/EditPopups";


const wedding = [
    {
        id: '1',
        timeWith: '07:00',
        timeBefore: '09:00',
        title: 'Śniadanie i prysznice',
        location: 'Kraków, ul. Krakowskie Przedmieście 8',
        status: true
    },
    {
        id: '2',
        timeWith: '09:00',
        timeBefore: '11:00',
        title: 'Rozpocznij przygotowanie włosów i makijażu lub czesanie',
        location: 'Kraków, ul. Krakowskie Przedmieście 8',
        name:'Elena Fryzura',
        nameStatus:true,
        status: false
    },

    {
        id: '3',
        timeWith: '11:00',
        timeBefore: '12:00',
        title: 'Dostawy bukietów i dziurek na guziki',
        location: 'Kraków, ul. Krakowskie Przedmieście 201, Zielonki Trojanowice, Małopolskie',
        status: true
    },
    {
        id: '4',
        timeWith: '11:00',
        timeBefore: '12:00',
        title: 'Dostawy bukietów i dziurek na guziki',
        location: 'Kraków, ul. Krakowskie Przedmieście 201, Zielonki Trojanowice, Małopolskie',
        status: true
    },
    {
        id: '5',
        timeWith: '11:00',
        timeBefore: '12:00',
        title: 'Dostawy bukietów i dziurek na guziki',
        location: 'Kraków, ul. Krakowskie Przedmieście 201, Zielonki Trojanowice, Małopolskie',
        name:'Elena Fryzura',
        nameStatus:false,
        status: true
    },
]


export const PlanWedding = () => {
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();


    return (
        <PlanWrap>
            <PlanTitle>Plan wesela</PlanTitle>
            <PlanContainer>
                {wedding.length > 0 ? (
                    wedding.map((wedding, index) => (
                        <PlanBlock key={index}>
                            <PlanItems>
                                <PlanTime>
                                    <p>{`${wedding.timeWith} - ${wedding.timeBefore}`}</p>
                                    <PlanEditNew>
                                        <Edit/>
                                    </PlanEditNew>
                                </PlanTime>
                                <PlanText>
                                    <p>{wedding.title}</p>
                                </PlanText>
                                {wedding.nameStatus === true ?
                                    <PlanName>
                                        {wedding.name}
                                    </PlanName>
                                    :
                                    <PlanFalseName>
                                        {wedding.name}
                                    </PlanFalseName>
                                }
                                <PlanLocation>
                                    <Location/>
                                    <span>{wedding.location}</span>
                                </PlanLocation>
                                {wedding.status ?
                                    <PlanJust>
                                        <ForAllIcon/>
                                        <span>Tylko dla mnie</span>
                                    </PlanJust>
                                    :
                                    <PlanJust>
                                        <ForMeIcon/>
                                        <span>Tylko dla mnie</span>
                                    </PlanJust>
                                }
                                <PlanEdit>
                                    <Edit/>
                                </PlanEdit>
                            </PlanItems>
                        </PlanBlock>
                    ))
                ) : (
                    <NoText>Dodaj nową pozycję</NoText>
                )}
            </PlanContainer>

            <CalendarButton onClick={onClickHandler}>
                <Plus/>
                <CalendarText>
                    Dodaj nową pozycję
                </CalendarText>
            </CalendarButton>
            <EditPopups show={showPopup} handleClosePopup={handleClosePopup} onClickClosest={onClickClosest}/>
        </PlanWrap>
    );
};

