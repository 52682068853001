import styled from "styled-components";
import {AdsPinterPopup} from "../../../ads/ads-points/ads-prints-popups/AdsPrintsPopups";

export const PointsPopupWrap = styled.div`
  position: absolute;
  left: -95px;
  top: -40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #FFFFFF;
  box-shadow: 0 1px 4px rgba(0, 40, 99, 0.15);
  border-radius: 10px;
  padding: 10px;
  z-index: 5;
  width: 100px;

  p {
    cursor: pointer;
  }
`

export const PointsNotePopupWrap = styled.div`
  position: absolute;
  right: -100px;
  top: -63px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #FFFFFF;
  box-shadow: 0 1px 4px rgba(0, 40, 99, 0.15);
  border-radius: 10px;
  padding: 10px;
  z-index: 5;

  p {
    cursor: pointer;
  }
`

export const PointsAdsPopupWrap = styled.div`
  position: absolute;
  right: -43px;
  top: -63px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #FFFFFF;
  box-shadow: 0 1px 4px rgba(0, 40, 99, 0.15);
  border-radius: 10px;
  padding: 10px;
  z-index: 5;

  p {
    cursor: pointer;
  }
`


export const PointsGuestPopupWrap = styled.div`
  position: absolute;
  right: 26px;
  top: -18px;
  display: flex;
  align-items: self-start;
  flex-direction: column;
  gap: 10px;
  background: #FFFFFF;
  box-shadow: 0 1px 4px rgba(0, 40, 99, 0.15);
  border-radius: 10px;
  padding: 10px;
  z-index: 5;

  p {
    cursor: pointer;
  }
`

export const PointsNewlywedsPopupWrap = styled.div`
  width: 100px;
  position: absolute;
  right: 26px;
  top: -33px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #FFFFFF;
  box-shadow: 0 1px 4px rgba(0, 40, 99, 0.15);
  border-radius: 10px;
  padding: 10px;
  z-index: 5;
  align-items: flex-start;

  p {
    cursor: pointer;
  }
`