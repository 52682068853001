import {v1} from "uuid";
import {ReactComponent as True} from '../../../../../../assets/main/guest/check-true.svg';
import {ReactComponent as False} from '../../../../../../assets/main/guest/check-false.svg';
import avatarBride from '../../../../../../assets/main/guest/bride.png';
import avatarHusband from '../../../../../../assets/main/guest/husband.png';

import {NewlywedsData} from "../../GuestNewlyweds";

export const newlywedsDataBride: NewlywedsData[] = [
    {
        index: 1,
        title: 'Od Panny Młodej',
        avatar:avatarBride,
        newlywedsArray: [
            {
                id: v1(),
                number: 1,
                name: "Janusz Biernacka",
                iconInvited: True,
                iconConfirmed: False,
                iconChurch: False,
                iconRestaurant: False,
                iconAccommodation: False,
                iconTransport: False,
                iconDiet: False,
            },
            {
                id: v1(),
                number: 2,
                name: "Idalia Biernacka",
                iconInvited: True,
                iconConfirmed: True,
                iconChurch: True,
                iconRestaurant: True,
                iconAccommodation: True,
                iconTransport: False,
                iconDiet: False,
            },
            {
                id: v1(),
                number: 3,
                name: "Lena Biernacka",
                iconInvited: True,
                iconConfirmed: True,
                iconChurch: True,
                iconRestaurant: True,
                iconAccommodation: True,
                iconTransport: False,
                iconDiet: False,
            },
        ],
    },
];


export const newlywedsDataHusband: NewlywedsData[] = [
    {
        index: 2,
        title: "Od Panny Młodej",
        avatar:avatarHusband,
        newlywedsArray: [
            {
                id: "1",
                number: 1,
                name: "Janusz Biernacka",
                iconInvited: True,
                iconConfirmed: False,
                iconChurch: False,
                iconRestaurant: False,
                iconAccommodation: False,
                iconTransport: False,
                iconDiet: False,
            },
            {
                id: "2",
                number: 2,
                name: "Idalia Biernacka",
                iconInvited: True,
                iconConfirmed: True,
                iconChurch: True,
                iconRestaurant: True,
                iconAccommodation: True,
                iconTransport: False,
                iconDiet: False,
            },
            {
                id: "3",
                number: 3,
                name: "Lena Biernacka",
                iconInvited: True,
                iconConfirmed: True,
                iconChurch: True,
                iconRestaurant: True,
                iconAccommodation: True,
                iconTransport: False,
                iconDiet: False,
            },
        ],
    },
];





// import {v1} from "uuid";
// import {ReactComponent as True} from '../../../../../../assets/main/guest/check-true.svg';
// import {ReactComponent as False} from '../../../../../../assets/main/guest/check-false.svg';
// import avatarBride from '../../../../../../assets/main/guest/bride.png';
// import avatarHusband from '../../../../../../assets/main/guest/husband.png';
//
// import {NewlywedsData} from "../../GuestNewlyweds";
//
// export const newlywedsDataBride: NewlywedsData[] = [
//     {
//         index: 1,
//         title: 'Od Panny Młodej',
//         avatar:avatarBride,
//         newlywedsArray: [
//             {
//                 id: v1(),
//                 number: 1,
//                 name: "Janusz Biernacka",
//                 iconInvited: True,
//                 iconConfirmed: False,
//                 iconChurch: False,
//                 iconRestaurant: False,
//                 iconAccommodation: False,
//                 iconTransport: False,
//                 iconDiet: False,
//             },
//             {
//                 id: v1(),
//                 number: 2,
//                 name: "Idalia Biernacka",
//                 iconInvited: True,
//                 iconConfirmed: True,
//                 iconChurch: True,
//                 iconRestaurant: True,
//                 iconAccommodation: True,
//                 iconTransport: False,
//                 iconDiet: False,
//             },
//             {
//                 id: v1(),
//                 number: 3,
//                 name: "Lena Biernacka",
//                 iconInvited: True,
//                 iconConfirmed: True,
//                 iconChurch: True,
//                 iconRestaurant: True,
//                 iconAccommodation: True,
//                 iconTransport: False,
//                 iconDiet: False,
//             },
//         ],
//         newlywedsArrayTwo: [
//             {
//                 id: v1(),
//                 number: 1,
//                 name: "Adam Dąbrowski",
//                 iconInvited: True,
//                 iconConfirmed: False,
//                 iconChurch: False,
//                 iconRestaurant: False,
//                 iconAccommodation: False,
//                 iconTransport: False,
//                 iconDiet: False,
//             },
//             {
//                 id: v1(),
//                 number: 2,
//                 name: "Seweryna Dąbrowski",
//                 iconInvited: True,
//                 iconConfirmed: True,
//                 iconChurch: True,
//                 iconRestaurant: True,
//                 iconAccommodation: True,
//                 iconTransport: False,
//                 iconDiet: False,
//             },
//             {
//                 id: v1(),
//                 number: 3,
//                 name: "Patrycja Dąbrowski",
//                 iconInvited: True,
//                 iconConfirmed: True,
//                 iconChurch: True,
//                 iconRestaurant: True,
//                 iconAccommodation: True,
//                 iconTransport: False,
//                 iconDiet: False,
//             },
//         ],
//     },
// ];
//
// export const newlywedsDataHusband: NewlywedsData[] = [
//     {
//         index: 2,
//         title: "Od Panny Młodej",
//         avatar:avatarHusband,
//         newlywedsArray: [
//             {
//                 id: "1",
//                 number: 1,
//                 name: "Janusz Biernacka",
//                 iconInvited: True,
//                 iconConfirmed: False,
//                 iconChurch: False,
//                 iconRestaurant: False,
//                 iconAccommodation: False,
//                 iconTransport: False,
//                 iconDiet: False,
//             },
//             {
//                 id: "2",
//                 number: 2,
//                 name: "Idalia Biernacka",
//                 iconInvited: True,
//                 iconConfirmed: True,
//                 iconChurch: True,
//                 iconRestaurant: True,
//                 iconAccommodation: True,
//                 iconTransport: False,
//                 iconDiet: False,
//             },
//             {
//                 id: "3",
//                 number: 3,
//                 name: "Lena Biernacka",
//                 iconInvited: True,
//                 iconConfirmed: True,
//                 iconChurch: True,
//                 iconRestaurant: True,
//                 iconAccommodation: True,
//                 iconTransport: False,
//                 iconDiet: False,
//             },
//         ],
//         newlywedsArrayTwo: [
//             {
//                 id: v1(),
//                 number: 1,
//                 name: "Adam Englert ",
//                 iconInvited: True,
//                 iconConfirmed: False,
//                 iconChurch: False,
//                 iconRestaurant: False,
//                 iconAccommodation: False,
//                 iconTransport: False,
//                 iconDiet: False,
//             },
//             {
//                 id: v1(),
//                 number: 2,
//                 name: "Marcin Kryński ",
//                 iconInvited: True,
//                 iconConfirmed: True,
//                 iconChurch: True,
//                 iconRestaurant: True,
//                 iconAccommodation: True,
//                 iconTransport: False,
//                 iconDiet: False,
//             },
//             {
//                 id: v1(),
//                 number: 3,
//                 name: "Sylwia Stępień",
//                 iconInvited: True,
//                 iconConfirmed: True,
//                 iconChurch: True,
//                 iconRestaurant: True,
//                 iconAccommodation: True,
//                 iconTransport: False,
//                 iconDiet: False,
//             },
//         ],
//     },
// ];
//
//
