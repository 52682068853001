import React, {FC} from 'react';
import {Form, Formik} from 'formik';

import {CloseButton, StyledTitle} from "../plan-table-group-popup/style-plan-tab-popup";

import {ReactComponent as Checkbox} from '../../../../../../../../../assets/main/form/checkbox.svg';
import {ReactComponent as СrossIcon} from '../../../../../../../../../assets/main/wedding/close.svg';
import {
    FormBlock,
    FormContainer,
    FormWrapper,
    StyledInput,
    StyledLabel
} from "../../../../../../../wedding/usersForm/style-usersform";
import {ButtonPlanBlock, ButtonPlanSubmit} from "../../../../../../plan-tab/style-plan-tab";



interface MyFormValues {
    id: string,
    numberOfTables: string,
    numberOfSeats: string,
}

interface PropsType {
    handleClosePopup: () => void;
}


export const FormTableGroup: FC<PropsType> = ({handleClosePopup}) => {


        const handleSubmit = () => {
            // id: uuidv1(),
            // numberOfTables: '',
            // numberOfSeats: '',
        };


        return (
            <Formik
                initialValues={{
                    id: '',
                    numberOfTables: '',
                    numberOfSeats: '',
                }}
                enableReinitialize={true}
                onSubmit={handleSubmit}
            >
                {({values, handleSubmit, handleChange, errors}) => (
                    <FormWrapper onClick={(e) => e.stopPropagation()}>
                        <Form onSubmit={handleSubmit}>
                            <CloseButton type="button" onClick={handleClosePopup}>
                                <СrossIcon/>
                            </CloseButton>
                            <StyledTitle>Dodaj grupę stołów</StyledTitle>
                            <FormContainer>
                                <FormBlock>
                                    <StyledLabel htmlFor="numberOfTables">Liczba stołów</StyledLabel>
                                    <StyledInput
                                        type="text"
                                        name="numberOfTables"
                                        value={values.numberOfTables}
                                        onChange={handleChange}
                                    />
                                </FormBlock>
                                <FormBlock>
                                    <StyledLabel htmlFor="numberOfSeats">Ilość miejsc</StyledLabel>
                                    <StyledInput
                                        type="text"
                                        name="numberOfSeats"
                                        value={values.numberOfSeats}
                                        onChange={handleChange}
                                    />
                                </FormBlock>
                                <ButtonPlanBlock>
                                    <ButtonPlanSubmit
                                        type="submit"
                                        disabled={Object.keys(errors).length !== 0}
                                    >
                                        <Checkbox/>
                                        <span>Zapisz</span>
                                    </ButtonPlanSubmit>
                                </ButtonPlanBlock>
                            </FormContainer>
                        </Form>
                    </FormWrapper>
                )}
            </Formik>
        );
    }
;
