import React from 'react';
import {
    SeatingPlanButton,
    SeatingPlanIcon,
    SeatingPlanSubTitle,
    SeatingPlanTitle,
    WebsiteSeatingPlanBlock,
    WebsiteSeatingPlanWrap
} from "./style-website-seating-plan";
import {CalendarText} from "../../../../calendar-Info/styles";

import {ReactComponent as LeftPlanIcon} from '../../../../../assets/main/website/left-plan.svg';
import {Container} from "../website-attendance/style-website-attendance";
import {IWebsite} from "../types";
import {DownloadIcons} from "../website-collection/style-website-collection";
import {ReactComponent as ReserveIcon} from '../../../../../assets/main/website/reserve.svg'

export const WebsiteSeatingPlan = ({backgroundColor,textColor}: IWebsite) => {
    return (
        <WebsiteSeatingPlanWrap id="planMiejsc">
            <Container>
                <SeatingPlanIcon>
                    <LeftPlanIcon/>
                </SeatingPlanIcon>
                <WebsiteSeatingPlanBlock>
                    <SeatingPlanTitle>Plan miejsc dla gości</SeatingPlanTitle>
                    <SeatingPlanSubTitle>
                        Z wyprzedzeniem można zobaczyć plan miejsc dla naszych gości weselnych.
                    </SeatingPlanSubTitle>
                    <SeatingPlanButton style={{background: textColor}}>
                        <ReserveIcon/>
                        <CalendarText>
                            Zobacz plan stołu
                        </CalendarText>
                    </SeatingPlanButton>
                </WebsiteSeatingPlanBlock>
            </Container>
        </WebsiteSeatingPlanWrap>
    );
};

