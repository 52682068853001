import styled from "styled-components";

export const SpoilerWrapper = styled.div`
  width: 374px;
  margin-bottom: 1rem;
  position: relative;
  @media (max-width: 1440.98px) {
    width: 100%;
  }
`;

export const SpoilerHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SpoilerIcon = styled.span`
  margin-right: 0.5rem;
`;

export const SpoilerIconWrap = styled.div<{ isActive: boolean }>`
  width: 374px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  background: #f5f9ff;
  padding: 12px 15px;
  opacity: ${({isActive}) => (isActive ? '0.6' : '1')};
  border-radius: 5px;

  @media (max-width: 1440.98px) {
    width: calc(100vw - 270px);
    max-width: 100%;
  }

  @media (max-width: 1000.98px) {
    width: calc(100vw - 30px);
    max-width: 100%;
  }

  h3 {
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
`;

export const SpoilerContent = styled.div<{ isVisible: boolean }>`
  width: 374px;
  background: #ffffff;
  display: ${({isVisible}) => (isVisible ? 'block' : 'none')};
  animation: ${({isVisible}) => (isVisible ? 'fadeIn' : 'fadeOut')} 0.3s ease-in-out;
  //position: absolute;
  //top: 100%;
  //left: 0;
  //z-index: 10;

  @media (max-width: 1440.98px) {
    width: calc(100vw - 270px);
    max-width: 100%;
  }

  @media (max-width: 1000.98px) {
    width: calc(100vw - 30px);
    max-width: 100%;
  }


  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
`;

export const SpoilerItemNameBody = styled.div`
  width: 170px;
  display: flex;
  align-items: center;
  gap: 20px;
  text-align: center;

  @media (max-width: 360.98px) {
    gap: 8px;
  }

  span {
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #0c1215;
  }
`;

export const SpoilerItemR = styled.div`
  display: flex;
  //justify-content: space-between;
  gap: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: rgba(12, 18, 21, 0.4);
`;

export const SpoilerItemName = styled.div`
  width: 170px;
  display: flex;
  align-items: center;
  gap: 20px;
  text-align: center;
  font-family: 'Gilroy', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #0c1215;
  justify-content: flex-start;
`;


export const SpoilerItemConfirmed = styled.div`
  width: 73px;
  text-align: center;
`;

export const SpoilerItemLocation = styled.div`
  width: 42px;
  text-align: center;
`;


export const SpoilersItem = styled.div`
  display: flex;
  //justify-content: space-between;
  gap: 10px;
`;