import styled from "styled-components";


export const PlanTablesWrap = styled.div`

`;

export const PlanTablesTitle = styled.p`
  font-family: 'Gilroy', sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #051F41;
  margin-bottom: 25px;
`;


export const PlanTablesContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 30px;
  @media (max-width: 636.98px) {
    flex-wrap: wrap;
  }

`;


export const Table = styled.div`
  width: 246px;
  @media (max-width: 538.98px) {
   width: 100%;
  }
`;

export const TableTitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  background-color: #F5F9FF;
  padding: 14px 15px;
  border-radius: 5px;
`;


export const TableTitle = styled.p`
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #0C1215;
`;

export const TableContent = styled.div`
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0 30px 0;
`;

export const TableRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-family: 'Gilroy', sans-serif;
  color: #0C1215;
  padding: 0 15px;
`;

export const TableNumber = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
`;

export const TableName = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

export const PlanButtonBlock = styled.div`
  font-family: 'Roboto',sans-serif;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 440px;

  @media (max-width: 463.98px) {
    width: 100%;
    flex-direction: column;
    gap: 15px;
  }

`;


