import styled from "styled-components";

// export const AdsWrap = styled.section`
//
// `
export const AdsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const AdsButton = styled.div`
margin-bottom: 100px;
`


