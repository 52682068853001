import React from 'react';
import {GuestPrintWrap, InfoBlock, InfoContentBlock, PrintBlock, PrintContentBlock} from "./style-guest-print";
import {ReactComponent as PrinterIcon} from '../../../../assets/main/guest/printer.svg';
import {ReactComponent as DownloadIcon} from '../../../../assets/main/guest/download.svg';
import {ReactComponent as ExpandIcon} from '../../../../assets/main/guest/expand_more.svg';
import {ReactComponent as EnvelopeIcon} from '../../../../assets/main/guest/envelope.svg';

import {Link} from "react-router-dom";


export const GuestPrint = () => {
    return (
        <GuestPrintWrap>
            <PrintBlock>
                <PrintContentBlock><PrinterIcon/><span>Drukuj</span></PrintContentBlock>
                <PrintContentBlock><DownloadIcon/><span>Pobierz PDF</span></PrintContentBlock>
            </PrintBlock>
            <InfoBlock>
                <Link to="/sharing">
                    <InfoContentBlock>
                        <EnvelopeIcon style={{marginRight:"4px"}}/>
                        <span>Udostępniaj informacje gościom</span>
                        <ExpandIcon/>
                    </InfoContentBlock>
                </Link>
            </InfoBlock>
        </GuestPrintWrap>
    );
};
