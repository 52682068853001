import styled from "styled-components";

export const ImageBlock = styled.div`
  display: flex;
  gap: 10px;
  max-width: 550px;
  flex-wrap: wrap;
  overflow: auto;
  max-height: 780px;
`

export const ImageItems = styled.div`
  position: relative;
  width: 250px;
  overflow: hidden;

  img {
    width: 100%;
    display: block;
    transition: opacity 0.3s;
    height: 250px;
    object-fit: cover;
  }

  /* Add a semi-transparent overlay */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Adjust the opacity as needed */
    opacity: 0; /* Initially, the overlay is transparent */
    transition: opacity 0.3s; /* Add a smooth transition for the overlay */
  }

  &:hover {
    .delete-icon {
      display: block;
    }

    /* Darken the overlay on hover */
    &::before {
      opacity: 1;
    }
  }

  .delete-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
    cursor: pointer;
    z-index: 1; /* Ensure the delete icon is above the overlay */
  }
`;