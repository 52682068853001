import React, {useState} from 'react';
import {v1} from "uuid";
import Avatar from "../../../../../assets/main/weddingqa/avatar.png";
import Avatar2 from "../../../../../assets/main/weddingqa/avatar2.png";
import Avatar3 from "../../../../../assets/main/weddingqa/avatar2.png";
import Avatar4 from "../../../../../assets/main/weddingqa/avatar4.png";
import Avatar5 from "../../../../../assets/main/weddingqa/avatar5.png";
import {
    ListCategory,
    ListComment,
    ListContainer,
    ListDate,
    ListReply,
    ListText,
    ListTitle,
    ListUser
} from "../../questions/list-questions/style-list-questions";
import {AnswersQuestionsContent, AnswersQuestionsWrap,} from "./style-answers-questions";
import {ShowMore, ShowMoreBlock} from "../../../budget/butget-expense/style-budgetExpense";
import {ReactComponent as ArrayUp} from '../../../../../assets/main/budget/arrayUp.svg';
import {ReactComponent as ArrayDown} from '../../../../../assets/main/budget/arrowDown.svg';
import {ColorShowMoreButton, ShowMoreButton} from "../answer-latest/style-answer-latest";
import {AnswerLatestShow} from "../answer-latest/answer-latest-show/AnswerLatestShow";

const answers = [
    {
        id: v1(),
        title: 'Wasz pomysł na rozsadzenie gości na weselu?',
        number: '24',
        date: '13.12.2022',
        categories: 'Przygotowania',
        reply: 'Odpowiedz',
        latest: 'Najnowsza odpowiedź',
        text: 'Nikt ponieważ wiedzielismy że rzadko bedziemy tam siedzieć więc nie chcieliśmy żeby nasi świadkowie zostawali sami i żeby siedzieli ze swoimi znajomymi.',
        avatar: Avatar,
        name: 'Aneta',
        dateUser: '01.03.2022',
    },
]

const questions = [
    {
        id: v1(),
        text: 'Do większości będziemy jechać osobiście, ale mamy pare osób z zagranicy więc do nich wyślemy zaproszenia ale również obowiązkowo.',
        avatar: Avatar2,
        name: 'Magda',
        dateUser: '25.02.2022',
    },
    {
        id: v1(),
        text: 'Nikt ponieważ wiedzielismy że rzadko bedziemy tam siedzieć więc nie chcieliśmy żeby nasi świadkowie zostawali sami i żeby siedzieli ze swoimi znajomymi.',
        avatar: Avatar3,
        name: 'Justyna',
        dateUser: '28.02.2022',
    },
    {
        id: v1(),
        text: 'Nikt ponieważ wiedzielismy że rzadko bedziemy tam siedzieć więc nie chcieliśmy żeby nasi świadkowie zostawali sami i żeby siedzieli ze swoimi znajomymi.',
        avatar: Avatar4,
        name: 'Justyna',
        dateUser: '28.02.2022',
    },
    {
        id: v1(),
        text: 'Nikt ponieważ wiedzielismy że rzadko bedziemy tam siedzieć więc nie chcieliśmy żeby nasi świadkowie zostawali sami i żeby siedzieli ze swoimi znajomymi.',
        avatar: Avatar5,
        name: 'Piotr',
        dateUser: '10.02.2022',
    },
]

export const AnswersQuestions = () => {
    const [showMore, setShowMore] = useState(false);
    const [showInput, setShowInput] = useState(false);


    const toggleShowMore = () => {
        setShowMore((prevState) => !prevState);
    };

    const toggleShowInput = () => {
        setShowInput((prevState) => !prevState);
    };

    const visibleQuestions = showMore ? questions : questions.slice(0, 1);

    const closeAnswerLatestShow = () => {
        setShowInput(false);
    };

    return (
        <AnswersQuestionsWrap>
            {answers.map((el) => (
                <ListContainer key={el.id}>
                    <ListTitle>
                        <p>{el.title}</p>
                        <span>{el.number}</span>
                    </ListTitle>
                    <ListCategory>
                        <p>{el.date}</p>
                        <span>{el.categories}</span>
                        <ListReply onClick={toggleShowInput}>{el.reply}</ListReply>
                    </ListCategory>
                    {showInput && <AnswerLatestShow onClose={closeAnswerLatestShow} />}
                    <ListComment>
                        <span>{el.latest}</span>
                    </ListComment>
                    <ListText>{el.text}</ListText>
                    <ListUser>
                        <img src={el.avatar} alt="avatar" />
                        <ListDate>
                            <p>{el.name}</p>
                            <span></span>
                            <p>{el.dateUser}</p>
                        </ListDate>
                    </ListUser>
                </ListContainer>
            ))}
            <AnswersQuestionsContent>
                {visibleQuestions.map((el) => {
                    return (
                        <ListContainer key={el.id}>
                            <ListText>{el.text}</ListText>
                            <ListUser>
                                <img src={el.avatar} alt="avatar" />
                                <ListDate>
                                    <p>{el.name}</p>
                                    <span></span>
                                    <p>{el.dateUser}</p>
                                </ListDate>
                            </ListUser>
                        </ListContainer>
                    );
                })}
                {questions.length > 1 && (
                    <ColorShowMoreButton>
                        <ShowMoreButton>
                            <ShowMore onClick={toggleShowMore}>
                                {showMore ? (
                                    <ShowMoreBlock>
                                        <span>Zwiń</span>
                                        <span>
                                        {' '}
                                            <ArrayDown />
                                    </span>
                                    </ShowMoreBlock>
                                ) : (
                                    <ShowMoreBlock>
                                        <span>Zobacz wszystkie</span>
                                        <span>
                                        {' '}
                                            <ArrayUp />
                                    </span>
                                    </ShowMoreBlock>
                                )}
                            </ShowMore>
                        </ShowMoreButton>

                    </ColorShowMoreButton>
                )}
            </AnswersQuestionsContent>
        </AnswersQuestionsWrap>
    );
};
