import React, {useState} from 'react';
import {v1} from "uuid";
import {FormBlock, StyledLabel, StyledTextArea} from "../../wedding/usersForm/style-usersform";
import {Form, Formik} from "formik";
import {QuestionSelect} from "../../weddingqa/answers/answer-question/question-select/QuestionSelect";
import {
    optionsColor,
    optionsDelivery,
    optionsHell,
    optionsLocation,
    optionsPrice,
    optionsStatus,
    optionsType
} from "./options";
import {
    AddInput,
    AdNewRow,
    AdsAnswerQuestionBlock,
    AdsButtonSubmit, AdsButtonSubmitApproval,
    AdsFileContainer, AdsFileItem,
    AdsFormBlock,
    AdsFormWrapper,
    AdsNumberWrap,
    AdsPlaceButtonBlock,
    AdsPlaceFormContainer,
    AdsPlaceListContainer,
    AdsPlaceWrap,
    AdsRow,
    AdsStyledInput,
    AdsUploadFormBlock,
    FileIconContainer, FileTitle,
    FileUploadIcon,
    FileUploadText,
    StyledAdsPlusTitle,
    StyledFileInputContainer
} from "./style-ads-place";
import {ColorSelect} from "./сolor-select/ColorSelect";
import {StyledAdsTitle} from "../../wedding/popUp/style-popups";
import {ReactComponent as CameraIcon} from '../../../../assets/main/adsplace/camera.svg';


interface MyFormValues {
    id: string;
    profilePhoto: File | null,
    photoGallery: File | null,
    nameProduct: string;
    selectType: string,
    location: string,
    selectStatus: string,
    selectDelivery: string,
    price: string,
    selectColor: string,
    selectHeel: string,
    name: string,
    email: string,
    tel: string[];
}


export const AdsPlace = () => {
    const [phoneNumbers, setPhoneNumbers] = useState(['']);


    const handleAddPhone = () => {
        if (phoneNumbers.length < 4) {
            setPhoneNumbers(prevPhoneNumbers => [...prevPhoneNumbers, ""]);
        }
    };

    // const StyledFileInput = ({ onChange }) => {
    //     const handleFileChange = (event) => {
    //         const file = event.currentTarget.files?.[0];
    //         if (onChange) {
    //             onChange(file);
    //         }
    //     };


    const handleSubmit = (values: MyFormValues) => {
        // dispatch(addGroup({ newlywedsId: newlyweds[index].id, values}));
    };

    return (
        <AdsPlaceWrap>
            <Formik
                initialValues={{
                    id: v1(),
                    profilePhoto: null,
                    photoGallery: null,
                    nameProduct: '',
                    selectType: '',
                    location: "",
                    selectStatus: '',
                    selectDelivery: '',
                    price: '',
                    selectColor: '',
                    selectHeel: '',
                    name: '',
                    email: '',
                    tel: phoneNumbers,
                }}
                enableReinitialize={true}
                onSubmit={handleSubmit}
            >
                {({values, handleSubmit, handleChange, setFieldValue, errors}) => (
                    <AdsFormWrapper onClick={(e) => e.stopPropagation()}>
                        <Form onSubmit={handleSubmit}>
                            <StyledAdsPlusTitle>Umieść ogłoszenie</StyledAdsPlusTitle>
                            <AdsPlaceFormContainer>
                                <AdsFileContainer>
                                    <AdsFileItem>
                                        <FileTitle>Zdjęcie profilowe</FileTitle>
                                        <AdsUploadFormBlock>
                                            <StyledFileInputContainer>
                                                <CameraIcon/>
                                                <FileIconContainer>
                                                    <FileUploadIcon>+</FileUploadIcon>
                                                    <FileUploadText>Dodaj zdjęcie</FileUploadText>
                                                </FileIconContainer>
                                                <input
                                                    id="profilePhoto"
                                                    name="profilePhoto"
                                                    type="file"
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue('profilePhoto', event.currentTarget.files?.[0] || null);
                                                    }}
                                                    value={undefined}
                                                    accept="image/*"
                                                />
                                            </StyledFileInputContainer>
                                        </AdsUploadFormBlock>
                                    </AdsFileItem>
                                    <AdsFileItem>
                                        <FileTitle>Galeria zdjęć</FileTitle>
                                        <AdsUploadFormBlock>
                                            <StyledFileInputContainer>
                                                <CameraIcon/>
                                                <FileIconContainer>
                                                    <FileUploadIcon>+</FileUploadIcon>
                                                    <FileUploadText>Dodaj zdjęcie</FileUploadText>
                                                </FileIconContainer>
                                                <input
                                                    id="photoGallery"
                                                    name="photoGallery"
                                                    type="file"
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue('photoGallery', event.currentTarget.files?.[0] || null);
                                                    }}
                                                    value={undefined}
                                                    accept="image/*"
                                                />
                                            </StyledFileInputContainer>
                                        </AdsUploadFormBlock>
                                    </AdsFileItem>
                                </AdsFileContainer>
                                <AdsPlaceListContainer>
                                    <AdsAnswerQuestionBlock>
                                        <AdsFormBlock>
                                            <StyledLabel htmlFor="nameProduct">Nazwa produktu/usługi</StyledLabel>
                                            <AdsStyledInput
                                                type='text'
                                                name="nameProduct"
                                                value={values.nameProduct}
                                                onChange={handleChange}
                                            />
                                        </AdsFormBlock>
                                        <AdsFormBlock>
                                            <StyledLabel htmlFor="selectType">Wybór kategorii</StyledLabel>
                                            <QuestionSelect name="selectType" options={optionsType}/>
                                        </AdsFormBlock>
                                    </AdsAnswerQuestionBlock>
                                    <AdsAnswerQuestionBlock>
                                        <AdsFormBlock>
                                            <StyledLabel htmlFor="location">Lokalizacja towarów/usług</StyledLabel>
                                            <AdsStyledInput
                                                type='text'
                                                name="location"
                                                value={values.location}
                                                onChange={handleChange}
                                            />
                                            {/*<QuestionSelect name="selectLocation" options={optionsLocation}/>*/}
                                        </AdsFormBlock>
                                        <AdsFormBlock>
                                            <StyledLabel htmlFor="selectStatus">Stan</StyledLabel>
                                            <QuestionSelect name="selectStatus" options={optionsStatus}/>
                                        </AdsFormBlock>
                                    </AdsAnswerQuestionBlock>

                                    <AdsAnswerQuestionBlock>
                                        <AdsFormBlock>
                                            <StyledLabel htmlFor="selectDelivery">Dostawa</StyledLabel>
                                            <QuestionSelect name="selectDelivery" options={optionsDelivery}/>
                                        </AdsFormBlock>
                                        <AdsFormBlock>
                                            <StyledLabel htmlFor="price">Cena, zł</StyledLabel>
                                            <AdsStyledInput
                                                type='text'
                                                name="price"
                                                value={values.price}
                                                onChange={handleChange}
                                            />
                                            {/*<QuestionSelect name="selectPrice" options={optionsPrice}/>*/}
                                        </AdsFormBlock>
                                    </AdsAnswerQuestionBlock>

                                    <AdsAnswerQuestionBlock>
                                        <AdsFormBlock>
                                            <StyledLabel htmlFor="selectColor">Kolor</StyledLabel>
                                            <ColorSelect options={optionsColor}/>
                                        </AdsFormBlock>
                                        <AdsFormBlock>
                                            <StyledLabel htmlFor="selectHeel">Rodzaj obcasa</StyledLabel>
                                            <QuestionSelect name="selectHeel" options={optionsHell}/>
                                        </AdsFormBlock>
                                    </AdsAnswerQuestionBlock>
                                    <FormBlock>
                                        <StyledLabel htmlFor="content">Opis</StyledLabel>
                                        <StyledTextArea
                                            style={{width: '100%'}}
                                            id="content"
                                            name="content"
                                            autoSize={{minRows: 3.5, maxRows: 10}}
                                        />
                                    </FormBlock>


                                    <StyledAdsTitle>O sprzedawcy</StyledAdsTitle>
                                    <AdsAnswerQuestionBlock>
                                        <AdsFormBlock>
                                            <StyledLabel htmlFor="subject">Imię</StyledLabel>
                                            <AdsStyledInput
                                                type='text'
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                            />
                                        </AdsFormBlock>
                                        <AdsFormBlock>
                                            <StyledLabel htmlFor="email">E-mail</StyledLabel>
                                            <AdsStyledInput
                                                type='email'
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                            />
                                        </AdsFormBlock>
                                    </AdsAnswerQuestionBlock>
                                    <AdsNumberWrap>
                                        <AdsRow>
                                            {phoneNumbers.slice(0, 2).map((phoneNumber, index) => (
                                                <AdsFormBlock key={index}>
                                                    <StyledLabel htmlFor={`tel[${index}]`}>Telefon</StyledLabel>
                                                    <AdsStyledInput
                                                        type='tel'
                                                        name={`tel[${index}]`}
                                                        value={values.tel[index]}
                                                        onChange={handleChange}
                                                    />
                                                </AdsFormBlock>
                                            ))}
                                        </AdsRow>

                                        <AdNewRow>
                                            {phoneNumbers.slice(2).map((phoneNumber, index) => (
                                                <AdsFormBlock key={index}>
                                                    <StyledLabel htmlFor={`tel[${index + 2}]`}>Telefon</StyledLabel>
                                                    <AdsStyledInput
                                                        type='tel'
                                                        name={`tel[${index + 2}]`}
                                                        value={values.tel[index + 2]}
                                                        onChange={handleChange}
                                                    />
                                                </AdsFormBlock>

                                            ))}
                                        </AdNewRow>
                                    </AdsNumberWrap>
                                </AdsPlaceListContainer>

                                <AddInput onClick={handleAddPhone}
                                          style={{display: phoneNumbers.length === 4 ? 'none' : 'block'}}>
                                    + Dodaj telefon
                                </AddInput>
                                <AdsPlaceListContainer>
                                    <AdsPlaceButtonBlock>
                                        {/*<AdsButtonSubmitApproval*/}
                                        {/*    type="submit"*/}
                                        {/*    disabled={Object.keys(errors).length !== 0}*/}
                                        {/*>*/}
                                        {/*    <span>Przedłożyć do zatwierdzenia</span>*/}
                                        {/*</AdsButtonSubmitApproval>*/}
                                        <AdsButtonSubmit
                                            type="submit"
                                            disabled={Object.keys(errors).length !== 0}
                                        >
                                            <span>Umieść ogłoszenie</span>
                                        </AdsButtonSubmit>
                                    </AdsPlaceButtonBlock>
                                </AdsPlaceListContainer>
                            </AdsPlaceFormContainer>
                        </Form>
                    </AdsFormWrapper>
                )}
            </Formik>
        </AdsPlaceWrap>
    );
};
