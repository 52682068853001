import styled from "styled-components";


interface WeddingProps {
    show: boolean;
}

export const Popup = styled.div<WeddingProps>`
  display: ${(props) => (props.show ? "flex" : "none")};
  width: 100%;
  background: rgba(12, 18, 21, 0.4);
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  height: 100vh;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  cursor: auto;
`;

export const FormPresentationWrap = styled.form`
    display: flex;
    flex-direction: column;
    //gap: 80px;
    //
    //@media (max-width: 768.98px) {
    //    gap: 60px;
    //}

`;
export const CloseButton = styled.button`
  position: absolute;
  top: -4px;
  right: -38px;
  @media (max-width: 770.98px) {
    top: -2px;
    right: -24px;
  }
  @media screen and (max-width: 425.98px) {
    top: -2px;
    right: 0;
  }
`;

export const StyledTitle = styled.p`
  color:  #0C1215;
  font-family: Gilroy,sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.22px;
  
  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
    padding: 0 15px;
  }
`;


export const StyledAdsTitle = styled.p`
  color:  #0C1215;
  font-family: Gilroy,sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.22px;
  padding-top: 60px;
  margin-bottom: 30px;
  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
    padding: 0 15px;
  }
`;

export const StyleSubTitle = styled.p`
  font-family: 'Gilroy',serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #0C1215;
  opacity: 0.5;
  padding-top: 15px;
`;

export const StyledTitleAbout = styled.p`
  flex:1 1 auto;
  font-family: 'Gilroy', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #0C1215;
`;

export const StyledFavoriteTitle = styled.p`
  color: #0C1215;
  font-family: Gilroy,sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.22px;
  margin-bottom: 15px;
  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
    padding: 0 15px;
  }
`;
