import React from 'react';
import {FavoriteWrap} from "./style-favorite";
import {FavoriteContainer} from "./favorite-container/FavoriteContainer";

export const Favorite = () => {
    return (
        <FavoriteWrap>
            <FavoriteContainer/>
        </FavoriteWrap>
    );
};
