import React from 'react';
import {
    SharingArrayBlock, SharingArrayLeftBlock,
    SharingButtonBlock,
    SharingButtonSubmit,
    SharingInformationWrap,
    SharingLabel,
    SharingLinkBlock
} from "./style-sharing-information";
import {v1} from "uuid";
import {
    CalendarButton,
    FormBlock,
    FormContainer,
    StyledArea,
    StyledInput,
    StyledLabel
} from "../../wedding/usersForm/style-usersform";
import {Form, Formik} from "formik";
import {StyledTitle} from "../../../popups/style-popups";
import {ReactComponent as LinkIcon} from '../../../../assets/main/wedding/link.svg'
import {AdsFormWrapper} from "../../ads/ads-place/style-ads-place";
import {SharingPopup} from "./sharing-pop-up/SharingPopup";
import usePopup from "../../../../common/hook/use-popup";
import {Link} from "react-router-dom";
import {ReactComponent as ArrayLeftIcon} from '../../../../assets/main/guest/array-left.svg';


interface MyFormValues {
    id: string;
    subject: string;
    message: string;
    link: string;
}


export const SharingInformation = () => {
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();


    const handleSubmit = (values: MyFormValues) => {
        // dispatch(addGroup({ newlywedsId: newlyweds[index].id, values}));
    };


    return (
        <SharingInformationWrap>
            <Formik
                initialValues={{
                    id: v1(),
                    subject: '',
                    message: '',
                    link: '',
                }}
                enableReinitialize={true}
                onSubmit={handleSubmit}
            >
                {({values, handleSubmit, handleChange, errors}) => (
                    <AdsFormWrapper onClick={(e) => e.stopPropagation()}>
                        <Form onSubmit={handleSubmit}>
                            <StyledTitle>Informacje kontaktowe</StyledTitle>
                            <FormContainer>
                                <FormBlock>
                                    <StyledLabel htmlFor="subject">Temat</StyledLabel>
                                    <StyledInput
                                        type='text'
                                        name="subject"
                                        value={values.subject}
                                        onChange={handleChange}
                                    />
                                </FormBlock>
                                <FormBlock>
                                    <StyledLabel htmlFor="message">Wiadomość</StyledLabel>
                                    <StyledArea
                                        name="message"
                                        value={values.message}
                                        onChange={handleChange}
                                    />
                                </FormBlock>
                                <SharingLinkBlock >
                                <StyledLabel htmlFor="wendingDate">Link dla gości</StyledLabel>
                                <CalendarButton >
                                    <LinkIcon style={{opacity: '0.9'}}/>
                                </CalendarButton>
                                <StyledInput type="text" name="link"
                                             value={values.link} onChange={handleChange}
                                             autoComplete="off"/>
                            </SharingLinkBlock>

                                <FormBlock>
                                    <SharingLabel onClick={onClickHandler}>+ Wybierz gości</SharingLabel>
                                    <SharingPopup
                                        show={showPopup}
                                        handleClosePopup={handleClosePopup}
                                        onClickClosest={onClickClosest}
                                    />
                                </FormBlock>
                                <SharingButtonBlock>
                                    <SharingButtonSubmit
                                        type="submit"
                                        disabled={Object.keys(errors).length !== 0}
                                    >
                                        <span>Wysłać zaproszenie</span>
                                    </SharingButtonSubmit>
                                </SharingButtonBlock>
                            </FormContainer>
                        </Form>
                    </AdsFormWrapper>
                )}
            </Formik>
            <SharingArrayBlock>
            <Link to='/guest'>
                <SharingArrayLeftBlock>
                    <ArrayLeftIcon/>
                    <span>Wróć</span>
                </SharingArrayLeftBlock>
            </Link>
            </SharingArrayBlock>
        </SharingInformationWrap>

    );
};

// <FormBlock>
//     <StyledLabel htmlFor="provider">Link dla gości</StyledLabel>
//     <CalendarButton style={{opacity:'0.9'}}>
//         <LinkIcon style={{opacity:'0.9'}}/>
//     </CalendarButton>
//     <StyledInput type="text" name="link"
//                  value={values.link} onChange={handleChange}
//                  autoComplete="off"/>
// </FormBlock>