import React, {ReactNode} from 'react';


import {PopupDark} from "../../../../../../popups/style-popups";
import {Portal} from "../../../../../../../common/portal/Portal";


interface PopupProps {
    show: boolean;
    handleClosePopups: () => void;
    onClickClosest: () => void;
    formComponent: ReactNode;
    textColor?: string;
}

export const PopupBlack = ({ show, onClickClosest, formComponent ,textColor}: PopupProps) => {
    return (
        <Portal>
            <PopupDark show={show} onClick={onClickClosest} >
                {formComponent}
            </PopupDark>
        </Portal>
    );
}

