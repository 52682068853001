import React, {FC, useState} from 'react';
import {Form, Formik} from 'formik';
import {ReactComponent as СrossIcon} from '../../../../../../../../assets/main/wedding/close.svg';
import {ReactComponent as Checkbox} from '../../../../../../../../assets/main/form/checkbox.svg';
import {ReactComponent as CloseIcon} from '../../../../../../../../assets/main/form/close.svg';
import {
    ButtonBlock,
    ButtonCloses,
    ButtonSubmit,
    CalendarButton,
    FormBlock,
    FormContainer,
    FormWrapper,
    StyledInput,
    StyledLabel
} from "../../../../../../wedding/usersForm/style-usersform";
import {CloseButton, StyledTitle} from "../../../../../../wedding/popUp/style-popups";
import {useAppDispatch, useAppSelector} from "../../../../../../../../common/hook";
import {ReactComponent as CalendarIcon} from '../../../../../../../../assets/main/wedding/calendar_24px.svg';
import {CalendarExpense} from "../../../../calendar-expense";
import { Expense, updateExpense} from "../../../../../../../../app/budget-reduser";
import {categorySelector} from "../../../../../../../../app/selectors/category";
import {expenseDate} from "../../../../../../../../app/selectors/expense-date";


interface MyFormValues {
    name: string;
    location: string;
    linkLocation: string;
    totalPrice: string;
    prepaid: string;
    fullPaymentDate: string;
    status: 'unpaid' | 'prepayment' | 'repaid';
}

type PropsType = {
    handleClosePopup: () => void;
    expensesId: string;
};

export const FormEditExpense: FC<PropsType> = ({handleClosePopup, expensesId}) => {
    const dispatch = useAppDispatch();
    const {currentCategoryID} = useAppSelector(categorySelector);
    const {date} = useAppSelector(expenseDate);
    const [isOpen, setIsOpen] = useState(false);


    const expense = useAppSelector(state => {
        const category = state.budget.categories.find(category => category.id === currentCategoryID);
        return category?.expenses?.find(expense => expense.id === expensesId);
    });

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    // Заполняем форму значениями уже существующей статьи
    const initialValues: MyFormValues = {
        name: expense?.name || '',
        location: expense?.location || '',
        linkLocation: expense?.linkLocation || '',
        totalPrice: expense?.totalPrice || '',
        prepaid: expense?.prepaid || '',
        fullPaymentDate: expense?.fullPaymentDate || '',
        status: expense?.status || 'unpaid',
    };

    const handleSubmit = (values: MyFormValues) => {
        const updatedExpense: Expense = {
            ...expense!,
            id: expensesId!,
            name: values.name,
            location: values.location,
            linkLocation: values.linkLocation,
            totalPrice: values.totalPrice,
            prepaid: values.prepaid,
            fullPaymentDate: values.fullPaymentDate,
            status: values.status,
        };


        dispatch(updateExpense({categoryId: currentCategoryID, expenseId: expensesId, expense: updatedExpense}));
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            // validationSchema={schema}
        >
            {({values, handleSubmit, handleChange, errors}) => (
                <FormWrapper onClick={(e) => e.stopPropagation()}>
                    <Form onSubmit={handleSubmit}>
                        <CloseButton type="button" onClick={handleClosePopup}>
                            <СrossIcon/>
                        </CloseButton>
                        <StyledTitle>Edytuj wydatek</StyledTitle>
                        <FormContainer>
                            <FormBlock>
                                <StyledLabel htmlFor='name'>Nazwa</StyledLabel>
                                <StyledInput type='text' name='name' value={values.name}
                                             onChange={handleChange}/>
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor='location'>Nazwa Miejsce/Usługodawca</StyledLabel>
                                <StyledInput type='text' name='location'
                                             value={values.location} onChange={handleChange}/>
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor='linkLocation'>Łącze Miejsce/Usługodawca</StyledLabel>
                                <StyledInput type='text' name='linkLocation'
                                             value={values.linkLocation} onChange={handleChange}/>
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor='totalPrice'>Cena całkowita</StyledLabel>
                                <StyledInput type='text' name='totalPrice'
                                             value={values.totalPrice} onChange={handleChange}/>
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor='prepaid'>Przedpłata</StyledLabel>
                                <StyledInput type='text' name='prepaid' value={values.prepaid}
                                             onChange={handleChange}/>
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor='fullPaymentDate'>Pełna płatność do</StyledLabel>
                                <CalendarButton onClick={togglePopup}>
                                    <CalendarIcon/>
                                </CalendarButton>
                                <StyledInput type='text' name='fullPaymentDate'
                                             value={values.fullPaymentDate} onChange={handleChange}/>
                            </FormBlock>
                            {isOpen && <CalendarExpense/>}
                            <ButtonBlock>
                                <ButtonCloses>
                                    <CloseIcon/>
                                    <span>Anuluj</span>
                                </ButtonCloses>
                                <ButtonSubmit type='submit' disabled={Object.keys(errors).length !== 0}>
                                    <Checkbox/>
                                    <span>Zapisz</span>
                                </ButtonSubmit>
                            </ButtonBlock>
                        </FormContainer>
                    </Form>
                </FormWrapper>
            )}
        </Formik>
    );
};


