import React from 'react';
import {
    CalendarBlock,
    CompletedContainer,
    KillingsNumber,
    KillingsNumberBlock,
    KillingsTitle,
    Number,
    TasksAbout,
    TasksBlock,
    TasksBudget,
    TasksBudgetBlock,
    TasksItems,
    TasksWrapper
} from "./style-tasks";
import {ReactComponent as Completed} from '../../../../assets/main/wedding/completed.svg';
import {ReactComponent as Sitting} from '../../../../assets/main/wedding/sitting.svg';
import completed from "../../../../assets/main/wedding/completed.png";
import seating from "../../../../assets/main/wedding/seating.png";
import CalendarComponent from "../../../layout/Calendar/CalendarComponent";
import {Link} from "react-router-dom";


export const Tasks = () => {


    return (
        <TasksWrapper>
            <TasksBlock>
                <TasksItems>
                    <TasksAbout>
                        <Link to="/tasks">
                            <CompletedContainer imageUrl={completed}>
                                <Completed/>
                                <KillingsNumberBlock>
                                    <KillingsNumber>
                                        <Number>5</Number>
                                        <span>/ 36</span>
                                    </KillingsNumber>
                                    <KillingsTitle>Ukończone zadania</KillingsTitle>
                                </KillingsNumberBlock>
                            </CompletedContainer>
                        </Link>
                        <Link to="/plan">
                            <CompletedContainer imageUrl={seating}>
                                <Sitting/>
                                <KillingsNumberBlock>
                                    <KillingsNumber>
                                        <Number>4</Number>
                                        <span>/ 58</span>
                                    </KillingsNumber>
                                    <KillingsTitle>Usadzanie gości</KillingsTitle>
                                </KillingsNumberBlock>
                            </CompletedContainer>
                        </Link>
                    </TasksAbout>
                    <TasksBudget>
                        <TasksBudgetBlock>
                            <p>Całkowita kwota:</p>
                            <span>10 200 zł</span>
                        </TasksBudgetBlock>
                        <TasksBudgetBlock>
                            <p>Planowana kwota:</p>
                            <span>60 000 zł</span>
                        </TasksBudgetBlock>
                    </TasksBudget>


                </TasksItems>
                <CalendarBlock>
                    <p>Kalendarz zadań</p>
                    <CalendarComponent isShowCalendarInfo={true}/>
                </CalendarBlock>
            </TasksBlock>
        </TasksWrapper>
    );
};

