import {createSlice, PayloadAction} from '@reduxjs/toolkit';


export interface InitialStateType {
    date: string | null,
}

const initialState: InitialStateType = {
    date: null
}


const slice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        setData(state, action: PayloadAction<string>) {
            state.date = action.payload;
        },
    }
})



export const mainReducer = slice.reducer
export const {setData} = slice.actions
