import React from 'react';
import {Popup} from "../../../wedding/popUp/style-popups";
import {FormExpense} from "../butget-expense-form/form-expense/FormExpense";



interface PopupProps {
    show: boolean;
    handleClosePopup: () => void;
    onClickClosest: () => void;
}

export const ExpensePopup = ({show, handleClosePopup, onClickClosest}: PopupProps) => {
    return (
        <Popup show={show} onClick={onClickClosest}>
            <FormExpense handleClosePopup={handleClosePopup}/>
        </Popup>
    );
}

