import usePopup from "../../../../../../../../common/hook/use-popup";
import React from "react";
import {ExpenseEditPopup} from "../expense-edit-popup/ExpenseEditPopup";
import {PointsTitle} from "../../../style-points";

interface IExpensesId {
    expensesId: string;
}

export const  PointsPopupEdit = ({expensesId}: IExpensesId) => {
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();

    return (
        <>
            <PointsTitle onClick={onClickHandler}>Edytuj</PointsTitle>
            {showPopup && <ExpenseEditPopup expensesId={expensesId} show={showPopup} handleClosePopup={handleClosePopup}
                                            onClickClosest={onClickClosest}/>}
        </>
    );
};

