import {Option} from "../../weddingqa/answers/answer-question/question-select/QuestionSelect";

export const optionsType: Option[] = [
    {name: 'Buty', value: 'option1'},
    {name: 'Suknie', value: 'option2'},
    {name: 'Dekoracje', value: 'option3'},
    {name: 'Dekoracje', value: 'option4'},
];

export const optionsLocation: Option[] = [
    {name: 'Określ lokalizację', value: 'option1'},
    {name: 'Określ lokalizację', value: 'option2'},
    {name: 'Określ lokalizację', value: 'option3'},
    {name: 'Określ lokalizację', value: 'option4'},
];
export const optionsStatus: Option[] = [
    {name: 'Wybierz stan', value: 'option1'},
    {name: 'Wybierz stan', value: 'option2'},
    {name: 'Wybierz stan', value: 'option3'},
    {name: 'Wybierz stan', value: 'option4'},
];
export const optionsDelivery: Option[] = [
    {name: 'Wybierz rodzaj dostawy', value: 'option1'},
    {name: 'Wybierz rodzaj dostawy', value: 'option2'},
    {name: 'Wybierz rodzaj dostawy', value: 'option3'},
    {name: 'Wybierz rodzaj dostawy', value: 'option4'},
];
export const optionsPrice: Option[] = [
    {name: '399,00 zł', value: 'option1'},
    {name: '599,00 zł', value: 'option2'},
    {name: '499,00 zł', value: 'option3'},
    {name: '333,00 zł', value: 'option4'},
];
export const optionsHell: Option[] = [
    {name: 'Określ rodzaj obcasa', value: 'option1'},
    {name: 'Określ rodzaj obcasa', value: 'option2'},
    {name: 'Określ rodzaj obcasa', value: 'option3'},
    {name: 'Określ rodzaj obcasa', value: 'option4'},
];


export const optionsColor: Option[] = [
    { name: '#ffffff', value: 'White' },
    { name: '#2ecc71', value: 'Green' },
    { name: '#3498db', value: 'Blue' },
    { name: '#9b59b6', value: 'Purple' },
    { name: '#34495e', value: 'Dark Blue' },
    { name: '#1abc9c', value: 'Turquoise' },
    { name: '#e74c3c', value: 'Red' },
    { name: '#7f8c8d', value: 'Grey' },
    { name: '#f1c40f', value: 'Yellow' },
    { name: '#e67e22', value: 'Orange' },
    { name: '#000000', value: 'Black' },
];