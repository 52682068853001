import React from 'react';
import {IWebsite} from "../../types";
import {CalendarText} from "../../../../../calendar-Info/styles";
import {CollectionButton} from "../style-website-collection";
import usePopup from "../../../../../../common/hook/use-popup";
import {PopupBlack} from "./poppups-black/PopusBlack";
import {FormUploadGallery} from "./form-upload-gallery/FormUploadGallery";

export const WebsitePreview = ({backgroundColor, textColor}: IWebsite) => {
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();
    return (
        <CollectionButton onClick={onClickHandler}  style={{background: textColor}}>
            <CalendarText>
                Przejdź do galerii
            </CalendarText>

            <PopupBlack
                show={showPopup}
                handleClosePopups={handleClosePopup}
                onClickClosest={onClickClosest}
                formComponent={<FormUploadGallery handleClosePopup={handleClosePopup}/>}
            />
        </CollectionButton>
    );
};

