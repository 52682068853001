import styled from "styled-components";

export const WebsiteOurStoryWrap = styled.section`
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease; /* Add a transition for all properties */

  &:hover {
    border: 1px dashed #D2DAE3;
    padding: 10px;

    .edit-button,
    .eye-button {
      display: block;
    }
  }
`;




export const OurStoryImage = styled.img`
  width: 100%;
  height: auto;
  min-height: 400px;
  display: block;

  @media (max-width: 768.98px) {
    object-fit: cover;
    max-width: unset;
  }
`;

export const OurStoryBlock = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 47px;
  background: rgba(12, 18, 21, 0.5);
`;

export const OurStoryTitle = styled.p`
  color: #FFF;
  text-align: center;
  font-family: Gilroy, serif;
  font-size: 46px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const OurStoryText = styled.span`
  max-width: 600px;
  padding: 10px;
  color: #FFF;
  text-align: center;
  font-family: Gilroy, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
`;

export const OurStoryButton = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;
  display: flex;
  gap: 10px;
`;


export const Button = styled.button`
  display: none;
  margin: 10px;
  padding: 8px 10px;
  background-color: #D2DAE3;
  cursor: pointer;
  border-radius: 200px 100px 100px 0px;
  border: 1px solid var(--Border, #D2DAE3);
  opacity: 0.8;
  background: var(--White, #FFF);
`;

export const EditBlocks = styled.div`
  color: #0C1215;
  font-family: Gilroy,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 30px;
`;


export const ButtonEye = styled.button`
  display: none;
  margin: 10px;
  padding: 8px 10px;
  cursor: pointer;
  border-radius: 23px 23px 0px 23px;
  border: 1px solid #D2DAE3;
  opacity: 0.8;
  background: #FFF;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const HiddenMessage = styled.div`
  background-color: #F0F0F0;
  display: flex;
  justify-content: space-between; /* Align items horizontally */
  align-items: center; /* Center items vertically */
  padding: 0 20px; /* Add horizontal padding for spacing */
  height: 80px;
  color: #0C1215;
  font-family: Gilroy,sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  span {
    flex-grow: 1; 
    text-align: center; 
    
  }

  svg {
    flex-shrink: 0; /* Prevent the icon from shrinking and allow it to maintain its size */
  }
`;


export const ButtonVisibilityOff = styled.div`
  border-radius: 23px 23px 23px 0;
  border: 1px solid #D2DAE3;
  opacity: 0.8;
  background: #FFF;
  display: inline-flex;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  cursor: pointer;
`;