import styled from "styled-components";

export const WebsitePreviewingWrap = styled.section`
margin-bottom: 100px;
  @media (max-width: 768.98px) {
    margin-bottom: 20px;
  }
`;


export const PreviewingBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  cursor: pointer;
`;


export const PreviewingText = styled.p`
  color:  #1056B2;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
