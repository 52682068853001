import React, {useEffect} from 'react';
import {ActiveNavLink, LinksList, LinksMenu, WeddingQAContainer, WeddingQALinks} from "./style-weddingqa";
import {Outlet, useNavigate} from "react-router-dom";
import {FullWrapper} from "../../layout/style-layout";


export const WeddingQA = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/weddingqa/answers');
    }, []);

    return (
        <FullWrapper>
            <WeddingQAContainer>
                <WeddingQALinks>
                    <LinksMenu>
                        <LinksList>
                            <ActiveNavLink to="answers">Moje pytania</ActiveNavLink>
                        </LinksList>
                        <LinksList>
                            <ActiveNavLink to="questions">Odpowiedzi</ActiveNavLink>
                        </LinksList>
                    </LinksMenu>
                </WeddingQALinks>
                <Outlet />
            </WeddingQAContainer>
        </FullWrapper>
    );
};

