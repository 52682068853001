import styled from "styled-components";

export const CartWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 60px;
  @media (max-width: 682.98px){
    padding-top: 20px;
    gap: 20px;
  }
`;

export const CartTitle = styled.p`
  font-family: 'Gilroy',serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.01em;
  color: #0C1215;
  padding-top: 80px;
`

export const CartBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px 20px;
  
  @media (max-width: 682.98px) {
    gap: 50px 20px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 420.98px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 374px;
  cursor: pointer;
`;

export const ImageBlock = styled.div`
  max-width: 374px;
  width: 100%;
`;

export const Image = styled.img`
  width: 100%;
  max-width: 100%;
  filter: drop-shadow(0px 6.05348px 45.4011px rgba(11, 62, 138, 0.08));
  border-radius: 5px;
`;

export const Category = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  padding: 8px 12px;
  gap: 10px;
 //width: 80px;
  height: 30px;
  background: #1056B2;
  border-radius: 20px;
`;

export const ByStatus = styled.p`
  color:  #1056B2;
  text-align: left;
  font-family: Gilroy,sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; 
  letter-spacing: 0.12px;
  
`;


export const Title = styled.h2`
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  color: #0C1215;
  opacity: 0.8;
  @media (max-width: 682.98px){
    font-size: 17px;
  }

`;

export const Price = styled.p`
  font-family: 'Cormorant Garamond',serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #0C1215;
  opacity: 0.5;
`;