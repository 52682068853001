import React from 'react';
import full from '../../../../../assets/main/website/full.png'
import {
    BlockDate,
    BlockItem,
    BlockTitle,
    ImageContainer,
    ItemBlock,
    ItemNumber,
    ItemText,
    ResponsiveImage,
    WebsiteFullBlock
} from "./style-website-full";

export const WebsiteFull = () => {


    return (
        <ImageContainer>
            <ResponsiveImage src={full} alt=""/>
            <WebsiteFullBlock>
                <BlockTitle>Alex <span>&</span> Iwona</BlockTitle>
                <BlockDate>12 czerwca 2022</BlockDate>
                <BlockItem>
                    <ItemBlock>
                        <ItemNumber>
                            159
                        </ItemNumber>
                        <ItemText>
                            dni
                        </ItemText>
                    </ItemBlock>
                    <ItemBlock>
                        <ItemNumber>
                            159
                        </ItemNumber>
                        <ItemText>
                            godzin
                        </ItemText>
                    </ItemBlock>
                    <ItemBlock>
                        <ItemNumber>
                            43
                        </ItemNumber>
                        <ItemText>
                            minut
                        </ItemText>
                    </ItemBlock>
                </BlockItem>
            </WebsiteFullBlock>
        </ImageContainer>
    );
};

