import React, {FC} from 'react';
import {Form, Formik} from 'formik';
import {ReactComponent as СrossIcon} from '../../../../../../assets/main/wedding/close.svg';
import {ReactComponent as Checkbox} from '../../../../../../assets/main/form/checkbox.svg';
import {ReactComponent as Сlose} from '../../../../../../assets/main/form/close.svg';
import {useAppDispatch} from "../../../../../../common/hook";
import {
    ButtonBlock,
    ButtonCloses,
    ButtonSubmit,
    FormBlock,
    FormContainer,
    FormWrapper,
    StyledInput,
    StyledLabel,
    StyledTextArea
} from "../../../../wedding/usersForm/style-usersform";
import {CloseButton, StyledTitle} from "../../../../../popups/style-popups";
import {RadioBlock} from "./style-form-guestbook";


interface MyFormValues {
    title: string,
    price: string,
}

type PropsType = {
    handleClosePopup: () => void;
};


export const FormGuestbook: FC<PropsType> = ({handleClosePopup}) => {
    const dispatch = useAppDispatch();

    const handleSubmit = (values: MyFormValues) => {

    };




    return (
        <Formik initialValues={
            {
                title: '',
                price: '',
            }
        }
                onSubmit={handleSubmit}
            // validationSchema={schema}
        >
            {({values, handleSubmit, handleChange,setFieldValue, errors}) => (
                <FormWrapper onClick={(e) => e.stopPropagation()}>
                    <Form onSubmit={handleSubmit}>
                        <CloseButton type="button" onClick={handleClosePopup}>
                            <СrossIcon/>
                        </CloseButton>
                        <StyledTitle>Zyczenia dla młodych</StyledTitle>
                        <FormContainer>
                            <FormBlock>
                                <StyledLabel htmlFor="title">Imię & Nazwisko</StyledLabel>
                                <StyledInput type="text" name="title" value={values.title}
                                             onChange={handleChange}/>
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor="additionalText">Dodaj życzenia</StyledLabel>
                                <StyledTextArea
                                    id="additionalText"
                                    name="additionalText"
                                    onChange={handleChange}
                                    autoSize={{ minRows: 6, maxRows: 12 }}
                                />
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor="additionalText">Spraw, aby gratulacje były widoczne:</StyledLabel>
                                <RadioBlock>
                                    <label>
                                        <input
                                            type="radio"
                                            name="visibilityOption"
                                            value="option1"
                                            onChange={handleChange}
                                        />
                                        dla wszystkich
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="visibilityOption"
                                            value="option2"
                                            onChange={handleChange}
                                        />
                                        tylko dla młodych
                                    </label>

                                </RadioBlock>
                            </FormBlock>

                            <ButtonBlock>
                                <ButtonCloses><Сlose/><span>Anuluj</span></ButtonCloses>
                                <ButtonSubmit type="submit"
                                              disabled={Object.keys(errors).length !== 0}><Checkbox/><span>Zapisz</span></ButtonSubmit>
                            </ButtonBlock>
                        </FormContainer>
                    </Form>
                </FormWrapper>
            )}
        </Formik>
    );
};


