import styled from "styled-components";


export const GuestItem = styled.div`
  padding: 50px 12px 50px 0;
  border-bottom: 1px solid #D2DAE3;
  //background-color: #fdfdfd ;
  @media (max-width: 1076.98px) {
    padding: 50px 0 ;
  }
`

export const NewlywedsInfo = styled.div`

`

export const NewlywedsContainer = styled.div`

`
