import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    AvatarImage,
    AvatarPlaceholder,
    AvatarWrapper,
    Dropdown,
    DropdownItem,
    DropdownTail,
    LogoutItem
} from "./style-avatar";
import {RootState} from "services/store";
import Cookies from "universal-cookie";
import {useNavigate} from "react-router-dom";


interface UserProps {
    userId: string;
    size?: number;
}


export const Avatar: React.FC<UserProps> = ({userId, size = 36}) => {
    const dispatch = useDispatch();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const avatarUrl = useSelector((state: RootState) => state.app.avatar);
    const cookies = new Cookies();
    const navigate = useNavigate()



    const handleLogout = () => {
        // Удаляем куки с токеном авторизации
        cookies.remove('jwt_authorization');
        cookies.remove('company_id');
        // // Удаляем куки с сохраненным email и паролем
        // cookies.remove('rememberedEmail');
        // cookies.remove('rememberedPassword');
        // cookies.remove('rememberMe');
        // Проверяем, что куки действительно удалены
        if (!cookies.get('jwt_authorization')) {
            navigate('/login');
        }
    };



    return (
        <AvatarWrapper size={size} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            {avatarUrl ? (<AvatarImage src={avatarUrl} alt="Avatar"/>) : (<AvatarPlaceholder size={size}>{userId.charAt(0)}</AvatarPlaceholder>)}
            <Dropdown isOpen={isDropdownOpen}>
                <DropdownItem to="/login">Twoje informacje</DropdownItem>
                <LogoutItem onClick={handleLogout}>Wyloguj</LogoutItem>
                <DropdownTail />
            </Dropdown>
        </AvatarWrapper>
    );
};
