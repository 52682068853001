import React from 'react';
import {InspirationsPopup} from "./InspirationsPopup";
import {Popup} from "../../../../wedding/popUp/style-popups";


interface InspirationsPopupsProps {
    show: boolean;
    handleClosePopup: () => void;
    onClickClosest: () => void;
    image: string;
}

export const InspirationsPopups: React.FC<InspirationsPopupsProps> = (
    {
        show,
        handleClosePopup,
        onClickClosest,
        image
    }
) => {
    return (
        <Popup show={show} onClick={onClickClosest}>
            <InspirationsPopup handleClosePopup={handleClosePopup} image={image}/>
        </Popup>
    );
};