import React, {useState} from 'react';
import {PointsWrap} from "../../../budget/butget-expense/points-expense/style-points";
import {StyledPointsIcon} from "../../../tasks/style-tasks";
import {GuestPrinterPopup} from "./guest-printer-popup/GuestPrinterPopup";


interface IExpensesId {
    expensesId: string;
}

export const GuestPoints = ({ expensesId }: IExpensesId) => {
    const [isOpen, setIsOpen] = useState(false); // State to manage popup visibility

    const handleTogglePopup = () => {
        setIsOpen(!isOpen);
    };

    return (
        <PointsWrap>
            <StyledPointsIcon onClick={handleTogglePopup} />
            {isOpen && <GuestPrinterPopup expensesId={expensesId} />}
        </PointsWrap>
    );
};