import {LoginArgs, LoginRespons, PasswordChangeType, User} from "../auth/auth.types";
import {baseApi} from "../base-api";
import Cookies from "universal-cookie";

const cookies = new Cookies();

// Функция для извлечения токена из куки
const getToken = () => {
    const token = cookies.get('jwt_authorization');
    if (!token) {
        throw new Error('Токен авторизации не найден в куки');
    }
    return token;
}

export const authService = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        me: builder.query<User, void>({
            providesTags: ['Me'],
            query: () => {
                const token = getToken();
                return {
                    method: 'GET',
                    url: '/auth/me/',
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),

        login: builder.mutation<LoginRespons, LoginArgs>({
            invalidatesTags: ['Me'],
            query: body => ({
                body,
                method: 'POST',
                url: '/auth/login/',
            }),
        }),

        passwordChange: builder.mutation<void, PasswordChangeType>({
            invalidatesTags: ['Me'],
            query: (body) => {
                const token = getToken();
                return {
                    body,
                    method: 'PATCH',
                    url: '/user/password/',
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),
    }),
});

export const { useLoginMutation, usePasswordChangeMutation, useMeQuery } = authService;
