import styled from "styled-components";

export const GuestPrintWrap = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 33px;
  @media (max-width: 469.98px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    gap: 25px;
    text-align: -webkit-right;
  }
`;

export const PrintBlock = styled.div`
  display: flex;
  gap: 26.88px;

  @media (max-width: 469.98px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const InfoBlock = styled.div`

`;

export const PrintContentBlock = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;

  span {
    font-family: 'Gilroy',sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #0C1215;
    @media (max-width: 442.98px) {
      font-size: 14px;
    }
  }
`;

export const InfoContentBlock = styled.div`
  display: flex;
  gap: 5px;
  cursor: pointer;
  align-items: center;
  max-width: 285px;
  border-bottom: 1px dashed rgba(16, 86, 178, 0.6);
  transition: filter 0.3s;
  &:hover {
    border-bottom: none;
    padding-bottom: 1px;
    filter: brightness(0.8);
  }

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    color: #1056B2;

    @media (max-width: 469.98px) {
      font-size: 14px;
    }
  }
`;

export const InfoContentLinkBlock = styled.div`
  display: flex;
  gap: 5px;
  cursor: pointer;
  align-items: center;
  max-width: 285px;

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    color: #1056B2;
    transition: filter 0.3s; 

    @media (max-width: 469.98px) {
      font-size: 14px;
    }

    /* Добавление изменения яркости при hover */
    &:hover {
      filter: brightness(0.8);
    }
  }
`;