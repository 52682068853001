import React from 'react';
import {
    AttendanceBlock,
    AttendanceButton,
    AttendanceConfirm,
    AttendanceInformation,
    AttendanceText,
    AttendanceTitle,
    ConfirmBlock,
    ConfirmBlockInput,
    ConfirmInput,
    ConfirmTitle, Container,
    RadioInput,
    RadioLabel,
    RadioWrapper,
    WebsiteAttendanceWrap
} from "./style-website-attendance";
import {IconLeft, IconRight} from "../website-information/style-website-information";

import {ReactComponent as LeftIcon} from '../../../../../assets/main/website/left.svg';
import {ReactComponent as RightIcon} from '../../../../../assets/main/website/right.svg';
import {FormBlock, StyledLabel} from "../../../wedding/usersForm/style-usersform";
import {CalendarText} from "../../../../calendar-Info/styles";
import {CollectionButton} from "../website-collection/style-website-collection";
import {IWebsite} from "../types";


export const WebsiteAttendance = ({backgroundColor,textColor}: IWebsite) => {
    return (
        <WebsiteAttendanceWrap id='potwierdzenie'>
            <Container>
                <AttendanceBlock>
                    <AttendanceInformation>
                        <AttendanceTitle>
                            Potwierdź obecność
                        </AttendanceTitle>
                        <AttendanceText>
                            Prosimy nasi mili abyście osobno potwierdzali swoja obecność na przyjęciu weselnym, ponieważ
                            niezwykle
                            cenne są dla nas informacje o Waszych preferencjach żywieniowych oraz o tym czy potrzebujecie
                            noclegu.
                        </AttendanceText>
                    </AttendanceInformation>

                    <AttendanceConfirm>
                        <ConfirmBlock>
                            <ConfirmTitle>
                                Potwierdź obecność na naszym ślubie:
                            </ConfirmTitle>
                            <RadioWrapper>
                                <RadioInput
                                    id="radioOption1"
                                    name="attendance"
                                    value="option1"
                                />
                                <RadioLabel htmlFor="radioOption1">Jasne że będę</RadioLabel>
                            </RadioWrapper>
                            <RadioWrapper>
                                <RadioInput
                                    id="radioOption2"
                                    name="attendance"
                                    value="option2"
                                />
                                <RadioLabel htmlFor="radioOption2">Przykro mi, nie mogę być z Wami tego dnia</RadioLabel>
                            </RadioWrapper>
                        </ConfirmBlock>
                        <ConfirmBlockInput>
                            <FormBlock>
                                <StyledLabel htmlFor="name">Imię i Nazwisko</StyledLabel>
                                <ConfirmInput
                                    type="text"
                                    name="name"
                                />
                            </FormBlock>
                        </ConfirmBlockInput>
                    </AttendanceConfirm>
                    <AttendanceButton>
                        <CollectionButton style={{ background: textColor }}>
                            <CalendarText>
                                Wyślij
                            </CalendarText>
                        </CollectionButton>
                    </AttendanceButton>
                    <IconLeft>
                        <LeftIcon/>
                    </IconLeft>
                    <IconRight>
                        <RightIcon/>
                    </IconRight>
                </AttendanceBlock>
            </Container>
        </WebsiteAttendanceWrap>
    )
        ;
};

