import React, {useState} from 'react';
import {PointsWrap} from "../../../../budget/butget-expense/points-expense/style-points";
import {StyledPointsIcon} from "../../../../tasks/style-tasks";
import {NewlywedsPrinter} from "./newlyweds-popup/NewlywedsPrinter";


interface IExpensesId {
    expensesId: string;
}

export const NewlywedsPoints = ({ expensesId }: IExpensesId) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleTogglePopup = () => {
        setIsOpen(!isOpen);
    };

    return (
        <PointsWrap>
            <StyledPointsIcon onClick={handleTogglePopup} />
            {isOpen && <NewlywedsPrinter expensesId={expensesId} />}
        </PointsWrap>
    );
};