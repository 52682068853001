import styled from 'styled-components';

export const GalleryWrap = styled.div`
  cursor: pointer;
`;

export const MagnifierWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25px;
  height:  25px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
  }

  transform: translate(-50%, -50%) scale(0);
  transition: all 300ms 0ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
`;

export const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  text-align: center;

  img {
    max-width: 100%;
    vertical-align: top;
    transition: filter 0.3s;
  }

  &:hover img {
    filter: brightness(50%);
    transition: filter 0.3s;
  }

  &:hover {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.8);
      border-radius: 5px;
    }
  }

  &:hover ${MagnifierWrapper} {
    transform: translate(-50%, -50%) scale(1);
    transition: all 300ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  /* New styles for the Like icon */
  svg.Like {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    fill: #fff;
  }
`;