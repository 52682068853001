import React from 'react';
import {
    CardText,
    CardTime,
    CardTitle,
    WebsitePlanBlock,
    WebsitePlanCard,
    WebsitePlanCards,
    WebsitePlanTitle,
    WebsitePlanWrap
} from "./style-website-plan";
import {Container} from "../website-attendance/style-website-attendance";
import {PlanBlock} from "../../../wedding/plan/style-Plan";



const planData = [
    {
        title: "Photo",
        time: "10:00 - 12:00 AM"
    },
    {
        title: "Kościół",
        time: "12:00 - 14:00 AM",
        text: "Nasza ukochana Rodzino i Przyjaciele, z przyjemnością będziemy dzielić z Wami te najbardziej radosne chwile."
    },
    {
        title: "Restauracja",
        time: "14:00 - 15:00 AM",
        text: "Podziel się z nami tymi chwilami"
    },
    {
        title: "Pierwszy taniec i rozpoczęcie zabawy",
        time: "16:00 - 16:30 AM"
    },
    {
        title: "Tort",
        time: "12:00 - 14:00 AM"
    },
    {
        title: "I kolacja",
        time: "14:00 - 15:00 AM"
    },
    {
        title: "Niespodzianka dla gości!",
        time: "16:00 - 16:30 AM"
    },
    {
        title: "Oczepiny",
        time: "12:00 - 14:00 AM",
        text: "Podziel się z nami tymi chwilami"
    },
    {
        title: "Zakończenie zabawy",
        time: "14:00 - 15:00 AM"
    }
];

export const WebsitePlan = () => {
    return (
        <WebsitePlanWrap id="planWesela">
            <Container>
                    <WebsitePlanTitle>Plan wesela</WebsitePlanTitle>
                    <WebsitePlanBlock>
                        <WebsitePlanCards>
                            {planData.map((item, index) => (
                                <WebsitePlanCard key={index}>
                                    <CardTitle>{item.title}</CardTitle>
                                    <CardTime>{item.time}</CardTime>
                                    {item.text && <CardText>{item.text}</CardText>}
                                </WebsitePlanCard>
                            ))}
                        </WebsitePlanCards>
                    </WebsitePlanBlock>
            </Container>

        </WebsitePlanWrap>
    );
};