import React from "react";
import usePopup from "../../../../../../../../common/hook/use-popup";
import {PointsTitle} from "../../../../../../budget/butget-expense/points-expense/style-points";
import {NewlywedsDelete} from "../newlyweds-delete/NewlywedsDelete";





interface IExpensesId {
    expensesId: string;
    show:boolean
}

export const NewlywedsRename = ({expensesId,show}: IExpensesId) => {
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();

    return (
        <>
            <PointsTitle onClick={onClickHandler}>{!show && 'Zmień nazwę' }</PointsTitle>
            {/*{showPopup && <FormFavorite handleClosePopup={handleClosePopup}/>}*/}
        </>
    );
};

