import React from 'react';
import {Formik, Form} from 'formik';
import {useAppDispatch, useAppSelector} from "../../../../../../common/hook";
import {
    ButtonBlock, ButtonCloses, ButtonSubmit,
    ButtonWrap,
    FormBlock,
    FormContainer,
    FormWrapper, StyledArea, StyledLabel, StyledTextArea
} from "../../../../wedding/usersForm/style-usersform";
import {CloseButton, StyledTitle} from "../../../../wedding/popUp/style-popups";
import {ReactComponent as CloseIcon} from '../../../../../../assets/main/form/close.svg';
import {ReactComponent as СrossIcon} from '../../../../../../assets/main/wedding/close.svg';
import {ReactComponent as Checkbox} from '../../../../../../assets/main/form/checkbox.svg';
import {categorySelector} from "../../../../../../app/selectors/category";
import {addNoteExpense} from "../../../../../../app/budget-reduser";
import {v4 as uuid} from "uuid";
import TextArea from "antd/es/input/TextArea";


interface MyFormValues {
    content: string
}

interface PropsType {
    handleClosePopup: () => void;
    expensesId: string;
}


export const AddNoteForm: React.FC<PropsType> = ({handleClosePopup, expensesId}) => {
    const dispatch = useAppDispatch();
    const {currentCategoryID, categories} = useAppSelector(categorySelector);


    const handleSubmit = (values: MyFormValues) => {
        dispatch(addNoteExpense({
            categoryId: currentCategoryID, expenseId: expensesId, note: {
                id: uuid(),
                content: values.content
            }
        }));
    };

    console.log(categories, 'categories')


    return (
        <Formik
            initialValues={{
                content: '',
            }}
            onSubmit={handleSubmit}
        >
            {({values, handleSubmit, handleChange, errors}) => (
                <FormWrapper onClick={(e) => e.stopPropagation()}>
                    <Form onSubmit={handleSubmit}>
                        <CloseButton type="button" onClick={handleClosePopup}>
                            <СrossIcon/>
                        </CloseButton>
                        <StyledTitle>Dodaj notatkę</StyledTitle>
                        <FormContainer>
                            <FormBlock>
                                <StyledLabel htmlFor="content">Opis</StyledLabel>
                                <StyledTextArea
                                    id="content"
                                    name="content"
                                    onChange={handleChange}
                                    value={values.content}
                                    autoSize={{ minRows: 6, maxRows: 12 }}
                                />
                            </FormBlock>
                            <ButtonWrap>
                                <ButtonBlock>
                                    <ButtonCloses>
                                        <CloseIcon/>
                                        <span>Anuluj</span>
                                    </ButtonCloses>
                                    <ButtonSubmit
                                        type="submit"
                                        disabled={Object.keys(errors).length !== 0}
                                    >
                                        <Checkbox/>
                                        <span>Zapisz</span>
                                    </ButtonSubmit>
                                </ButtonBlock>
                            </ButtonWrap>
                        </FormContainer>
                    </Form>
                </FormWrapper>
            )}
        </Formik>
    );
};

