import React, {useState} from 'react';
import {CategoriesContainer, CategoriesContainerWrap, CategoriesLink, CategoriesText} from "./style-categories";


const categoriesArray = [
    {
        narrative: 'Narzeczeństwo',
        preparations: 'Przygotowania',
        marriage: 'ślub',
        wedding: 'Wesele',
        bride: 'Panna młoda',
        groom: 'Pan młody',
        after: 'Po ślubie'
    },
];

export const Categories = () => {
    const [activeCategories, setActiveCategories] = useState<string[]>([]);

    const handleClick = (category: string) => {
        setActiveCategories((prevCategories) => {
            if (prevCategories.includes(category)) {
                return prevCategories.filter((c) => c !== category);
            } else {
                return [...prevCategories, category];
            }
        });
    };

    const isCategoryActive = (category: string) => activeCategories.includes(category);

    return (
        <CategoriesContainerWrap>
            {categoriesArray.map((el, index) => (
                <CategoriesContainer key={index}>
                    <CategoriesText>Kategoria</CategoriesText>
                    {Object.entries(el).map(([key, category]) => (
                        <CategoriesLink
                            key={key}
                            active={isCategoryActive(category)}
                            onClick={() => handleClick(category)}
                        >
                            {category}
                        </CategoriesLink>
                    ))}
                </CategoriesContainer>
            ))}
        </CategoriesContainerWrap>
    );
};
