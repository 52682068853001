import React from 'react';
import Icon from '../../../../../assets/main/weddingqa/icon.svg'
import Avatar from '../../../../../assets/main/weddingqa/avatar.png'
import {v1} from "uuid";
import {
    ListCategory,
    ListComment,
    ListContainer, ListDate,
    ListQuestionsWrap,
    ListText,
    ListTitle,
    ListUser
} from "./style-list-questions";


const listQuestions = [
    {
        id: v1(),
        title: 'Wasz pomysł na rozsadzenie gości na weselu?',
        number: '24',
        date: '13.12.2022',
        categories: 'Przygotowania',
        icon: Icon,
        comment: 'Mój komentarz',
        text: 'Nikt ponieważ wiedzielismy że rzadko bedziemy tam siedzieć więc nie chcieliśmy żeby nasi świadkowie zostawali sami i żeby siedzieli ze swoimi znajomymi.',
        avatar: Avatar,
        name: 'Aneta',
        dateUser: '01.03.2022'
    },
    {
        id: v1(),
        title: 'Wasz pomysł na rozsadzenie gości na weselu?',
        number: '24',
        date: '13.12.2022',
        categories: 'Przygotowania',
        icon: Icon,
        comment: 'Mój komentarz',
        text: 'Nikt ponieważ wiedzielismy że rzadko bedziemy tam siedzieć więc nie chcieliśmy żeby nasi świadkowie zostawali sami i żeby siedzieli ze swoimi znajomymi.',
        avatar: Avatar,
        name: 'Aneta',
        dateUser: '28.02.2022'
    },
    {
        id: v1(),
        title: 'Bez jakiego dania nie odbyłoby się wasze wesele i dlaczego?',
        number: '24',
        date: '13.12.2022',
        categories: 'Przygotowania',
        icon: Icon,
        comment: 'Mój komentarz',
        text: 'Transport Gości leżał po ich stronie. Uważamy że to powinna być każdego indywidualna sprawa jak dostać się na wesele i z niego odjechać. Załatwianie transportu dla Gości przez Parę Młoda to zbedne zmartwienie',
        avatar: Avatar,
        name: 'Aneta',
        dateUser: '22.02.2022'
    },
    {
        id: v1(),
        title: 'Make up i fryzura w dniu ślubu. O której zaczęłaś przygotowania i jak one?',
        number: '24',
        date: '13.12.2022',
        categories: 'Przygotowania',
        icon: Icon,
        comment: 'Mój komentarz',
        text: 'Do większości będziemy jechać osobiście, ale mamy pare osób z zagranicy więc do nich wyślemy zaproszenia ale również obowiązkowo. Do większości będziemy jechać osobiście, ale mamy pare osób z zagranicy więc do nich wyślemy zaproszenia ale również obowiązkowo.Do większości będziemy jechać osobiście, ale mamy pare osób z zagranicy więc do nich wyślemy zaproszenia ale również obowiązkowo.Transport Gości leżał po ich stronie. Uważamy że to powinna być każdego indywidualna sprawa jak dostać się na wesele i z niego odjechać. Załatwianie transportu dla Gości przez Parę Młoda to zbedne zmartwienie',
        avatar: Avatar,
        name: 'Aneta',
        dateUser: '10.01.2022'
    },
]

export const ListQuestions = () => {
    return (
        <ListQuestionsWrap>
            {listQuestions.map(el => {
                return (
                    <ListContainer key={el.id}>
                        <ListTitle><p>{el.title}</p><span>{el.number}</span></ListTitle>
                        <ListCategory><p>{el.date}</p><span>{el.categories}</span></ListCategory>
                        <ListComment>
                            <img src={el.icon} alt="icon"/>
                            <span>{el.comment}</span>
                        </ListComment>
                        <ListText>{el.text}</ListText>
                        <ListUser><img src={el.avatar} alt="avatar"/>
                        <ListDate>
                            <p>{el.name}</p>
                            <span></span>
                            <p>{el.dateUser}</p>
                        </ListDate>
                        </ListUser>
                    </ListContainer>
                )
            })}
        </ListQuestionsWrap>
    );
};
