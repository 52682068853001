import React, {FC, useEffect, useState} from 'react';
import {ReactComponent as СrossIcon} from '../../../../assets/main/wedding/close.svg';
import {
    ButtonBlock,
    ButtonCloses,
    ButtonSubmit,
    FormBlock,
    FormContainer,
    FormWrapper,
    StyledInput,
    StyledLabel,
    StyledTextAreaForm,
    StyledUserTitle,
    SwitchBlock,
    SwitchButton,
    SwitchContainer,
    SwitchText,
} from './style-usersform';
import {CloseButton, FormPresentationWrap} from "../popUp/style-popups";
import {ReactComponent as Checkbox} from '../../../../assets/main/form/checkbox.svg';
import {ReactComponent as Сlose} from '../../../../assets/main/form/close.svg';
import {AdsFormBlock,} from "../../ads/ads-place/style-ads-place";
import {useUserQuery, useUserUpdateMutation} from "services/user/faq/faq.service";
import {SubmitHandler, useForm} from "react-hook-form";
import {UserFormValues, UserSchema} from "common/validation/userSchema";
import {MultimediaContact} from "components/MultimediaContact";
import {PresentationFile} from "components/PresentationFile/PresentationFile";
import {FileData} from "services/user/faq/faq.types";
import {useAutoResizeTextarea} from "common/hook/useAutoResizeTextarea";
import {CalendarChooseTime} from "components/layout/Calendar/ calendar-choose-time/ CalendarChooseTime";
import {ContentButton, ErrorText, Loader} from "components/sign-in-page/sing-in/style-sing-in";
import {CalendarText} from "components/calendar-Info/styles";
import {zodResolver} from "@hookform/resolvers/zod";


type PropsType = {
    handleClosePopup: () => void;
};

const UsersForm: FC<PropsType> = ({handleClosePopup}) => {
    useAutoResizeTextarea();
    const {data, isError, error, isLoading} = useUserQuery();
    const [weddingAtTimestamp, setWeddingAtTimestamp] = useState<number | null>(null); // State to hold the Unix timestamp


    const [editUser, {
        isLoading: AddFileIsLoading,
        isError: AddFileIsError,
        error: AddFileError
    }] = useUserUpdateMutation();


    const [formData, setFormData] = useState<any | null>(null);
    const [imageFull, setImageFull] = useState<File | FileData | string>('');
    const [imageBridePhoto, setBridePhoto] = useState<File | FileData | string>('');
    const [imageGroomPhoto, setGroomPhoto] = useState<File | FileData | string>('');


    const [isOpen, setIsOpen] = useState(false);


    const {register, handleSubmit, setValue, getValues, formState: {errors}} = useForm<any>({
        resolver: zodResolver(UserSchema),
        defaultValues: {
            wedding_image: {filename: '', link: ''},
            wedding_message: '',
            bride_name: '',
            bride_email: '',
            bride_phone: '',
            bride_photo: {filename: '', link: ''},
            ceremony_location: '',
            groom_email: '',
            groom_name: '',
            groom_phone: '',
            groom_photo: {filename: '', link: ''},
            wedding_at: null,
            wedding_location: '',
        }
    });

    useEffect(() => {
        if (data) {
            setFormData({
                wedding_image: data?.wedding_image?.link,
                wedding_message: data?.wedding_message,
                bride_name: data?.bride_name,
                bride_email: data?.bride_email,
                bride_phone: data?.bride_phone,
                bride_photo: data?.bride_photo?.link,
                ceremony_location: data?.ceremony_location,
                groom_email: data?.groom_email,
                groom_name: data?.groom_name,
                groom_phone: data?.groom_phone,
                groom_photo: data?.groom_photo?.link,
                wedding_at: data?.wedding_at,
                wedding_location: data?.wedding_location,
                is_countdown_set: data?.is_countdown_set,
            });
            setImageFull(data.wedding_image?.link || '');
            setBridePhoto(data.bride_photo?.link || '');
            setGroomPhoto(data.groom_photo?.link || '');
            setWeddingAtTimestamp(data?.wedding_at || null);
        }
    }, [data]);


    useEffect(() => {
        if (formData) {
            setValue('wedding_image', formData.wedding_image || {filename: '', link: ''});
            setValue('wedding_message', formData.wedding_message || '');
            setValue('bride_name', formData.bride_name || '');
            setValue('bride_email', formData.bride_email || '');
            setValue('bride_phone', formData.bride_phone || '');
            setValue('bride_photo', formData.bride_photo || {filename: '', link: ''});
            setValue('ceremony_location', formData.ceremony_location || '');
            setValue('groom_email', formData.groom_email || '');
            setValue('groom_name', formData.groom_name || '');
            setValue('groom_phone', formData.groom_phone || '');
            setValue('groom_photo', formData.groom_photo || {filename: '', link: ''});
            setValue('wedding_at', formData.wedding_at || null);
            setValue('wedding_location', formData.wedding_location || '');
            setValue('is_countdown_set', formData.is_countdown_set || 0);

        }
    }, [formData, setValue]);


    useEffect(() => {
        if (data) {
            setValue('wedding_image', {
                link: data.wedding_image?.link || '',
                filename: data.wedding_image?.filename || ''
            });
            setValue('wedding_message', data.wedding_message || '');
            setValue('bride_name', data.bride_name || '');
            setValue('bride_email', data.bride_email || '');
            setValue('bride_phone', data.bride_phone || '');
            setValue('bride_photo', {
                link: data.bride_photo?.link || '',
                filename: data.bride_photo?.filename || ''
            });
            setValue('ceremony_location', data.ceremony_location || '');
            setValue('groom_email', data.groom_email || '');
            setValue('groom_name', data.groom_name || '');
            setValue('groom_phone', data.groom_phone || '');
            setValue('groom_photo', {
                link: data.groom_photo?.link || '',
                filename: data.groom_photo?.filename || ''
            });
            setValue('wedding_at', data.wedding_at || null);
            setValue('wedding_location', data.wedding_location || '');
            setValue('is_countdown_set', data.is_countdown_set || 0);
        }
    }, [data, setValue]);


    const [isOn, setIsOn] = useState<boolean>(formData?.is_countdown_set === 1);

    useEffect(() => {
        if (formData) {
            setIsOn(formData.is_countdown_set === 1);
        }
    }, [formData]);


    const handleToggle = () => {
        setIsOn(prevState => {
            const newState = !prevState;
            setValue('is_countdown_set', newState ? 1 : 0);
            return newState;
        });
    };

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };


    const handleFormSubmit: SubmitHandler<UserFormValues> = async (formValues) => {
        const formData = new FormData();

        console.log(formValues, 'formValues')


        if (imageFull instanceof File) {
            formData.append('wedding_image', imageFull);
        } else if (imageFull === '') {
            formData.append('wedding_image', '');
        }

        if (imageBridePhoto instanceof File) {
            formData.append('bride_photo', imageBridePhoto);
        } else if (imageBridePhoto === '') {
            formData.append('bride_photo', '');
        }

        if (imageGroomPhoto instanceof File) {
            formData.append('groom_photo', imageGroomPhoto);
        } else if (imageGroomPhoto === '') {
            formData.append('groom_photo', '');
        }


        if (formValues.wedding_message) formData.append('wedding_message', formValues.wedding_message);
        if (formValues.bride_name) formData.append('bride_name', formValues.bride_name);
        if (formValues.bride_email) formData.append('bride_email', formValues.bride_email);
        if (formValues.bride_phone) formData.append('bride_phone', formValues.bride_phone);
        if (formValues.ceremony_location) formData.append('ceremony_location', formValues.ceremony_location);
        if (formValues.groom_email) formData.append('groom_email', formValues.groom_email);
        if (formValues.groom_name) formData.append('groom_name', formValues.groom_name);
        if (formValues.groom_phone) formData.append('groom_phone', formValues.groom_phone);
        formData.append('wedding_at', weddingAtTimestamp ? weddingAtTimestamp.toString() : ''); // Use the Unix timestamp

        // if (formValues.wedding_at) formData.append('wedding_at', formValues.wedding_at.toString());
        if (formValues.wedding_location) formData.append('wedding_location', formValues.wedding_location);
        formData.append('is_countdown_set', formValues.is_countdown_set ? formValues.is_countdown_set.toString() : '0');

        try {
            await editUser({body: formData});
            handleClosePopup();
        } catch (e) {
            console.error('Ошибка при отправке данных:', e);
        }
    };


    const handleImageChange = (file: string | File | null) => {
        setImageFull(file || '');
    };


    const handleBridePhotoChange = (file: string | File | null) => {
        setBridePhoto(file || '');
    };


    const handleGroomPhoneChange = (file: string | File | null) => {
        setGroomPhoto(file || '');
    };


    return (
        <FormWrapper onClick={(e) => e.stopPropagation()}>
            <FormPresentationWrap onSubmit={handleSubmit(handleFormSubmit)}>
                <CloseButton type="button" onClick={handleClosePopup}>
                    <СrossIcon/>
                </CloseButton>
                <StyledUserTitle>Edytuj dane</StyledUserTitle>
                <FormContainer>
                    <AdsFormBlock>
                        <MultimediaContact data={data} onImageChange={handleImageChange}/>
                    </AdsFormBlock>
                    <FormBlock>
                        <StyledLabel
                            htmlFor="bride_name"
                            error={errors.bride_name?.message || data?.error}
                        >
                            Panna Młoda
                        </StyledLabel>
                        <StyledInput
                            error={errors.bride_name?.message || data?.error}
                            type="text"
                            id="bride_name"
                            {...register('bride_name')}
                        />
                        {(errors.bride_name?.message || data?.error) && (
                            <ErrorText>{String(errors.bride_name?.message || data?.error)}</ErrorText>
                        )}

                    </FormBlock>
                    <FormBlock>
                        <StyledLabel
                            htmlFor="bride_email"
                            error={errors.bride_email?.message || data?.error}
                        >
                            E-mail Panna Młoda
                        </StyledLabel>

                        <StyledInput
                            error={errors.bride_email?.message || data?.error}
                            id="bride_email"
                            type="email"
                            {...register('bride_email')}
                        />
                        {(errors.bride_email?.message || data?.error) && (
                            <ErrorText>{String(errors.bride_email?.message || data?.error)}</ErrorText>
                        )}

                    </FormBlock>

                    <FormBlock>
                        <StyledLabel
                            htmlFor="bride_phone">
                            Telefon Panna Młoda
                        </StyledLabel>
                        <StyledInput
                            type="tel"
                            pattern="\+?[0-9\s\-\(\)]+"
                            {...register('bride_phone')}
                        />
                    </FormBlock>
                    <FormBlock>

                        <PresentationFile
                            onImageChange={handleBridePhotoChange}
                            photo={data?.bride_photo as FileData}
                            isError={isError}
                            error={error}
                            isLoading={isLoading}
                            text={'+ Dodaj zdjęcie Panny Młodej'}
                        />


                    </FormBlock>
                    <FormBlock>
                        <StyledLabel
                            error={errors.groom_name?.message || data?.error}
                            htmlFor="groom_name"
                        >Pan Młody</StyledLabel>
                        <StyledInput
                            error={errors.groom_name?.message || data?.error}
                            type="text"
                            autoComplete="off"
                            {...register('groom_name')}
                        />
                        {(errors.groom_name?.message || data?.error) && (
                            <ErrorText>{String(errors.groom_name?.message || data?.error)}</ErrorText>
                        )}
                    </FormBlock>
                    <FormBlock>
                        <StyledLabel
                            error={errors.groom_email?.message || data?.error}
                            htmlFor="groom_email">
                            E-mail Pan Młody</StyledLabel>
                        <StyledInput
                            error={errors.groom_email?.message || data?.error}
                            type="email"
                            {...register('groom_email')}
                        />
                        {(errors.groom_email?.message || data?.error) && (
                            <ErrorText>{String(errors.groom_email?.message || data?.error)}</ErrorText>
                        )}
                    </FormBlock>

                    <FormBlock>
                        <StyledLabel
                            error={errors.groom_phone?.message || data?.error}
                            htmlFor="groom_phone">
                            Telefon Pan Młody
                        </StyledLabel>
                        <StyledInput
                            error={errors.groom_phone?.message || data?.error}
                            type="tel"
                            pattern="\+?[0-9\s\-\(\)]+"
                            {...register('groom_phone')}
                        />
                        {(errors.groom_phone?.message || data?.error) && (
                            <ErrorText>{String(errors.groom_phone?.message || data?.error)}</ErrorText>
                        )}
                    </FormBlock>

                    <FormBlock>
                        <PresentationFile
                            onImageChange={handleGroomPhoneChange}
                            photo={data?.groom_photo as FileData}
                            isError={isError}
                            error={error}
                            isLoading={isLoading}
                            text={'+ Dodaj zdjęcie Pana Młodego'}
                        />
                    </FormBlock>


                    <FormBlock>
                        <StyledLabel htmlFor="wedding_at">Data i czas ślubu</StyledLabel>
                        <CalendarChooseTime
                            weddingAt={formData?.wedding_at}
                            onDateChange={(timestamp) => setWeddingAtTimestamp(timestamp)}
                        />
                    </FormBlock>

                    <FormBlock>
                        <SwitchBlock>
                            <SwitchText>Odliczanie do daty ślubu</SwitchText>
                            <SwitchContainer>
                                <SwitchContainer>
                                    <SwitchButton isOn={isOn} onClick={handleToggle}/>
                                </SwitchContainer>
                            </SwitchContainer>
                        </SwitchBlock>
                    </FormBlock>
                    <FormBlock>
                        <StyledLabel
                            error={errors.ceremony_location?.message || data?.error}
                            htmlFor="ceremony_location"
                        >Lokalizacja ślubu
                        </StyledLabel>
                        <StyledInput
                            error={errors.ceremony_location?.message || data?.error}
                            type="text"
                            {...register('ceremony_location')}
                        />
                        {(errors.ceremony_location?.message || data?.error) && (
                            <ErrorText>{String(errors.ceremony_location?.message || data?.error)}</ErrorText>
                        )}

                    </FormBlock>
                    <FormBlock>
                        <StyledLabel
                            error={errors.wedding_location?.message || data?.error}
                            htmlFor="wedding_location"
                        >Lokalizacja wesela</StyledLabel>
                        <StyledInput
                            error={errors.wedding_location?.message || data?.error}
                            type="text"
                            {...register('wedding_location')}
                        />
                        {(errors.wedding_location?.message || data?.error) && (
                            <ErrorText>{String(errors.wedding_location?.message || data?.error)}</ErrorText>
                        )}

                    </FormBlock>
                    <FormBlock>
                        <StyledLabel
                            htmlFor="wedding_message"
                            error={errors.wedding_message?.message || data?.error}
                        >
                            Dodaj tekst</StyledLabel>
                        <StyledTextAreaForm
                            id="wedding_message"
                            error={errors.wedding_message?.message || data?.error}
                            {...register('wedding_message')}
                        />

                        {(errors.wedding_message?.message || data?.error) && (
                            <ErrorText>{String(errors.wedding_message?.message || data?.error)}</ErrorText>
                        )}
                    </FormBlock>
                    <ButtonBlock>
                        <ButtonCloses onClick={() => handleClosePopup()}><Сlose/><span>Anuluj</span></ButtonCloses>
                        <ButtonSubmit type="submit">

                            <ContentButton>
                                {AddFileIsLoading ? <Loader/> : <Checkbox/>}
                                <CalendarText>
                                    Zapisz
                                </CalendarText>
                            </ContentButton>

                        </ButtonSubmit>
                    </ButtonBlock>
                </FormContainer>
            </FormPresentationWrap>
        </FormWrapper>
    );
};

export default UsersForm;


{/*<FormBlockCalendar>*/
}
{/*    <StyledLabel htmlFor="wedding_at">Data ślubu</StyledLabel>*/
}
{/*    <CalendarButton onClick={togglePopup}>*/
}
{/*        <CalendarIcon/>*/
}
{/*    </CalendarButton>*/
}
{/*    <StyledInputCalendar*/
}
{/*        onClick={togglePopup}*/
}
{/*        type="text"*/
}
{/*        value={getValues('wedding_at') || ''}*/
}
{/*        readOnly*/
}
{/*    />*/
}
{/*</FormBlockCalendar>*/
}


{/*{isOpen && <CalendarItem setValue={setValue} togglePopup={togglePopup}/>}*/
}


{/*<FormBlock>*/
}
{/*    <StyledLabel htmlFor="wedding_at">Czas ślubu</StyledLabel>*/
}
{/*    <StyledInput*/
}
{/*        type="text"*/
}
{/*        {...register('wedding_at')}*/
}
{/*    />*/
}

{/*</FormBlock>*/
}
