import styled from "styled-components";


interface WeddingProps {
    show: boolean;
}

export const Popup = styled.div<WeddingProps>`
  display: ${(props) => (props.show ? "flex" : "none")};
  width: 100%;
  background: rgba(12, 18, 21, 0.4);
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  height: 100vh;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  cursor: auto;
`;


export const CloseButton = styled.button`
  position: absolute;
  top: -4px;
  right: -38px;
  @media (max-width: 770.98px) {
    top: 17px;
    right: 27px;
  }
`;

export const StyledTitle = styled.p`
  font-family: 'Gilroy',sans-serif;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #0C1215;
  margin-bottom: 15px;
  font-style: normal;
  font-size: 30px;
  line-height: 26px;
  
  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

export const StyleSubTitle = styled.p`
  font-family: 'Gilroy',serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #0C1215;
  opacity: 0.5;
  margin-bottom: 10px;
`;

