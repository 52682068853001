import React, {useState} from 'react';
import {ReactComponent as BeigeIcon} from '../../../../assets/main/website/colors/beige.svg';
import {ReactComponent as BlueIcon} from '../../../../assets/main/website/colors/blue.svg';
import {ReactComponent as GreenIcon} from '../../../../assets/main/website/colors/green.svg';
import {ReactComponent as MaroonIcon} from '../../../../assets/main/website/colors/maroon.svg';
import {
    ChangeViewBlock,
    ChangeViewButton,
    ChangeViewCheckbox,
    ChangeViewContainer,
    ChangeViewItem,
    ChangeViewItems,
    ChangeViewTitle,
    ChangeViewWrap
} from "./style-website-change-view";
import {Checkbox, GuestBlockInfo} from "../../guest/sharing-information/sharing-form/style-sharing-form";
import {CalendarText} from "../../../calendar-Info/styles";
import {useNavigate} from "react-router-dom";


export const WebsiteChangeView = () => {
    const [selectedOption, setSelectedOption] = useState<string | null>(null);

    console.log(selectedOption, 'selectedOption');
    const navigate = useNavigate(); // Initialize navigate



    const handleCreateWebsite = () => {
        navigate('/website-create/website/website-pages');
    };

    const handleOptionSelect = (colors: string[]): void => {
        const colorsString = colors.join(',');
        setSelectedOption(colorsString);
        localStorage.setItem('selectedColors', colorsString); // Сохраняем выбранные цвета в localStorage
    };

    return (
        <ChangeViewWrap>
            <ChangeViewContainer>
                <ChangeViewTitle>Zmień widok</ChangeViewTitle>
                <ChangeViewBlock>
                    <ChangeViewItems>
                        <ChangeViewItem>
                            <BeigeIcon />
                        </ChangeViewItem>
                        <label htmlFor="beigeColorCheckbox" style={{ cursor: 'pointer' }}>
                        <ChangeViewCheckbox>
                            <Checkbox
                                type="checkbox"
                                id="beigeColorCheckbox"
                                name="colorOptions" // Установите одинаковое имя для всех чекбоксов
                                value="beige"
                                checked={selectedOption === '#FAF7F2,#7D7465'}
                                onChange={() => handleOptionSelect(['#FAF7F2', '#7D7465'])}
                            />
                            <GuestBlockInfo>
                                <p>Beżowy</p>
                            </GuestBlockInfo>
                        </ChangeViewCheckbox>
                        </label>
                    </ChangeViewItems>
                    <ChangeViewItems>
                        <ChangeViewItem>
                            <BlueIcon />
                        </ChangeViewItem>
                        <label htmlFor="blueColorCheckbox" style={{ cursor: 'pointer' }}>
                            <ChangeViewCheckbox>
                                <Checkbox
                                    type="checkbox"
                                    id="blueColorCheckbox"
                                    name="colorOptions"
                                    value="blue"
                                    checked={selectedOption === '#F5F9FF,#1056B2'}
                                    onChange={() => handleOptionSelect(['#F5F9FF', '#1056B2'])}
                                />
                                <GuestBlockInfo>
                                    <p>Niebieski</p>
                                </GuestBlockInfo>
                            </ChangeViewCheckbox>
                        </label>
                    </ChangeViewItems>
                    <ChangeViewItems>
                        <ChangeViewItem>
                            <GreenIcon />
                        </ChangeViewItem>
                        <label htmlFor="greenColorCheckbox" style={{ cursor: 'pointer' }}>
                        <ChangeViewCheckbox>
                            <Checkbox
                                type="checkbox"
                                id="greenColorCheckbox"
                                name="colorOptions" // Установите одинаковое имя для всех чекбоксов
                                value="green"
                                checked={selectedOption === '#F3F6F1,#6E7869'}
                                onChange={() => handleOptionSelect(['#F3F6F1', '#6E7869'])}
                            />
                            <GuestBlockInfo>
                                <p>Zielony</p>
                            </GuestBlockInfo>
                        </ChangeViewCheckbox>
                        </label>
                    </ChangeViewItems>
                    <ChangeViewItems>
                        <ChangeViewItem>
                            <MaroonIcon />
                        </ChangeViewItem>
                        <label htmlFor="maroonColorCheckbox" style={{ cursor: 'pointer' }}>
                        <ChangeViewCheckbox>
                            <Checkbox
                                type="checkbox"
                                id="maroonColorCheckbox"
                                name="colorOptions"
                                value="maroon"
                                checked={selectedOption === '#FFF8F8,#805555'}
                                onChange={() => handleOptionSelect(['#FFF8F8', '#805555'])}
                            />
                            <GuestBlockInfo>
                                <p>Bordowy</p>
                            </GuestBlockInfo>
                        </ChangeViewCheckbox>
                        </label>
                    </ChangeViewItems>
                </ChangeViewBlock>
                <ChangeViewButton
                    onClick={handleCreateWebsite}
                    disabled={selectedOption === null}
                >
                    <CalendarText>Zaczynaj</CalendarText>
                </ChangeViewButton>
            </ChangeViewContainer>
        </ChangeViewWrap>
    );
};

// const colorPairs = {
//     beige: ['#FAF7F2', '#7D7465'],
//     blue: ['#F5F9FF', '#1056B2'],
//     green: ['#F3F6F1', '#6E7869'],
//     maroon: ['#FFF8F8', '#805555'],
// };