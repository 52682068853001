import styled from "styled-components";

interface ActiveProps {
    active?: boolean;
}

export const CategoriesContainerWrap = styled.div`
  padding-top: 20px;
  margin-bottom: 30px;
  @media (max-width: 425.98px) {
    padding-top: 30px;
  }
`


export const CategoriesText = styled.p`
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(12, 18, 21, 0.4);
`


export const CategoriesContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  @media (max-width: 1076.98px) {
    gap: 5px;
    justify-content: space-between;
  }
  @media (max-width: 768.98px) {
    flex-wrap: wrap;
    justify-content: normal;
  }
`;

export const CategoriesLink = styled.span<{ active: boolean }>`
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  opacity: ${({ active }) => (active ? '1' : '0.5')};
  border-radius: 20px;
  border: 1px solid #1056B2;
  padding: 10px;
  cursor: pointer;
  background-color: ${({ active }) => (active ? '#1056B2' : 'transparent')};
  color: ${({ active }) => (active ? '#fff' : '#0C1215')};

  &:hover {
    opacity: ${({ active }) => (active ? '1' : '0.7')};
    color: ${({ active }) => (active ? '#fff' : '#1056B2')}; 
  }
`;