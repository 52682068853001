import React from 'react';
import {
    AnswerQuestionBlock,
    AnswerQuestionInput,
    AnswerQuestionWrap,
    ListButtonSubmit,
    ListSubmitBlock
} from "./style-answer-question";
import {ListContainer, ListTitle} from "../../questions/list-questions/style-list-questions";
import {FormBlock, StyledInput, StyledLabel} from "../../../wedding/usersForm/style-usersform";
import {Option, QuestionSelect} from "./question-select/QuestionSelect";

export const AnswerQuestion = () => {

    const options: Option[] = [
        {name: 'Narzeczeństwo', value: 'option1'},
        {name: 'Przygotowania', value: 'option2'},
        {name: 'Wesele', value: 'option3'},
        {name: 'Panna młoda', value: 'option4'},
        {name: 'Pan młody', value: 'option5'},
        {name: 'Po ślubie', value: 'option6'},
    ];

    return (
        <AnswerQuestionWrap>
            <ListContainer>
                <ListTitle><p>Nie ma pytania, na które chcesz udzielić odpowiedzi? </p></ListTitle>
                <AnswerQuestionBlock>
                    <FormBlock>
                        <StyledLabel htmlFor="subject">Zaproponuj własne:</StyledLabel>
                        <AnswerQuestionInput
                            type='text'
                            name="subject"
                        />
                    </FormBlock>
                    <FormBlock>
                        <StyledLabel htmlFor="subject">Kategoria</StyledLabel>
                        <QuestionSelect name="mySelectBox" options={options}/>
                    </FormBlock>
                </AnswerQuestionBlock>
                <ListSubmitBlock>
                    <ListButtonSubmit type="submit">Wyślij</ListButtonSubmit>
                </ListSubmitBlock>
            </ListContainer>

        </AnswerQuestionWrap>
    );
};

