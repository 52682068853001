import React, {FC} from 'react';
import {Formik, Form, FormikValues, useFormikContext} from 'formik';
import {v1 as uuidv1} from 'uuid';

import {CloseButton, StyledTitle} from "../plan-tab-popup/style-plan-tab-popup";

import {ReactComponent as Checkbox} from '../../../../../../assets/main/form/checkbox.svg';
import {ReactComponent as CloseIcon} from '../../../../../../assets//main/form/close.svg';
import {ReactComponent as СrossIcon} from '../../../../../../assets/main/wedding/close.svg';
import {
    ButtonBlock, ButtonCloses, ButtonSubmit,
    FormBlock,
    FormContainer,
    FormWrapper,
    StyledInput,
    StyledLabel
} from "../../../../wedding/usersForm/style-usersform";
import {ButtonPlanBlock, ButtonPlanSubmit} from "../../style-plan-tab";


interface MyFormValues {
    id: string,
    itemName: string,
    numberTables: string,
    numberSeats: string,
}

interface PropsType {
    handleClosePopup: () => void;
}


export const FormPlan: FC<PropsType> = ({handleClosePopup}) => {


        const handleSubmit = () => {
            // id: uuidv1(),
            // itemName: '',
            // numberTables: '',
            // numberSeats: '',
        };

        return (
            <Formik
                initialValues={{
                    id: '',
                    itemName: '',
                    numberTables: '',
                    numberSeats: '',
                }}
                enableReinitialize={true}
                onSubmit={handleSubmit}
            >
                {({values, handleSubmit, handleChange, errors}) => (
                    <FormWrapper onClick={(e) => e.stopPropagation()}>
                        <Form onSubmit={handleSubmit}>
                            <CloseButton type="button" onClick={handleClosePopup}>
                                <СrossIcon/>
                            </CloseButton>
                            <StyledTitle>Dodaj nową pozycję</StyledTitle>
                            <FormContainer>
                                <FormBlock>
                                    <StyledLabel htmlFor="itemName">Nazwa pozycji</StyledLabel>
                                    <StyledInput
                                        type="text"
                                        name="itemName"
                                        value={values.itemName}
                                        onChange={handleChange}
                                    />
                                </FormBlock>
                                <FormBlock>
                                    <StyledLabel htmlFor="numberTables">Liczba stołów</StyledLabel>
                                    <StyledInput
                                        type="text"
                                        name="numberTables"
                                        value={values.numberTables}
                                        onChange={handleChange}
                                    />
                                </FormBlock>
                                <FormBlock>
                                    <StyledLabel htmlFor="numberSeats">Ilość miejsc</StyledLabel>
                                    <StyledInput
                                        type="text"
                                        name="numberSeats"
                                        value={values.numberSeats}
                                        onChange={handleChange}
                                    />
                                </FormBlock>
                                <ButtonPlanBlock>
                                    <ButtonPlanSubmit
                                        type="submit"
                                        disabled={Object.keys(errors).length !== 0}
                                    >
                                        <Checkbox/>
                                        <span>Zapisz</span>
                                    </ButtonPlanSubmit>
                                </ButtonPlanBlock>
                            </FormContainer>
                        </Form>
                    </FormWrapper>
                )}
            </Formik>
        );
    }
;
