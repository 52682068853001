import { Category } from "../../app/budget-reduser";

export const calcPlannedPrice = (items: Category[]) => {
    return items.reduce((acc, category) => {
        const categoryPrice = Number(category.price);
        const expensesTotal = category.expenses
            ? category.expenses.reduce((acc, expense) => acc + Number(expense.totalPrice), 0)
            : 0;
        return acc + categoryPrice + expensesTotal;
    }, 0);
};