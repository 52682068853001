import React from 'react';
import {PointsWrap} from "../../../../../budget/butget-expense/points-expense/style-points";
import {StyledPointsIcon} from "../../../../../tasks/style-tasks";
import {PointsPopup} from "../../../../../tasks/points-tasks/points-popup/PointsPopup";


interface IExpensesId {
    expensesId: string;
    open: boolean;
    togglePopup: (id: string) => void;
}

export const PointsPointsTask = ({ expensesId, open, togglePopup }: IExpensesId) => {
    const handleTogglePopup = () => {
        togglePopup(expensesId);
    };

    return (
        <PointsWrap>
            <StyledPointsIcon onClick={handleTogglePopup} />
            {open && <PointsPopup expensesId={expensesId} />}
        </PointsWrap>
    );
};
