import { useMemo } from 'react';

/**
 * Кастомный хук для работы с датами.
 * @returns {Object} Функции для форматирования даты и вычисления дней до события.
 */
const useDateUtils = () => {
    /**
     * Форматирует дату и время из Unix timestamp.
     * @param timestamp - Unix timestamp (в секундах) или null
     * @returns Форматированная строка с датой и временем или сообщение о неизвестной дате
     */
    const formatDate = (timestamp: number | null): string => {
        if (timestamp === null) return 'Data nieznana';

        const date = new Date(timestamp * 1000); // Преобразование Unix timestamp в миллисекунды
        const dateOptions: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        };
        const timeOptions: Intl.DateTimeFormatOptions = {
            hour: '2-digit',
            minute: '2-digit',
        };

        // Форматирование даты и времени
        const formattedDate = date.toLocaleDateString('pl-PL', dateOptions);
        const formattedTime = date.toLocaleTimeString('pl-PL', timeOptions);

        return `${formattedDate} | ${formattedTime}`;
    };

    /**
     * Вычисляет количество дней до события на основе Unix timestamp.
     * @param eventDate - Unix timestamp (в секундах) или null
     * @returns Количество дней до события или сообщение о неизвестной дате
     */
    const calculateDaysUntilEvent = (eventDate: number | null): string => {
        if (eventDate === null) return 'Data nieznana';

        const now = new Date();
        const eventDateObj = new Date(eventDate * 1000);

        // Вычисление разницы в днях
        const timeDiff = eventDateObj.getTime() - now.getTime();
        const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

        return `${daysDiff} dni`;
    };

    return useMemo(() => ({
        formatDate,
        calculateDaysUntilEvent,
    }), []);
};

export default useDateUtils;
