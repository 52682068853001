import React from 'react';
import {Button, Info, UserWrapDown, WeddingBlock, WeddingItems, WeddingUser,} from './style-wedding';
import {ReactComponent as EditIcon} from '../../../assets/main/wedding/edit.svg';
import ImageViewer from './imag/ImageViewer';
import {Avatar} from '../../layout/header/avatar/Avatar';
import {PageHeaderDown} from "../../layout/header/title/PageHeader";
import {UserName} from "../../layout/header/userName/UserName";
import {ButtonWrap} from "./userInformation/style-userInformation";
import {UserInformation} from "./userInformation/UserInformation";
import {Tasks} from "./tasks/Tasks";
import {Cart} from "./cart/Cart";
import {Popups} from "./popUp/Popups";
import {PlanWedding} from "./plan/PlanWedding";
import usePopup from "../../../common/hook/use-popup";
import {FullWrapper} from "../../layout/style-layout";
import {useUserQuery} from "services/user/faq/faq.service";
import {UserDataResponse} from "services/user/faq/faq.types";





export const Wedding = () => {
    const {data, error, isLoading} = useUserQuery();
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();



    return (
        <FullWrapper>
            <UserWrapDown>
                <PageHeaderDown/>
                <Info><UserName/><Avatar userId={''}/></Info>
            </UserWrapDown>
            <WeddingBlock>
                <WeddingItems>
                    <WeddingUser>
                        <ButtonWrap>
                            <Button onClick={onClickHandler}>
                                <EditIcon/><span>Edytuj</span></Button>
                        </ButtonWrap>
                        <UserInformation data={data}/>
                    </WeddingUser>
                    <ImageViewer data={data}/>
                </WeddingItems>
            </WeddingBlock>
            <Tasks/>
            <PlanWedding/>
            <Cart/>
            <Popups show={showPopup} handleClosePopup={handleClosePopup} onClickClosest={onClickClosest}/>
        </FullWrapper>
    );
};
