import React from 'react';

import usePopup from "../../../../../../../common/hook/use-popup";
import {ReactComponent as NewPlusIcons} from '../../../../../../../assets/main/plan/newPlus.svg';
import {PlanTableGroupPopups} from "./plan-tables-form-table-group/plan-table-group-popup/PlanTableGroupPopups";
import {PlanButtonCloses} from "./style-plan-button-table";

export const PlanButtonTableGroup = () => {
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();

    return (
        <>
            <PlanButtonCloses onClick={onClickHandler}>
                <NewPlusIcons/>
                <span>Dodaj grupę stołów</span>
            </PlanButtonCloses>
            <PlanTableGroupPopups
                show={showPopup}
                handleClosePopup={handleClosePopup}
                onClickClosest={onClickClosest}
            />
        </>


    );
};

