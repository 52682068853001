import React from 'react';
import {
    AnswerLatesInput,
    AnswerLatesInputBlock,
    BlockListUser,
    ListButton,
    ShowInputBlock
} from "../style-answer-latest";
import {ListDate, ListUser} from "../../../questions/list-questions/style-list-questions";
import {ListButtonSubmit, ListSubmitBlock} from "../../answer-question/style-answer-question";
import {ReactComponent as СrossIcon} from '../../../../../../assets/main/wedding/close.svg';
import {v1} from "uuid";
import Avatar from "../../../../../../assets/main/weddingqa/avatar.png";
import {AnswerLatestShowWrap} from "./style-answer-latest-show";


interface AnswerLatestShowProps {
    onClose: () => void;
}


export const AnswerLatestShow = ({ onClose }:AnswerLatestShowProps) => {

    const answers = [
        {
            id: v1(),
            avatar: Avatar,
            name: 'Aneta',
            dateUser: '01.03.2022',
        },
    ]


    return (
        <AnswerLatestShowWrap>
            {answers.map((el) => {
                return (
                    <ShowInputBlock key={el.id}>
                        <BlockListUser>
                            <ListUser>
                                <img src={el.avatar} alt="avatar" />
                                <ListDate>
                                    <p>{el.name}</p>
                                    <span></span>
                                    <p>{el.dateUser}</p>
                                </ListDate>
                            </ListUser>
                            <ListButton type="button" onClick={onClose}>
                                <СrossIcon />
                            </ListButton>
                        </BlockListUser>
                        <AnswerLatesInputBlock>
                            <p>Mój komentarz</p>
                            <AnswerLatesInput type="text" />
                        </AnswerLatesInputBlock>

                        <ListSubmitBlock>
                            <ListButtonSubmit type="submit">Wyślij</ListButtonSubmit>
                        </ListSubmitBlock>
                    </ShowInputBlock>
                );
            })}
        </AnswerLatestShowWrap>
    );
};