import React from 'react';
import {StatusPopup} from "./status-popup/StatusPopup";
import usePopup from "../../../../../../../common/hook/use-popup";
import { PointsTitle} from "../../style-points";



interface IExpensesId {
    expensesId: string;
}

export const PointsPopupStatus = ({expensesId}: IExpensesId) => {
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();

    return (
        <>
            <PointsTitle onClick={onClickHandler}>Zmień status</PointsTitle>
            {showPopup && <StatusPopup expensesId={expensesId} show={showPopup} handleClosePopup={handleClosePopup}
                                       onClickClosest={onClickClosest}/>}
        </>
    );
};

