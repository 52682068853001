import styled from "styled-components";

export const AnswerQuestionWrap = styled.div`
  padding-top: 30px;

`

export const AnswerQuestionBlock = styled.div`
  display: flex;
  gap: 14.37px;
  padding-top: 7px;
  margin-bottom: 18px;

  @media screen and (max-width: 768.98px) {
   flex-wrap: wrap;
  }
`

export const ListSubmitBlock = styled.div`
  display: flex;
  justify-content: end;
`

export const ListButtonSubmit = styled.button`
  padding: 12px 50px;
  background: #1056B2;
  border-radius: 100px 50px 50px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;

  &:hover,
  &:focus {
    background-color: #1976d2;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const AnswerQuestionInput = styled.input`
  font-size: 16px;
  line-height: 46px;
  color: rgba(12, 18, 21, 0.8);
  height: 48px;
  padding: 0 20px;
  background: #fff;
  border: 1px solid rgba(12, 18, 21, 0.2);
  border-radius: 5px;

  @media screen and (max-width: 1263.98px) {
    width: 100%;
  }
  //&:hover {
  //  border-color: #007aff;
  //}
  //
  //&:focus {
  //  border-color: #007aff;
  //  outline: none;
  //  box-shadow: 0 0 3px #007aff;
  //}

`