import img1 from "../../../assets/main/favorites/dresses/one.png";
import img2 from "../../../assets/main/favorites/dresses/two.png";
import img3 from "../../../assets/main/favorites/dresses/three.png";
import img4 from "../../../assets/main/favorites/dresses/four.png";
import img5 from "../../../assets/main/favorites/dresses/five.png";
import {v1} from "uuid";


export const dates: string[] = ['Weselny bankiet', 'Drugi dzień', 'Obiad z przyjaciółmi'];




export const TablesArray = [
    {
        index: '1',
        title: 'Stół 1',
        tableArray: [
            {
                id: v1(),
                number: '1',
                name: 'Janusz Biernacka',
            },
            {
                id: v1(),
                number: '2',
                name: 'Idalia Biernacka',
            },
            {
                id: v1(),
                number: '3',
                name: 'Idalia Dąbrowska',
            },
            {
                id: v1(),
                number: '4',
                name: 'Lena Biernacka',
            },
            {
                id: v1(),
                number: '5',
                name: 'Lena Biernacka',
            },
            {
                id: v1(),
                number: '6',
                name: 'Adam Dąbrowski',
            },
            {
                id: v1(),
                number: '7',
                name: 'Seweryna Dąbrowski',
            },
            {
                id: v1(),
                number: '8',
                name: 'Peter Dąbrowski',
            },
        ]
    },
    {
        index: '2',
        title: 'Stół 2',
        tableArray: [
            {
                id: v1(),
                number: '1',
                name: 'Janusz Biernacka',
            },

        ]
    },
    {
        index: '3',
        title: 'Stół 3',
        tableArray: [
            {
                id: v1(),
                number: '1',
                name: 'Janusz Biernacka',
            },
            {
                id: v1(),
                number: '2',
                name: 'Idalia Biernacka',
            },
            {
                id: v1(),
                number: '3',
                name: 'Idalia Dąbrowska',
            },
        ]
    },
];
