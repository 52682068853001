import styled from 'styled-components';


export const CalendarContainer = styled.div`
  padding: 18px 18px 25px 18px;
  max-width: 328px;
  box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  @media (max-width: 682.98px) {
    margin: 0 auto;
  }
  .react-calendar {
    border: none;
  }
  
  .react-calendar__navigation__arrow {
    color: #1056B2;
  }

  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__prev2-button {
    display: none;
  }

  .react-calendar__navigation__label__labelText {
    font-family: 'Gilroy', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #1056B2;
    text-transform: capitalize;
  }

  .react-calendar__month-view__weekdays__weekday {
    font-family: 'Gilroy', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #0C1215;
    opacity: 0.5;
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
  }

  .react-calendar__month-view__days__day {
    font-family: 'Gilroy', serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #0C1215;
  }

  .react-calendar__tile--active {
    opacity: 0.5;
    border-radius: 50px;
    border: 0.5px solid #0C1215;
    background-color: white;
  }

  .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background: none;
  }
  @media (max-width: 320.98px) {
    max-width: 290px;
    padding: 9px 18px 9px 18px;
  }
`;



