import React from 'react';
import {
    CalendarBlock,
    LocationBlocks,
    LocationItem,
    LocationItems,
    UserBlock,
    UserData,
    UserDays,
    UserInformationWrap,
    UserLocation,
    UserName,
    UserText,
    UserWrapper
} from "./style-userInformation";
import {ReactComponent as Calendar} from '../../../../assets/main/wedding/calendar_24px.svg';
import {ReactComponent as Location} from '../../../../assets/main/wedding/location.svg';
import {UserDataResponse} from "services/user/faq/faq.types";
import useDateUtils from "common/hook/useDateUtils";

interface UserInformationProps {
    data?: UserDataResponse
}


export const UserInformation = ({data}: UserInformationProps) => {
    const { formatDate, calculateDaysUntilEvent } = useDateUtils();

    return (
        <UserInformationWrap>
            <UserName>{data ? `${data.bride_name} & ${data.groom_name}` : 'Nazwa nieznana'}</UserName>
            <UserWrapper>
                <UserText>Do Twojego ślubu pozostało</UserText>
                <UserDays>
                    {data?.is_countdown_set
                        ? calculateDaysUntilEvent(data.wedding_at)
                        : 'liczanie nie zostało rozpoczęte'}
                </UserDays>
                <UserBlock>
                    <CalendarBlock>
                        <Calendar/>
                        <UserData>
                            {formatDate(data?.wedding_at ?? null)}
                        </UserData>
                    </CalendarBlock>
                    <LocationBlocks>
                        <LocationItems>
                            <LocationItem>
                                <Location/>
                                <UserLocation><span>Kościół</span></UserLocation>
                            </LocationItem>
                            <LocationItem>
                                <span>{data?.wedding_location ? data.wedding_location : 'Lokalizacja nieznana'}</span>
                            </LocationItem>
                        </LocationItems>
                        <LocationItems>
                            <LocationItem>
                                <Location/>
                                <UserLocation><span>Ślub</span></UserLocation>
                            </LocationItem>
                            <LocationItem>
                                <span>{data?.ceremony_location ? data.ceremony_location : 'Lokalizacja nieznana'}</span>
                            </LocationItem>
                        </LocationItems>
                    </LocationBlocks>
                </UserBlock>
            </UserWrapper>
        </UserInformationWrap>
    );
};

