import styled from "styled-components";

type IsActiveProps = {
    isActive: boolean;
};




export const TabContent = styled.div<IsActiveProps>`
  width: 100%;
  display: ${(props) => (props.isActive ? 'block' : 'none')};
  padding-top: 26px;

  @media (max-width: 1440.98px) {
    padding-top: 15px;
  }

`;


export const PlanTabWrapper = styled.div`
  display: flex;
  gap: 20px;
`;