import styled from "styled-components";
import {NavLink} from "react-router-dom";

export const AvatarWrapper = styled.div<{ size: number }>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 18px 18px 0 18px;
  overflow: hidden;
  cursor: pointer;

`;

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const AvatarPlaceholder = styled.div<{ size: number }>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 18px 18px 0 18px;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: ${props => props.size * 0.4}px;
`;

export const Dropdown = styled.div<{ isOpen: boolean }>`
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  position: absolute;
  bottom: -106px;
  right: -3px;
  width: 162px;
  height: 84px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #1056b2;
  z-index: 1; 
`;

export const DropdownItem = styled(NavLink)`
  display: block;
  color:  #FFF;
  font-family: Gilroy,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.84px;
  transition: opacity 0.3s ease;
  

  &:hover {
    opacity: 0.6;
  }
`;

export const LogoutItem = styled.p`
  display: block;
  color:  #FFF;
  font-family: Gilroy,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.84px;
  transition: opacity 0.3s ease;
  

  &:hover {
    opacity: 0.6;
  }
`;


export const DropdownTail = styled.div`
  position: absolute;
  top: -13px;
  left: 79%;
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 14px solid #1056b2; 
`;
