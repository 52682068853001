import styled from "styled-components";


export const SpoilerTitle = styled.p`
  margin-bottom: 1rem;
  padding-top: 15px;
`;


export const SpoilerBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 23px;
  padding: 15px 26px;
  width: 100%;

  @media (max-width: 360.98px) {
    padding: 15px 10px;
  }
`;








