import styled from "styled-components";
import {ReactComponent as Array} from '../../../../src/assets/main/tasks/array.svg';
import {ReactComponent as Points} from '../../../../src/assets/main/tasks/points.svg';

export const TasksWrap = styled.section`
    display: flex;
    gap: 26px;
    flex-grow: 1;
    padding-top: 124px;
    padding-left: 30px;
    background-color: #fff;
    @media (max-width: 1000.98px) {
        padding-top: 100px;
        width: 100%;
        padding-left: 0;
    }
    @media (max-width: 1000.98px) {
        padding-top: 100px;
        width: 100%;
        padding-left: 0;
    }
    @media (max-width: 682.98px) {
        flex-direction: column-reverse;
    }
`

export const TasksContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    //gap: 63px
    @media (max-width: 682.98px) {
        gap: 15px;
    }
`
export const TaskItem = styled.div`
    margin-bottom: 50px;

`

export const SpoilerContainer = styled.div`
    position: relative;
    margin-bottom: 10px;
`;
export const SpoilerBox = styled.div<{ isOpenSpoiler: boolean }>`

    max-height: ${(props) => (props.isOpenSpoiler ? "1000px" : "0")};
    opacity: ${(props) => (props.isOpenSpoiler ? "1" : "0")};
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
    background-color: white;

    @media (max-width: 1251.98px) {
        overflow-x: auto;
        overflow-y: hidden;
    }

`;

export const SpoilerContent = styled.div<{ isOpen: boolean }>`
    transition: all 0.3s ease-out;
    max-height: ${props => (props.isOpen ? '1000px' : '0')};
    padding: 16px 0 0 0;
`;


export const ContentInput = styled.input`
    cursor: pointer;
`;


export const ContentTitle = styled.div<{ isDone: boolean }>`
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: ${props => (props.isDone ? 'rgba(12, 18, 21, 0.40)' : '#0C1215')};
    cursor: pointer;
`;



export const ContentContainer = styled.div`
    font-family: 'Gilroy', serif;
    display: flex;
    gap: 19px;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #0C1215;
    margin-bottom: 10px;
    padding-left: 36px;
    @media (max-width: 682.98px) {
        gap: 10px;
        align-items: flex-start;
    }
`;


export const ContentItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;

export const ContentBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const ContainerIcons = styled.div`
    display: flex;
    gap: 8px;
`;


export const ContentPaints = styled.div`

`;


export const ContentDate = styled.div`
    display: flex;
    gap: 9px;
    //align-self: center;
    opacity: 0.5;
`;
export const ContentLocation = styled.div`
    display: flex;
    //align-items: center;
    gap: 6px;
    opacity: 0.5;
`;


export const ContentProvider = styled.a`
    color: #0C1215;
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    opacity: 0.5;
`;
export const ContentBlueProvider = styled.a`
    color: #1056B2;
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    text-decoration-line: underline !important;
    opacity: 1;
    cursor: pointer;

    &:hover {
        opacity: 0.4;
    }
`;

export const ContentAddNote = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #0C1215;
    opacity: 0.8;
    padding-left: 36px;
    max-width: 588px;
    width: 100%;
`;


export const SpoilerButton = styled.button`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    border-bottom: 1px solid #D2DAE3;
    padding: 5px 0 10px 0;
    cursor: pointer;
    align-items: center;
    border-radius: 5px;

`;
export const SpoilerTitle = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #0C1215;
    opacity: 0.5;
`;

export const ArrayBlock = styled.div`
    display: flex;
    gap: 23px;
    align-items: center;
`;
export const SpolletItemBlock = styled.div`
    display: flex;
    gap: 23px;
    padding-right: 15px;
`;
export const ButtonContainer = styled.div`
    display: flex;
    gap: 23px;
    align-items: center;
`;


export const StyledArrayIcon = styled(Array)<{ isOpenSpoiler: boolean }>`
    transform: ${props => props.isOpenSpoiler ? 'rotate(180deg)' : 'rotate(0deg)'};
    opacity: 0.5;
`;


export const StyledPointsIcon = styled(Points)`
    width: 20px;
    opacity: 1;
    transition: all 0.3s ease-out;
    cursor: pointer;


    &:hover {
        opacity: 0.5;
    }
`;

export const SpoilerPopup = styled.div`
    position: absolute;
    right: 0%;
    top: -78%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 4px rgba(0, 40, 99, 0.15);
    border-radius: 10px;

    span {
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        text-align: center;
        letter-spacing: 0.01em;
        color: #0C1215;
        opacity: 0.5;
        cursor: pointer;
    }
`;
