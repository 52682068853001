import {ColorOption, ColorSelectContainer, ColorSelectDropdown, IconSelectColor} from "./style-сolor-select";
import React, {useState} from "react";
import {ReactComponent as Array} from '../../../../../assets/main/weddingqa/array.svg';


export const ColorSelect: React.FC<{ options: { name: string; value: string }[] }> = ({ options }) => {
    const [selectedColor, setSelectedColor] = useState<string | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectOpen, setSelectOpen] = useState(false);

    const handleToggleSelect = () => {
        setSelectOpen((prevState) => !prevState);
    };

    const handleToggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleColorSelect = (color: string, text: string) => {
        setSelectedColor(color);
        setIsOpen(false);
    };

    return (
        <ColorSelectContainer onClick={handleToggleDropdown}>
            <IconSelectColor onClick={handleToggleSelect}>
                <Array />
            </IconSelectColor>
            <span>
        {selectedColor ? (
            <>
                {selectedColor && <span style={{ background: selectedColor }} />}
                {options.find((option) => option.name === selectedColor)?.value}
            </>
        ) : (
            'Określ kolor'
        )}
      </span>
            <ColorSelectDropdown className={isOpen ? 'open' : ''}>
                {options.map((option) => (
                    <ColorOption
                        key={option.value}
                        color={option.name}
                        onClick={() => handleColorSelect(option.name, option.value)}
                    >
                        <span>{option.value}</span>
                    </ColorOption>
                ))}
            </ColorSelectDropdown>
        </ColorSelectContainer>
    );
};