import React from 'react';
import {ReactComponent as Plus} from '../../assets/main/wedding/plus.svg';
import {ReactComponent as Edit} from '../../assets/main/plan/edit.svg';
import {CalendarButton, CalendarData, CalendarText, CatalogEducation, CatalogInfo, EducationText} from "./styles";
import {useAppSelector} from "common/hook";
import {mainSelector} from "app/selectors/main";

interface ICalendarInfo {
    onClickHandler(): void
}

export const CalendarInfoComponent = ({onClickHandler}: ICalendarInfo) => {
    const {date} = useAppSelector(mainSelector)
    return (
        <CatalogInfo>
            <CalendarData>
                {date ? date : '23 mar 2022'}
            </CalendarData>
            <CatalogEducation>
                <EducationText>
                    Zapisać się na nauki przedmałżeńskie
                </EducationText>
                <Edit/>
            </CatalogEducation>
            <CalendarButton onClick={onClickHandler}>
                <Plus/>
                <CalendarText>
                    Dodaj nową pozycję
                </CalendarText>
            </CalendarButton>
        </CatalogInfo>
    );
};

