import { useEffect } from 'react';

export const useAutoResizeTextarea = () => {
    useEffect(() => {
        const handleInput = (event: Event) => {
            const target = event.target as HTMLTextAreaElement;
            if (target.tagName.toLowerCase() !== 'textarea') return;
            autoResize(target);
        };

        const autoResize = (textarea: HTMLTextAreaElement) => {
            textarea.style.height = 'auto'; // Сбросить высоту
            textarea.style.height = (textarea.scrollHeight + 2) + 'px'; // Установить высоту
        };

        document.addEventListener('input', handleInput, false);

        // Очистка слушателя при размонтировании компонента
        return () => {
            document.removeEventListener('input', handleInput);
        };
    }, []);
};
