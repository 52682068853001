import styled from "styled-components";

export const PointsPopupWrap = styled.div`
  position: absolute;
  left: -93px;
  top: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 40, 99, 0.15);
  border-radius: 10px;
  padding: 10px;
z-index: 5;
  p {
    cursor: pointer;
  }
`