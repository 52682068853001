import styled, {keyframes} from "styled-components";


interface StyledContactFileImgProps {
    imageUrl: any;
}


export const LoaderBlock = styled.div`
    width: 100%;
    height: 100vh;
    background: #999999;
    box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
    border-radius: 5px;
    color: #FFFFFF;
    display: flex;
    justify-content: center; 
    align-items: center;      

    svg {
        z-index: 5;
    }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Loader = styled.div`
    border: 0.6px solid #1056B2;
    border-radius: 50%;
    border-top: 0.6px solid #ffffff;
    width: 16px;
    height: 16px;
    animation: ${spin} 1s linear infinite;
`;


export const ContactPersonWrap = styled.div`
    display: flex;
    gap: 30px;
    width: 100%;

    @media (max-width: 783.98px) {
        flex-direction: column;
    }
    
`;


export const ContactAvatarContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 240px;
    height: 275px;
    //@media (max-width: 425.98px) {
    //    width: 100%;
    //    max-width: 395px;
    //}
`;


export const ContactAvatar = styled.label`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //width: 100%;
    //max-width: 513px;
    width: 240px;
    height: 275px;
    outline: none;
    cursor: pointer;
    text-align: center;
    //padding: 16px;
    background: #FFFFFF;
    border: 1px solid #D2DAE3;
    box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
    border-radius: 5px;
    color: #1056B2;
    transition: all 0.3s ease;

    &:hover {
        background: #999999;
        box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
        border-radius: 5px;
        color: #FFFFFF;

        svg path {
            fill: #FFFFFF;
        }

        img {
            width: 100%;
        }
    }

    input[type='file'] {
        display: none;
    }

    svg {
        position: absolute;
        top: 5px;
        left: 5px;
        fill: #1056B2;
        transition: fill 0.3s ease;

        &:hover {
            fill: red;
        }
    }

    img {
        width: 30px;
    }

    @media (max-width: 558.98px) {
        max-width: 340px;
        height: 250px;
    }

    @media (max-width: 382.98px) {
        max-width: 330px;
        height: 250px;
    }
`;


export const StyledAvatarFileContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //width: 513px;
    //height: 330px;
    width: 240px;
    height: 275px;
    outline: none;
    cursor: pointer;
    text-align: center;
    //padding: 16px;
    background: #FFFFFF;
    border: 1px solid #D2DAE3;
    box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
    border-radius: 5px;
    color: #1056B2;
    transition: all 0.3s ease;

    &:hover {
        background: #999999;
        box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
        border-radius: 5px;
        color: #FFFFFF;

        img {
            width: 100%;
        }
    }

    input[type='file'] {
        display: none;
    }


    svg {
        position: absolute;
        top: 5px;
        left: 5px;
        fill: #1056B2;
        transition: fill 0.3s ease;

        &:hover {
            fill: #0e3a6c;
        }
    }

    img {
        width: 30px;
    }

    @media (max-width: 558.98px) {
        max-width: 100%;
        width: 340px;
        height: 250px;
    }
    @media (max-width: 382.98px) {
        width: 315px;
    }

`;


export const StyledContactFileImg = styled.div<StyledContactFileImgProps>`
    width: 240px;
    height: 275px;
    background-image: ${(props) => `url(${props.imageUrl})`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
`;
