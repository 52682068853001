import React from 'react';
import {NewQuestionsContainer, NewQuestionsTitle, NewQuestionsWrap} from "./new-questions";
import {v1} from "uuid";

import {InfoContentBlock} from "../../../guest/guest-print/style-guest-print";
import {Link} from "react-router-dom";
import {ReactComponent as Expand} from '../../../../../assets/main/guest/expand_more.svg';
import {ListCategory, ListContainer, ListTitle} from "../../questions/list-questions/style-list-questions";


const newQuestions = [
    {
        id: v1(),
        title: 'Kto siedział z wami przy stole pary młodej?',
        number: '24',
        date: '13.12.2022',
        categories: 'Przygotowania',
    },
    {
        id: v1(),
        title: 'Bez jakiego dania nie odbyłoby się wasze wesele i dlaczego?',
        number: '24',
        date: '13.12.2022',
        categories: 'Wesele',
    },
    {
        id: v1(),
        title: 'Bez jakiego dania nie odbyłoby się wasze wesele i dlaczego?',
        number: '24',
        date: '13.12.2022',
        categories: 'Wesele',
    },
    {
        id: v1(),
        title: 'Kto siedział z wami przy stole pary młodej?',
        number: '24',
        date: '13.12.2022',
        categories: 'Przygotowania',
    },
    {
        id: v1(),
        title: 'Kto siedział z wami przy stole pary młodej?',
        number: '24',
        date: '13.12.2022',
        categories: 'Przygotowania',
    },
    {
        id: v1(),
        title: 'Bez jakiego dania nie odbyłoby się wasze wesele i dlaczego?',
        number: '24',
        date: '13.12.2022',
        categories: 'Wesele',
    },
]

export const NewQuestions = () => {
    return (
        <NewQuestionsWrap>
            <NewQuestionsTitle>
                <p>Nowe pytania</p>
                <Link to="#">
                    <InfoContentBlock><span>Zobacz wszystkie</span><Expand/></InfoContentBlock>
                </Link>
            </NewQuestionsTitle>
            <NewQuestionsContainer>
                {newQuestions.map(el => {
                    return (
                        <ListContainer key={el.id}>
                            <ListTitle><a href='#'>{el.title}</a><span>{el.number}</span></ListTitle>
                            <ListCategory><p>{el.date}</p> <span>{el.categories}</span></ListCategory>
                        </ListContainer>
                    )
                })}
            </NewQuestionsContainer>
        </NewQuestionsWrap>
    );
};

