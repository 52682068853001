import React from 'react';
import {AdsCarts} from "./ads-carts/AdsCarts";
import {AdsButton, AdsContainer} from "./style-ads";
import {InspirationsLink} from "../favorites/insoirations/style-inspirations";
import {Link} from "react-router-dom";
import {InfoContentBlock, InfoContentLinkBlock} from "../guest/guest-print/style-guest-print";
import {ReactComponent as Expand} from '../../../assets/main/guest/expand_more.svg';
import {CalendarButton, CalendarText} from "../../calendar-Info/styles";
import {ReactComponent as Plus} from '../../../assets/main/wedding/plus.svg';
import {FullWrapper} from "../../layout/style-layout";


export const Ads = () => {
    return (
        <FullWrapper>
            <AdsContainer>
                <InspirationsLink style={{paddingRight:'25px'}}>
                    <Link to="#">
                        <InfoContentLinkBlock ><span>Market szlubny</span><Expand/></InfoContentLinkBlock>
                    </Link>
                </InspirationsLink>
                <AdsCarts/>
                <Link to="/adsplace">
                    <AdsButton>
                        <CalendarButton>
                            <Plus/>
                            <CalendarText>
                                <InfoContentBlock>Umieść ogłoszenie</InfoContentBlock>
                            </CalendarText>
                        </CalendarButton>
                    </AdsButton>
                </Link>
            </AdsContainer>
        </FullWrapper>
    )
};


