import React from 'react';
import {GuestItem, NewlywedsInfo} from "./style-guest";
import {ExpenseButton} from "../budget/butget-expense/style-budgetExpense";
import {CalendarButton, CalendarText} from "../../calendar-Info/styles";
import usePopup from "../../../common/hook/use-popup";
import {ReactComponent as Plus} from '../../../assets/main/wedding/plus.svg';

import {Popups} from "../../popups/Popups";
import {GuestPrint} from "./guest-print/GuestPrint";
import {GuestNewlyweds} from "./guest-newlyweds/GuestNewlyweds";
import {
    newlywedsDataBride,
    newlywedsDataHusband
} from "./guest-newlyweds/newlyweds-spoilers/newlyweds-spoilers-array/spoilers-array";

import avatarBride from '../../../assets/main/guest/bride.png';
import avatarHusband from '../../../assets/main/guest/husband.png';
import {GuestCounter} from "./guest-counter/GuestCounter";
import {FullWrapper} from "../../layout/style-layout";
import {FormGroup} from "./group-form/form-group/FormGroup";


export const Guest = () => {
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();

    return (
        <FullWrapper>
            <GuestPrint/>
            <GuestCounter/>
            <GuestItem>
                <GuestNewlyweds title="Od Panny Młodej" avatar={avatarBride} newlywedsData={newlywedsDataBride}/>
                <NewlywedsInfo>
                    <ExpenseButton>
                        <CalendarButton onClick={onClickHandler}>
                            <Plus/>
                            <CalendarText>Dodaj grupę</CalendarText>
                        </CalendarButton>
                    </ExpenseButton>
                    <Popups
                        show={showPopup}
                        handleClosePopups={handleClosePopup}
                        onClickClosest={onClickClosest}
                        formComponent={<FormGroup handleClosePopup={handleClosePopup}/>}
                    />
                </NewlywedsInfo>
            </GuestItem>
            <GuestItem>
                <GuestNewlyweds title="Od Pana Młodego" avatar={avatarHusband} newlywedsData={newlywedsDataHusband}/>
                <NewlywedsInfo>
                    <ExpenseButton>
                        <CalendarButton onClick={onClickHandler}>
                            <Plus/>
                            <CalendarText>Dodaj grupę</CalendarText>
                        </CalendarButton>
                    </ExpenseButton>
                    <Popups
                        show={showPopup}
                        handleClosePopups={handleClosePopup}
                        onClickClosest={onClickClosest}
                        formComponent={<FormGroup handleClosePopup={handleClosePopup}/>}
                    />
                </NewlywedsInfo>
            </GuestItem>
        </FullWrapper>
    );
};