import React, {useState} from 'react';
import {PlanSpoiler} from "./plan-spoiler/plan-spoiler";
import {SpoilerTitle} from "./style-plan-newlyweds";
import {familyBiernackaItems, familyDabrowskiItems, familyItems} from "./plan-spoiler/plan-spoiler-array";


export const PlanNewlyweds = () => {
    const spoilerTitleOne = 'Od Panny Młodej';
    const spoilerTitleTwo = 'Od Pana Młodego';
    const [activeSpoilerIndex, setActiveSpoilerIndex] = useState<number | null>(null);

    const toggleSpoiler = (index: number) => {
        setActiveSpoilerIndex((prevIndex) => {
            if (prevIndex === index) {
                return null;
            } else {
                return index;
            }
        });
    };

    return (
        <div>
            <div>
                <SpoilerTitle>{spoilerTitleOne}</SpoilerTitle>
                <div>
                    <PlanSpoiler title="Rodzina" items={familyItems} isActive={activeSpoilerIndex === 0}
                                 toggleSpoiler={() => toggleSpoiler(0)}/>
                    <PlanSpoiler title="Rodzina Biernacka" items={familyBiernackaItems}
                                 isActive={activeSpoilerIndex === 1} toggleSpoiler={() => toggleSpoiler(1)}/>
                    <PlanSpoiler title="Rodzina Dąbrowski" items={familyDabrowskiItems}
                                 isActive={activeSpoilerIndex === 2} toggleSpoiler={() => toggleSpoiler(2)}/>
                </div>
            </div>
            <div>
                <SpoilerTitle>{spoilerTitleTwo}</SpoilerTitle>
                <div>
                    <PlanSpoiler title="Rodzina" items={familyItems} isActive={activeSpoilerIndex === 3}
                                 toggleSpoiler={() => toggleSpoiler(3)}/>
                    <PlanSpoiler title="Przyjaciele" items={familyBiernackaItems} isActive={activeSpoilerIndex === 4}
                                 toggleSpoiler={() => toggleSpoiler(4)}/>
                </div>
            </div>
        </div>
    );
};