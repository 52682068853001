import styled from "styled-components";
import {Option} from "../../../weddingqa/answers/answer-question/question-select/QuestionSelect";




export const ColorSelectContainer = styled.div`
  font-size: 16px;
  line-height: 46px;
  color: rgba(12, 18, 21, 0.8);
  height: 48px;
  padding: 0 20px;
  background: #fff;
  border: 1px solid rgba(12, 18, 21, 0.2);
  border-radius: 5px;
  cursor: pointer;
  //&:hover {
  //  border-color: #007aff;
  //}
  //&:focus {
  //  border-color: #007aff;
  //  outline: none;
  //  box-shadow: 0 0 3px #007aff;
  //}
`;

export const ColorSelectDropdown = styled.ul`
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 999;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid #3e3e3e;
  display: none;
  margin-bottom: 20px;
  background: #1056b2;
  border-radius: 0 0 5px 5px;
  padding-top: 25px;

  &.open {
    display: block;
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }
`;

export const ColorOption = styled.li`
  list-style: none;
  width: 40px;
  height: 40px;
  background: #000000;
  border: 1px solid #d2dae3;
  display: block;
  border-radius: 50%;
  background: ${({ color }: { color?: string }) => color || 'defaultFallbackColor'};
  float: left;
  margin: 9px 14px;
  cursor: pointer;
  position: relative;
  top: -7px;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.15);
  }

  span {
    color: #0C1215;
    opacity: 0.5;
    position: absolute;
    bottom: 0;
    left: -25px;
    right: -25px;
    padding: 10px;
    z-index: -1;
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.01em;
    background: #FFFFFF;
    box-shadow: 0 1px 4px rgba(0, 40, 99, 0.15);
    border-radius: 10px;
    visibility: hidden;
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  span:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
    position: absolute;
    bottom: -5px;
    left: 40px;
  }

  &:hover span {
    bottom: 25px;
    visibility: visible;
    opacity: 1;
  }
`;

export const IconSelectColor = styled.span`
  position: absolute;
  top: 30px;
  right: 3%;
  height: 20px;
  transition: all 275ms;
  opacity: 0.6;
`;