import React from 'react';
import {ImageContainer, ImageWrap} from "./style-imageViewer";
import {UserDataResponse} from "services/user/faq/faq.types";


import image from '../../../../assets/main/wedding/image.png'

interface UserInformationProps {
    data?: UserDataResponse
}

const ImageViewer = ({data}: UserInformationProps) => {


    return (
        <ImageContainer>
            <ImageWrap src={data?.wedding_image?.link || image} alt="Image"/>
        </ImageContainer>
    );
};


export default ImageViewer;
