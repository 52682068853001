import {Category} from "../../../../../app/budget-reduser";


export const CalcTotalFlow = (items: Category[], currentCategoryID: string) => {
    const category = items.find(item => item.id === currentCategoryID);
    if (category) {
        return category.expenses
            ? category.expenses.reduce((acc, expense) => acc + Number(expense.totalPrice), 0)
            : 0;
    }
    return 0;
};
