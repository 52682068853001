import Cookies from "universal-cookie";
import {baseApi} from "services/base-api";
import {UserDataResponse} from "services/user/faq/faq.types";



const cookies = new Cookies();

const getToken = (): string => {
    const token = cookies.get('jwt_authorization');
    if (!token) {
        throw new Error('Токен авторизации не найден в куки');
    }
    return token;
}

export const getUserId = (): string => {
    const userId = cookies.get('company_id');
    if (!userId) {
        throw new Error('companyId не найден в куки');
    }
    return userId;
}

export const userService = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        user: builder.query<UserDataResponse, void>({
            providesTags: ['User'],
            query: () => {
                const token = getToken();
                const userId = getUserId();
                return {
                    method: 'GET',
                    url: `/wedding/${userId}/`,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),

        userUpdate: builder.mutation<any, any>({
            invalidatesTags: ['User'],
            query: ({body}) => {
                console.log(body,'body')
                const token = getToken();
                const userId = getUserId();
                return {
                    body,
                    method: 'POST',
                    url: `/wedding/${userId}/`,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),
    }),
});

export const {
    useUserQuery,
    useUserUpdateMutation,
} = userService;

