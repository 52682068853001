import styled from "styled-components";


type IsActiveProps = {
    isActive: boolean;
};

export const TabWrapper = styled.div`
  display: flex;
  gap: 25px;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: 0px -2px 0px 0px #D2DAE3 inset;
  @media (max-width: 837.98px) {
    gap: 10px;
  }
`;

export const TabButton = styled.button<IsActiveProps>`
  transition: 0.3s ease-in-out;
  padding-bottom: 9px;

  ${(props) =>
          props.isActive &&
          `
    border-bottom: 1px solid #1056B2;
    box-shadow: inset 0px -2px 0px #1056B2;
  `}
`;

export const TabButtonContent = styled.div`
  display: flex;
  gap: 22px;
  align-items: center;

  span {
    font-family: 'Gilroy', sans-serif;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    color: #0C1215;
    cursor: pointer;
    @media (max-width: 837.98px) {
      font-size: 18px;
    }
  }
`;

export const ButtonPopup = styled.a`
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #1056B2;
  padding-bottom: 9px;
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 7px;
    left: 0;
    width: 100%;
    height: 1px; /* Высота подчеркивания */
    background-color: #1056B2;
    transition: transform 0.3s ease; /* Анимация увеличения/уменьшения ширины */
  }

  &:hover::before {
    background-color: white;
  }
`;


export const ButtonPlanSubmit = styled.button`
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 30px;
  background: #1056B2;
  border-radius: 200px 100px 100px 0;
  gap: 8px;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
  }

  &:hover,
  &:focus {
    background-color: #1976d2;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const ButtonPlanBlock = styled.div`
  display: flex;
  justify-content: flex-end;
`;