import React from "react";
import {Popup} from "../../../../../wedding/popUp/style-popups";
import {
    FormPointsEdit
} from "../../../../../plan/plan-contant/plan-tables/plan-points/points-popup/points-tasks-popup-edit/form-points-edit/FormTasksEdit";


interface PopupProps {
    show: boolean;
    handleClosePopup: () => void;
    onClickClosest: () => void;
    expensesId:string;
}

export const TasksEditPopup = ({show, handleClosePopup, onClickClosest,expensesId}: PopupProps) => {
    return (
        <Popup show={show} onClick={onClickClosest}>
            <FormPointsEdit handleClosePopup={handleClosePopup} />
        </Popup>
    );
}