import React from 'react';
import {CustomLink, WebsiteHeaderWrap, WebsiteNavigateWrap} from "./style-website-header";
import {IWebsite} from "../types";


export const WebsiteHeader = ({ backgroundColor, textColor }: IWebsite) => {
    return (
        <WebsiteHeaderWrap background={backgroundColor}>
            <WebsiteNavigateWrap>
                <CustomLink href="#lokalizacja">Lokalizacja</CustomLink>
                <CustomLink href="#kontakty">Kontakty</CustomLink>
                <CustomLink href="#nasza">Nasza historia</CustomLink>
                <CustomLink href="#planWesela">Plan wesela</CustomLink>
                <CustomLink href="#prezenty">Prezenty</CustomLink>
                <CustomLink href="#planMiejsc">Plan miejsc</CustomLink>
                <CustomLink href="#księgaGości">Księga gości</CustomLink>
                <CustomLink href="#photos">Photos</CustomLink>
                <CustomLink href="#potwierdzenie">Potwierdzenie</CustomLink>
            </WebsiteNavigateWrap>
        </WebsiteHeaderWrap>
    );
};