import React from 'react';
import {BudgetTitleBlock} from "./butget-title";
import {TotalPrice} from "./totalPrice";

export const BudgetTitle = () => {
    return (
        <BudgetTitleBlock>
           <TotalPrice/>
        </BudgetTitleBlock>
    );
};

