import React, {useState} from 'react';
import {ImageContainer, ResponsiveImage} from "../website-full/style-website-full";
import fullTho from "../../../../../assets/main/website/fullTwo.png";
import {ReactComponent as EyeIcon} from '../../../../../assets/main/website/eye.svg';
import {ReactComponent as VisibilityOffEyeIcon} from '../../../../../assets/main/website/visibility-off-eye.svg';
import {ReactComponent as EditIcon} from '../../../../../assets/main/website/edit.svg';
import {
    Button,
    ButtonEye,
    ButtonVisibilityOff,
    EditBlocks,
    HiddenMessage,
    OurStoryBlock,
    OurStoryButton, OurStoryImage,
    OurStoryText,
    OurStoryTitle,
    WebsiteOurStoryWrap
} from "./style-website-our-story";
import usePopup from "../../../../../common/hook/use-popup";
import {Popups} from "../../../../popups/Popups";
import {FormOurStory} from "./form-our-story/FormOurStory";


const stories = [
    {
        title: "Nasza Historia",
        text: "Mamy nadzieję, że będziecie tego dnia razem z nami. Poniżej znajdziecie niezbędne informacje organizacyjne oraz sekcję 'potwierdzenie obecności', w której jednym kliknięciem powiadomicie nas o Swoim przybyciu.",
        imageSrc: fullTho,
        alt: "Image 1 Alt Text"
    },
];

export const WebsiteOurStory = () => {
    const [isHidden, setIsHidden] = useState<boolean>(true);
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();

    const toggleHidden = () => {
        setIsHidden(!isHidden);
    };

    return (
        <div id="nasza">
            {isHidden ? (
                <div>
                    {stories.map((story, index) => (
                        <WebsiteOurStoryWrap key={index}>
                            <ImageContainer>
                                <OurStoryImage src={story.imageSrc} alt={story.alt}/>
                                <OurStoryBlock>
                                    <OurStoryTitle>{story.title}</OurStoryTitle>
                                    <OurStoryText>{story.text}</OurStoryText>
                                </OurStoryBlock>
                            </ImageContainer>
                            <OurStoryButton>
                                <Button
                                    onClick={onClickHandler}
                                    className="edit-button">
                                    <EditBlocks>
                                        <EditIcon/>
                                        <span>Edytuj</span>
                                    </EditBlocks>
                                </Button>
                                <ButtonEye onClick={toggleHidden} className="eye-button"><EyeIcon/></ButtonEye>
                            </OurStoryButton>
                        </WebsiteOurStoryWrap>
                    ))}
                </div>
            ) : (
                <HiddenMessage>
                    <span>Blok jest ukryty. Kliknij przycisk Ukryj/Pokaż po prawej stronie, aby był widoczny.</span>
                    <ButtonVisibilityOff onClick={toggleHidden}
                                         className="eye-button"><VisibilityOffEyeIcon/></ButtonVisibilityOff>
                </HiddenMessage>
            )}
            <Popups
                show={showPopup}
                handleClosePopups={handleClosePopup}
                onClickClosest={onClickClosest}
                formComponent={<FormOurStory handleClosePopup={handleClosePopup}/>}
            />

        </div>
    );
};