import styled from "styled-components";
import {PropsMenu} from "./menu/menu";

export const NavigateWrap = styled.nav`
  flex: 1 0 auto;
  max-width: 218px;
  z-index: 10;
  position: sticky;
  align-self: flex-start;
  top: 0;
  height: 100vh;
  box-shadow: 0 4px 30px 0 #0B3E8A14;
`

export const MenuList = styled.ul<PropsMenu>`
  display: flex;
  flex-direction: column;
  gap: 32.5px;
  padding: 50px 48px 44px 32px;
  @media (max-width: 1000.98px) {
    left: ${({isOpenBurger}) => isOpenBurger ? '0' : '-100%'};
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    row-gap: 20px;
    overflow: auto;
    height: 100%;
    position: fixed;
    top: 0;
    padding-top: 120px;
    background: linear-gradient(90deg, #1056B2 0%, #2779E4 100%);
    width: 100%;
    transition: left 0.3s;
  }
`

export const MenuItem = styled.li`
  @media (max-width: 1000.98px) {
    width: 156px;
  }

  svg {
    margin-right: 10px;
    vertical-align: middle;
    width: 24px;
    height: 24px;
  }

  svg * {
    @media (max-width: 1000.98px) {
      stroke: #fff;
    }
  }

  a {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #0C1215;
    opacity: 0.8;
    transition: all .2s ease;
    @media (max-width: 1000.98px) {
      color: #fff;
    }

    &:hover {
      color: #0C1215;
      opacity: 0.4;
      outline: none;
    }
  }

  &:last-child {
    padding-top: 28px;
    max-width: 127px;
    border-top: 1px solid #E7ECF3;
    @media (max-width: 1000.98px) {
      max-width: 100%;
    }
    svg {
      margin-right: 15px;
    }
  }

  span {
    display: inline-block;
  }
  &.active a {
    color: #1056B2;
    @media (max-width: 1000.98px) {
      color: #fff;
      opacity: 0.6;
    }
  }
  
`;

