import React from 'react';
import {BlockFeedback, BlockSlider, FeedbackTitle, GuestbookBlock, GuestbookContainer} from "./style-website-guestbook";

import './slider/style.css'
import {Slider} from "./slider/Slider";
import {WebsiteGiftsTitle} from "../website-gifts/style-website-gifts";
import {AttendanceButton} from "../website-attendance/style-website-attendance";
import {CollectionButton} from "../website-collection/style-website-collection";
import {CalendarText} from "../../../../calendar-Info/styles";
import {IWebsite} from "../types";
import usePopup from "../../../../../common/hook/use-popup";
import {Popups} from "../../../../popups/Popups";
import {FormGuestbook} from "./form-guestbook/FormGuestbook";
import {WebsiteSeatingPlanWrap} from "../website-seating-plan/style-website-seating-plan";
import {PreviewMode} from "./preview-mode/PreviewMode";

export const WebsiteGuestbook = ({backgroundColor, textColor}: IWebsite) => {
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();


    return (
        <WebsiteSeatingPlanWrap id="księgaGości" style={{background: backgroundColor}}>
            <GuestbookContainer>
                <WebsiteGiftsTitle>Księga gości</WebsiteGiftsTitle>
                <GuestbookBlock>
                    <BlockFeedback>
                        <FeedbackTitle>
                            Będzie nam bardzo milo przeczytać wasze słowa
                        </FeedbackTitle>
                        <AttendanceButton>
                            <CollectionButton onClick={onClickHandler} style={{background: textColor}}>
                                <CalendarText>
                                    Zostaw gratulacje
                                </CalendarText>
                            </CollectionButton>
                        </AttendanceButton>
                    </BlockFeedback>
                    <BlockSlider style={{cursor:'pointer'}}>
                        <Slider backgroundColor={backgroundColor} textColor={textColor}/>
                    </BlockSlider>
                </GuestbookBlock>
                    <PreviewMode backgroundColor={backgroundColor} textColor={textColor}/>
            </GuestbookContainer>
            <Popups
                show={showPopup}
                handleClosePopups={handleClosePopup}
                onClickClosest={onClickClosest}
                formComponent={<FormGuestbook handleClosePopup={handleClosePopup}/>}
            />
        </WebsiteSeatingPlanWrap>

    );
};

