import React from 'react';
import {DownloadIcons} from "../style-website-collection";
import {CollectionUploadWrap} from "./style-collection-upload";
import {IWebsite} from "../../types";
import usePopup from "../../../../../../common/hook/use-popup";
import {PopupBlack} from "../website-preview/poppups-black/PopusBlack";
import {FormUpload} from "./form-upload/FormUpload";

export const CollectionUpload = ({backgroundColor, textColor}: IWebsite) => {
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();

    return (
        <CollectionUploadWrap onClick={onClickHandler}>
            <DownloadIcons color={textColor}/>
            <p style={{color: textColor}}>Prześlij zdjęcie</p>

            <PopupBlack
                show={showPopup}
                handleClosePopups={handleClosePopup}
                onClickClosest={onClickClosest}
                formComponent={<FormUpload handleClosePopup={handleClosePopup}/>}
            />
        </CollectionUploadWrap>
    );
};




// <UploadPopups show={showPopup} handleClosePopup={handleClosePopup} onClickClosest={onClickClosest}/>