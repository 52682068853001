import {createSlice, PayloadAction} from '@reduxjs/toolkit';


export type StatusType = 'unpaid' | 'prepayment' | 'repaid';

export type NoteType = {
    id: string,
    content: string,
}

export interface Expense {
    id: string;
    name: string;
    notes: NoteType[];
    location: string;
    linkLocation: string;
    totalPrice: string;
    prepaid: string;
    fullPaymentDate: string;
    status: StatusType;
}

export interface Category {
    id: string;
    title: string;
    price: string;
    expenses?: Expense[];
}

interface InitialStateType {
    categories: Category[];
    currentCategoryID: string;

}

const initialState: InitialStateType = {
    categories: [],
    currentCategoryID: '',
};

const slice = createSlice({
    name: 'budget',
    initialState,
    reducers: {
        addCategory(state, action) {
            state.categories = [...state.categories, action.payload];
        },
        setCurrentCategory: (state, {payload}) => {
            state.currentCategoryID = payload;
            console.log(state.currentCategoryID, 'currentCategoryID')
        },
        editCategory: (state, {payload}) => {
            state.categories = state.categories.map((category) => (
                category.id === state.currentCategoryID ? ({
                    ...category, title: payload.title,
                    price: payload.price,
                }) : category
            ))
        },
        addExpense: (state, action: PayloadAction<{ categoryId: string; expense: Expense }>) => {
            const {categoryId, expense} = action.payload;
            const category = state.categories.find((cat) => cat.id === categoryId);
            if (category) {
                category.expenses = category.expenses || [];
                const newExpense: Expense = {...expense};
                category.expenses.push(newExpense);
                console.log(newExpense, 'newExpense');
            }
        },
        deleteExpense: (state, action: PayloadAction<{ id: string; filteredExpenses: Expense[] }>) => {
            state.categories = state.categories.map(cat => {
                if (cat.id === action.payload.id) {
                    return {...cat, expenses: action.payload.filteredExpenses};
                }
                return cat;
            });
        },
        changeStatus: (state, action: PayloadAction<{ id: string; status: StatusType }>) => {
            state.categories.forEach((category) => {
                const expense = category.expenses?.find((exp) => exp.id === action.payload.id);
                if (expense) {
                    expense.status = action.payload.status;
                }
            });
        },
        updateExpense: (state, action: PayloadAction<{ categoryId: string; expenseId: string; expense: Expense }>) => {
            const {categoryId, expenseId, expense} = action.payload;
            const category = state.categories.find((cat) => cat.id === categoryId);
            if (category) {
                category.expenses = category.expenses?.map((exp) => {
                    if (exp.id === expenseId) {
                        return {...exp, ...expense};
                    }
                    return exp;
                });
            }
        },
        addNoteExpense: (state, action: PayloadAction<{ categoryId: string; expenseId: string; note: NoteType }>) => {
            const {categoryId, expenseId, note} = action.payload;
            const category = state.categories.find((cat) => cat.id === categoryId);
            if (category) {
                const expense = category.expenses?.find((exp) => exp.id === expenseId);
                if (expense) {
                    expense.notes = [note];
                }
            }
        },
        editNoteContent(state, action: PayloadAction<{ categoryId: string; content: string }>) {
            const {categoryId, content} = action.payload;
            state.categories = state.categories.map((category) =>
                category.id === categoryId
                    ? {
                        ...category,
                        content: content
                    }
                    : category
            );
        }
    },
});


export const budgetReducer = slice.reducer;
export const {
    addCategory,
    updateExpense,
    changeStatus,
    editCategory,
    addExpense,
    deleteExpense,
    setCurrentCategory,
    addNoteExpense, editNoteContent
} = slice.actions;
