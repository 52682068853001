import React from 'react';
import {useAppSelector} from "../../../../../common/hook";
import {categorySelector} from "../../../../../app/selectors/category";
import {ReactComponent as EditIcon} from '../../../../../assets/main/plan/edit.svg'

import {
    PlannedContent,
    PlannedTitle,
    PlannedWrap,
    PlannedZeroPrice
} from "../../budget-category/budget-planned/style-planned";
import {CalcTotalFlow} from "./CalcTotalFlow";


export const TotalFlow = () => {
    const {currentCategoryID, categories} = useAppSelector(categorySelector);
    const totalFlowPrice = CalcTotalFlow(categories, currentCategoryID);

    return (
        <PlannedWrap>
            <PlannedContent>
                <PlannedTitle>Całkowita kwota wydatków:</PlannedTitle>
                <PlannedZeroPrice>10 200 zł</PlannedZeroPrice>
            </PlannedContent>
            <PlannedContent>
                <PlannedTitle>Kwota planowanych wydatków dla kategorii </PlannedTitle>
                <PlannedZeroPrice>10 700 zł <EditIcon/></PlannedZeroPrice>
            </PlannedContent>
        </PlannedWrap>
    );
};
