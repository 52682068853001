import React, {FC, useState} from 'react';
import {Form, Formik} from 'formik';
import {ReactComponent as СrossIcon} from '../../../../../assets/main/wedding/close.svg'
import {CloseButton, StyledTitle} from "../../../wedding/popUp/style-popups";
import {
    Checkbox, FormDiv,
    GuestBlock,
    GuestBlockInfo,
    SharingFormBlock,
    SharingFormContainer,
    SharingFormWrapper
} from "./style-sharing-form";


interface MyFormValues {
    id: string;
    checkbox: boolean;
}

type PropsType = {
    handleClosePopup: () => void;
};


const guests = [
    {
        id: 1,
        checkbox: true,
        name: 'Janusz Biernacka',
        email: 'janusz_biernacka@gmail.com',
    },
    {
        id: 2,
        checkbox: false,
        name: 'Małgorzata Żurek   ',
        email: 'Żurek1975@gmail.com',
    },
    {
        id: 3,
        checkbox: true,
        name: 'Małgorzata Żurek   ',
        email: 'Żurek1975@gmail.com',
    },
    {
        id: 4,
        checkbox: true,
        name: 'Małgorzata Żurek   ',
        email: 'Żurek1975@gmail.com',
    },
    {
        id: 5,
        checkbox: true,
        name: 'Janusz Biernacka',
        email: 'janusz_biernacka@gmail.com',
    },
    {
        id: 6,
        checkbox: false,
        name: 'Małgorzata Żurek   ',
        email: 'Żurek1975@gmail.com',
    },
    {
        id: 7,
        checkbox: true,
        name: 'Małgorzata Żurek   ',
        email: 'Żurek1975@gmail.com',
    },
    {
        id: 8,
        checkbox: true,
        name: 'Małgorzata Żurek   ',
        email: 'Żurek1975@gmail.com',
    },
    {
        id: 9,
        checkbox: true,
        name: 'Janusz Biernacka',
        email: 'janusz_biernacka@gmail.com',
    },
    {
        id: 10,
        checkbox: false,
        name: 'Małgorzata Żurek   ',
        email: 'Żurek1975@gmail.com',
    },
    {
        id: 11,
        checkbox: true,
        name: 'Małgorzata Żurek   ',
        email: 'Żurek1975@gmail.com',
    },
    {
        id: 12,
        checkbox: true,
        name: 'Małgorzata Żurek   ',
        email: 'Żurek1975@gmail.com',
    },
    {
        id: 13,
        checkbox: true,
        name: 'Janusz Biernacka',
        email: 'janusz_biernacka@gmail.com',
    },
    {
        id: 14,
        checkbox: false,
        name: 'Małgorzata Żurek   ',
        email: 'Żurek1975@gmail.com',
    },
    {
        id: 15,
        checkbox: true,
        name: 'Małgorzata Żurek   ',
        email: 'Żurek1975@gmail.com',
    },
    {
        id: 16,
        checkbox: true,
        name: 'Małgorzata Żurek   ',
        email: 'Żurek1975@gmail.com',
    },
    {
        id: 17,
        checkbox: false,
        name: 'Małgorzata Żurek   ',
        email: 'Żurek1975@gmail.com',
    },
    {
        id: 18,
        checkbox: false,
        name: 'Małgorzata Żurek   ',
        email: 'Żurek1975@gmail.com',
    },
    {
        id: 19,
        checkbox: true,
        name: 'Małgorzata Żurek   ',
        email: 'Żurek1975@gmail.com',
    },
    {
        id: 20,
        checkbox: false,
        name: 'Janusz Biernacka',
        email: 'janusz_biernacka@gmail.com',
    },
    {
        id: 21,
        checkbox: false,
        name: 'Małgorzata Żurek   ',
        email: 'Żurek1975@gmail.com',
    },
];


const SharingForm: FC<PropsType> = ({handleClosePopup}) => {
    const [checkboxValue, setCheckboxValue] = useState(true);

    const handleSubmit = (values: MyFormValues) => {
        // Обработка отправки формы
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        setCheckboxValue(isChecked);
    };

    return (
        <Formik
            initialValues={{
                id: '',
                checkbox: checkboxValue,
            }}
            onSubmit={handleSubmit}
        >
            {({values, handleSubmit, handleChange, setFieldValue, errors, touched}) => (
                <SharingFormWrapper onClick={(e) => e.stopPropagation()}>
                    {/*<Form onSubmit={handleSubmit}>*/}
                    <FormDiv>
                        <CloseButton type="button" onClick={handleClosePopup}>
                            <СrossIcon/>
                        </CloseButton>
                        <StyledTitle>Wybierz gości</StyledTitle>
                        <SharingFormContainer>
                            <SharingFormBlock>
                                {guests.map((guest) => (
                                    <GuestBlock key={guest.id}>
                                        <Checkbox
                                            type="checkbox"
                                            checked={guest.checkbox}
                                            onChange={handleCheckboxChange}
                                            value={guest.checkbox.toString()}
                                        />
                                        <GuestBlockInfo>
                                            <p>{guest.name}</p>
                                            <span>{guest.email}</span>
                                        </GuestBlockInfo>
                                    </GuestBlock>
                                ))}
                            </SharingFormBlock>
                        </SharingFormContainer>
                </FormDiv>
                    {/*</Form>*/}
                </SharingFormWrapper>
            )}
        </Formik>
    );
};
export default SharingForm;

{/*<FormBlock>*/
}
{/*    <StyledLabel htmlFor="title">Tytuł</StyledLabel>*/
}
{/*    <StyledInput id="title" name="title" type="text"*/
}
{/*                 onChange={handleChange}*/
}
{/*                 value={values.title}/>*/
}
{/*</FormBlock>*/
}
