import React from 'react';
import usePopup from "../../../../../../common/hook/use-popup";
import {AddNotePopup} from "./AddNotePopup";
import {PointsTitle} from "../../points-expense/style-points";




interface IExpensesId {
    expensesId: string;
}

export const PointsPopupAddNote = ({expensesId}: IExpensesId) => {
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();

    return (
        <>
            <PointsTitle onClick={onClickHandler}>Dodaj notatkę</PointsTitle>
            {showPopup &&
                <AddNotePopup expensesId={expensesId} show={showPopup} handleClosePopup={handleClosePopup}
                                       onClickClosest={onClickClosest}/>}
        </>
    );
};

