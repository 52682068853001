import React, {useState} from 'react';
import {
    ClickMap,
    ClickMapText,
    IconLeft,
    IconRight,
    Information,
    InformationBlock,
    InformationText,
    LocationInformation,
    LocationInformationBlock,
    LocationText,
    LocationTitle,
    WebsiteInformationWrap
} from "./style-website-information";
import {ReactComponent as LeftIcon} from '../../../../../assets/main/website/left.svg';
import {ReactComponent as RightIcon} from '../../../../../assets/main/website/right.svg';
import {Container} from "../website-attendance/style-website-attendance";
import {ReactComponent as EyeIcon} from '../../../../../assets/main/website/eye.svg';
import {ReactComponent as VisibilityOffEyeIcon} from '../../../../../assets/main/website/visibility-off-eye.svg';
import {ReactComponent as EditIcon} from '../../../../../assets/main/website/edit.svg';
import {
    Button,
    ButtonEye,
    ButtonVisibilityOff,
    EditBlocks,
    HiddenMessage,
    OurStoryButton
} from "../website-our-story/style-website-our-story";
import usePopup from "../../../../../common/hook/use-popup";
import {Popups} from "../../../../popups/Popups";
import {FormInformation} from "./form-information/FormInformation";
import {IWebsite} from "../types";


const information = [
    {
        title: 'Ceremonia zaślubin',
        text: "Kraków, ul. Krakowskie Przedmieście 201, Zielonki - Trojanowice, Małopolskie",
    },
    {
        title: 'Ceremonia zaślubin',
        text: "Kraków, ul. Krakowskie Przedmieście 201, Zielonki - Trojanowice, Małopolskie",
    },
]


export const WebsiteInformation = ({backgroundColor,textColor}: IWebsite) => {
    const [isHidden, setIsHidden] = useState<boolean>(true);
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();
    const toggleHidden = () => {
        setIsHidden(!isHidden);
    };


    return (
        <div id="lokalizacja">
            {isHidden ? (
                <WebsiteInformationWrap>
                    <Container>
                        <OurStoryButton>
                            <Button
                                onClick={onClickHandler}
                                className="edit-button">
                                <EditBlocks>
                                    <EditIcon/>
                                    <span>Edytuj</span>
                                </EditBlocks>
                            </Button>
                            <ButtonEye onClick={toggleHidden} className="eye-button"><EyeIcon/></ButtonEye>
                        </OurStoryButton>
                        <InformationBlock>
                            <Information>
                                <InformationText>
                                    Mamy nadzieję, że będziecie tego dnia razem z nami.
                                    Poniżej znajdziecie niezbędne informacje organizacyjne oraz sekcję "potwierdzenie
                                    obecności"
                                </InformationText>
                            </Information>
                            <LocationInformation>
                                {information.map((el, index) => {
                                    return (
                                        <LocationInformationBlock key={index}>
                                            <LocationTitle>
                                                {el.title}
                                            </LocationTitle>
                                            <LocationText style={{ color: textColor }}>
                                                {el.text}
                                            </LocationText>
                                        </LocationInformationBlock>
                                    )
                                })}
                            </LocationInformation>
                            <ClickMap>
                                <ClickMapText>
                                    Kliknij w link, aby zobaczyć adres na mapie
                                </ClickMapText>
                            </ClickMap>
                            <IconLeft>
                                <LeftIcon/>
                            </IconLeft>
                            <IconRight>
                                <RightIcon/>
                            </IconRight>
                        </InformationBlock>
                    </Container>
                </WebsiteInformationWrap>
            ) : (
                <HiddenMessage>
                    <span>Blok jest ukryty. Kliknij przycisk Ukryj/Pokaż po prawej stronie, aby był widoczny.</span>
                    <ButtonVisibilityOff onClick={toggleHidden}
                                         className="eye-button"><VisibilityOffEyeIcon/></ButtonVisibilityOff>
                </HiddenMessage>
            )}
            <Popups
                show={showPopup}
                handleClosePopups={handleClosePopup}
                onClickClosest={onClickClosest}
                formComponent={<FormInformation handleClosePopup={handleClosePopup}/>}
            />
        </div>
    );
};

