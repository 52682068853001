import React, {FC} from 'react';
import {
    ContentInfo,
    ContentName,
    ContentNameBlock,
    NewlywedsSpoilersContainer,
    NewlywedsSpoilersContent,
    NewlywedsSpoilersWrap,
    SpoilerH, SpoilerHOne,
    SpoilerItem,
    SpoilerNewlywedsTitle
} from "./style-newlyweds-spoilers";
import {useAppDispatch, useAppSelector} from "../../../../../common/hook";
import {spoilersSelector} from "../../../../../app/selectors/spoilers";
import {
    ArrayBlock,
    ContentPaints,
    SpoilerBox,
    SpoilerButton,
    SpoilerContainer,
    SpoilerContent,
    SpolletItemBlock,
    StyledArrayIcon
} from "../../../tasks/style-tasks";
import {setIsOpen} from "../../../../../app/task-reduser";
import {ExpenseButton, ExpenseWhiteButton} from "../../../budget/butget-expense/style-budgetExpense";
import {ButtonGuest, ButtonWhiteGuest, CalendarText} from "../../../../calendar-Info/styles";
import usePopup from "../../../../../common/hook/use-popup";
import {ReactComponent as Plus} from '../../../../../assets/main/guest/plus.svg';
import {GuestPopups} from "../../gruest-container/guest-form/guest-popup-form/GuestPopups";
import {GuestPoints} from "../guest-points/GuestPoints";
import {NewlywedsPoints} from "./newlyweds-points/NewlywedsPoints";


export type NewlywedsArray = {
    id: string,
    number: number,
    name: string,
    iconInvited: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
    iconConfirmed: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
    iconChurch: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
    iconRestaurant: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
    iconAccommodation: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
    iconTransport: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
    iconDiet: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
}

interface PropsType {
    index: number;
    isExpanded: boolean;
    newlywedsArray: NewlywedsArray[]
    // newlywedsArrayTwo: NewlywedsArray[]

}


export const NewlywedsSpoilers: FC<PropsType> = ({index, newlywedsArray}) => {
    const spoilers = useAppSelector(spoilersSelector);
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();

    const dispatch = useAppDispatch();

    const handleSpoilerToggle = () => {
        dispatch(setIsOpen({index}));
    };



    return (
        <NewlywedsSpoilersWrap>
            <NewlywedsSpoilersContainer>
                <SpoilerButton style={{background: '#F5F9FF', border: 'none'}}
                               onClick={handleSpoilerToggle}>
                    <SpoilerNewlywedsTitle>Rodzina</SpoilerNewlywedsTitle>
                    <SpolletItemBlock>
                        <ArrayBlock>
                            <StyledArrayIcon isOpenSpoiler={!spoilers[index].isOpenSpoiler}/>
                        </ArrayBlock>
                        <ContentPaints onClick={(e) => e.stopPropagation()}>
                            <NewlywedsPoints expensesId={'1'} />
                        </ContentPaints>
                    </SpolletItemBlock>
                </SpoilerButton>
                <SpoilerBox isOpenSpoiler={!spoilers[index].isOpenSpoiler}>
                    <SpoilerH>
                        <SpoilerHOne>Imię i nazwisko</SpoilerHOne>
                        <SpoilerItem>
                            <span>Zaproszony</span>
                            <span>Potwierdzony</span>
                            <span>Kościół</span>
                            <span>Restauracja</span>
                            <span>Nocleg</span>
                            <span>Transport</span>
                            <span>Dieta specjalna</span>
                            <span style={{width:'45px'}}/>
                        </SpoilerItem>
                    </SpoilerH>
                    {newlywedsArray.map(el => {
                        return (
                            <SpoilerContainer>
                                <SpoilerContent isOpen={!spoilers[index].isOpenSpoiler}>
                                    <NewlywedsSpoilersContent>
                                        <ContentName>
                                            <ContentNameBlock>
                                                <p>{el.number}</p>
                                                <span>{el.name}</span>
                                            </ContentNameBlock>
                                        </ContentName>
                                        <ContentInfo>
                                            <span><el.iconInvited/></span>
                                            <span><el.iconConfirmed/></span>
                                            <span><el.iconChurch/></span>
                                            <span><el.iconRestaurant/></span>
                                            <span><el.iconAccommodation/></span>
                                            <span><el.iconTransport/></span>
                                            <span><el.iconDiet/></span>
                                            <span style={{width:'20px'}}>
                                                    <GuestPoints expensesId={el.id}/>
                                            </span>
                                        </ContentInfo>
                                    </NewlywedsSpoilersContent>
                                </SpoilerContent>
                            </SpoilerContainer>
                        )
                    })}
                </SpoilerBox>
                <ExpenseWhiteButton>
                    <ButtonWhiteGuest onClick={onClickHandler}>
                        <Plus/>
                        <CalendarText>Dodaj gościa</CalendarText>
                    </ButtonWhiteGuest>
                </ExpenseWhiteButton>
                <GuestPopups
                    show={showPopup}
                    handleClosePopup={handleClosePopup}
                    onClickClosest={onClickClosest}
                />
            </NewlywedsSpoilersContainer>
        </NewlywedsSpoilersWrap>
    );
};
