import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type UserStateType = {
    selectedImage: Blob | null;
    bride: string;
    brideEmail: string;
    brideTel: string;
    bridePhoto: Blob | null;
    groom: string;
    groomEmail: string;
    groomTel: string;
    groomPhoto: Blob | null;
    wendingDate: string | null;
    weddingTime: string;
    serviceLocation: string;
    wendingLocation: string;
    additionalText: string;
};

const initialState: UserStateType = {
    selectedImage: null,
    bride: '',
    brideEmail: '',
    brideTel: '',
    bridePhoto: null,
    groom: '',
    groomEmail: '',
    groomTel: '',
    groomPhoto: null,
    wendingDate: null,
    weddingTime: '',
    serviceLocation: '',
    wendingLocation: '',
    additionalText: '',
};

const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUserInfoAC(state, action: PayloadAction<UserStateType>) {
            return { ...state, ...action.payload };
        },
    },
});

export const {setUserInfoAC} = slice.actions;
export const userReducer = slice.reducer;
