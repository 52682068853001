import {fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import type {BaseQueryFn, FetchArgs, FetchBaseQueryError,} from '@reduxjs/toolkit/query'
import {Mutex} from 'async-mutex'
import Cookies from "universal-cookie";

// create a new mutex
const mutex = new Mutex()
const cookies = new Cookies();
const baseQuery = fetchBaseQuery(
    {
        // baseUrl: '/api',
        baseUrl: 'https://gdzie-wesele-corp.iqstage.online/api',
        // credentials: 'include',

        prepareHeaders: headers => {
            const token = cookies.get('jwt_authorization');

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    })

export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {

    // wait until the mutex is available without locking it
    await mutex.waitForUnlock()
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
        // checking whether the mutex is locked
        if (!mutex.isLocked()) {
            const release = await mutex.acquire()
            try {
                await baseQuery(
                    {
                        method: 'POST',
                        url: '/auth/refresh-token'
                    },
                    api,
                    extraOptions
                )
                result = await baseQuery(args, api, extraOptions)

            } finally {
                // release must be called once the mutex should be released again.
                release()
            }
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock()
            result = await baseQuery(args, api, extraOptions)
        }
    }
    return result
}
