import styled from 'styled-components';




export const ContHeg50 = styled.div`
  width: 100%;
  background-color: #fd7b52;
`;

export const ContSelectCenter = styled.div`

`;

export const SelectMate = styled.div`
  position: relative;
  float: left;
  width: 100%;
  height: 48px;
  color: #777;
  font-weight: 300;
  background-color: #fff;
  border: 0.6px solid #D2DAE3;
  border-radius: 5px;
  transition: all 375ms ease-in-out;
`;

export const Select = styled.select`
  position: absolute;
  overflow: hidden;
  height: 0;
  opacity: 0;
  z-index: -1;
`;

export const SelecionadoOpcion = styled.p`
  padding: 13px 20px;
  width: 100%;
  display: block;
  margin: 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  align-items: center;
  color: #0C1215;
  opacity: 0.8;
`;

export const IconSelectMate = styled.span`
  position: absolute;
  top: 13px;
  right: 3%;
  height: 20px;
  transition: all 275ms;
  opacity: 0.6;
`;

export const ContListSelectMate = styled.div`
  position: relative;
  float: left;
  width: 100%;
`;

export const ContSelectInt = styled.ul`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  overflow: hidden;
  height: 0;
  width: 100%;
  background-color: #1B5EB5;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;
  border-radius: 0 0 3px 3px;
  box-shadow: 1px 4px 10px -2px rgba(0, 0, 0, 0.2);
  transition: all 375ms ease-in-out;

  li {
    position: relative;
    float: left;
    width: 96%;
    //border-bottom: 1px solid #e0e0e0;
    background-color: #1B5EB5;
    list-style-type: none;
    padding: 10px 2%;
    margin: 0;
    transition: all 275ms ease-in-out;
    display: block;
    cursor: pointer;
    color: #FFF;
    font-family: Gilroy,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.84px;

    &:last-child {
      border-radius: 3px;
      border-bottom: 0;
    }

    &:hover {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .active {

  }
`;