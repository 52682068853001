import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './style.css'
import {Pagination} from "swiper";
import {FromWhom, QuoteIconIcon, SliderBlock, SliderContainer, SliderText} from "../style-website-guestbook";
import {IWebsite} from "../../types";


export const data = [
    {
        text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        from: 'Janusz Biernacka',
    },
    {
        text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        from: 'Ivan Ivanov',
    },
    {
        text:
            'Lorem ipsum dolor sit incididunt ostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        from: 'Janusz Biernacka',
    },
    {
        text:
            'Lorem ipsum dolor sit am aliqua.',
        from: 'Ivan Ivanov',
    },
    {
        text:
            'Lorem ipsum dolor sit incididunt ostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        from: 'Janusz Biernacka',
    },
    {
        text:
            'Lorem ipsum dolor sit am aliqua.',
        from: 'Ivan Ivanov',
    },
];


export const Slider = ({textColor}: IWebsite) => {
    return (
        <Swiper
            pagination={true}
            modules={[Pagination]}
            className="mySwiper"
        >
            {data.map((item, index) => (
                <SwiperSlide key={index}>
                    <SliderContainer>
                        <SliderBlock>
                            <QuoteIconIcon color={textColor}/>
                            <SliderText>{item.text}</SliderText>
                        </SliderBlock>
                        <FromWhom>{item.from}</FromWhom>
                    </SliderContainer>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};


