import React from 'react';
import {Popup} from "../../../../wedding/popUp/style-popups";
import {AddNoteForm} from "./AddNoteForm";



interface PopupProps {
    show: boolean;
    handleClosePopup: () => void;
    onClickClosest: () => void;
    expensesId: string;
}


export const AddNotePopup = ({show, handleClosePopup, onClickClosest,expensesId}: PopupProps) => {
    return (
        <Popup show={show} onClick={onClickClosest}>
            <AddNoteForm handleClosePopup={handleClosePopup} expensesId={expensesId} />
        </Popup>
    );
}

