import React, {useState} from "react";
import "react-calendar/dist/Calendar.css";

import {useAppDispatch} from "../../../common/hook";
import Calendar from "react-calendar";
import {CalendarContainer,} from "./style-calendar";
import pl from "date-fns/locale/pl";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import {View} from "react-calendar/dist/cjs/shared/types";
import {EditPopups} from "../../pages/wedding/popUpEdit/EditPopups";
import {CalendarInfoComponent} from "../../calendar-Info";
import {setData} from "../../../app/main-reduser";
import usePopup from "../../../common/hook/use-popup";


registerLocale("pl", pl);
setDefaultLocale("pl");

const locale = "pl";

interface ICalendarComponent {
    isShowCalendarInfo: boolean
}

const CalendarComponent = ({isShowCalendarInfo}: ICalendarComponent) => {
    const [date, setDate] = useState<Date>(new Date());
    const dispatch = useAppDispatch();
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();


    const onDateChange = (value: any) => {
        const currentDate = new Date();
        const startOfCurrentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        if (value && value instanceof Date) {
            if (value < startOfCurrentDate) {
                console.error("Вы не можете выбрать прошедшую дату");
                return;
            }
            const formattedDate = value.toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
            });
            onClickHandler()
            if (formattedDate) {
                dispatch(setData(formattedDate))
            } else {
                console.error("Неверный формат даты");
            }
        } else {
            console.error("Неверное значение даты");
        }
    };


    const shortWeekdayLabels = {
        weekdayShort: ["N","P", "W", "Ś", "C", "P", "Ś"],
    };


    return (
        <CalendarContainer>
            <div onClick={onDateChange}>
                <Calendar
                    locale={locale}
                    onChange={onDateChange}
                    value={date}
                    // defaultValue={[date, selectedDate]}
                    view={"month" as View}
                    formatShortWeekday={(locale, date) =>
                        shortWeekdayLabels.weekdayShort[date.getDay()]
                    }
                />
            </div>
            {isShowCalendarInfo && <CalendarInfoComponent onClickHandler={onClickHandler}/>}
            {isShowCalendarInfo && <EditPopups show={showPopup} handleClosePopup={handleClosePopup} onClickClosest={onClickClosest}/>}
        </CalendarContainer>
    );
};

export default CalendarComponent;
