import React from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../../../common/hook";
import {deleteExpense} from "../../../../../../../app/budget-reduser";
import {PointsTitle} from "../../style-points";


interface IExpensesId {
    expensesId :string;
}
export const PointsPopupDelete = ({expensesId}: IExpensesId) => {

    const dispatch = useAppDispatch();
    const { categories,currentCategoryID } = useAppSelector((state) => state.budget);

    const onChandlerDeleteExpense = ()=> {
        const categoryObject = categories.filter(cat => cat.id === currentCategoryID)[0];
        const filteredExpenses = categoryObject.expenses?.filter(exp => exp.id !== expensesId);
        dispatch(deleteExpense({id:currentCategoryID, filteredExpenses: filteredExpenses!}))
    }
    return (
            <PointsTitle onClick={onChandlerDeleteExpense}>Usuń</PointsTitle>
    );
};

