import styled from "styled-components";

export const WebsiteGiftsWrap = styled.section`
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease; 

  &:hover {
    border: 1px dashed #D2DAE3;
    padding: 10px;

    .edit-button,
    .eye-button {
      display: block;
    }
  }
`;


export const WebsiteGiftsBlock = styled.div`
  position: absolute;
  left: -1px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 3vw; /* Задайте отступы в процентах от ширины экрана */
  border-radius: 5px;
  background: #FFF;
  padding: 3vw 3vw 3vw 2vw; /* Задайте отступы в процентах от ширины экрана */
`;

export const WebsiteGiftsTitle = styled.p`
  color: #0C1215;
  font-family: Gilroy;
  font-size: 46px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 768.98px) {
    font-size: 36px;
  }
  @media (max-width: 425.98px) {
    font-size: 28px;
  }
`;

export const WebsiteGiftsItems = styled.ul`
  width: 581px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 769.98px) {
    width: 100%;
    gap: 10px;
  }
`;

export const WebsiteGiftsItem = styled.li`
  width: 444px;
  color: #0C1215;
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;

  @media (max-width: 768.98px) {
    width: 100%;
  }
`;

export const ItemBlock = styled.div`
  display: flex;
  gap: 18px;
  align-items: baseline;
  line-height: 140%;

  svg {
    width: 7px;
    height: 7px;
    flex-shrink: 0;
  }
`;

export const GiftText = styled.p`
  color: #0C1215;
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 25.2px */
  width: 444px;
  @media (max-width: 768.98px) {
    width: 100%;
  }
`;
