import React from 'react';

import SharingForm from "../sharing-form/SharingForm";
import {Popup} from "../../../wedding/popUp/style-popups";



interface PopupProps {
    show: boolean;
    handleClosePopup: () => void;
    onClickClosest: () => void;
}

export const SharingPopup = ({show, handleClosePopup, onClickClosest}: PopupProps) => {
    return (
        <Popup show={show} onClick={onClickClosest}>
            <SharingForm handleClosePopup={handleClosePopup}/>
        </Popup>
    );
}

