import styled from "styled-components";

export const ListQuestionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 80px;
  @media (max-width: 1024.98px) {
    gap: 10px;
    margin-bottom: 40px;
  }
`

export const ListContainer = styled.div`
  padding: 30px 25px;
  background-color: #F5F9FF;
 border-radius: 5px;
  @media (max-width: 425.98px) {
    padding: 10px;
  }
`

export const ListTitle = styled.div`
  font-family: 'Gilroy', sans-serif;
  display: flex;
  gap: 10px;
  color: #0C1215;
  align-items: start;
  margin-bottom: 12px;

  a {
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    cursor: pointer;
    transition: color 0.3s, text-decoration 0.3s;

    &:hover {
      text-decoration-line: underline !important;
      color: #1056B2;
    }
  }

  span {
    border-radius: 30px 30px 30px 0;
    border: 1px solid  #D2DAE3;
    padding: 6px 5.5px;
    margin-left: auto;
    color: #0C1215;
    font-family: Gilroy,sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const ListCategory = styled.div`
  font-family: 'Gilroy', sans-serif;
  display: flex;
  gap: 12px;
  margin-bottom: 28px;
  color: #0C1215;
  align-items: center;
  @media (max-width: 768.98px) {
   justify-content: space-between;
    margin-bottom: 10px;
  }


  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
  }

  span {
    background-color: #1056B2;
    border-radius: 20px;
    padding: 10px 14px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
  }
`
export const ListReply = styled.p`
  font-family: 'Gilroy',sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #1056B2;
  margin-left: 17px;
  cursor: pointer;
`

export const ListComment = styled.div`
  font-family: 'Gilroy', sans-serif;
  display: flex;
  gap: 18.5px;
  align-items: center;
  margin-bottom: 15px;

  img {
    width: 21.5px;
    height: 19px;
  }

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #0C1215;
  }
`
export const ListText = styled.p`
  font-family: 'Gilroy', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 140%;
  color: #0C1215;
  margin-bottom: 12px;
`

export const ListUser = styled.div`
  display: flex;
  gap: 13px;
  align-items: center;

`

export const ListDate = styled.div`
  display: flex;
  gap: 8px;

  p {
    font-family: 'Gilroy', sans-serif;
    color: #0C1215;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }

  span {
    border: 0.6px solid #0C1215;
    opacity: 0.4;
  }

`