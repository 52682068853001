import React, {FC, useState} from 'react';
import {StyledPointsIcon} from "../../tasks/style-tasks";
import {ButtonPopup, TabButton, TabButtonContent, TabWrapper} from "./style-plan-tab";

import usePopup from "../../../../common/hook/use-popup";
import {PlanTabPopups} from "./plan-tab-form/plan-tab-popup/PlanTabPopups";
import {PointsPointsTask} from "../plan-contant/plan-tables/plan-points/points-popup/PointsPopup";

type PlanTabProps = {
    dates: string[];
    activeTab: number;
    setActiveTab: (index: number) => void;
};

export const PlanTab: FC<PlanTabProps> = ({ dates, activeTab, setActiveTab }) => {
    const { showPopup, onClickHandler, handleClosePopup, onClickClosest } = usePopup();
    const [openPopupId, setOpenPopupId] = useState<string | null>(null);
    const handleTabClick = (index: number) => () => {
        setActiveTab(index);
    };
    const handleTogglePopup = (id: string) => {
        setOpenPopupId((prevId) => (prevId === id ? null : id));
    };


    return (
        <TabWrapper>
            {dates.map((date, index) => (
                <TabButton key={index} isActive={index === activeTab} onClick={handleTabClick(index)}>
                    <TabButtonContent>
                        <span>{date}</span>
                        <PointsPointsTask
                            expensesId={date}
                            open={openPopupId === date}
                            togglePopup={handleTogglePopup}
                        />
                    </TabButtonContent>
                </TabButton>
            ))}
            <ButtonPopup onClick={onClickHandler}>+ Dodaj nową pozycję</ButtonPopup>
            <PlanTabPopups
                show={showPopup}
                handleClosePopup={handleClosePopup}
                onClickClosest={onClickClosest}
            />
        </TabWrapper>
    );
};
