import React from 'react';
import {BudgetContainer} from "./style-budget";
import {BudgetCategory} from "./budget-category/BudgetCategory";
import {BudgetExpense} from "./butget-expense/BudgetExpense";
import {BudgetTitle} from "./budget-title/BudgetTitle";
import {FullWrapper} from "../../layout/style-layout";


export const Budget = () => {
    return (
        <FullWrapper>
            <BudgetTitle/>
            <BudgetContainer>
                <BudgetCategory/>
                <BudgetExpense/>
            </BudgetContainer>
        </FullWrapper>
    );
};

