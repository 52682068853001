import styled from "styled-components";

export const WebsiteWrap = styled.section`
  width: 100%;
  padding-top: 124px;
  padding-left: 30px;
  @media (max-width: 1000.98px) {
    padding-top: 100px;
    padding-left: 0;
  }
`;

export const WebsiteContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const WebsiteTitle = styled.p`
  color: #051F41;
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  margin-bottom: 30px;
`;

export const WebsiteItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

