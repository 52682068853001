import img1 from '../../../../../assets/main/favorites/dresses/one.png'
import img2 from '../../../../../assets/main/favorites/dresses/two.png'
import img3 from '../../../../../assets/main/favorites/dresses/three.png'
import img4 from '../../../../../assets/main/favorites/dresses/four.png'
import img5 from '../../../../../assets/main/favorites/dresses/five.png'
import img6 from '../../../../../assets/main/favorites/hotels-slider/one.png'
import img7 from '../../../../../assets/main/favorites/hotels-slider/two.png'
import img8 from '../../../../../assets/main/favorites/hotels-slider/three.png'
import img9 from '../../../../../assets/main/favorites/hotels-slider/four.png'
// import one from "../../../../../assets/main/favorites/hotels-slider/one.png";
// import two from "../../../../../assets/main/favorites/hotels-slider/two.png";
// import three from "../../../../../assets/main/favorites/hotels-slider/three.png";
// import four from "../../../../../assets/main/favorites/hotels-slider/four.png";


interface IProduct {
    name: string;
    priceLocal: string;
    img: string | string[];
    type: string;
}

const imagesArray = [img6, img7, img8, img9];

export const ProductsData:IProduct[] = [{
        name: 'Buty ślubne CINDRELLA',
        priceLocal: '399,00 zł',
        img: img1,
        type: 'footwear',
    },
    {
        name: 'Elagancka suknia firmy Agnes LO 253',
        priceLocal: '600,00 zł',
        img: img2,
        type: 'dresses',
    },
    {
        name: 'Kwiatowa dekoracja',
        priceLocal: '106,00 zł',
        img: img3,
        type: 'dresses',
    },
    {
        name: 'Suknia firmy Agnes LO 115',
        priceLocal: '399,00 zł',
        img: img4,
        type: 'dresses',
    },
    {
        name: 'Koło ścianka stelaż',
        priceLocal: '99,00 zł',
        img: img5,
        type: 'footwear',
    },
    //
    {
        name: 'Hotel Chopin',
        priceLocal: 'Michałowem, mazowieckie',
        img: imagesArray,
        type: 'hotels',
    },
    {
        name: 'Willa Zagórze',
        priceLocal: 'Michałowem, mazowieckie',
        img: imagesArray,
        type: 'hotels',
    },

    {
        name: 'Suknia firmy Agnes LO 115',
        priceLocal: 'Michałowem, mazowieckie',
        img: imagesArray,
        type: 'hotels',
    },
    {
        name: 'Suknia firmy Agnes LO 115',
        priceLocal: 'Michałowem, mazowieckie',
        img: imagesArray,
        type: 'hotels',
    },
    {
        name: 'Photographer',
        priceLocal: 'Michałowem, mazowieckie',
        img: imagesArray,
        type: 'photographer',
    },
    ////
]