import React from 'react';
import {Popup} from "../../../wedding/popUp/style-popups";
import {FormCategory} from "../form-category/FormCategory";



interface PopupProps {
    show: boolean;
    handleClosePopup: () => void;
    onClickClosest: () => void;
}

export const CategoryPopup = ({show, handleClosePopup, onClickClosest}: PopupProps) => {
    return (
        <Popup show={show} onClick={onClickClosest}>
            <FormCategory handleClosePopup={handleClosePopup}/>
        </Popup>
    );
}

