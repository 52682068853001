import styled from "styled-components";
import {Budget} from "./Budget";


export const BudgetContainer = styled.div`
  display: flex;
  gap: 30px;

  @media (max-width: 1384.98px) {
    flex-direction: column;
  }


`