import React from 'react';
import {PointsPopupWrap} from "./style-points-popup";
import {PointsPopupDelete} from "./points-popup-delete/PointsPopupDelete";
import {PointsPopupStatus} from "./points-popup-status/PointsPopupStatus";
import {PointsPopupEdit} from "./points-popup-edit/points-popup-edit/PointsPopupEdit";
import {PointsPopupAddNote} from "../../expense-add-note/points-popup-add-note/PointsPopupAddNote";


interface IExpensesId {
    expensesId: string;
}

export const PointsPopup = ({ expensesId }: IExpensesId) => {
    return (
        <PointsPopupWrap>
            <PointsPopupEdit expensesId={expensesId} />
            <PointsPopupStatus expensesId={expensesId} />
            <PointsPopupAddNote expensesId={expensesId} />
            <PointsPopupDelete expensesId={expensesId} />
        </PointsPopupWrap>
    );
};