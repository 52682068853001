import React, {useState} from 'react';
import {
    GiftText,
    WebsiteGiftsBlock,
    WebsiteGiftsItem,
    WebsiteGiftsItems,
    WebsiteGiftsTitle,
    WebsiteGiftsWrap
} from "../website-gifts/style-website-gifts";
import {ImageContainer, ResponsiveImage} from "../website-full/style-website-full";
import imageSrc from "../../../../../assets/main/website/collection.png";
import {
    CollectionBlock,
    CollectionButton,
    CollectionFooter,
    CollectionText,
    DownloadIcons,
    ItemBlockNumber
} from "./style-website-collection";
import {ReactComponent as EyeIcon} from '../../../../../assets/main/website/eye.svg';
import {ReactComponent as VisibilityOffEyeIcon} from '../../../../../assets/main/website/visibility-off-eye.svg';
import {ReactComponent as EditIcon} from '../../../../../assets/main/website/edit.svg';
import {CalendarText} from "../../../../calendar-Info/styles";
import {IWebsite} from "../types";
import {
    Button,
    ButtonEye,
    ButtonVisibilityOff,
    EditBlocks,
    HiddenMessage,
    OurStoryButton
} from "../website-our-story/style-website-our-story";
import usePopup from "../../../../../common/hook/use-popup";
import {Popups} from "../../../../popups/Popups";
import {FormCollection} from "./form-collection/FormCollection";
import {CollectionUpload} from "./collection-upload/CollectionUpload";
import {WebsitePreview} from "./website-preview/WebsitePreview";

export const WebsiteCollection = ({backgroundColor, textColor}: IWebsite) => {
    const [isHidden, setIsHidden] = useState<boolean>(true);
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();
    const toggleHidden = () => {
        setIsHidden(!isHidden);
    };

    const giftData = [
        {
            number: '1',
            text: "Prześlij zdjęcie"
        },
        {
            number: '2',
            text: "Sprawdzimy Twoje zdjęcia"
        },
        {
            number: '3',
            text: "Jesteśmy słodyczoholikami, dlatego zamiast kwiatów prosimy o słodki podarunek."
        },
        {
            number: '4',
            text: "Zdjęcia pojawią się w galerii na tej stronie"
        }
    ];

    return (
        <div id="photos">
            {isHidden ? (
                <WebsiteGiftsWrap>
                    <ImageContainer>
                        <ResponsiveImage src={imageSrc} alt={''}/>
                        <OurStoryButton>
                            <Button
                                onClick={onClickHandler}
                                className="edit-button">
                                <EditBlocks>
                                    <EditIcon/>
                                    <span>Edytuj</span>
                                </EditBlocks>
                            </Button>
                            <ButtonEye onClick={toggleHidden} className="eye-button"><EyeIcon/></ButtonEye>
                        </OurStoryButton>
                        <WebsiteGiftsBlock>
                            <WebsiteGiftsTitle>
                                Kolekcja zdjęć gości
                            </WebsiteGiftsTitle>
                            <WebsiteGiftsItems>
                                <CollectionText>Podziel się zdjęciami z naszego ślubu! Uchwyćmy razem ten dzień! To
                                    bardzo
                                    proste, wystarczy kilka prostych kroków:</CollectionText>
                                {giftData.map((gift, index) => (
                                    <WebsiteGiftsItem key={index}>
                                        <CollectionBlock>
                                            <ItemBlockNumber style={{background: backgroundColor}}>
                                                {gift.number}
                                            </ItemBlockNumber>
                                            <GiftText>
                                                {gift.text}
                                            </GiftText>
                                        </CollectionBlock>
                                    </WebsiteGiftsItem>
                                ))}
                                <CollectionFooter>
                                    <CollectionUpload backgroundColor={backgroundColor} textColor={textColor}/>
                                    <WebsitePreview backgroundColor={backgroundColor} textColor={textColor}/>
                                </CollectionFooter>
                            </WebsiteGiftsItems>
                        </WebsiteGiftsBlock>
                    </ImageContainer>
                </WebsiteGiftsWrap>
            ) : (
                <HiddenMessage>
                    <span>Blok jest ukryty. Kliknij przycisk Ukryj/Pokaż po prawej stronie, aby był widoczny.</span>
                    <ButtonVisibilityOff
                        onClick={toggleHidden}
                        className="eye-button">
                        <VisibilityOffEyeIcon/>
                    </ButtonVisibilityOff>
                </HiddenMessage>
            )}
            <Popups
                show={showPopup}
                handleClosePopups={handleClosePopup}
                onClickClosest={onClickClosest}
                formComponent={<FormCollection handleClosePopup={handleClosePopup}/>}
            />
        </div>
    );
};

