import one from '../../../../assets/main/favorites/inspirations/one.png'
import two from '../../../../assets/main/favorites/inspirations/two.png'
import three from '../../../../assets/main/favorites/inspirations/three.png'
import four from '../../../../assets/main/favorites/inspirations/four.png'
import five from '../../../../assets/main/favorites/inspirations/five.png'
import six from '../../../../assets/main/favorites/inspirations/six.png'
import seven from '../../../../assets/main/favorites/inspirations/seven.png'
import eight from '../../../../assets/main/favorites/inspirations/eight.png'



export const imagesDate = [
    { image: one, noted: true },
    { image: two, noted: false },
    { image: three, noted: true },
    { image: four, noted: false },
    { image: five, noted: true },
    { image: six, noted: false },
    { image: seven, noted: true },
    { image: eight, noted: false },
    { image: one, noted: true },
    { image: two, noted: false },
    { image: three, noted: true },
    { image: four, noted: false },
    { image: five, noted: true },
    { image: six, noted: false },
    { image: seven, noted: true },
    { image: eight, noted: false }
];
