import styled from "styled-components";


export const UploadGalleryWrap = styled.div`
  white-space: nowrap;
  padding: 20px;
  position: relative;
  width: 50%;
  height: calc(100vh - 100px);
`;

export const GalleryBlock = styled.div`
  margin-bottom: 95px;
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 1440.98px) {
    margin-bottom: 40px;
  }
  @media (max-width: 680.98px) {
 flex-direction: column;
    gap: 15px;
  }
`

export const GalleryLeftBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  svg {
    width: 17px;
    height: 17px;
  }

  span {
    color: #FFF;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }
`;

export const GalleryRightBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }

  span {
    color: #FFF;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }
`;

export const GalleryContent = styled.div`
  width: 100%;
  overflow: hidden;
`;


export const GalleryFooter = styled.div`
  padding-top: 26px;
  text-align: end;
  
  @media (max-width: 770.98px) {
    padding-top: 15px;
  }

`;


export const GalleryImage = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  text-align: center;

  img {
    width: 100%;
  }
`;

export const DownloadWhiteIconWrapper = styled.div`
  position: absolute;
  background-color: rgba(12, 18, 21, 0.10);
  border-radius: 50%;
  width: 30px; /* Equal width and height to create a perfect circle */
  height: 30px; /* Equal width and height to create a perfect circle */
  top: 10px;
  right: 10px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    transition: fill 0.3s ease;
    
  }
  
  &:hover svg path {
    fill: #1056B2; /* Change fill color on hover */
  }
`;

