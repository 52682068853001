import React from 'react';
import {Popup} from "./style-popups";
import {FormGuest} from "../form-guest/FormGuest";



interface PopupProps {
    show: boolean;
    handleClosePopup: () => void;
    onClickClosest: () => void;
}

export const GuestPopups = ({show, handleClosePopup, onClickClosest}: PopupProps) => {
    return (
        <Popup show={show} onClick={onClickClosest}>
            <FormGuest handleClosePopup={handleClosePopup}/>
        </Popup>
    );
}

