import styled from "styled-components";

export const RadioBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  label {
    color: #0C1215;
    font-family: Gilroy,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
  }
`;
