import React, {FC, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../../common/hook';
import {Form, Formik} from 'formik';
import {ReactComponent as CalendarIcon} from '../../../../assets/main/wedding/calendar_24px.svg';
import {CloseButton, StyledTitle} from '../popUp/style-popups';
import {ReactComponent as Checkbox} from '../../../../assets/main/form/checkbox.svg';
import {ReactComponent as Сlose} from '../../../../assets/main/form/close.svg';
import {ReactComponent as СrossIcon} from '../../../../assets/main/wedding/close.svg'
import {ReactComponent as OutlinedIcon} from '../../../../assets/main/wedding/outlined.svg'
import {ReactComponent as LinkIcon} from '../../../../assets/main/wedding/link.svg'
import {v4 as uuid} from 'uuid';

import {
    ButtonBlock,
    ButtonCloses, ButtonDelete,
    ButtonSubmit,
    CalendarButton, CheckBlock,
    ErrorMessage,
    FormBlock,
    FormContainer,
    FormWrapper, PhotoLabel,
    RadioBlock,
    StyledArea,
    StyledInput,
    StyledLabel, StyledTextArea,
    TimeBlock,
    TimeInput,
    VisibilityBlock
} from "../usersForm/style-usersform";
import {changeWeddingData, TaskCalendar} from "../../../../app/tasks-wedding-reducer";
import {weddingSelector} from "../../../../app/selectors/wending";
import CalendarComponent from "../../../layout/Calendar/CalendarComponent";
import {mainSelector} from "../../../../app/selectors/main";
import {userSelector} from "../../../../app/selectors/users";


interface MyFormValues {
    id: string;
    title: string;
    date: string;
    timeWith: string;
    timeBefore: string;
    location: string;
    addNote: string;
    provider: string;
    providerLink: string;
}

type PropsType = {
    handleClosePopup: () => void;
};

const TaskForm: FC<PropsType> = ({handleClosePopup}) => {
    const {} = useAppSelector(weddingSelector);
    const {date} = useAppSelector(mainSelector)
    const {wendingDate} = useAppSelector(userSelector)
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useAppDispatch();


    const compareDateToDispatch = () => {
        const wendingDateFormat = new Date(wendingDate as string);
        const dataDateFormat = new Date(date as string);
        console.log(dataDateFormat, 'console.log(dataDateFormat)');
        console.log(wendingDateFormat, 'console.log(wendingDateFormat)');

        if (dataDateFormat.getTime() < wendingDateFormat.getTime()) {
            return 'beforeWedding';
        } else if (dataDateFormat.getTime() === wendingDateFormat.getTime()) {
            return 'wedding';
        } else {
            return 'afterWedding';
        }
    }

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    const handleSubmit = (values: MyFormValues) => {
        try {
            if (values.date.trim() === '') {
                values.date = date as string;
            }
            const taskCalendar: TaskCalendar = {
                id: uuid(),
                title: values.title,
                date: date || '',
                timeWith: values.timeWith,
                timeBefore: values.timeBefore,
                location: values.location,
                addNote: values.addNote,
                provider: values.provider,
                done: true,
                providerLink: values.providerLink,
            };
            dispatch(changeWeddingData({target: compareDateToDispatch(), tasks: taskCalendar}));
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <Formik
            initialValues={{
                id: '',
                timeWith: '',
                timeBefore: '',
                title: '',
                date: date || '',
                location: '',
                addNote: '',
                provider: '',
                providerLink: ''
            }}
            onSubmit={handleSubmit}
            // validationSchema={schema}
        >
            {({values, handleSubmit, handleChange, setFieldValue, errors, touched}) => (
                <FormWrapper onClick={(e) => e.stopPropagation()}>
                    <Form onSubmit={handleSubmit}>
                        <CloseButton type="button" onClick={handleClosePopup}>
                            <СrossIcon/>
                        </CloseButton>
                        <StyledTitle>Dodaj nową pozycję</StyledTitle>
                        <FormContainer>
                            <FormBlock>
                                <StyledLabel htmlFor="title">Tytuł</StyledLabel>
                                <StyledInput id="title" name="title" type="text"
                                             onChange={handleChange}
                                             value={values.title}/>
                                {touched.title && errors.title &&
                                    <ErrorMessage>{errors.title}</ErrorMessage>}
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor="data">Data</StyledLabel>
                                <CalendarButton onClick={togglePopup}>
                                    <CalendarIcon/>
                                </CalendarButton>
                                <StyledInput
                                    onChange={handleChange}
                                    type="text" value={date || ''}/>
                                {touched.date && errors.date &&
                                    <ErrorMessage>{errors.date}</ErrorMessage>}
                            </FormBlock>
                            {isOpen && <CalendarComponent isShowCalendarInfo={false}/>}
                            <FormBlock>
                                <StyledLabel htmlFor="timeWith">Czas</StyledLabel>
                                <TimeBlock>
                                    <TimeInput type="text" name="timeWith"
                                               value={values.timeWith} onChange={handleChange} autoComplete="off"/>
                                    <TimeInput type="text" name="timeBefore"
                                               value={values.timeBefore} onChange={handleChange} autoComplete="off"/>
                                </TimeBlock>
                                {touched.timeBefore && errors.timeBefore &&
                                    <ErrorMessage>{errors.timeBefore}</ErrorMessage>}
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor="location">Lokalizacja</StyledLabel>
                                <StyledInput
                                    type="text" name="location" value={values.location} onChange={handleChange}
                                    autoComplete="off"/>
                                {touched.location && errors.location &&
                                    <ErrorMessage>{errors.location}</ErrorMessage>}
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor="provider">Nazwa Usługodawcy</StyledLabel>
                                <StyledInput type="text" name="provider"
                                             value={values.provider} onChange={handleChange}
                                             autoComplete="off"/>
                                {touched.provider && errors.provider &&
                                    <ErrorMessage>{errors.provider}</ErrorMessage>}
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor="providerLink">www usługodawcy</StyledLabel>
                                <CalendarButton style={{opacity:'0.9'}}>
                                    <LinkIcon style={{opacity:'0.9'}}/>
                                </CalendarButton>
                                <StyledInput type="text"
                                             name="providerLink" value={values.providerLink}
                                             onChange={handleChange}
                                             autoComplete="off"/>
                                {touched.providerLink && errors.providerLink &&
                                    <ErrorMessage>{errors.providerLink}</ErrorMessage>}
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor="addNote">Dodaj notatkę</StyledLabel>
                                <StyledTextArea
                                    id="addNote"
                                    name="addNote"
                                    onChange={handleChange}
                                    value={values.addNote}
                                    autoSize={{ minRows: 4, maxRows: 8 }}
                                />
                            </FormBlock>
                            {wendingDate === date &&
                                <VisibilityBlock>
                                    <StyledLabel htmlFor="addNote">Ustawienie widoczności</StyledLabel>
                                    <RadioBlock>
                                        <input type="radio"/> <span>Widoczne tylko dla mnie</span>
                                    </RadioBlock>
                                    <RadioBlock>
                                        <input type="radio"/> <span>Widoczny dla mnie i wszystkich gości</span>
                                    </RadioBlock>
                                </VisibilityBlock>
                            }

                            {/*<FormBlock>*/}
                            {/*    <StyledLabel htmlFor="planWesela">Plan wesela</StyledLabel>*/}
                            {/*    <CheckBlock>*/}
                            {/*        <div>*/}
                            {/*            <input type="checkbox"/> <span>Duplikuj w plan wesela</span>*/}
                            {/*        </div>*/}
                            {/*        <OutlinedIcon/>*/}
                            {/*    </CheckBlock>*/}
                            {/*</FormBlock>*/}

                            <PhotoLabel htmlFor="bridePhoto">+ Dodaj w Kalendarzu Google</PhotoLabel>
                            <ButtonBlock>
                                <ButtonCloses><Сlose/><span>Anuluj</span></ButtonCloses>
                                <ButtonSubmit type="submit"
                                              disabled={Object.keys(errors).length !== 0}><Checkbox/><span>Zapisz</span></ButtonSubmit>
                            </ButtonBlock>
                            {/*<ButtonDelete>Usuń</ButtonDelete>*/}
                        </FormContainer>
                    </Form>
                </FormWrapper>
            )}
        </Formik>
    );
};

export default TaskForm;
