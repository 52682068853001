import React, {FC, useState} from 'react';
import {Form, Formik} from 'formik';
import {ReactComponent as Checkbox} from '../../../../../../assets/main/form/checkbox.svg';
import {ReactComponent as СrossIcon} from '../../../../../../assets/main/wedding/cross.svg';
import {ReactComponent as CloseIcon} from '../../../../../../assets/main/wedding/close.svg';
import {ReactComponent as LinkIcon} from '../../../../../../assets/main/wedding/link.svg'
import {
    ButtonBlock, ButtonCloses,
    ButtonSubmit,
    CalendarButton,
    FormBlock,
    FormContainer,
    FormWrapper,
    StyledInput,
    StyledLabel
} from "../../../../wedding/usersForm/style-usersform";
import {CloseButton, StyledTitle} from "../../../../wedding/popUp/style-popups";
import {useAppDispatch, useAppSelector} from "../../../../../../common/hook";
import {ReactComponent as CalendarIcon} from '../../../../../../assets/main/wedding/calendar_24px.svg';
import {CalendarExpense} from "../../calendar-expense";
import {addExpense, Expense, NoteType} from "../../../../../../app/budget-reduser";
import {v4 as uuid} from 'uuid';
import {categorySelector} from "../../../../../../app/selectors/category";
import {expenseDate} from "../../../../../../app/selectors/expense-date";


// import {schema} from "../../../../yup/yup";

interface MyFormValues {
    name: string,
    notes: NoteType[],
    location: string,
    linkLocation: string,
    totalPrice: string,
    prepaid: string,
    fullPaymentDate: string,
    status: 'unpaid' | 'prepayment' | 'repaid',
}

type PropsType = {
    handleClosePopup: () => void;
};


export const FormExpense: FC<PropsType> = ({handleClosePopup}) => {
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const {currentCategoryID} = useAppSelector(categorySelector)
    const {date} = useAppSelector(expenseDate)


    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    const handleSubmit = (values: MyFormValues) => {
        const expense: Expense = {
            id: uuid(),
            name: values.name,
            notes: [],
            location: values.location,
            linkLocation: values.linkLocation,
            totalPrice: values.totalPrice,
            prepaid: values.prepaid,
            fullPaymentDate: date as string,
            status: values.status,
        };
        console.log(expense, 'expense')

        dispatch(addExpense({categoryId: currentCategoryID, expense}));
    };

    return (
        <Formik initialValues={
            {
                name: '',
                notes: [],
                location: '',
                linkLocation: '',
                totalPrice: '',
                prepaid: '',
                fullPaymentDate: date as string,
                status: 'unpaid',
            }
        }
                onSubmit={handleSubmit}
            // validationSchema={schema}
        >
            {({values, handleSubmit, handleChange, errors}) => (
                <FormWrapper onClick={(e) => e.stopPropagation()}>
                    <Form onSubmit={handleSubmit}>
                        <CloseButton type="button" onClick={handleClosePopup}>
                            <CloseIcon/>
                        </CloseButton>
                        <StyledTitle>Dodaj nowy wydatek</StyledTitle>
                        <FormContainer>
                            <FormBlock>
                                <StyledLabel htmlFor="name">Nazwa</StyledLabel>
                                <StyledInput type="text" name="name" value={values.name}
                                             onChange={handleChange}/>
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor="location">Nazwa Miejsce/Usługodawca</StyledLabel>
                                <StyledInput type="text" name="location"
                                             value={values.location}
                                             onChange={handleChange}/>
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor="totalPrice">Łącze Miejsce/Usługodawca</StyledLabel>
                                <CalendarButton>
                                    <LinkIcon style={{opacity: '0.9'}}/>
                                </CalendarButton>
                                <StyledInput type="text" name="linkLocation"
                                             value={values.linkLocation}
                                             onChange={handleChange}/>
                            </FormBlock>

                            <FormBlock>
                                <StyledLabel htmlFor="totalPrice">Cena całkowita</StyledLabel>
                                <StyledInput type="text" name="totalPrice"
                                             value={values.totalPrice}
                                             onChange={handleChange}/>
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor="prepaid">Przedpłata</StyledLabel>
                                <StyledInput type="text" name="prepaid"
                                             value={values.prepaid}
                                             onChange={handleChange}/>
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor="fullPaymentDate">Pełna płatność do</StyledLabel>
                                <CalendarButton onClick={togglePopup}>
                                    <CalendarIcon/>
                                </CalendarButton>
                                <StyledInput type="text" name="fullPaymentDate"
                                             value={date || ''}
                                             onChange={handleChange}/>
                            </FormBlock>
                            {isOpen && <CalendarExpense/>}
                            <ButtonBlock>
                                <ButtonCloses>
                                    <СrossIcon/>
                                    <span>Anuluj</span>
                                </ButtonCloses>
                                <ButtonSubmit type='submit' disabled={Object.keys(errors).length !== 0}>
                                    <Checkbox/>
                                    <span>Zapisz</span>
                                </ButtonSubmit>
                            </ButtonBlock>

                            {/*<ButtonBlock>*/}
                            {/*    <ButtonSubmit type="submit"*/}
                            {/*                  disabled={Object.keys(errors).length !== 0}><Checkbox/><span>Zapisz</span></ButtonSubmit>*/}
                            {/*</ButtonBlock>*/}
                        </FormContainer>
                    </Form>
                </FormWrapper>
            )}
        </Formik>
    );
};


