import styled from "styled-components";

export const ChangeViewWrap = styled.div`

`;

export const ChangeViewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChangeViewTitle = styled.p`
  color: #0C1215;
  font-family: Gilroy, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.22px;
  margin-bottom: 18px;
`;

export const ChangeViewBlock = styled.div`
  display: flex;
  gap: 25px;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
`;
export const ChangeViewItems = styled.div`
display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  
`;


export const ChangeViewItem = styled.div`

`;

export const ChangeViewCheckbox = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ChangeViewButton = styled.button`
  width: 100%;
  background: linear-gradient(90deg, #1056B2 0%, #2779E4 100%);
  border-radius: 208px 100px 100px 0;
  padding: 12px 30px;
  gap: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  cursor: pointer;
  max-width: 292px;
  @media (max-width: 425.98px) {
    max-width: 100%;
  }
  &:hover {
    opacity: 0.9;
  }
  &[disabled] {
    opacity: 0.5; 
    cursor: not-allowed; 

  }
`;
