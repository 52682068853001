import React, {useState} from 'react';
import {useLoginMutation} from 'services/auth/auth.service';
import {SingIn} from "./../sign-in-page/sing-in/sing-in";
import {useNavigate} from "react-router-dom";
import {LoginArgs} from "services/auth/auth.types";
import Cookies from "universal-cookie";

interface MyFormValuesPassword {
    password: string;
}

export const SignInPage: React.FC = () => {
    const navigate = useNavigate()
    const [login, {data ,isError, error,isLoading}] = useLoginMutation()
    const cookies = new Cookies();
    const [showPassword, setShowPassword] = useState<{
        password: boolean;
    }>({
        password: false,
    });


    if(data?.success) {
        navigate('/');
    }

    const togglePasswordVisibility = (field: keyof MyFormValuesPassword) => {
        setShowPassword((prevShowPassword) => ({
            ...prevShowPassword,
            [field]: !prevShowPassword[field],
        }));
    };

    if (data?.data && data?.data.token) {
        cookies.set('jwt_authorization', data?.data.token, {});
        cookies.set('company_id', data?.data.userId, {});
        navigate('/');
        return null;
    }

    if (isError) {
        const err = error as any
        console.log(err.data.error,'err.data.error')
        return <h1>{err.data.error}</h1>
    }


    const handlerLogin = async (args: LoginArgs) => {
        try {
            await login(args)
        } catch (e) {
            console.error(e);
        }
    }


    return (
        <SingIn data={data} onSubmit={handlerLogin} showPassword={showPassword}
                togglePasswordVisibility={togglePasswordVisibility} isLoading={isLoading}/>
    )
};
