import React, {useState} from 'react';
import {WebsiteContainer, WebsiteItem, WebsiteTitle, WebsiteWrap} from "./style-website";
import {
    FormBlock,
    StyledLabel,
    SwitchBlock,
    SwitchButton,
    SwitchContainer,
    SwitchText
} from "../wedding/usersForm/style-usersform";
import {ConfirmInput} from "./website-pages/website-attendance/style-website-attendance";
import {WebsiteChangeView} from "./website-change-view/WebsiteChangeView";


export const Website = () => {
    const [isOn, setIsOn] = useState(false);

    const handleToggle = () => {
        setIsOn(prevState => !prevState);
    };


    return (
        <WebsiteWrap>
            <WebsiteContainer>
                <WebsiteTitle>
                    Wprowadź wymagane dane, aby rozpocząć tworzenie witryny
                </WebsiteTitle>
                <WebsiteItem>
                    <FormBlock>
                        <StyledLabel htmlFor="name">Nazwa strony</StyledLabel>
                        <ConfirmInput
                            type="text"
                            name="name"
                        />
                    </FormBlock>
                    <FormBlock>
                        <SwitchBlock>
                            <SwitchText>Widoczność strony</SwitchText>
                            <SwitchContainer>
                                <SwitchButton isOn={isOn} onClick={handleToggle}/>
                            </SwitchContainer>
                        </SwitchBlock>
                    </FormBlock>
                    {isOn &&
                        <WebsiteChangeView/>
                    }
                </WebsiteItem>
            </WebsiteContainer>
        </WebsiteWrap>
    );
};

