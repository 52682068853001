import styled from "styled-components";

export const SharingInformationWrap = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding-top: 124px;
  padding-left: 30px;
  @media (max-width: 1000.98px) {
    padding-top: 100px;
    padding-left: 0;
  }
`

export const SharingSelect = styled.span`
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #1056B2;
  cursor: pointer;
`

export const SharingLink = styled.span`
  position: absolute;
  right: 67%;
  top: 37px;
  cursor: pointer;
  opacity: 0.7;
  z-index: 1;

  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    opacity: 0.6;
  }

  @media (max-width: 1023.98px){
    left: 675px;

  }

  @media (max-width: 1000.98px){
    left: 75px;
  }
  
  
`
export const SharingLinkBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 416px;
  @media (max-width: 1023.98px) {
    width: 100%;
  }
`;

export const SharingButtonBlock = styled.div`
  display: flex;
  gap: 14.37px;
  padding-top: 10px;
  margin-bottom: 18px;
  width: 416px;
  @media (max-width: 1023.98px) {
    width: 100%;
  }
  @media (max-width: 497.98px) {
    flex-direction: column;
  }
`

export const SharingLabel = styled.label`
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #1056b2;
  text-decoration-line: underline;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  padding: 8px 0 10px 0 ;

  &:hover {
    color: #0e408f;
    text-decoration-line: none;
  }
`;

export const SharingFormBlock = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;


export const SharingButtonSubmit = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  padding: 12px 30px;
  background: #1056B2;
  border-radius: 200px 100px 100px 0px;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
  }

  &:hover,
  &:focus {
    background-color: #1976d2;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const SharingArrayBlock = styled.div`
  margin-top: 10px;
  padding-top: 24px;
  border-top: 1px solid #D2DAE3;

`
export const SharingArrayLeftBlock = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;

  svg {
    width: 17px;
    height: 17px;
  }

  span {
    color:  #0C1215;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }

`
