import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {RootState} from "../../../../services/store";
import {NameWrapper} from "./style-username";
import {ReactComponent as Icon} from '../../../../assets/header/icon.svg';


export const UserName: React.FC = () => {
    const dispatch = useDispatch();
    const username = useSelector((state: RootState) => state.app.name);

    useEffect(() => {
        // dispatch(fetchUsername());
    }, [dispatch]);

    return (
        <NameWrapper>
            <Icon/>
            <p>{username}</p>
            {/* your account page content */}
        </NameWrapper>
    );
};

// content: "";
// position: absolute;
// left: -30px;
// top: -9px;
// right: -36px;
// bottom: 0;
// background-color: #F5F9FF;
// height: 39px;
