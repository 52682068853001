import styled from "styled-components";

export const NewQuestionsWrap = styled.div`
    margin-bottom: 100px;
  @media (max-width: 1024.98px) {
    margin-bottom: 40px;
  }
`

export const NewQuestionsTitle = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
  align-items: center;

  p {
    flex: 1;
    font-family: 'Gilroy',sans-serif;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    color: #0C1215;
  } 
`

export const NewQuestionsContainer = styled.div`
  //background-color: #F5F9FF;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  @media (max-width: 768.98px) {
    grid-template-columns: repeat(1, 1fr);
  }

`


