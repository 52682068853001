import styled from "styled-components";
import {ReactComponent as UploadIcon} from '../../../../../assets/main/website/upload.svg';

interface IDownloadArrow {
    color: string
}


export const CollectionText = styled.p`
  width: 581px;
  color: #0C1215;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  @media (max-width: 650.98px) {
    width: 100%;
  }
`;


export const CollectionBlock = styled.div`
  display: flex;
  gap: 18px;
  align-items: center;
  line-height: 140%;
`;


export const ItemBlockNumber = styled.div`
  width: 50px;
  height: 50px;
  background-color: #F5F9FF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0C1215;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Cormorant Garamond, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;


export const CollectionFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;

  @media (max-width: 650.98px) {
    flex-direction: column;
    gap: 15px;
  }
`;





export const CollectionButton = styled.button`
  width: 100%;
  background: linear-gradient(90deg, #1056B2 0%, #2779E4 100%);
  border-radius: 208px 100px 100px 0;
  padding: 12px 30px;
  gap: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  cursor: pointer;
  max-width: 240px;
  @media (max-width: 425.98px) {
    max-width: 100%;
  }
  &:hover {
    opacity: 0.9;
  }
`;

export const DownloadIcons = styled(UploadIcon)<IDownloadArrow>`
  path {
    stroke: ${(props) => (props.color ? props.color : "")};
  }
`;