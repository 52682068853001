import React from 'react';
import {PointsNotePopupWrap, PointsPopupWrap} from "../../../../tasks/points-tasks/points-popup/style-points-popup";
import {
    PointsTasksPopupDelete
} from "../../../../tasks/points-tasks/points-popup/points-tasks-popup-delete/PointsTasksPopupDelete";
import {AddNote} from "./add-note/AddNote";
import {SendMessage} from "./send-message/SendMessage";


interface IExpensesId {
    expensesId: string;
}

export const FavoritePinterPopup = ({ expensesId }: IExpensesId) => {
    return (
        <PointsNotePopupWrap>
            <AddNote expensesId={expensesId} show={false}/>
            <SendMessage expensesId={expensesId} show={false}/>
        </PointsNotePopupWrap>
    );
};