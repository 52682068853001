import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import { Pagination, Autoplay } from 'swiper';
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import './style-hotel-sliders.css'
import {ReactComponent as Notes} from '../../../../../../assets/main/favorites/notes.svg'
import {ReactComponent as Like} from '../../../../../../assets/main/favorites/like.svg'


interface Iimages {
    images: string[];
}


export const Slider = ({ images }: Iimages) => {
    return (
        <div className='container'>
            <Swiper
                className='mySwiper'
                modules={[Pagination, Autoplay]}
                pagination={true}
                loop={true}
            >
                {images.map((image, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <img src={image} alt='image' />
                            <div className='slider-icons'>
                                <Notes />
                                <Like />
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
};






// export const Slider = ({images}: Iimages) => {
//     return (
//         <div className='container'>
//             <Swiper
//                 modules={[Pagination]}
//                 slidesPerView={1}
//                 loop={true}
//                 effect={"creative"}
//                 creativeEffect={{
//                     prev: {
//                         shadow: true,
//                         translate: ["-20%", 0, -1],
//                     },
//                     next: {
//                         translate: ["100%", 0, 0],
//                     },
//                 }}
//                 pagination={{
//                     clickable: true,
//                 }}
//             >
//                 {images.map((image, index) => (
//                     <SwiperSlide key={index}>
//                         <img src={image} alt={`Слайд ${index + 1}`}/>
//                     </SwiperSlide>
//                 ))}
//             </Swiper>
//         </div>
//     );
// };

