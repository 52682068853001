import styled from "styled-components";

export const WebsitePlanWrap = styled.section`

`;



export const WebsitePlanTitle = styled.p`
  color: #0C1215;
  font-family: Gilroy,sans-serif;
  font-size: 46px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 47px;
  text-align: center;

  @media (max-width: 768.98px) {
    font-size: 36px;
  }

  @media (max-width: 425.98px) {
    font-size: 28px;
  }
`;

export const WebsitePlanBlock = styled.div`
  display: flex;
  justify-content: center;
`;

export const WebsitePlanCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 карточки в ряду */
  gap: 20px; /* Расстояние между карточками */

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* 2 карточки в ряду на маленьких экранах */
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr; /* 1 карточка в ряду на очень маленьких экранах */
  }
`;

export const WebsitePlanCard = styled.div`
  border-radius: 5px;
  width: 374px;
  height: 166px;
  padding: 15px 20px;
  opacity: 0.8;
  background: #FFF;
  box-shadow: 0px 4px 30px 0px rgba(11, 62, 138, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @media (max-width: 1435.98px) {
    width: 100%; 
  }
`;

export const CardTitle = styled.p`
  max-width: 280px;
  color: #0C1215;
  text-align: center;
  font-family: Gilroy,sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const CardTime = styled.p`
  color: rgba(12, 18, 21, 0.60);
  text-align: center;
  font-family: Gilroy,sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%
`;

export const CardText = styled.p`
  color: rgba(12, 18, 21, 0.60);
  text-align: center;
  font-family: Gilroy,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
`;