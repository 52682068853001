import styled from "styled-components";


export const ExpenseWrapper = styled.div`
          flex: 1 1 auto;
          border-radius: 5px;
          background-color: #F5F9FF;
          padding: 0 0 40px 20px;
    `
;

export const ExpenseButton = styled.div`
  text-align: end;
  margin-top: 40px;
  margin-bottom: 15px;
  padding-right: 15px;
  //background-color: white;
`

export const ExpenseWhiteButton = styled.div`
  text-align: end;
  margin-bottom: 15px;
  background-color: white;
`

export const ExpenseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;

  @media (max-width: 1054.98px) {
    overflow-x: auto;
  }

`


export const ExpenseContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;

`

export const ShowMoreWrapper = styled.div`
  transition: all 0.3s ease;
  padding-top: 30px;
`;

export const ShowMoreListWrapper = styled.div`
  transition: all 0.3s ease;

`;

export const ShowMore = styled.div`
  display: flex;
  gap: 7px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #1056B2;
  cursor: pointer;
  transition: all 0.3s ease;
`;

export const ShowMoreBlock = styled.div`
  display: flex;
  gap: 7px;
  transition: all 0.3s ease;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background: transparent;
    border-bottom: 2px dotted rgba(16, 86, 178, 0.6);
  }
`;


export const ExpenseMenuBlock = styled.div`
  display: flex;
  gap: 11px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #0C1215;
`


export const NoText = styled.div`
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #0C1215;
  margin: 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
  width: 100%;
`

export const ItemBlock = styled.div`
  display: flex;
  gap: 10px;
  font-family: 'Gilroy', serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(12, 18, 21, 0.5);
  //@media (max-width: 1384.98px) {
  //  justify-content: space-between;
  //}
`
export const BlockName = styled.div`
  display: flex;
  gap: 11px;
`
export const MenuName = styled.div`
  width: 170px;
`
export const MenuLocation = styled.div`
  width: 170px;
`
export const BlockPrice = styled.div`
  display: flex;
  gap: 11px;
`

export const MenuPrice = styled.div`
  width: 101px;
`

export const BlockIcons = styled.div`
  display: flex;
  gap: 11px;
`
export const MenuIcon = styled.div`
  width: 38px;
`


export const ExpenseText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(12, 18, 21, 0.5);
`