import styled from "styled-components";




export const CalendarData = styled.p`
  color: #0C1215;
  text-align: center;
  font-family: Gilroy,sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 8px;
  opacity: 0.4;
`
export const CatalogEducation = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  color: #0C1215;
  opacity: 0.4;
`;
export const CalendarButton = styled.button`
  margin-top: 30px;
  width: 100%;
  background:  #1056B2;
  border-radius: 208px 100px 100px 0;
  padding: 12px 30px;
  gap: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  cursor: pointer;
  max-width: 292px;
  @media (max-width: 425.98px) {
    max-width: 100%;
  }
  &:hover {
    background: linear-gradient(90deg, #1056B2 0%, #2779E4 100%);
  }
`;

export const ButtonGuest = styled.button`
  margin-top: 40px;
  background: #FFFFFF;
  opacity: 0.8;
  border: 1px solid rgba(210, 218, 227, 0.8);
  color: #0c1215;
  border-radius: 208px 100px 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 30px;
  gap: 8px;
  width: 200px;
  &:hover {
    opacity: 0.9;
  }
`;

export const ButtonWhiteGuest = styled.button`
  margin-top: 25px;
  background: #FFFFFF;
  opacity: 0.8;
  border: 1px solid rgba(210, 218, 227, 0.8);
  color: #0c1215;
  border-radius: 208px 100px 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 30px;
  gap: 8px;
  width: 200px;

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 425.98px) {
    width: 100%;
  }
`;


export const CalendarText = styled.span`
  font-family: 'Gilroy', serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`

export const CatalogInfo = styled.div`
  border-top: 1px solid #E7ECF3;
  padding-top: 12px;
  text-align: center;

`


export const EducationText = styled.span`
  font-family: 'Gilroy', serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;
