import styled from 'styled-components';

export const WebsiteHeaderWrap = styled.nav<{ background: string }>`
  width: 100%;
  padding: 16px 30px 20px 30px;
  position: sticky;
  top: 70px; 
  z-index: 3;
  background: ${props => props.background};

`;

export const WebsiteNavigateWrap = styled.ul`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 780.98px) {
    justify-content: center;
  }

`;

export const CustomLink = styled.a`
  cursor: pointer;
  transition: opacity 0.3s ease;
  color: #0c1215;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    border-bottom: 1px dotted;
  }

  &:hover {
    opacity: 0.7;
  }
`;

// export const WebsiteHeaderWrap = styled.nav<{ background: string }>`
//   max-width: 1220px;
//   width: 100%;
//   position: relative;
//   padding: 10px;
//   overflow: clip;
//   position: fixed;
//   right: 0;
//   top: 70px;
//   z-index: 100;
//
//
//   &::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background: ${props => props.background}; // Используйте значение из props для фона
//     z-index: -1;
//   }
// `;