import styled from "styled-components";

export const GuestNewlywedsWrap = styled.div`

`

export const NewlywedsContent = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
  p {
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    color: #0C1215;
  }
`

