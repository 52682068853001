import styled from "styled-components";



export const NameWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: end;
  cursor: pointer;
  transition: opacity 0.3s;

  
  svg {
    path {
      fill:white;

      @media (max-width: 1000.98px) {
        fill:#006edc;
      }
    }
    
  }


  &:hover {
    opacity: 0.7;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;

    @media (max-width: 1000.98px) {
      color: #006edc;
    }
  }
`;