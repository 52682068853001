import styled, {css} from "styled-components";


interface ICategoryContentProps {
    isActive: boolean;
}

export const CategoryWrapper = styled.div`
  flex: 1 1 370px;
  padding-top: 10px;
  @media (max-width: 1076.98px) {
    flex: 1 1 auto;
  }
`

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  cursor: pointer;
`
export const CategoryContent = styled.div<ICategoryContentProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 9px;
  align-items: center;
  background-color: ${props => (props.isActive ? '#F5F9FF' : '#fdfdfd')};


  ${props =>
          props.isActive &&
          css`
      &:before {
         content: "";
         position: absolute;
         left: -30px;
         top: -9px;
         right: -35px;
         bottom: 0;
         background-color: #F5F9FF;
         height: 39px;
      }
    `}
`;

export const CategoryTitle = styled.p`
  color: #0C1215;
  font-family: Gilroy,sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  z-index: 2;
`
export const CategoryPrice = styled.p`
  position: relative;
  z-index: 2;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #0C1215;
`

export const CategoryZeroPrice = styled.p`
  position: relative;
  z-index: 2;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #0C1215;
  opacity: 0.6;
`


export const CategoryButton = styled.div`
  margin-bottom: 20px;
`

