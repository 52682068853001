import React from 'react';
import {Popup} from "./style-plan-tab-popup";
import {FormAddGuest} from "../form-add-guest/FormAddGuest";


interface PopupProps {
    show: boolean;
    handleClosePopup: () => void;
    onClickClosest: () => void;
}

export const PlanAddGuestPopups = ({show, handleClosePopup, onClickClosest}: PopupProps) => {
    return (
        <Popup show={show} onClick={onClickClosest}>
            <FormAddGuest handleClosePopup={handleClosePopup} />
        </Popup>
    );
}

