import React, {FC} from 'react';
import {FavoriteList, FavoriteMenu} from "../favorite-container/style-favorite-container";

type FavoriteMenuContainerProps = {
    selectedType: string;
    filter: (type: string) => () => void;
};


export const FavoritesMenu: FC<FavoriteMenuContainerProps> = ({selectedType, filter}) => {
    return (
        <FavoriteMenu>
            <FavoriteList selected={selectedType === 'hotels'} onClick={filter('hotels')}>
                Hotele
            </FavoriteList>
            <FavoriteList selected={selectedType === 'dresses'} onClick={filter('dresses')}>
                Sukienki
            </FavoriteList>
            <FavoriteList selected={selectedType === 'footwear'} onClick={filter('footwear')}>
                Obuwie
            </FavoriteList>
            <FavoriteList selected={selectedType === 'photographer'} onClick={filter('photographer')}>
                Fotograf
            </FavoriteList>
        </FavoriteMenu>
    );
};

