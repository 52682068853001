import React, {FC} from 'react';
import {Form, Formik} from 'formik';

import {CloseButton, StyledTitle} from "../../../../popups/style-popups";
import {
    ButtonBlock,
    ButtonCloses,
    ButtonSubmit,
    FormBlock,
    FormContainer,
    FormWrapper,
    StyledInput,
    StyledLabel
} from "../../../wedding/usersForm/style-usersform";

import {ReactComponent as Checkbox} from '../../../../../assets/main/form/checkbox.svg';
import {ReactComponent as CloseIcon} from '../../../../../assets/main/form/close.svg';
import {ReactComponent as СrossIcon} from '../../../../../assets/main/wedding/close.svg';
import {IVisitor} from "../../../../../app/guest-reduser";
import {v1} from "uuid";


// import {schema} from "../../../../yup/yup";

interface MyFormValues {
    id: string;
    groupName: string;
    visitors: IVisitor[];
}

interface PropsType {
    handleClosePopup: () => void;
}

export const FormGroup: FC<PropsType> = ({ handleClosePopup }) => {
    // const dispatch = useAppDispatch();
    // const { newlyweds } = useAppSelector((state) => state.guest);
    // console.log(index,'index')


    const handleSubmit = (values: MyFormValues) => {
        // dispatch(addGroup({ newlywedsId: newlyweds[index].id, values}));
    };

    return (
        <Formik
            initialValues={{
                id: v1(),
                groupName: '',
                visitors: [],
            }}
            enableReinitialize={true}
            onSubmit={handleSubmit}
        >
            {({ values, handleSubmit, handleChange, errors }) => (
                <FormWrapper onClick={(e) => e.stopPropagation()}>
                    <Form onSubmit={handleSubmit}>
                        <CloseButton type="button" onClick={handleClosePopup}>
                            <СrossIcon/>
                        </CloseButton>
                        <StyledTitle>Dodaj grupę</StyledTitle>
                        <FormContainer>
                            <FormBlock>
                                <StyledLabel htmlFor="groupName">Nazwa grupy</StyledLabel>
                                <StyledInput
                                    type="text"
                                    name="groupName"
                                    value={values.groupName}
                                    onChange={handleChange}
                                />
                            </FormBlock>
                            <ButtonBlock>
                                <ButtonCloses>
                                    <CloseIcon/>
                                    <span>Anuluj</span>
                                </ButtonCloses>
                                <ButtonSubmit
                                    type="submit"
                                    disabled={Object.keys(errors).length !== 0}
                                >
                                    <Checkbox/>
                                    <span>Zapisz</span>
                                </ButtonSubmit>
                            </ButtonBlock>
                        </FormContainer>
                    </Form>
                </FormWrapper>
            )}
        </Formik>
    );
};
