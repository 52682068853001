import styled from "styled-components";

export const BudgetTitleBlock = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #0C1215;
`


export const TotalAmount = styled.div`
  color: #0C1215;
  font-family: Gilroy,sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 30px;
`