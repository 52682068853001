import styled from "styled-components";

export const StyleWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: clip;
  position: relative;
`
export const MainWrapper = styled.main`
  flex: 1 1 auto;
  background-color: #fdfdfd ;

  @media (max-width: 1000.98px) {
    background-color: #fff;
  }
`

export const NavigateMainWrap = styled.div`
  display: flex;
  width: 100%;
  //justify-content: space-between;
  @media (max-width: 1000.98px) {
    column-gap: 0;
  }
`
export const OutletWrapper = styled.div`
  padding-top: 124px;
  padding-left: 30px;
  flex-grow: 1;
  background-color: #F5F5F5;
  @media (max-width: 1000.98px) {
    padding-top: 100px;
    width: 100%;
    padding-left: 0;
  }
`
export const FullWrapper = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding-top: 124px;
  padding-left: 30px;
  background-color: white;
  @media (max-width: 1000.98px) {
    padding-top: 100px;
    width: 100%;
    padding-left: 0;
  }
`
