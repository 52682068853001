import React, {useState} from 'react';
import {GuestNewlywedsWrap, NewlywedsContent} from "./style-guest-newlyweds";
import bride from '../../../../assets/main/guest/bride.png';
import {NewlywedsArray, NewlywedsSpoilers} from "./newlyweds-spoilers/NewlywedsSpoilers";


export interface NewlywedsData {
    index: number;
    title: string;
    avatar:string;
    newlywedsArray: NewlywedsArray[];
    // newlywedsArrayTwo:NewlywedsArray[]

}


export const GuestNewlyweds = ({ title,avatar, newlywedsData }: { title: string,avatar:string, newlywedsData: NewlywedsData[] }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <GuestNewlywedsWrap>
            <NewlywedsContent>
                <img src={avatar} alt="avatar" />
                <p>{title}</p>
            </NewlywedsContent>
            {newlywedsData.map((data, i) => (
                <NewlywedsSpoilers
                    key={i}
                    index={data.index}
                    isExpanded={isExpanded}
                    newlywedsArray={data.newlywedsArray}

                />
            ))}
        </GuestNewlywedsWrap>
    );
};