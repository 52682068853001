import styled, {keyframes} from "styled-components";

export interface ErrorProps {
    error?: string;
}

export const SingInContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
`

export const SingInImgContainer = styled.div`
    position: relative;
    width: 50%;
    height: 100vh;
    @media screen and (max-width: 897.98px) {
        display: none;
    }
`

export const SingInIcon = styled.img`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    fill: #fff;
    stroke-width: 1.10px;
    stroke: #fff;
    filter: brightness(80%);
`

export const SingInImg = styled.img`
    position: relative;
    width: 100%;
    height: 100vh;
    filter: brightness(40%);
`;

export const SingInFormContainer = styled.div`
    height: 100vh;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;


    @media screen and (max-width: 897.98px) {
        width: 100%;
        padding: 0 10px 0 10px;
    }
`

export const SingInForm = styled.form`
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    max-width: 416px;
    width: 100%;
    //width: 416px;
    gap: 10px;
`
export const SingInBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const SingInContentBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
`;

export const SingInButton = styled.button`
    border-radius: 200px 100px 100px 0;
    height: 48px;
    margin-top: 15px;
    width: 100%;
    background: #1056B2;
    padding: 12px 30px;
    gap: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    cursor: pointer;
    @media (max-width: 425.98px) {
        max-width: 100%;
    }

    &:hover {
        background: linear-gradient(90deg, #1056B2 0%, #2779E4 100%);
    }
`;

export const ContentButton = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;


export const SingInForgot = styled.div`
    font-weight: 500;
    font-size: 12px;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    color: #1056b2;
    transition: text-decoration 0.3s ease; /* Продолжительность анимации: 0.3 секунды */

    &:hover {
        text-decoration: none; /* Убираем подчеркивание при наведении */
        color: #6a8bb6;
    }
`;

export const SignSocialNetworksBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 18px;
`;

export const NetworksBlockText = styled.p`
    font-weight: 500;
    font-size: 12px;
    color: #1056b2;
`;

export const NetworksBlockSocials = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;


const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;
export const Loader = styled.div`
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #ffffff;
    width: 15px;
    height: 15px;
    animation: ${spin} 1s linear infinite;
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const ErrorText = styled.p`
    color: #E52C13;
    opacity: 0;
    animation: ${fadeIn} 0.3s ease-in-out forwards;
    font-size: 16px;
`;


export const FormBlockPassword = styled.div<ErrorProps>`
    padding: 0.5rem 1rem;
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: ${props => props.error ? '0.6px solid red' : '0.6px solid rgba(12, 18, 21, 0.2)'};
    border-radius: 5px;
    font-size: 16px;
    line-height: 46px;
    color: rgba(12, 18, 21, 0.8);
    height: 48px;
    transition: border-color 0.3s ease-in-out;
    //padding: 0 20px;

    input {
        outline: none;
        font-size: 16px;
        color: rgba(12, 18, 21, 0.8);
        background: #fff;

        @media screen and (max-width: 767px) {
            width: 100%;
        }

        @media screen and (min-width: 768px) and (max-width: 1023px) {
            width: 100%;
        }

        @media screen and (min-width: 1024px) {
            width: 358px;
        }
    }

    svg {
        cursor: pointer;
    }
`;
