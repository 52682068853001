import styled from "styled-components";

export const WrapperPresentationFile = styled.div`
    max-width: 860px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    
    
    span {
        cursor: pointer;
    }
`

