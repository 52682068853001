import { z } from 'zod';


export const UserSchema = z.object({


    wedding_message: z.string().min(1, 'Dodaj tekst is required'),

    bride_name: z.string().min(1, 'Bride name is required'),

    bride_email: z.string().email('Invalid email address'),


    bride_phone: z.string().min(1, 'Bride phone is required'),


    ceremony_location: z.string().min(1, 'Lokalizacja ślubu is required'),

    created_at: z.number().nullable().optional(),

    groom_email: z.string().email('Invalid email address'),

    groom_name: z.string().min(1, 'Groom name is required'),

    groom_phone: z.string().min(1, 'Groom phone is required'),

    is_countdown_set: z.number().optional(),

    wedding_at: z.number().nullable().optional(),
    wedding_location: z.string().min(1, 'Lokalizacja wesela is required'),
});

export type UserFormValues = z.infer<typeof UserSchema>;
