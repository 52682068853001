import React from 'react';
import {
    ContentNumber,
    ContentTitle,
    CounterCard,
    GuestCounterBlock,
    GuestCounterWrap
} from "./style-guest-counter";

const Counter = [
    {'title': 'Liczba gości na przyjęciu','number': '18'},
    {'title': 'Potwierdzenia','number': '6/18'},
    {'title': 'Kościół','number': '3'},
    {'title': 'Restauracja','number': '3'},
    {'title': 'Nocleg','number': '6'},
    {'title': 'Transport','number': '2'},
    {'title': 'Dzieci','number': '2'},
    {'title': 'Dieta specjalna','number': '1'},
];

export const GuestCounter = () => {
    return (
        <GuestCounterWrap>
            <GuestCounterBlock>
                {Counter.map((el, index) => {
                    return (
                        <CounterCard key={index}>
                            <ContentTitle>
                                {el.title}
                            </ContentTitle>
                            <ContentNumber>
                                {el.number}
                            </ContentNumber>
                        </CounterCard>
                    );
                })}
            </GuestCounterBlock>
        </GuestCounterWrap>
    );
};

