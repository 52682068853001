import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useAppSelector} from "../../../../common/hook";
import {categorySelector} from "../../../../app/selectors/category";
import {setCurrentCategory} from "../../../../app/budget-reduser";
import {CalendarButton, CalendarText} from "../../../calendar-Info/styles";
import {CategoryPopup} from "./category-popup/CategoryPopup";
import {ReactComponent as Plus} from '../../../../assets/main/wedding/plus.svg';
import usePopup from "../../../../common/hook/use-popup";
import {
    CategoryButton,
    CategoryContainer,
    CategoryContent,
    CategoryPrice,
    CategoryTitle,
    CategoryWrapper, CategoryZeroPrice,
} from "./style-budgetCategory";
import {Planned} from "./budget-planned/Planned";
import {NoText, ShowMore, ShowMoreBlock, ShowMoreWrapper} from "../butget-expense/style-budgetExpense";
import {ReactComponent as ArrayUp} from '../../../../assets/main/budget/arrayUp.svg';
import {ReactComponent as ArrayDown} from '../../../../assets/main/budget/arrowDown.svg';

const category = [
    {
        id: '1',
        title:'Ślub',
        price:'8 700'
    },
    {
        id: '2',
        title:'Panna Młoda',
        price:'10 700'
    },
    {
        id: '3',
        title:'Pan Młody',
        price:'7 200'
    },
    {
        id: '4',
        title:'Przyjęcie Weselne',
        price:'7 200'
    },
    {
        id: '5',
        title:'Muzyka',
        price:'7 200'
    },
    {
        id: '6',
        title:'Fotografia i Filmografia',
        price:'7 200'
    },
    {
        id: '7',
        title:'Dekoracje',
        price:'7 200'
    },
    {
        id: '8',
        title:'Transport',
        price:'7 200'
    },
    {
        id: '9',
        title:'Pozostałe Usługi',
        price:'7 200'
    },
    {
        id: '10',
        title:'Panna Młoda',
        price:'7 200'
    },
    {
        id: '11',
        title:'Pozostałe Usługi',
        price:'7 200'
    },

]




export const BudgetCategory = () => {
    const dispatch = useDispatch();
    const {categories, currentCategoryID} = useAppSelector(categorySelector);
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();
    const [showAll, setShowAll] = useState(false);

    // Установите начальное значение currentCategoryID на основе первого элемента в массиве category
    const initialCategoryID = category[0].id;
    const [activeCategoryID, setActiveCategoryID] = useState(initialCategoryID);

    const handleCategoryClick = (categoryID: string) => () => {
        dispatch(setCurrentCategory(categoryID));
        setActiveCategoryID(categoryID);
    };

    const visibleCategories = showAll ? categories : categories.slice(0, 9);

    const handleShowMore = () => {
        setShowAll((prevShowAll) => !prevShowAll);
    };

    return (
        <CategoryWrapper>
            <CategoryContainer>
                {category.map((category) => (
                    <CategoryContent
                        key={category.id}
                        onClick={handleCategoryClick(category.id)}
                        isActive={category.id === activeCategoryID}
                        style={{
                            display: showAll || parseInt(category.id, 10) <= 9 ? 'flex' : 'none',
                        }}
                    >
                        <CategoryTitle>{category.title}</CategoryTitle>
                        {category.price === '0' ? (
                            <CategoryZeroPrice>{category.price} zł</CategoryZeroPrice>
                        ) : (
                            <CategoryPrice>{category.price} zł</CategoryPrice>
                        )}
                    </CategoryContent>
                ))}
            </CategoryContainer>
            {category.length > 9 && (
                <ShowMoreWrapper>
                    <ShowMore onClick={handleShowMore}>
                        {showAll ? (
                            <ShowMoreBlock>
                                <span>Zwiń</span>
                                <span> <ArrayDown/></span>
                            </ShowMoreBlock>
                        ) : (
                            <ShowMoreBlock>
                                <span>Zobacz wszystkie kategorie</span>
                                <span> <ArrayUp/></span>
                            </ShowMoreBlock>
                        )}
                    </ShowMore>
                </ShowMoreWrapper>
            )}
            <CategoryButton>
                <CalendarButton onClick={onClickHandler}>
                    <Plus/>
                    <CalendarText>
                        Dodaj nową kategorię
                    </CalendarText>
                </CalendarButton>
            </CategoryButton>
            <Planned/>
            <CategoryPopup show={showPopup} handleClosePopup={handleClosePopup} onClickClosest={onClickClosest}/>
        </CategoryWrapper>
    );
};