import thunkMiddleware from 'redux-thunk';
import {AnyAction, combineReducers, configureStore, ThunkAction} from '@reduxjs/toolkit';
import {userReducer} from "app/user-reduser";
import {tasksWeddingReducer} from "app/tasks-wedding-reducer";
import {productsReducer} from "app/products-reduser";
import {spoilerReducer} from "app/task-reduser";
import {mainReducer} from "app/main-reduser";
import {budgetReducer} from "app/budget-reduser";
import {expenseDateReducer} from "app/expense-date-reduser";
import {guestReducer} from "app/guest-reduser";
import {appReducer} from "app/app-reduser";
import {scrollReducer} from "app/scroll-reduser";
import {baseApi} from "services/base-api";


const rootReducer = combineReducers({
    users: userReducer,
    tasksWedding: tasksWeddingReducer,
    main: mainReducer,
    products: productsReducer,
    spoiler: spoilerReducer,
    budget: budgetReducer,
    expenseDate: expenseDateReducer,
    guest: guestReducer,
    // не знаю для чего верхние
    app: appReducer,
    scroll: scrollReducer,
    [baseApi.reducerPath]: baseApi.reducer,
})


export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .prepend(thunkMiddleware)
        .concat(baseApi.middleware),
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    AnyAction
>
// @ts-ignore
window.store = store;


