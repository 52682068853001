import {createSlice} from '@reduxjs/toolkit';


const initialState: InitialStateType = {
    id: 0 ,
    image: '',
    category: '',
    name: '',
    price: 0,
}


const slice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        // setThemeAC(state, action: PayloadAction<{ theme: ThemeType }>) {
        //     state.theme = action.payload.theme;
        // },
    }
})

type InitialStateType = {
    id: number;
    image: string;
    category: string;
    name: string;
    price: number;

}



export const productsReducer = slice.reducer
// export const {setThemeAC} = slice.actions
