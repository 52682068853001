import React from "react";
import {Block, CartBlock, CartTitle, CartWrap, Category, Image, ImageBlock, Price,} from "./style-cart";
import {AdsDataThree} from "../../ads/adsDate";
import {StatusBlock} from "../../ads/ads-carts/style-ads-carts";
import {
    DressesCarTitleBlock,
    DressesTitle,
    DressWrap
} from "../../favorites/favorite/favorite-container/style-favorite-container";




export const Cart = () => {

    return (
        <CartWrap>
            <CartTitle>Sklep Ślubny</CartTitle>
            <CartWrap>
                <CartBlock>
                    {AdsDataThree.map((cart) => {
                        return (
                            <Block key={cart.id}>
                                <ImageBlock>
                                    <Image src={cart.img} alt={cart.name}/>
                                </ImageBlock>
                                <StatusBlock>
                                    <Category>{cart.type}</Category>
                                </StatusBlock>
                                <DressWrap>
                                    <DressesCarTitleBlock>
                                        <DressesTitle>{cart.name}</DressesTitle>
                                    </DressesCarTitleBlock>
                                </DressWrap>
                                <Price>{cart.priceLocal}</Price>
                            </Block>
                        )
                    })}
                </CartBlock>
            </CartWrap>

        </CartWrap>
    );
};
