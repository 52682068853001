import React from 'react';
import {Popup} from "./style-popups";
import TodoForm from "../usersForm/UsersForm";
import UsersForm from "../usersForm/UsersForm";


interface PopupProps {
    show: boolean;
    handleClosePopup: () => void;
    onClickClosest: () => void;
}

export const Popups = ({show, handleClosePopup, onClickClosest}: PopupProps) => {
    return (
        <Popup show={show} onClick={onClickClosest}>
            <UsersForm handleClosePopup={handleClosePopup}/>
        </Popup>
    );
}

