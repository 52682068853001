import styled from "styled-components";
import backgroundImage from '../../../assets/main/wedding/bg.svg';


export const WeddingBlock = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 30px rgba(11, 62, 138, .08);
`;

export const WeddingUser = styled.div`
  padding: 20px 0 29px 0;
  display: flex;
  flex-direction: column;
  width: 50%;
  background: rgba(255, 255, 255, 0.8) url(${backgroundImage}) 0 -5px / 100% 100% no-repeat;
  background-size: 100% 100%;
  border-radius: 5px 0 0 5px;
  @media (max-width: 682.98px) {
    width: 100%;
    min-height: 0;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  margin-right: 20px;
  padding: 10px 30px;
  gap: 8px;
  background: #FFFFFF;
  border: 1px solid #D2DAE3;
  border-radius: 208px 100px 100px 0;
  opacity: 0.8;
  transition: opacity 0.2s ease;
  color: #0C1215;
  margin-left: auto;

  &:hover {
    opacity: 1;
  }

  svg path {
    fill: #0C1215;
    transition: fill 0.2s ease;
  }

  &:hover svg path {
    fill: #1056B2;
  }

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    transition: color 0.2s ease;
  }

  &:hover span {
    color: #1056B2;
  }
`;



export const WeddingImage = styled.div`
  width: 50%;
  background-size: cover;
  align-self: center;
  @media (max-width: 682.98px) {
    width: auto;
    align-self: normal;
  }
`;

export const UserWrapDown = styled.div`
  display: none;
  @media (max-width: 1000.98px) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
  }
`;
export const WeddingItems = styled.div`
  display: flex;
  @media (max-width: 682.98px) {
    flex-direction: column-reverse;
    gap: 15px;
  }

`
export const Info = styled.div`
  display: flex;
  gap: 25px;
  align-items: center;
  margin-right: 20px;
  @media (max-width: 1000.98px) {
    margin-right: 0;
  }

`