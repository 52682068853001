import React from "react";

import usePopup from "../../../../../../../common/hook/use-popup";
import {PointsTitle} from "../../../../../budget/butget-expense/points-expense/style-points";



interface IExpensesId {
    expensesId: string;
    show: boolean
}

export const SendMessage = ({expensesId,show}: IExpensesId) => {
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();

    return (
        <>
            <PointsTitle onClick={onClickHandler}>{!show && 'Wyślij wiadomość'}</PointsTitle>
            {/*{showPopup && <FormFavorite handleClosePopup={handleClosePopup}/>}*/}
        </>
    );
};

