import React from 'react';
import usePopup from "../../../../../../common/hook/use-popup";
import {PlanAddGuestButton, PlanAddGuestWrap} from "./style-plan-add-guest";
import {ReactComponent as Plus} from '../../../../../../assets/main/plan/plus.svg';
import {PlanAddGuestPopups} from "./plan-add-guest-form/plan-add-guest-popup/plan-add-guest-popups";

export const PlanAddGuest = () => {

    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();

    return (
        <PlanAddGuestWrap onClick={onClickHandler}>
            <PlanAddGuestButton>Dodaj gościa</PlanAddGuestButton>
            <Plus style={{ fill: 'red' }}/>
            <PlanAddGuestPopups
                show={showPopup}
                handleClosePopup={handleClosePopup}
                onClickClosest={onClickClosest}
            />
        </PlanAddGuestWrap>


    );
};

