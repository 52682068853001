import styled from "styled-components";

export const WebsiteInformationWrap = styled.section`
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;

  &:hover {
    border: 1px dashed #D2DAE3;
    padding: 10px;

    .edit-button,
    .eye-button {
      display: block;
    }
  }
;

`;
export const InformationBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 47px;
  @media (max-width: 633.98px) {
    gap: 20px;
  }
`;

export const Information = styled.div`
  display: flex;
  justify-content: center;
`;

export const InformationText = styled.p`
  width: 558px;
  color: #0C1215;
  text-align: center;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;

`;

export const LocationInformation = styled.div`
  display: flex;
  gap: 13px;
  margin: 0 auto;
  @media (max-width: 1315.98px) {
    justify-content: flex-start;
    flex-direction: column;
  }

`;

export const LocationInformationBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: center;
  max-width: 567px;
`;

export const LocationTitle = styled.p`
  color: #0C1215;
  text-align: center;
  font-family: Gilroy;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; 
`;

export const LocationText = styled.a`
  color: #1056B2;
  text-align: center;
  font-family: Gilroy,sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
   
  &:hover {
    opacity: 0.6;
  }
`;

export const ClickMap = styled.div`
  margin: 0 auto;
  
`;
export const ClickMapText = styled.p`
  color: #D2DAE3;
  text-align: center;
  font-family: Gilroy,serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
`;


export const IconLeft = styled.div`
  position: absolute;
  top: 43px;
  left: -60px;
  display: none;

  @media (min-width: 633.98px) {
    display: block; 
  }
`;

export const IconRight = styled.div`
  position: absolute;
  bottom: 60px;
  right: 20px;
  display: none;

  @media (min-width: 633.98px) {
    display: block; /* Show the icon on screens with width 633.98px or less */
  }
`;

