import styled from "styled-components";

export const PlanButtonCloses = styled.a`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  padding: 12px 30px;
  background: #ffffff;
  border: 1px solid #d2dae3;
  border-radius: 200px 100px 100px 0px;
  color: #0C1215;
  cursor: pointer;

  span {
    font-size: 14px;
    color: #0C1215;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    opacity: 0.8;
  }

`;