import React from 'react';
import {HeaderImage, HeaderStyle, HeaderUser, HeaderWrapper, PageWrap, StyledContainer, UserWrap} from "./style-header";
import {Link} from "react-router-dom";
import logo from '../../../assets/header/logo.svg'
import {Avatar} from "./avatar/Avatar";
import {UserName} from "./userName/UserName";
import {Wedding} from "./title/Wedding";
import {Burger} from "./burger/style-burger";
import {useAppDispatch, useAppSelector} from "common/hook";
import {setOpenBurgerAC} from "app/app-reduser";
import {ReactComponent as LineIcons} from '../../../assets/header/line.svg';


export const Header = () => {
    const dispatch = useAppDispatch()
    const openBurger = useAppSelector(state => state.app.isOpenBurger)


    const onClickBurger = () => {
        dispatch(setOpenBurgerAC(!openBurger));
    };

    return (
        <HeaderStyle>
            <StyledContainer>
                <HeaderWrapper>
                    <PageWrap>
                    <Link to="/">
                        <HeaderImage>
                            <img src={logo} alt="Logo"/>
                        </HeaderImage>
                    </Link>
                    <LineIcons/>
                </PageWrap>
                    <HeaderUser>
                        <Wedding/>
                        <UserWrap>
                            <UserName/>
                            <Avatar userId={''}/>
                        </UserWrap>
                    </HeaderUser>
                    <Burger isOpenBurger={openBurger} onClick={onClickBurger}>
                        <span/>
                    </Burger>
                </HeaderWrapper>
            </StyledContainer>
        </HeaderStyle>
    );
};


