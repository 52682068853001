import React from 'react';
import {PointsPopupWrap} from "./style-points-popup";
import {PointsTasksPopupDelete} from "./points-tasks-popup-delete/PointsTasksPopupDelete";
import {TasksPopupEdit} from "./points-tasks-popup-edit/tasks-popup-edit/TasksPopupEdit";




interface IExpensesId {
    expensesId: string;
}

export const PointsPopup = ({ expensesId }: IExpensesId) => {
    return (
        <PointsPopupWrap>
            <TasksPopupEdit expensesId={expensesId} show={false}/>
            <PointsTasksPopupDelete expensesId={expensesId} />
        </PointsPopupWrap>
    );
};