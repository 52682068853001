import styled from "styled-components";

export const CollectionUploadWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
  
  
  p {
    color: #1056B2;
    text-align: center;
    font-family: Gilroy, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
  }
`;
