import React from 'react';

import usePopup from "../../../../../../../common/hook/use-popup";
import {PlanTablePopups} from "./plan-tables-form-table/plan-tab-popup/PlanTabPopups";
import {ReactComponent as Plus} from '../../../../../../../assets/main/wedding/plus.svg';
import {PlanButtonSubmit} from "./style-plan-button-table";

export const PlanButtonTable = () => {
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();

    return (
        <>
            <PlanButtonSubmit
                onClick={onClickHandler}
            >
                <Plus/>
                <span>Dodaj stół</span>
            </PlanButtonSubmit>
            <PlanTablePopups
                show={showPopup}
                handleClosePopup={handleClosePopup}
                onClickClosest={onClickClosest}
            />
        </>
    );
};

