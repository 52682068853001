import React, {FC, useEffect} from 'react';
import {Form, Formik} from 'formik';
import {ReactComponent as СrossIcon} from '../../../../../assets/main/wedding/close.svg';
import {
    ButtonBlock,
    ButtonUserSubmit,
    FormBlock,
    FormContainer,
    FormWrapper,
    StyledInput,
    StyledLabel,
    StyledTextArea
} from "../../../wedding/usersForm/style-usersform";
import {CloseButton, StyledFavoriteTitle} from "../../../wedding/popUp/style-popups";
import {useAppDispatch, useAppSelector} from "../../../../../common/hook";
import {addCategory, setCurrentCategory} from "../../../../../app/budget-reduser";
import {v4 as uuid} from 'uuid';
import {categorySelector} from "../../../../../app/selectors/category";


interface MyFormValues {
    name: string,
    email: string,
    tel: string,
    message: string,
}

type PropsType = {
    handleClosePopup: () => void;
};


export const FormFavorite: FC<PropsType> = ({handleClosePopup}) => {
    const dispatch = useAppDispatch();
    const {categories} = useAppSelector(categorySelector)
    const handleSubmit = (values: MyFormValues) => {
        dispatch(addCategory({...values, id: uuid()}));
    };

    useEffect(() => {
        if (categories.length === 1) {
            dispatch(setCurrentCategory(categories[0].id))
        }
    }, [categories])


    return (
        <Formik initialValues={
            {
                name: '',
                email: '',
                tel: '',
                message: '',
            }
        }
                onSubmit={handleSubmit}
            // validationSchema={schema}
        >
            {({values, handleSubmit, handleChange, errors}) => (
                <FormWrapper onClick={(e) => e.stopPropagation()}>
                    <Form onSubmit={handleSubmit}>
                        <CloseButton type="button" onClick={handleClosePopup}>
                            <СrossIcon/>
                        </CloseButton>
                        <StyledFavoriteTitle>Wyślij zaproszenie do<br/>wszystkich wyświetlonych prezentacji</StyledFavoriteTitle>
                        <FormContainer>
                            <FormBlock>
                                <StyledLabel htmlFor="name">Imię i nazwisko</StyledLabel>
                                <StyledInput type="text" name="name" value={values.name}
                                             onChange={handleChange}/>
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor="email">Email</StyledLabel>
                                <StyledInput type="email" name="email"
                                             value={values.email}
                                             onChange={handleChange}/>
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor="tel">Telefon</StyledLabel>
                                <StyledInput type="tel" name="tel"
                                             value={values.tel}
                                             onChange={handleChange}/>
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor="addNote">Wiadomość</StyledLabel>
                                <StyledTextArea
                                    id="content"
                                    name="message"
                                    onChange={handleChange}
                                    value={values.message}
                                    autoSize={{ minRows: 6, maxRows: 12 }}
                                />

                            </FormBlock>
                            <ButtonBlock>
                                <ButtonUserSubmit type="submit"
                                              disabled={Object.keys(errors).length !== 0}><span>Wyślij wiadomość</span></ButtonUserSubmit>
                            </ButtonBlock>
                        </FormContainer>
                    </Form>
                </FormWrapper>
            )}
        </Formik>
    );
};


