import React, {FC} from 'react';
import fotoOne from '../../../../../../../assets/main/website/gallery/aaron-burden-eZrIiv5bKxY-unsplash.png';
import foto5 from '../../../../../../../assets/main/website/gallery/aaron-burden-eZrIiv5bKxY-unsplash.png';
import foto2 from '../../../../../../../assets/main/website/gallery/nathan-dumlao-EdULZpOKsUE-unsplash.png';
import {ReactComponent as ArrayLeftIcon} from '../../../../../../../assets/main/website/arrow-left.svg';
import {ReactComponent as DownloadWhiteIcon} from '../../../../../../../assets/main/website/downloadWhite.svg';
import foto4 from '../../../../../../../assets/main/website/gallery/nathan-dumlao-w5hhoYM_JsU-unsplash.png';


import {
    DownloadWhiteIconWrapper,
    GalleryBlock,
    GalleryContent,
    GalleryFooter,
    GalleryImage, GalleryLeftBlock, GalleryRightBlock,
    UploadGalleryWrap
} from "./style-form-upload-gallery";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import {Link} from "react-router-dom";


type PropsType = {
    handleClosePopup: () => void;
};

const photos = [
    {
        id: 1,
        src: fotoOne,
        alt: 'Photo 1',
        title: 'Beautiful Landscape',
    },
    {
        id: 2,
        src: foto2,
        alt: 'Photo 2',
        title: 'Sunset by the Beach',
    },
    {
        id: 4,
        src: foto4,
        alt: 'Photo 4',
        title: 'Another Photo',
    },
    {
        id: 5,
        src: foto5,
        alt: 'Photo 5',
        title: 'Yet Another Photo',
    },
    {
        id: 6,
        src: foto4,
        alt: 'Photo 6',
        title: 'Last Photo',
    },
    {
        id: 7,
        src: fotoOne,
        alt: 'Photo 5',
        title: 'Cityscape at Night',
    },
    {
        id: 8,
        src: fotoOne,
        alt: 'Photo 5',
        title: 'Cityscape at Night',
    },
    {
        id: 9,
        src: fotoOne,
        alt: 'Photo 3',
        title: 'Cityscape at Night',
    },
];


export const FormUploadGallery: FC<PropsType> = ({handleClosePopup}) => {

    return (
        <UploadGalleryWrap>
            <GalleryBlock
                onClick={(e) => { e.stopPropagation(); handleClosePopup(); }}>
                <Link to='#'>
                    <GalleryLeftBlock>
                        <ArrayLeftIcon/>
                        <span>Wróć</span>
                    </GalleryLeftBlock>
                </Link>
                <GalleryRightBlock>
                    <DownloadWhiteIcon/>
                    <span>Pobierz wszystkie zdjęcia</span>
                </GalleryRightBlock>
            </GalleryBlock>

            <GalleryContent>
                <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
                    <Masonry gutter="20px">
                        {photos.map((photo) => (
                            <GalleryImage key={photo.id}>
                                <img src={photo.src} alt={photo.alt} />
                                <DownloadWhiteIconWrapper>
                                    <DownloadWhiteIcon/>
                                </DownloadWhiteIconWrapper>
                            </GalleryImage>
                        ))}
                    </Masonry>
                </ResponsiveMasonry>
            </GalleryContent>
        </UploadGalleryWrap>
    );
};