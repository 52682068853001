import React, {useEffect} from 'react';
import {Link, NavLink, useLocation} from 'react-router-dom';
import {MenuItem, MenuList, NavigateWrap} from './style-navigate';
import {ReactComponent as HomeIcon} from '../../../assets/navigate/wedding.svg';
import {ReactComponent as TaskIcon} from '../../../assets/navigate/tasks.svg';
import {ReactComponent as BudgetIcon} from '../../../assets/navigate/budget.svg';
import {ReactComponent as GuestIcon} from '../../../assets/navigate/guest.svg';
import {ReactComponent as PlanIcon} from '../../../assets/navigate/plan.svg';
import {ReactComponent as QAIcon} from '../../../assets/navigate/weddingqa.svg';
import {ReactComponent as WebsiteIcon} from '../../../assets/navigate/website.svg';
import {ReactComponent as AdsIcon} from '../../../assets/navigate/ads.svg';
import {ReactComponent as FavoritesIcon} from '../../../assets/navigate/favorites.svg';
import {useAppDispatch, useAppSelector} from "../../../common/hook";
import {setOpenBurgerAC} from "../../../app/app-reduser";
import {HeaderImage, PageWrap, PageWrapNav} from "../header/style-header";
import logo from "../../../assets/header/logo.svg";
import {ReactComponent as LineIcons} from '../../../assets/header/line.svg';


// Определение типов для путей


const menuItems = [
    {
        icon: <HomeIcon/>,
        title: 'Mój ślub',
        path: '/',
    },
    {
        icon: <WebsiteIcon/>,
        title: 'Strona ślubna',
        path: '/website-create',
    },
    {
        icon: <TaskIcon/>,
        title: 'Lista zadań',
        path: '/tasks',
    },
    {
        icon: <BudgetIcon/>,
        title: 'Budżet',
        path: '/budget',
    },
    {
        icon: <GuestIcon/>,
        title: 'Lista gości',
        path: '/guest',
    },
    {
        icon: <PlanIcon/>,
        title: 'Plan stołów',
        path: '/plan',
    },
    {
        icon: <QAIcon/>,
        title: 'Ślubne Q&A',
        path: '/weddingqa/answers',
    },
    {
        icon: <AdsIcon/>,
        title: 'Moje ogłoszenia',
        path: '/ads',
    },
    {
        icon: <FavoritesIcon/>,
        title: 'Ulubione i inspiracje',
        path: '/favorites',
    },
];


type Path = string;


const isMenuItemActive = (locationPath: string, itemPath: string) => {
    if (locationPath === itemPath) {
        return true;
    }
    if (itemPath === '/weddingqa/answers' && locationPath.startsWith('/weddingqa')) {
        return true;
    }
    return false;
};

export const Navigate = () => {
    const dispatch = useAppDispatch();
    const openBurger = useAppSelector(state => state.app.isOpenBurger);
    const location = useLocation();
    const onClickHandler = () => {
        dispatch(setOpenBurgerAC(false));
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <NavigateWrap>
            <PageWrapNav>
                <Link to="/">
                    <HeaderImage>
                        <img src={logo} alt="Logo"/>
                    </HeaderImage>
                </Link>
                <LineIcons/>
            </PageWrapNav>
            <MenuList isOpenBurger={openBurger}>
                {menuItems.map((item, index) => (
                    <MenuItem key={index} className={isMenuItemActive(location.pathname, item.path) ? 'active' : ''}>
                        <NavLink to={item.path} onClick={onClickHandler} end={true}>
                            {item.icon}
                            <span>{item.title}</span>
                        </NavLink>
                    </MenuItem>
                ))}
            </MenuList>
        </NavigateWrap>
    );
};