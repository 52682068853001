import styled from "styled-components";


export const HeaderStyle = styled.header`
  background: linear-gradient(90deg, #1056B2 0%, #2779E4 100%);
  position: fixed;
  top: 0;
  z-index: 5;
  width: 100%;
  left: 0;
  margin-bottom: 2rem;

`

export const StyledContainer = styled.div`
  max-width: 1470px;
  box-sizing: content-box;
  margin: 0 auto;
  padding: 0 10px;
`

export const HeaderWrapper = styled.div`
  min-height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 28px;
  @media (max-width: 1000.98px) {
    position: relative;
  }
`
export const HeaderImage = styled.div`
  cursor: pointer;
`;

export const PageWrap = styled.div`
  display: flex;
  
  @media (min-width: 1000.98px) {
    display: none;
  }

  a {
    position: relative;
    height: 100%;

    
    svg {
      width: 2px;
      height: 70px;
      flex-shrink: 0;
    }
  }

  @media (max-width: 1000.98px) {
    svg {
      display: none;
    }
  }

  @media (max-width: 400.98px) {
    a {
      display: none;
    }
  }
`;

export const PageWrapNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1000.98px) {
    display: none;
  }

  a {
    position: relative;
    height: 100%;
    padding-left: 25px;

    
    svg {
      width: 2px;
      height: 70px;
      flex-shrink: 0;
    }
  }

  @media (max-width: 1000.98px) {
    svg {
      display: none;
    }
  }

  @media (max-width: 400.98px) {
    a {
      display: none;
    }
  }
`;

export const HeaderUser = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 25px;
  align-items: center;
  width: 100%;
  @media (max-width: 1000.98px) {
    position: absolute;
    top: 295%;
    left: 0%;
  }
`


export const UserWrap = styled.div`
  position: relative;
  display: flex;
  gap: 25px;
  align-items: center;
  @media (max-width: 1000.98px) {
    display: none;
  }
`

