import React from 'react';
import {Popup} from "../../../wedding/popUp/style-popups";
import {FormFavorite} from "../favorive-form/FormCategory";




interface PopupProps {
    show: boolean;
    handleClosePopup: () => void;
    onClickClosest: () => void;
}

export const FavoritePopup = ({show, handleClosePopup, onClickClosest}: PopupProps) => {
    return (
        <Popup show={show} onClick={onClickClosest}>
            <FormFavorite handleClosePopup={handleClosePopup}/>
        </Popup>
    );
}

