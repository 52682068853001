import {SpoilerItem} from "./plan-spoiler";

export const familyItems: SpoilerItem[] = [
    {
        name: 'Janusz Biernacka',
        confirmed: true,
        location: false,
        hasBaby: true,
    },
    {
        name: 'Idalia Biernacka',
        confirmed: true,
        location: true,
        hasBaby: false,
    },
    {
        name: 'Lena Biernacka',
        confirmed: true,
        location: true,
        hasBaby: true,
    },
];

export const familyBiernackaItems: SpoilerItem[] = [
    {
        name: 'Janusz Biernacka',
        confirmed: false,
        location: true,
    },
    {
        name: 'Idalia Biernacka',
        confirmed: true,
        location: false,
    },
    {
        name: 'Lena Biernacka',
        confirmed: false,
        location: false,
    },
];

export const familyDabrowskiItems: SpoilerItem[] = [
    {
        name: 'Janusz Biernacka',
        confirmed: true,
        location: true,
    },
    {
        name: 'Idalia Biernacka',
        confirmed: true,
        location: true,
    },
    {
        name: 'Lena Biernacka',
        confirmed: true,
        location: true,
    },
];