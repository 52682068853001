import React from 'react';
import {
    PointsGuestPopupWrap,
    PointsNewlywedsPopupWrap
} from "../../../../../tasks/points-tasks/points-popup/style-points-popup";
import {NewlywedsRename} from "./newlyweds-rename/NewlywedsRename";
import {NewlywedsDelete} from "./newlyweds-delete/NewlywedsDelete";


interface IExpensesId {
    expensesId: string;
}

export const NewlywedsPrinter = ({ expensesId }: IExpensesId) => {
    return (
        <PointsNewlywedsPopupWrap>
            <NewlywedsRename expensesId={expensesId} show={false}/>
            <NewlywedsDelete expensesId={expensesId} show={false}/>
        </PointsNewlywedsPopupWrap>
    );
};