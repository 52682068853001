import React, {ReactNode} from 'react';

import {Portal} from "common/portal/Portal";
import {Popup} from "./style-popups";


interface PopupProps {
    show: boolean;
    handleClosePopups: () => void;
    onClickClosest: () => void;
    formComponent: ReactNode;
    textColor?: string;
}

export const Popups = ({ show, onClickClosest, formComponent ,textColor}: PopupProps) => {
    return (
        <Portal>
            <Popup show={show} onClick={onClickClosest} >
                {formComponent}
            </Popup>
        </Portal>
    );
}

