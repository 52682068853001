import React from 'react';
import {calcTotalPrice} from "../../../../common/utils/calcTotalPrice";
import {useAppSelector} from "../../../../common/hook";
import {categorySelector} from "../../../../app/selectors/category";
import {TotalAmount} from "./butget-title";


export const TotalPrice = () => {
    const { categories} = useAppSelector((categorySelector));
    const totalPrice = calcTotalPrice(categories);

    return <TotalAmount>Całkowita kwota: {totalPrice} zł</TotalAmount>;
};
