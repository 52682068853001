import styled from "styled-components";

export const InspirationsWrap = styled.div`
padding-top: 30px;
`

export const InspirationsLink = styled.div`
  display: flex;
  justify-content: right;
  padding-bottom: 30px;
`


// export const InspEditPopup = styled.div`
//   width: 100%;
//   height: 100vh;
//   background-color: rgba(12, 18, 21, 0.4);
//   position: fixed;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   overflow: hidden;
//   button {
//     position: absolute;
//     top: 10px;
//     right: 10px;
//   }
//   img {
//     width: auto;
//     max-width: 90%;
//     max-height: 90%;
//   }
// `
