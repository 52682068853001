import React, {useState} from "react";
import "react-calendar/dist/Calendar.css";
import usePopup from "../../../../../common/hook/use-popup";
import {useAppDispatch} from "../../../../../common/hook";
import Calendar from "react-calendar";
import {CalendarContainer} from "../../../../layout/Calendar/style-calendar";
import {setExpenseDate} from "../../../../../app/expense-date-reduser";


export const CalendarExpense = () => {
    const [date, setDate] = useState<Date>(new Date());
    const dispatch = useAppDispatch();
    const { onClickHandler } = usePopup();

    const onDateChange = (value: any) => {
        const currentDate = new Date();
        const startOfCurrentDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate()
        );
        if (value && value instanceof Date) {
            if (value < startOfCurrentDate) {
                console.error("Вы не можете выбрать прошедшую дату");
                return;
            }
            const formattedDate = value.toLocaleDateString("pl", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            });
            onClickHandler();
            if (formattedDate) {
                console.log(date, "CalendarExpense");
                dispatch(setExpenseDate(formattedDate));
            } else {
                console.error("Неверный формат даты");
            }
        } else {
            console.error("Неверное значение даты");
        }
    };

    const shortWeekdayLabels = {
        weekdayShort: ["P", "W", "Ś", "C", "P", "S", "N"],
    };

    return (
        <CalendarContainer>
            <div onClick={onDateChange}>
                <Calendar
                    locale="pl"
                    onChange={onDateChange}
                    value={date}
                    view="month"
                    formatShortWeekday={(locale, date) =>
                        shortWeekdayLabels.weekdayShort[date.getDay()]
                    }
                />
            </div>
        </CalendarContainer>
    );
};
