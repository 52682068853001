import React, {FC, useEffect} from 'react';
import {Form, Formik} from 'formik';
import {ReactComponent as СrossIcon} from '../../../../../assets/main/wedding/close.svg';
import {ReactComponent as Checkbox} from '../../../../../assets/main/form/checkbox.svg';
import {ReactComponent as Сlose} from '../../../../../assets/main/form/close.svg';
import {
    ButtonBlock, ButtonCloses, ButtonSubmit,
    FormBlock,
    FormContainer,
    FormWrapper,
    StyledInput,
    StyledLabel
} from "../../../wedding/usersForm/style-usersform";
import {CloseButton, StyledTitle} from "../../../wedding/popUp/style-popups";
import {useAppDispatch, useAppSelector} from "../../../../../common/hook";
import {addCategory, setCurrentCategory} from "../../../../../app/budget-reduser";
import {v4 as uuid} from 'uuid';
import {categorySelector} from "../../../../../app/selectors/category";



interface MyFormValues {
    title: string,
    price: string,
}

type PropsType = {
    handleClosePopup: () => void;
};


export const FormCategory: FC<PropsType> = ({handleClosePopup}) => {
    const dispatch = useAppDispatch();
    const {categories} = useAppSelector(categorySelector)
    const handleSubmit = (values: MyFormValues) => {
        dispatch(addCategory({...values, id: uuid()}));
    };

    useEffect(() => {
        if (categories.length === 1) {
            dispatch(setCurrentCategory(categories[0].id))
        }
    }, [categories])


    return (
        <Formik initialValues={
            {
                title: '',
                price: '',
            }
        }
                onSubmit={handleSubmit}
            // validationSchema={schema}
        >
            {({values, handleSubmit, handleChange, errors}) => (
                <FormWrapper onClick={(e) => e.stopPropagation()}>
                    <Form onSubmit={handleSubmit}>
                        <CloseButton type="button" onClick={handleClosePopup}>
                            <СrossIcon/>
                        </CloseButton>
                        <StyledTitle>Dodaj nową kategorię</StyledTitle>
                        <FormContainer>

                            <FormBlock>
                                <StyledLabel htmlFor="title">Tytuł</StyledLabel>
                                <StyledInput type="text" name="title" value={values.title}
                                             onChange={handleChange}/>
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor="price">Kwota planowanych wydatków</StyledLabel>
                                <StyledInput type="text" name="price"
                                             value={values.price}
                                             onChange={handleChange}/>
                            </FormBlock>
                            <ButtonBlock>
                                <ButtonCloses><Сlose/><span>Anuluj</span></ButtonCloses>
                                <ButtonSubmit type="submit"
                                              disabled={Object.keys(errors).length !== 0}><Checkbox/><span>Zapisz</span></ButtonSubmit>
                            </ButtonBlock>
                        </FormContainer>
                    </Form>
                </FormWrapper>
            )}
        </Formik>
    );
};


