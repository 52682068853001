import React from 'react';
import { WebsiteCreateTitle, WebsiteCreateWrap } from './style-website-create';
import create from '../../../../assets/main/website/create.png';
import { CalendarText } from '../../../calendar-Info/styles';
import { ChangeViewButton } from '../website-change-view/style-website-change-view';
import {useNavigate} from "react-router-dom";

export const WebsiteCreate = () => {
    const navigate = useNavigate(); // Initialize navigate

    const handleCreateWebsite = () => {
        navigate('/website-create/website/website-pages');
    };

    return (
        <WebsiteCreateWrap>
            <WebsiteCreateTitle>
                Stwórz Waszą własną stronę ślubną w Internecie! Umieść na niej wszystkie informacje organizacyjne dotyczące
                ślubu i wesela. Dla Waszych gości weselnych będzie to zbiór cennych informacji, które będą mieli zawsze pod
                ręką, a dla Was ogromne ułatwienie podczas planowania przyjęcia i wspaniała wirtualna pamiątka.
            </WebsiteCreateTitle>
            <img src={create} alt="" />
            <ChangeViewButton onClick={handleCreateWebsite}>
                <CalendarText>Stwórz</CalendarText>
            </ChangeViewButton>
        </WebsiteCreateWrap>
    );
};
