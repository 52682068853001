import styled from "styled-components";

export const AnswerLatestWrap = styled.div`
  background-color: #F5F9FF;
  margin-bottom: 80px;
  border-radius: 5px;
  @media (max-width: 1024.98px) {
    gap: 10px;
    margin-bottom: 40px;
  }
`


export const ShowMoreButton = styled.div`
  padding: 0 25px 30px 25px;
  @media (max-width: 425.98px) {
    padding: 10px;
  }
`
export const ColorShowMoreButton = styled.div`
  background-color: #F5F9FF;
  border-radius: 0 0 5px 5px;
`

export const ShowInputBlock = styled.div`
  background: #E7ECF3;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 20px;
`

export const BlockListUser = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 18px;

`

export const ListButton = styled.button`

`

export const AnswerLatestTitle = styled.p`
  padding: 0 25px;
  font-family: 'Gilroy',sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #0C1215;
`

export const AnswerLatesInputBlock = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 18px;
  p {
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #0C1215;
    width: 105px;
  }

  @media (max-width: 768.98px) {
    flex-wrap: wrap;
 
  }
  
`
export const AnswerLatesInput = styled.input`
  width: 100%;
  height: 48px;
  background: #FFFFFF;
  border: 0.6px solid #D2DAE3;
  border-radius: 5px;
  font-size: 16px;
  line-height: 46px;
  color: rgba(12,18,21,0.8);
  padding: 0 20px;
`
