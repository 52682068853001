import React, {useEffect, useState} from 'react';
import {ProductsData} from "./productsDate";
import {
    DressesBlock,
    DressesCart,
    DressesIcons,
    DressesImage,
    DressesPrice,
    DressesTitle,
    DressesTitleBlock,
    FavoriteButtonBlock,
    FavoriteContainerWrap,
    FavoriteSliderContainer,
    FavoriteSliderContentHotels,
    FavoriteSliderIcon,
    FavoriteSliderTitle,
    FavoriteSliderWrap,
    Image,
    ImageContainer,
    SliderBlock,
    SliderTitle
} from "./style-favorite-container";
import {Slider} from "./hotels-slider/HotelSlider";
import {ReactComponent as NotesIcon} from '../../../../../assets/main/favorites/notes.svg'
import {ReactComponent as LikeIcon} from '../../../../../assets/main/favorites/like.svg'
import {ReactComponent as LocationIcon} from '../../../../../assets/main/favorites/location.svg'
import {ReactComponent as ExpandIcon} from '../../../../../assets/main/guest/expand_more.svg';
import {ReactComponent as EnvelopeIcon} from '../../../../../assets/main/guest/envelope.svg';
import usePopup from "../../../../../common/hook/use-popup";
import {FavoritePopup} from "../favorite-popup/FavoritePopup";
import {ContentPaints, StyledPointsIcon} from "../../../tasks/style-tasks";
import {FavoritesMenu} from "../favorites-menu/FavoritesMenu";
import {FavoritePoints} from "../favorite-pointer/FavoritePointer";
import {InfoContentBlock} from "../../../guest/guest-print/style-guest-print";


export const FavoriteContainer = () => {
    const { showPopup, onClickHandler, handleClosePopup, onClickClosest } = usePopup();
    const [menuProducts, setMenuProducts] = useState(ProductsData);
    const [selectedType, setSelectedType] = useState('hotels');
    const [popup, setPopup] = useState(false);

    const handleTogglePopup = () => {
        setPopup(!popup);
    };

    const filter = (type: string) => () => {
        setMenuProducts(ProductsData.filter((product) => product.type === type));
        setSelectedType(type);
    };

    useEffect(() => {
        const filteredProducts = ProductsData.filter((product) => product.type === selectedType);
        setMenuProducts(filteredProducts);
    }, [selectedType]);



    return (
        <FavoriteContainerWrap>
            <div>
                <FavoritesMenu filter={filter} selectedType={selectedType} />

                <FavoriteButtonBlock onClick={onClickHandler}>
                        <InfoContentBlock style={{maxWidth:'320px'}}>
                            <EnvelopeIcon style={{marginRight:"4px"}}/>
                            <span>Wyślij zapytanie do wszystkich z listy</span>
                            <ExpandIcon/>
                        </InfoContentBlock>
                </FavoriteButtonBlock>

                <FavoritePopup
                    show={showPopup}
                    handleClosePopup={handleClosePopup}
                    onClickClosest={onClickClosest}
                />
                <FavoriteSliderWrap isArray={Array.isArray(menuProducts[0]?.img)}>
                    {menuProducts.map((product, index) => (
                        <FavoriteSliderContainer key={index}>
                            {Array.isArray(product.img) ? (
                                <SliderBlock>
                                    <Slider images={product.img} />
                                    <FavoriteSliderContentHotels>
                                        <FavoriteSliderTitle>
                                            <SliderTitle>{product.name}</SliderTitle>
                                            <FavoritePoints expensesId="someExpenseId"/>
                                        </FavoriteSliderTitle>
                                        <FavoriteSliderIcon> <LocationIcon/><span>{product.priceLocal}</span></FavoriteSliderIcon>
                                    </FavoriteSliderContentHotels>
                                </SliderBlock>
                            ) : (
                                <DressesCart>
                                    <DressesBlock>
                                        <DressesImage>
                                            <ImageContainer>
                                                <Image src={product.img} alt={`product-${index + 1}`} />
                                                <DressesIcons>
                                                     <NotesIcon />
                                                    <LikeIcon />
                                                </DressesIcons>
                                            </ImageContainer>
                                        </DressesImage>
                                        <DressesTitleBlock>
                                            <DressesTitle>{product.name}</DressesTitle>
                                            <ContentPaints>
                                                <StyledPointsIcon onClick={handleTogglePopup} />
                                            </ContentPaints>
                                        </DressesTitleBlock>
                                        <DressesPrice>{product.priceLocal}</DressesPrice>
                                    </DressesBlock>
                                </DressesCart>
                            )}
                        </FavoriteSliderContainer>
                    ))}
                </FavoriteSliderWrap>
            </div>
        </FavoriteContainerWrap>
    );
};
