import React from 'react';
import {AnswersWrap} from "./style-answers";
import {Categories} from "../container/categories-container/Categories";
import {AnswerQuestion} from "./answer-question/AnswerQuestion";
import {AnswersQuestions} from "./answers-questions/answers-questions";
import {AnswerLatest} from "./answer-latest/AnswerLatest";
import {NewQuestions} from "../container/new-questions/NewQuestions";


export const Answers = () => {
    return (
        <AnswersWrap>
            <AnswerQuestion/>
            <Categories/>
            <AnswersQuestions/>
            <AnswersQuestions/>
            <AnswerLatest/>
            <NewQuestions/>
        </AnswersWrap>
    );
};

