import { useState } from "react";

const usePopup = (closeOtherPopups = () => {}) => {
    const [showPopup, setShowPopup] = useState(false);

    const onClickHandler = () => {
        if (closeOtherPopups) {
            closeOtherPopups();
        }
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const onClickClosest = () => {
        handleClosePopup();
    };

    return {
        showPopup,
        handleClosePopup,
        onClickHandler,
        onClickClosest,
    };
};

export default usePopup;