import styled from "styled-components";

export const TitleDown = styled.div`
  font-family: 'Gilroy', sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 1.2em;
  letter-spacing: .01em;
  color: #fff;
  display: none;
  
  @media (max-width: 1000.98px) {
    display: inline-block;
    color: #0C1215;
  }
`