import React, {useState} from 'react';
import {FullWrapper} from "../../layout/style-layout";
import {PlanTabWrapper, TabContent} from "./style-plan";
import {PlanContent} from "./plan-contant/plan-content";
import {PlanTab} from "./plan-tab/plan-tab";
import {dates} from "./plan-array";


export const Plan = () => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <FullWrapper>
            <PlanTab dates={dates} activeTab={activeTab} setActiveTab={setActiveTab}/>
            <PlanTabWrapper>
                {dates.map((date, index) => (
                    <TabContent key={index} isActive={index === activeTab}>
                        {/* Здесь можно разместить содержимое таба для каждой даты */}
                        {/*<p>Содержимое для даты: {date}</p>*/}
                        <PlanContent/>
                    </TabContent>
                ))}
            </PlanTabWrapper>
        </FullWrapper>
    );
};