import React, {useState} from 'react';
import {
    PlanButtonBlock,
    PlanTablesContainer,
    PlanTablesTitle,
    PlanTablesWrap,
    Table,
    TableContent,
    TableName,
    TableNumber,
    TableRow,
    TableTitle,
    TableTitleBlock
} from "./style-plan-tables";
import {TablesArray} from "../../plan-array";

import {PlanButtonTableGroup} from "./plan-button/plan-button-table-group/planButtonTable";
import {PlanButtonTable} from "./plan-button/plan-button-table/planButtonTable";
import {PlanAddGuest} from "./plan-add-guest/plan-add-guest";
import {PointsPointsTask} from "./plan-points/points-popup/PointsPopup";

export const PlanTables = () => {
    const [openPopupId, setOpenPopupId] = useState<string | null>(null);

    const handleTogglePopup = (id: string) => {
        setOpenPopupId((prevId) => (prevId === id ? null : id));
    };


    return (
        <PlanTablesWrap>
            <PlanTablesTitle>Stoły</PlanTablesTitle>
            <PlanTablesContainer>
                {TablesArray.map((table) => (
                    <Table key={table.index}>
                        <TableTitleBlock>
                            <TableTitle>{table.title}</TableTitle>
                       <PointsPointsTask
                           expensesId={table.index}
                           open={openPopupId === table.index}
                           togglePopup={handleTogglePopup}
                       />
                        </TableTitleBlock>
                        <TableContent>
                            {table.tableArray.map((item, index) => (
                                <TableRow key={item.id}>
                                    <TableNumber>{item.number}</TableNumber>
                                    <TableName>{item.name}</TableName>
                                </TableRow>
                            ))}
                            {table.tableArray.length < 8 && (
                                <PlanAddGuest/>
                            )}
                        </TableContent>
                    </Table>
                ))}
            </PlanTablesContainer>
            <PlanButtonBlock>
                <PlanButtonTable/>
                <PlanButtonTableGroup/>
            </PlanButtonBlock>
        </PlanTablesWrap>
    );
};

