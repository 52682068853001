import React from 'react';
import {PreviewingBlock, PreviewingText, WebsitePreviewingWrap} from "./style-website-previewing";
import {IWebsite} from "../types";
import {RightArrow} from "../website-guestbook/preview-mode/style-preview-mode";


export const WebsitePreviewing = ({backgroundColor,textColor}: IWebsite) => {


    return (
        <WebsitePreviewingWrap >
            <PreviewingBlock>
                <PreviewingText style={{color: textColor}}>
                    Zobacz w trybie podglądu
                </PreviewingText>
                <RightArrow color={textColor}/>
            </PreviewingBlock>
        </WebsitePreviewingWrap>
    );
};

