import styled from "styled-components";
import {NavLink} from "react-router-dom";


export const WeddingQAContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const WeddingQALinks = styled.div`
  border-bottom: 1px solid #D2DAE3;;
  padding-bottom: 8px;
  //margin-bottom: 30px;

`
export const LinksMenu = styled.ul`
  display: flex;
  gap: 26px;
`

export const LinksList = styled.li`
  font-family: 'Roboto', sans-serif;
  color: #0c1215;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
`


export const ActiveNavLink = styled(NavLink)`
  font-family: 'Gilroy',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #0C1215;
  padding-bottom: 8px;
  transition: 0.3s ease-in-out;
  &.active {
    border-bottom: 1px solid #1056B2;
    box-shadow: inset 0px -2px 0px #1056B2;
  }
`;
