import React, {useEffect,} from 'react';
import {Outlet, useNavigate} from "react-router-dom";
import {ActiveNavLink, LinksList, LinksMenu, WeddingQAContainer, WeddingQALinks} from "../weddingqa/style-weddingqa";
import {FullWrapper} from "../../layout/style-layout";

export const Favorites = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/favorites/favorite');
    }, []);

    return (
        <FullWrapper>
            <WeddingQAContainer>
                <WeddingQALinks>
                    <LinksMenu>
                        <LinksList>
                            <ActiveNavLink to="favorite">Ulubione</ActiveNavLink>
                        </LinksList>
                        <LinksList>
                            <ActiveNavLink to="inspirations">Inspiracje</ActiveNavLink>
                        </LinksList>
                    </LinksMenu>
                </WeddingQALinks>
                <Outlet/>
            </WeddingQAContainer>
        </FullWrapper>
    );
};

