import React from 'react';

import {useAppDispatch} from "../../../../../../common/hook";
import {PointsTitle} from "../../../../budget/butget-expense/points-expense/style-points";

import {deleteTask} from "../../../../../../app/tasks-wedding-reducer";


interface IExpensesId {
    expensesId: string;
}

export const PointsTasksPopupDelete = ({ expensesId }: IExpensesId) => {
    const dispatch = useAppDispatch();

    const handleDeleteExpense = () => {
        dispatch(deleteTask(expensesId));
        console.log('Сработало')
    };

    return (
        <PointsTitle onClick={handleDeleteExpense}>Usuń</PointsTitle>
    );
};