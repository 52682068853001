import React, {useState} from "react";
import {TasksContainer, TasksWrap} from './style-tasks';
import {useAppSelector} from 'common/hook';
import CalendarComponent from "../../layout/Calendar/CalendarComponent";
import {CalendarBlock} from "../wedding/tasks/style-tasks";
import {weddingSelector} from "app/selectors/wending";
import {userSelector} from "app/selectors/users";
import {ShowMoreListWrapper, ShowMoreWrapper} from "../budget/butget-expense/style-budgetExpense";
import {TasksItem} from "./taskItem/TasksItem";

export const Tasks = () => {
    const tasks = useAppSelector(weddingSelector);
    const {wendingDate } = useAppSelector(userSelector);
    const [isExpanded, setIsExpanded] = useState(false);


    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <TasksWrap>
            <TasksContainer>
                <ShowMoreListWrapper>
                    <TasksItem
                        taskArray={isExpanded ? tasks.beforeWedding : tasks.beforeWedding.slice(0, 6)}
                        title="Do uzupełnienia przed ślubem"
                        index={0}
                        isExpanded={isExpanded}
                        toggleExpand={toggleExpand}
                    />
                </ShowMoreListWrapper>
                <ShowMoreListWrapper>
                    <TasksItem
                        taskArray={isExpanded ? tasks.wedding : tasks.wedding.slice(0, 6)}
                        title={
                            wendingDate === null
                                ? 'Do wykonania podczas ślubu'
                                : `Do wykonania podczas ślubu ${wendingDate}`
                        }
                        index={1}
                        isExpanded={isExpanded}
                        toggleExpand={toggleExpand}
                    />
                </ShowMoreListWrapper>
                <ShowMoreListWrapper>
                    <TasksItem
                        taskArray={isExpanded ? tasks.afterWedding : tasks.afterWedding.slice(0, 6)}
                        title="Do uzupełnienia po ślubie"
                        index={2}
                        isExpanded={isExpanded}
                        toggleExpand={toggleExpand}
                    />
                </ShowMoreListWrapper>
            </TasksContainer>
            <CalendarBlock>
                <p>Kalendarz zadań</p>
                <CalendarComponent isShowCalendarInfo={true} />
            </CalendarBlock>
        </TasksWrap>
    );
};
