import {Popup} from "../../../../../../wedding/popUp/style-popups";
import React from "react";
import {
    FormTasksEdit
} from "../../../../../../tasks/points-tasks/points-popup/points-tasks-popup-edit/form-tasks-edit/FormTasksEdit";
import {FormEditExpense} from "../form-edit-expense/FormEditExpense";


interface PopupProps {
    show: boolean;
    handleClosePopup: () => void;
    onClickClosest: () => void;
    expensesId:string;
}

export const ExpenseEditPopup = ({show, handleClosePopup, onClickClosest,expensesId}: PopupProps) => {
    return (
        <Popup show={show} onClick={onClickClosest}>
            <FormEditExpense handleClosePopup={handleClosePopup} expensesId={expensesId}/>
        </Popup>
    );
}