import {createSlice, PayloadAction} from '@reduxjs/toolkit';


import avatar from "../assets/header/ava.png";



type InitialStateType = {
    avatar: null |string,
    name: string,
    userId: string,
    email: string,
    isOpenBurger:boolean,
}


const initialState: InitialStateType = {
    avatar: avatar,
    name: 'Wiadomości',
    userId: 'qwerty',
    email: '123',
    isOpenBurger:false,
}


const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setOpenBurgerAC(state, action: PayloadAction<boolean>) {
            state.isOpenBurger = action.payload;
        },
    }
})


export const appReducer = slice.reducer
export const {setOpenBurgerAC} = slice.actions
