import styled from "styled-components";




interface StatisticsBlockProps {
    imageUrl: string;
}

export const TasksWrapper = styled.section`
  padding-top: 80px;
  @media (max-width: 682.98px) {
    padding-top: 20px;
  }
`

export const TasksBlock = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  @media (max-width: 682.98px) {
    flex-direction: column-reverse;
    gap: 15px;
  }
`


export const TasksItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;

`
export const TasksAbout = styled.div`
  width: 100%;
  max-height: 350px;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 36px 0 0;
  background: rgba(255, 255, 255, .8);
  box-shadow: 0 4px 30px rgba(11, 62, 138, .08);
  border-radius: 5px;
  @media (max-width: 964.98px) {
    display: block;
  }

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 44px;
    bottom: 44px;
    width: 1px;
    background: #D2DAE3;
    @media (max-width: 964.98px) {
      left: 15%;
      right: 15%;
      top: calc(50% - 8px);
      bottom: auto;
      height: 1px;
      width: auto;
    }
  }
  
  a {
    text-align: center;
    width: 50%;
  }
  
`


export const TasksBudget = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const TasksBudgetBlock = styled.div`
  display: flex;
  gap: 10px;

  p {
    color: rgba(0, 0, 0, 0.60);
    font-family: Gilroy, serif;
    font-size: 14px;
    font-weight: 500;
  }

  span {
    color: #0C1215;
    font-family: Gilroy, sans-serif;
    font-size: 22px;
    font-weight: 500;
  }

`

export const CalendarBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    font-family: 'Gilroy', serif;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #0C1215;
  }

`
export const CompletedContainer =  styled.div<StatisticsBlockProps>`
  text-align: center;
  padding: 60px 20px;

  @media (min-width: 1024.98px) {
    background: url(${props => props.imageUrl}) no-repeat center/contain;
  }
  
  @media (max-width: 964.98px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 5px;
    height: auto;
    margin-bottom: 0;
    width: 100%;
    gap: 40px;
  }
`;



export const KillingsNumberBlock = styled.div`
  font-family: 'Cormorant Garamond', serif;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #0C1215;
  font-weight: 600;
  line-height: 140%;
  text-align: center;

  span {
    font-size: 30px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    opacity: 0.4;
  }
`

export const KillingsNumber = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 25px;
  gap: 5px;

`

export const Number = styled.p`
  font-size: 65px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #0C1215;
  @media (max-width: 682.98px){
    font-size: 45px;
  }
`

export const KillingsTitle = styled.p`
  font-family: Gilroy,serif;
  color: #0C1215;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
`


