import React from 'react';
import {InspirationsGallery} from "./Inspirations-gallery/Inspirations-gallery";
import {InspirationsLink, InspirationsWrap} from "./style-inspirations";
import {Link} from "react-router-dom";
import {InfoContentBlock} from "../../guest/guest-print/style-guest-print";
import {ReactComponent as Expand} from '../../../../assets/main/guest/expand_more.svg';


export const Inspirations = () => {

    return (
        <InspirationsWrap>
            <InspirationsLink>
                <Link to="#">
                    <InfoContentBlock><span>Powrót do strony „Inspiracje”</span><Expand/></InfoContentBlock>
                </Link>
            </InspirationsLink>
            <InspirationsGallery/>
        </InspirationsWrap>

    )
};
