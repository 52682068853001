import styled from "styled-components";

export const PointsWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #0C1215;
`

export const PointsTitle = styled.div`
  font-family: 'Gilroy', serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #0C1215;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.3s ease; 
  
  
  
  &:hover {
    opacity: 1;
  }
`;
