import React from 'react';
import {TitleDown} from "./style-titleDown";

interface HeaderProps {
    title: string;
}

export const HeaderTitleDown: React.FC<HeaderProps> = ({ title }) => {
    return <TitleDown>{title}</TitleDown>;
};
