import styled from "styled-components";

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%; 
  height: 100%; 
`;

export const ResponsiveImage = styled.img`
  width: 100%; 
  height: auto; 
  min-height: 400px;
  display: block;

  @media (max-width: 768.98px) {
    object-fit: cover;
    max-width: unset;
  }
`;

export const WebsiteFullBlock = styled.div`
  width: 100%;
  max-width: 784px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 40px;
  background: rgba(12, 18, 21, 0.50);
  color: white;
  padding: 20px 5%;
  margin-bottom: 20px;
  align-items: center;

  @media (max-width: 1172.98px) {
    padding: 20px 10px;
    font-size: 16px;
    gap: 10px;
    max-width: 90%; /* Adjust the max-width for smaller screens */
  }

  @media (max-width:1046.98px) {
    max-width: 100%;
  }

  @media (max-width: 725.98px) {
    padding: 10px;
    margin-bottom: 10px;
  }
`;


export const BlockTitle = styled.div`
  color: #FFF;
  text-align: center;
  font-family: Cormorant Garamond, serif;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (max-width: 1172.98px) {
    font-size: 60px;
  }

  @media (max-width: 381.98px) {
    font-size: 40px;
  }

  span {
    color: #FFF;
    font-family: Cormorant Garamond, serif;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media (max-width: 1172.98px) {
      font-size: 40px;
    }
  }
`;


export const BlockDate = styled.p`
  color: #FFF;
  text-align: center;
  font-family: Cormorant Garamond;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (max-width: 1172.98px) {
    font-size: 26px;
  }
`;

export const BlockItem = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width: 725.98px) {
    gap: 10px;
  }
`;

export const ItemBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;


export const ItemNumber = styled.div`
  display: flex;
  width: 102px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #FFF;
  color: #0C1215;
  font-family: Gilroy;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media (max-width: 1172.98px) {
    font-size: 19px;
  }

  @media (max-width: 381.98px) {
    width: 70px;
  }

`;

export const ItemText = styled.div`
  color: #FFF;
  font-family: Gilroy;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 1172.98px) {
    font-size: 18px;
  }
`;

