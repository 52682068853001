import styled from "styled-components";

export const WebsiteHoneymoonWrap = styled.div`
  background-color: #f5f9ff;
`;

export const CardBlock = styled.div`
  display: flex;
  justify-content: center;
  gap: 100px;
  padding: 80px 0;

  @media (max-width: 633.98px) {
    padding: 20px 0;
    flex-direction: column;
    gap: 20px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 300px;
  width: 100%;

  @media (max-width: 768px) {
    max-width: none;
  }
`;


export const CardImage = styled.img`
  width: 100%;
  max-height: 380px;
  object-fit: cover;
  border-radius: 6.745px;
  margin-bottom: 30px;
  @media (max-width: 633.98px) {
    max-height: 230px;
    margin-bottom: 10px;
  }
`;


export const CardContent = styled.div`
  margin-top: 20px;
`;


export const CardName = styled.h2`
  color: #0C1215;
  text-align: center;
  font-family: Cormorant Garamond,sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 7px;
`;

export const CardPhoneNumber = styled.p`
  color: #0C1215;
  text-align: center;
  font-family: Gilroy,sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 7px;
`;

export const CardEmail = styled.p`
  color: #0C1215;
  text-align: center;
  font-family: Gilroy,sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
