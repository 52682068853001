import React, {useState} from 'react';
import {StyledPointsIcon} from "../../../tasks/style-tasks";
import {PointsWrap} from "../../../budget/butget-expense/points-expense/style-points";
import {FavoritePinterPopup} from "./favorite-pinter-popup/FavoritePinterPopup";


interface IExpensesId {
    expensesId: string;
}

export const FavoritePoints = ({ expensesId }: IExpensesId) => {
    const [isOpen, setIsOpen] = useState(false); // State to manage popup visibility

    const handleTogglePopup = () => {
        setIsOpen(!isOpen); // Toggle the popup visibility
    };

    return (
        <PointsWrap>
            <StyledPointsIcon onClick={handleTogglePopup} />
            {isOpen && <FavoritePinterPopup expensesId={expensesId} />}
        </PointsWrap>
    );
};