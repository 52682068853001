import React from "react";
import {
    SpoilerContent,
    SpoilerHeader,
    SpoilerIcon,
    SpoilerIconWrap,
    SpoilerItemConfirmed,
    SpoilerItemLocation,
    SpoilerItemName,
    SpoilerItemNameBody,
    SpoilerItemR,
    SpoilersItem,
    SpoilerWrapper
} from "./style-plan-spoiler";
import {ReactComponent as ArrayUp} from '../../../../../assets/main/budget/arrayUp.svg';
import {ReactComponent as ArrowDown} from '../../../../../assets/main/budget/arrowDown.svg';
import {ReactComponent as CheckTrue} from '../../../../../assets/main/guest/check-true.svg';
import {ReactComponent as CheckFalse} from '../../../../../assets/main/guest/check-false.svg';
import {ReactComponent as Baby} from '../../../../../assets/main/guest/baby.svg';
import {SpoilerBody} from "../style-plan-newlyweds";

export interface SpoilerItem {
    name: string;
    confirmed: boolean;
    location: boolean;
    hasBaby?: boolean;
}

interface SpoilerProps {
    title: string;
    items: SpoilerItem[];
    isActive: boolean;
    toggleSpoiler: () => void;
}

export const PlanSpoiler: React.FC<SpoilerProps> = (
    {
        title,
        items,
        isActive,
        toggleSpoiler,
    }
) => {
    return (
        <SpoilerWrapper>
            <SpoilerHeader onClick={toggleSpoiler}>
                <SpoilerIcon>
                    <SpoilerIconWrap isActive={isActive}>
                        <h3>{title}</h3>
                        {isActive ? <ArrayUp/> : <ArrowDown/>}
                    </SpoilerIconWrap>
                </SpoilerIcon>
            </SpoilerHeader>
            {isActive && (
                <SpoilerContent isVisible={isActive}>
                    <SpoilerBody>
                        <SpoilerItemR>
                            <SpoilerItemName>Imię i nazwisko</SpoilerItemName>
                            <SpoilerItemConfirmed>Potwierdzony</SpoilerItemConfirmed>
                            <SpoilerItemLocation>Miejsce</SpoilerItemLocation>
                        </SpoilerItemR>
                        {items.map((item, index) => (
                            <SpoilersItem key={index}>
                                <SpoilerItemNameBody>
                                    <span>{index + 1}</span>
                                    <SpoilerItemName>
                                        {item.name}
                                        {item.hasBaby && <Baby/>}
                                    </SpoilerItemName>
                                </SpoilerItemNameBody>
                                <SpoilerItemConfirmed>
                                    {item.confirmed ? <CheckTrue/> : <CheckFalse/>}
                                </SpoilerItemConfirmed>
                                <SpoilerItemLocation>
                                    {item.location ? <CheckTrue/> : <CheckFalse/>}
                                </SpoilerItemLocation>
                            </SpoilersItem>
                        ))}
                    </SpoilerBody>
                </SpoilerContent>
            )}
        </SpoilerWrapper>
    );
};
