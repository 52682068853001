import React from 'react';
import {Popup} from "../../../../../../wedding/popUp/style-popups";
import {StatusForm} from "../status-form/StatusForm";


interface PopupProps {
    show: boolean;
    handleClosePopup: () => void;
    onClickClosest: () => void;
    expensesId: string;
}


export const StatusPopup = ({show, handleClosePopup, onClickClosest,expensesId}: PopupProps) => {
    return (
        <Popup show={show} onClick={onClickClosest}>
            <StatusForm handleClosePopup={handleClosePopup} expensesId={expensesId} />
        </Popup>
    );
}

