import styled from "styled-components";

export const NewlywedsSpoilersWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`

export const NewlywedsSpoilersContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const NewlywedsSpoilersContent = styled.div`
  display: flex;
  gap: 80px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 15px;

  @media (max-width: 860.98px) {
    justify-content: flex-start;
    gap: 10px;
  }
`
export const ContentAbout = styled.div`
  width: 154px;
`

export const ContentName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`

export const ContentNameBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 19px;
  width: 154px;

  p {
    color: #0C1215;
    font-family: Gilroy,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    
  }

  span {
    color: #0C1215;
    font-family: Gilroy,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`


export const ContentInfo = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  
  span {
    height: 24px;
    width: 83px;
    text-align: center;
    min-width: 50px;

    svg {
      width: 83px;
      min-width: 50px;

    }
  }
`


export const SpoilerNewlywedsTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #0C1215;
  padding: 11px 0 10px 15px;
`

export const SpoilerH = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  width: 100%;
  padding-bottom: 3px;
  gap: 80px;

  @media (max-width: 860.98px) {
    justify-content: flex-start;
  }


  span {
    color: rgba(12, 18, 21, 0.40);
    font-family: Roboto, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
  }
`;
export const SpoilerHOne = styled.span`
  display: flex;
  width: 190px;
  justify-content: center;
  //padding-left: 40px;

  @media (max-width: 860.98px) {
    padding-left: 17px;
  }
`;


export const SpoilerItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;

  span {
    width: 83px;
    text-align: center;
  }
`;


