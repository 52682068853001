import styled from "styled-components";



export const InspirationsPopupWrapper = styled.div`
  background: #FFFFFF;
  border-radius: 5px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%);
  overflow: auto;
  @media (max-width: 555.98px) {
    border-radius: 5px;
    top: 10%;
  }
`

export const InspirationsPopupContainer = styled.div`
  display: flex;
  border-radius: 5px 0px 0px 5px;

  @media (max-width: 555.98px) {
    width: 100%;
    padding-top: 50px;
    flex-direction: column;
  }

`
export const ImageContainer = styled.div`
  position: relative;
  
  svg {
    position: absolute;
    right: 25px;
    top: 25px;
    width: 30px;
    height: 27px;
    flex-shrink: 0;
    
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px 0px 0px 5px;

    @media (max-width: 555.98px) {
      height: 300px;
      object-fit: cover;
    }

  }
  
  

`

export const ContentContainer = styled.div`
  padding: 50px 25px;
  width: 466px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (max-width: 555.98px) {
    min-width: 340px;
    width: 100%;
    padding: 50px 25px 25px 25px;
  }
`

export const ThirdBlock = styled.div`
  margin-top: auto;
`;

export const InspirationsPopupTitle = styled.p`
  font-family: 'Gilroy',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #0C1215;
`

export const InspirationsButtonCloses = styled.button`
  position: absolute;
  top: 15px;
  right: 20px;

  //@media (max-width: 770.98px) {
  //  top: 17px;
  //  right: 27px;
  //}

`