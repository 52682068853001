import React from 'react';
import {Popup} from "./style-plan-tab-popup";
import {FormTable} from "../form-table/FormTable";


interface PopupProps {
    show: boolean;
    handleClosePopup: () => void;
    onClickClosest: () => void;
}

export const PlanTablePopups = ({show, handleClosePopup, onClickClosest}: PopupProps) => {
    return (
        <Popup show={show} onClick={onClickClosest}>
            <FormTable handleClosePopup={handleClosePopup} />
        </Popup>
    );
}

