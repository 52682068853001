import React, {useState} from 'react';
import {PrintBlock, PrintContentBlock} from "../../guest/guest-print/style-guest-print";
import {ReactComponent as Printer} from '../../../../assets/main/guest/printer.svg';
import {ReactComponent as Download} from '../../../../assets/main/guest/download.svg';
import {
    PlanContentBlock,
    PlanContentCheckbox,
    PlanContentPrint,
    PlanContentWrap,
    PrintPlanContent
} from "./style-plan-content";
import {Checkbox, GuestBlock, GuestBlockInfo} from "../../guest/sharing-information/sharing-form/style-sharing-form";
import {PlanTables} from "./plan-tables/plan-tables";
import {PlanNewlyweds} from "../plan-newlyweds/plan-newlyweds";


export const PlanContent = () => {
    const [isChecked, setIsChecked] = useState(false);

    const handleChange = () => {
        setIsChecked(!isChecked);
    };
    return (
        <PlanContentWrap>
            <PlanContentPrint>
                <PrintPlanContent>
                    <PrintContentBlock><Printer/><span>Drukuj</span></PrintContentBlock>
                    <PrintContentBlock><Download/><span>Pobierz PDF</span></PrintContentBlock>
                </PrintPlanContent>
                <PlanContentCheckbox>
                    <Checkbox checked={isChecked} onChange={handleChange}
                              type="checkbox"
                    />
                    <GuestBlockInfo>
                        <p>Wyświetl plan stołów na stronie ślubu</p>
                    </GuestBlockInfo>
                </PlanContentCheckbox>
            </PlanContentPrint>
            <PlanContentBlock>
                <PlanTables/>
                <PlanNewlyweds/>
            </PlanContentBlock>
        </PlanContentWrap>
    );
};

