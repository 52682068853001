import styled from "styled-components";
import {ReactComponent as DownloadIcon} from '../../../../../assets/main/website/download.svg';

// interface IDownloadArrow {
//     color: string
// }

export const WebsiteSeatingPlanWrap = styled.section`
  position: relative;
`;

export const SeatingPlanIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  @media (min-width: 633.98px) {
    display: block;
  }
`;


export const WebsiteSeatingPlanBlock = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

export const SeatingPlanTitle = styled.p`
  color: #0C1215;
  font-family: Gilroy,sans-serif;
  font-size: 46px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 47px;
  @media (max-width: 768.98px) {
    font-size: 36px;
  }

  @media (max-width: 425.98px) {
    font-size: 28px;
  }
`;
export const SeatingPlanSubTitle = styled.span`
  color: #0C1215;
  font-family: Gilroy,sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
`;

export const SeatingPlanButton = styled.button`
  margin-top: 30px;
  width: 100%;
  background: linear-gradient(90deg, #1056B2 0%, #2779E4 100%);
  border-radius: 208px 100px 100px 0;
  padding: 12px 30px;
  gap: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  cursor: pointer;
  max-width: 295px;
  @media (max-width: 425.98px) {
    max-width: 100%;
  }
  &:hover {
    opacity: 0.9;
  }
`;



