import styled from "styled-components";

export const StatusBlock = styled.div`
  margin-top: 2px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
`

export const Status = styled.span`
  font-family: 'Gilroy',sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  text-align: right;
  letter-spacing: 0.01em;
  color: #0C1215;
  opacity: 0.5;
`
