import React, {useState} from 'react';
import {CalendarButton, CalendarText} from "../../../calendar-Info/styles";
import {ExpensePopup} from "./popup-expense/ExpensePopup";
import usePopup from "../../../../common/hook/use-popup";
import {ReactComponent as Plus} from '../../../../assets/main/wedding/plus.svg';
import {ReactComponent as ArrayUp} from '../../../../assets/main/budget/arrayUp.svg';
import {ReactComponent as ArrayDown} from '../../../../assets/main/budget/arrowDown.svg';
import {ReactComponent as Edit} from '../../../../assets/main/plan/edit.svg';


import {
    BlockName, BlockPrice,
    ExpenseButton,
    ExpenseContainer,
    ExpenseContent,
    ExpenseWrapper, ItemBlock,
    MenuLocation, MenuName, MenuPrice,
    ShowMore,
    ShowMoreBlock,
    ShowMoreWrapper
} from "./style-budgetExpense";
import {ButtonEditExpense} from "./button-edit-expense/ButtonEditExpense";

import {TotalFlow} from "./total-flow/TotalFlow";
import {ExpenseMenu} from "./expense-menu/ExpenseMenu";
import {v1} from "uuid";
import {Button} from "../../wedding/style-wedding";


const expenseArray = [
    {
        name: 'Nazwa',
        location: 'Miejsce / Usługodawca',
        totalPrice: 'Cena całkowita',
        prepaid: 'Przedpłata',
        fullPaymentDate: 'Pełna płatność do'
    }
];


const expense = [
    {
        id: v1(),
        name: 'Suknia Ślubna',
        location: 'Victoria Soprano',
        totalPrice: '7 000',
        prepaid: '1 000',
        fullPaymentDate: '10.04.2022',
        status: 'prepayment',
    },
    {
        id: v1(),
        name: 'Bielizna',
        location: 'Victoria Soprano',
        totalPrice: '7 000',
        prepaid: '1 000',
        fullPaymentDate: '10.04.2022',
        status: 'unpaid',
    },
    {
        id: v1(),
        name: 'Fryzura Ślubna',
        location: 'Victoria Soprano',
        totalPrice: '7 000',
        prepaid: '1 000',
        fullPaymentDate: '10.04.2022',
        status: 'repaid',
    },
    {
        id: v1(),
        name: 'Fryzura Ślubna',
        location: 'Victoria Soprano',
        totalPrice: '7 000',
        prepaid: '1 000',
        fullPaymentDate: '10.04.2022',
        status: 'repaid',
    },
    {
        id: v1(),
        name: 'Fryzura Ślubna',
        location: 'Victoria Soprano',
        totalPrice: '7 000',
        prepaid: '1 000',
        fullPaymentDate: '10.04.2022',
        status: 'repaid',
    },

]
export const BudgetExpense = () => {
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();
    const [showAll, setShowAll] = useState<boolean>(false);
    const [openPopupId, setOpenPopupId] = useState<string | null>(null);

    const handleShowMore = () => {
        setShowAll((prevShowAll) => !prevShowAll);
    };

    const handleTogglePopup = (id: string) => {
        setOpenPopupId((prevId) => (prevId === id ? null : id));
    };

    return (
        <ExpenseWrapper>
            <ExpenseButton>
                <Button >
                    <Edit/>
                    <span>Edytuj</span>
                </Button>
            </ExpenseButton>
            <ExpenseContainer>
                {expenseArray.map((expen, index) => (
                    <ItemBlock key={index}>
                        <BlockName>
                            <MenuName>{expen.name}</MenuName>
                            <MenuLocation>{expen.location}</MenuLocation>
                        </BlockName>
                        <BlockPrice>
                            <MenuPrice>{expen.totalPrice}</MenuPrice>
                            <MenuPrice>{expen.prepaid}</MenuPrice>
                            <MenuPrice>{expen.fullPaymentDate}</MenuPrice>
                        </BlockPrice>
                    </ItemBlock>
                ))}
                <ExpenseContent>
                    {showAll
                        ? expense.map((exp, index) => (
                            <ExpenseMenu
                                key={exp.id}
                                expenses={[exp]}
                                openPopupId={openPopupId}
                                handleTogglePopup={handleTogglePopup}
                            />
                        ))
                        : expense.slice(0, 4).map((exp, index) => (
                            <ExpenseMenu
                                key={exp.id}
                                expenses={[exp]}
                                openPopupId={openPopupId}
                                handleTogglePopup={handleTogglePopup}
                            />
                        ))}
                </ExpenseContent>
            </ExpenseContainer>
            {expense.length > 4 && (
                <ShowMoreWrapper>
                    <ShowMore onClick={handleShowMore}>
                        {showAll ? (
                            <ShowMoreBlock>
                                <span>Zwiń</span>
                                <span> <ArrayDown/></span>
                            </ShowMoreBlock>
                        ) : (
                            <ShowMoreBlock>
                                <span>Zobacz wszystkie pozycje</span>
                                <span> <ArrayUp/></span>
                            </ShowMoreBlock>
                        )}
                    </ShowMore>
                </ShowMoreWrapper>
            )}
            <ExpenseButton>
                <CalendarButton onClick={onClickHandler}>
                    <Plus/>
                    <CalendarText>Dodaj nowy wydatek</CalendarText>
                </CalendarButton>
            </ExpenseButton>
            <TotalFlow/>
            <ExpensePopup
                show={showPopup}
                handleClosePopup={handleClosePopup}
                onClickClosest={onClickClosest}
            />
        </ExpenseWrapper>
    );
};