import styled from "styled-components";


export const UserInformationWrap = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5em;
  color: rgba(12, 18, 21, .4);
  margin: auto 0;
  margin-top: 112px;
  @media (max-width: 1000.98px) {
    margin-top: 20px;
  }
  
`
export const UserWrapper = styled.div`

`

export const UserName = styled.h2`
  font-family: 'Cormorant Garamond', serif;
  font-size: 40px;
  line-height: 100%;
  text-transform: uppercase;
  color: #0C1215;
  margin-bottom: 28px;
  font-weight: 800;
  @media (max-width: 768.98px) {
    justify-content: space-between;
    margin-bottom: 10px;
  }
`
export const UserText = styled.p`
  margin-bottom: 8px;
`
export const UserDays = styled.p`
  font-family: 'Cormorant Garamond',sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */


  /* Black */

  color: #0C1215;

`

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const UserBlock = styled.div`
  padding-top: 135px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 33px;
  @media (max-width: 1288.98px) {
    padding-top: 50px;
  }

`
export const CalendarBlock = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;
  align-self: baseline;
  margin: 0 auto;
  
  svg {
    opacity: 0.4;
  }
`

export const UserData = styled.span`

`
export const UserLocation = styled.span`

`
export const LocationBlocks = styled.div`
  display: flex;
  justify-content: center;
  gap: 80px;
  @media (max-width: 425.98px) {
    gap: 20px;
  }
`
export const LocationItems = styled.div`
  max-width: 170px;
  display: flex;
  flex-direction: column;
  gap: 6px;

`
export const LocationItem = styled.div`
  display: flex;
  justify-content: center;
  gap: 9px;
  align-items: center;
  svg {
    opacity: 0.4;
  }
`
