import React, { useEffect, useState } from 'react';
import { DatePicker, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import plPL from 'antd/lib/locale/pl_PL';
import { FormBlock } from 'components/pages/wedding/usersForm/style-usersform';

// Set dayjs locale globally
dayjs.locale('pl');

interface CalendarChooseTimeProps {
    weddingAt?: number;
    onDateChange: (timestamp: number | null) => void;
}

export const CalendarChooseTime: React.FC<CalendarChooseTimeProps> = ({ weddingAt, onDateChange }) => {
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);

    useEffect(() => {
        if (weddingAt) {
            setSelectedDate(dayjs.unix(weddingAt));
        }
    }, [weddingAt]);

    const handleDateChange = (date: dayjs.Dayjs | null) => {
        setSelectedDate(date);
        if (date) {
            onDateChange(date.unix());
        } else {
            onDateChange(null);
        }
    };

    return (
        <ConfigProvider locale={plPL}>
            <FormBlock>
                <DatePicker
                    showTime
                    value={selectedDate}
                    onChange={handleDateChange}
                    format="DD.MM.YYYY HH:mm"
                    autoComplete="off"
                    placeholder=""
                    inputReadOnly={true}
                    showSecond={false}
                />
            </FormBlock>
        </ConfigProvider>
    );
};
