import React from 'react';
import {PointsAdsPopupWrap, PointsGuestPopupWrap} from "../../../../tasks/points-tasks/points-popup/style-points-popup";
import {AdsEdit} from "../../../../ads/ads-points/ads-prints-popups/ads-edit/AdsEdit";
import {AdsDelete} from "../../../../ads/ads-points/ads-prints-popups/ads-delete/AdsDelete";



interface IExpensesId {
    expensesId: string;
}

export const GuestPrinterPopup = ({ expensesId }: IExpensesId) => {
    return (
        <PointsGuestPopupWrap>
            <AdsEdit expensesId={expensesId} show={false}/>
            <AdsDelete expensesId={expensesId} show={false}/>
        </PointsGuestPopupWrap>
    );
};