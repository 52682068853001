import React, {FC, useState} from 'react';
import {Form, Formik} from 'formik';
import {ReactComponent as СrossIcon} from '../../../../../../../assets/main/wedding/close.svg';
import {ReactComponent as CloseWhiteIcon} from '../../../../../../../assets/main/website/closeWhite.svg';
import {ReactComponent as Checkbox} from '../../../../../../../assets/main/form/checkbox.svg';
import {ReactComponent as Сlose} from '../../../../../../../assets/main/form/close.svg';
import {
    ButtonBlock,
    ButtonCloses,
    ButtonSubmit,
    FormContainer,
    FormWrapper,
    StyledUserFileInputContainer
} from "../../../../../wedding/usersForm/style-usersform";
import {CloseButton, StyledTitle} from "../../../../../../popups/style-popups";
import {
    AdsFormBlock,
    FileIconContainer,
    FileUploadIcon,
    FileUploadText
} from "../../../../../ads/ads-place/style-ads-place";
import {ImageBlock, ImageItems} from "./style-form-upload";
import {DownloadWhiteIconWrapper} from "../../website-preview/form-upload-gallery/style-form-upload-gallery";


interface MyFormValues {
    title: string;
    price: string;
    selectedImages: File[];
}

type PropsType = {
    handleClosePopup: () => void;
};

const handleDeleteImage = (
    indexToDelete: number,
    setSelectedImages: (images: File[]) => void,
    setFieldValue: (field: string, value: any) => void,
    selectedImages: File[]
) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(indexToDelete, 1);
    setSelectedImages(updatedImages);
    setFieldValue('selectedImages', updatedImages);
};


function ImageItem() {
    return null;
}

export const FormUpload: FC<PropsType> = ({handleClosePopup}) => {
    const [selectedImages, setSelectedImages] = useState<File[]>([]);
    const handleSubmit = (values: MyFormValues) => {

    };


    return (
        <Formik
            initialValues={{
                title: '',
                price: '',
                selectedImages: [],
            }}
            onSubmit={handleSubmit}
        >
            {({ values, handleSubmit, setFieldValue, errors }) => (
                <FormWrapper onClick={(e) => e.stopPropagation()}>
                    <Form onSubmit={handleSubmit}>
                        <CloseButton type="button" onClick={handleClosePopup}>
                            <СrossIcon />
                        </CloseButton>
                        <StyledTitle>Prześlij zdjęcia</StyledTitle>
                        <FormContainer>
                            <AdsFormBlock>
                                <StyledUserFileInputContainer>
                                    <FileIconContainer>
                                        <FileUploadIcon>+</FileUploadIcon>
                                        <FileUploadText>Dodaj zdjęcia</FileUploadText>
                                    </FileIconContainer>
                                    <input
                                        id="selectedImages"
                                        name="selectedImages"
                                        type="file"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            const files = event.currentTarget.files;
                                            if (files) {
                                                const newImages = Array.from(files);
                                                setFieldValue('selectedImages', [
                                                    ...values.selectedImages,
                                                    ...newImages,
                                                ]);
                                                setSelectedImages([...selectedImages, ...newImages]);
                                            }
                                        }}
                                        value={undefined}
                                        accept="image/*"
                                        multiple
                                    />
                                </StyledUserFileInputContainer>
                            </AdsFormBlock>
                            {selectedImages.length > 0 && (
                                <ImageBlock>
                                    {selectedImages.map((image, index) => (
                                        <ImageItems key={index}>
                                            <img src={URL.createObjectURL(image)} alt="" />
                                            <DownloadWhiteIconWrapper
                                                onClick={() =>
                                                    handleDeleteImage(
                                                        index,
                                                        setSelectedImages,
                                                        setFieldValue,
                                                        selectedImages
                                                    )
                                                }
                                            >
                                                <CloseWhiteIcon/>
                                            </DownloadWhiteIconWrapper>
                                        </ImageItems>
                                    ))}
                                </ImageBlock>
                            )}

                            <ButtonBlock>
                                <ButtonCloses onClick={handleClosePopup}>
                                    <Сlose />
                                    <span>Anuluj</span>
                                </ButtonCloses>
                                <ButtonSubmit type="submit" disabled={Object.keys(errors).length !== 0}>
                                    <Checkbox />
                                    <span>Zapisz</span>
                                </ButtonSubmit>
                            </ButtonBlock>
                        </FormContainer>
                    </Form>
                </FormWrapper>
            )}
        </Formik>
    );
};
