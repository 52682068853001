import React from 'react';
import {Field, Form, Formik} from 'formik';
import {
    ButtonBlock,
    ButtonCloses,
    ButtonSubmit,
    ButtonWrap,
    FormBlock,
    FormContainer, FormRadioBlock,
    FormWrapper,
} from "../../../../../../wedding/usersForm/style-usersform";
import {CloseButton, StyledTitle, StyleSubTitle} from "../../../../../../wedding/popUp/style-popups";
import {ReactComponent as CloseIcon} from '../../../../../../../../assets/main/form/close.svg';
import {ReactComponent as СrossIcon} from '../../../../../../../../assets/main/wedding/close.svg';
import {ReactComponent as Checkbox} from '../../../../../../../../assets/main/form/checkbox.svg';
import {useAppDispatch} from "../../../../../../../../common/hook";
import {changeStatus, StatusType} from "../../../../../../../../app/budget-reduser";
import {PointsPopupDelete} from "../../points-popup-delete/PointsPopupDelete";


interface MyFormValues {
    status: StatusType;

}

interface PropsType {
    handleClosePopup: () => void;
    expensesId: string;
}

export const StatusForm: React.FC<PropsType> = ({handleClosePopup, expensesId}) => {

    const dispatch = useAppDispatch()
    const handleSubmit = (values: MyFormValues) => {
        dispatch(changeStatus({ id: expensesId, status: values.status }));
    };

    return (
        <Formik
            initialValues={{status:'unpaid'}}
            onSubmit={handleSubmit}
        >
            {({handleSubmit, handleChange, errors}) => (
                <FormWrapper onClick={(e) => e.stopPropagation()}>
                    <Form onSubmit={handleSubmit}>
                        <CloseButton type="button" onClick={handleClosePopup}>
                            <СrossIcon/>
                        </CloseButton>
                        <StyledTitle>Stan wydatków</StyledTitle>
                        <StyleSubTitle>Określ status wydatku:</StyleSubTitle>
                        <FormContainer>
                            <FormRadioBlock>
                                <label>
                                    <Field type="radio" name="status" value="unpaid"/>
                                    <p>Niezapłacone</p>
                                </label>
                            </FormRadioBlock>
                            <FormRadioBlock>
                                <label>
                                    <Field type="radio" name="status" value="prepayment"/>
                                    <p>Dokonana przedpłata</p>
                                </label>
                            </FormRadioBlock>
                            <FormRadioBlock>
                                <label>
                                    <Field type="radio" name="status" value="repaid"/>
                                    <p>Spłacone</p>
                                </label>
                            </FormRadioBlock>
                            <ButtonWrap>
                                <ButtonBlock>
                                    <ButtonCloses>
                                        <CloseIcon/>
                                        <span>Anuluj</span>
                                    </ButtonCloses>
                                    <ButtonSubmit
                                        type="submit"
                                        disabled={Object.keys(errors).length !== 0}
                                    >
                                        <Checkbox/>
                                        <span>Zapisz</span>
                                    </ButtonSubmit>
                                </ButtonBlock>
                               <PointsPopupDelete expensesId={expensesId}/>
                            </ButtonWrap>
                        </FormContainer>
                    </Form>
                </FormWrapper>
            )}
        </Formik>
    );
};

