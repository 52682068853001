import {ReactComponent as Repaid} from '../../../../../assets/main/status/repaid.svg';
import {ReactComponent as Prepayment} from '../../../../../assets/main/status/prepayment.svg';
import React, {FC} from "react";
import {
    BlockIcons,
    BlockName,
    BlockPrice,
    ExpenseMenuBlock,
    MenuIcon,
    MenuLocation,
    MenuName,
    MenuPrice, NoText
} from "../style-budgetExpense";
import {Points} from "../points-expense/Points";
import {v1} from "uuid";




interface Expense {
    id: string;
    name: string;
    location: string;
    totalPrice: string;
    prepaid: string;
    fullPaymentDate: string;
    status: string;
}

interface ExpenseMenuProps {
    expenses:Expense[];
    openPopupId: string | null;
    handleTogglePopup: (id: string) => void;
}


export const ExpenseMenu: React.FC<ExpenseMenuProps> = (
    {
        expenses,
        openPopupId,
        handleTogglePopup
    }
) => {
    return (
        <>
            {expenses.map((exp) => (
                    <ExpenseMenuBlock key={exp.id}>
                        <BlockName>
                            <MenuName>
                                <p>{exp.name}</p>
                            </MenuName>
                            <MenuLocation>{exp.location}</MenuLocation>
                        </BlockName>
                        <BlockPrice>
                            <MenuPrice>{exp.totalPrice} zł</MenuPrice>
                            <MenuPrice>{exp.prepaid} zł</MenuPrice>
                            <MenuPrice>{exp.fullPaymentDate}</MenuPrice>
                        </BlockPrice>
                        <BlockIcons>
                            <MenuIcon>
                                {exp.status === 'unpaid' ? (
                                    ''
                                ) : exp.status === 'prepayment' ? (
                                    <Repaid/>
                                ) : (
                                    <Prepayment/>
                                )}
                            </MenuIcon>
                            <MenuIcon>
                                <Points
                                    expensesId={exp.id}
                                    open={openPopupId === exp.id}
                                    togglePopup={handleTogglePopup}
                                />
                            </MenuIcon>
                        </BlockIcons>
                    </ExpenseMenuBlock>
                ))
            }
        </>
    );
};


// export const ExpenseMenu: React.FC<ExpenseMenuProps> = ({
//                                                             expenses,
//                                                             openPopupId,
//                                                             handleTogglePopup
//                                                         }) => {
//     return (
//         <>
//             {expenses.length > 0 ? (
//                 expenses.map((exp) => (
//                     <ExpenseMenuBlock key={exp.id}>
//                         <BlockName>
//                             <MenuName>
//                                 <p>{exp.name}</p>
//                             </MenuName>
//                             <MenuLocation>{exp.location}</MenuLocation>
//                         </BlockName>
//                         <BlockPrice>
//                             <MenuPrice>{exp.totalPrice} zł</MenuPrice>
//                             <MenuPrice>{exp.prepaid} zł</MenuPrice>
//                             <MenuPrice>{exp.fullPaymentDate}</MenuPrice>
//                         </BlockPrice>
//                         <BlockIcons>
//                             <MenuIcon>
//                                 {exp.status === 'unpaid' ? (
//                                     ''
//                                 ) : exp.status === 'prepayment' ? (
//                                     <Repaid/>
//                                 ) : (
//                                     <Prepayment/>
//                                 )}
//                             </MenuIcon>
//                             <MenuIcon>
//                                 <Points
//                                     expensesId={exp.id}
//                                     open={openPopupId === exp.id}
//                                     togglePopup={handleTogglePopup}
//                                 />
//                             </MenuIcon>
//                         </BlockIcons>
//                     </ExpenseMenuBlock>
//                 ))
//             ) : (
//                 <NoText>
//                     Dodaj nowy wydatek
//                 </NoText>
//             )}
//         </>
//     );
// };