import React, {FC} from 'react';
import {Form, Formik} from 'formik';
import {ReactComponent as Checkbox} from '../../../../../../../../../assets/main/form/checkbox.svg';
import {ReactComponent as СrossIcon} from '../../../../../../../../../assets/main/wedding/close.svg'
import {
    FormBlock,
    FormContainer,
    FormWrapper,
    StyledInput,
    StyledLabel
} from "../../../../../../../wedding/usersForm/style-usersform";
import {CloseButton, StyledTitle} from "../../../../../../../wedding/popUp/style-popups";
import {ButtonPlanBlock, ButtonPlanSubmit} from "../../../../../../plan-tab/style-plan-tab";


interface MyFormValues {
    id: string,
    oldName: string,
    newName: string,
}

interface PropsType {
    handleClosePopup: () => void;
}


export const FormPointsEdit: FC<PropsType> = ({handleClosePopup}) => {


        const handleSubmit = () => {
            // id: uuidv1(),
            // tableName: '',
            // numberOfSeats: '',
        };


        return (
            <Formik
                initialValues={{
                    id: '',
                    oldName: '',
                    newName: '',
                }}
                enableReinitialize={true}
                onSubmit={handleSubmit}
            >
                {({values, handleSubmit, handleChange, errors}) => (
                    <FormWrapper onClick={(e) => e.stopPropagation()}>
                        <Form onSubmit={handleSubmit}>
                            <CloseButton type="button" onClick={handleClosePopup}>
                                <СrossIcon/>
                            </CloseButton>
                            <StyledTitle>Zmień nazwę</StyledTitle>
                            <FormContainer>
                                <FormBlock>
                                    <StyledLabel htmlFor="oldName">Stara nazwa</StyledLabel>
                                    <StyledInput
                                        type="text"
                                        name="oldName"
                                        value={values.oldName}
                                        onChange={handleChange}
                                    />
                                </FormBlock>
                                <FormBlock>
                                    <StyledLabel htmlFor="newName">Nowa nazwa</StyledLabel>
                                    <StyledInput
                                        type="text"
                                        name="newName"
                                        value={values.newName}
                                        onChange={handleChange}
                                    />
                                </FormBlock>
                                <ButtonPlanBlock>
                                    <ButtonPlanSubmit
                                        type="submit"
                                        disabled={Object.keys(errors).length !== 0}
                                    >
                                        <Checkbox/>
                                        <span>Zapisz</span>
                                    </ButtonPlanSubmit>
                                </ButtonPlanBlock>
                            </FormContainer>
                        </Form>
                    </FormWrapper>
                )}
            </Formik>
        );
    }
;
