import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface TaskCalendar {
    id: string;
    title: string;
    date: string;
    timeWith: string;
    timeBefore: string;
    location: string;
    addNote: string;
    provider: string;
    providerLink: string;
    done: boolean;
    [target: string]: any;
}

export interface StateType {
    beforeWedding: TaskCalendar[];
    wedding: TaskCalendar[];
    afterWedding: TaskCalendar[];
}

const initialState: StateType = {
    beforeWedding: [],
    wedding: [],
    afterWedding: [],
};

const slice = createSlice({
    name: 'tasksWedding',
    initialState,
    reducers: {
        changeWeddingData: (state, action: PayloadAction<{ target: keyof StateType, tasks: TaskCalendar }>) => {
            const {target, tasks} = action.payload;
            state[target].push({...tasks, done: false});
        },
        editTask: (state, action: PayloadAction<{ taskId: string; updatedTasks: TaskCalendar }>) => {
            const {taskId, updatedTasks} = action.payload;
            Object.keys(state).forEach((key) => {
                state[key as keyof StateType] = state[key as keyof StateType].map((task) => {
                    if (task.id === taskId) {
                        return {...task, ...updatedTasks};
                    }
                    return task;
                });
            });
        },
        checkedTask: (state, action: PayloadAction<{ id: string, taskArray: TaskCalendar[] }>) => {
            const {taskArray, id} = action.payload;
            const targetArray = Object.values(state).find(arr => arr === taskArray);
            if (targetArray) {
                const task = targetArray.find(el => el.id === id);
                if (task) {
                    task.done = !task.done;
                }
            }
        },
        deleteTask: (state, action: PayloadAction<string>) => {
            const taskId = action.payload;
            state.beforeWedding = state.beforeWedding.filter(
                (task) => task.id !== taskId
            );
            state.wedding = state.wedding.filter((task) => task.id !== taskId);
            state.afterWedding = state.afterWedding.filter(
                (task) => task.id !== taskId
            );
        },

    },
});

export const {changeWeddingData, deleteTask, editTask, checkedTask} = slice.actions;
export const tasksWeddingReducer = slice.reducer;


