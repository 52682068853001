import React from 'react';
import {StyledPointsIcon} from "../../../tasks/style-tasks";
import {PointsWrap} from "./style-points";
import {PointsPopup} from "./points-popup/PointsPopup";


interface IExpensesId {
    expensesId: string;
    open: boolean;
    togglePopup: (id: string) => void;
}

export const Points = ({ expensesId, open, togglePopup }: IExpensesId) => {
    const handleTogglePopup = () => {
        togglePopup(expensesId);
    };

    return (
        <PointsWrap>
            <StyledPointsIcon onClick={handleTogglePopup} />
            {open && <PointsPopup expensesId={expensesId} />}
        </PointsWrap>
    );
};
