import styled, {css} from 'styled-components';
import right from '../../../../../assets/main/wedding/Cart/array-right.svg'

interface FavoriteSliderWrapProps {
    isArray: boolean;
}

export const FavoriteContainerWrap = styled.div`
  display: block;
  gap: 25px;
  //background: #F5F9FF;
  margin-bottom: 30px;
  transition: 0.3s ease-in-out;
  color: #0C1215;

`;

export const FavoriteMenu = styled.ul`
  display: flex;
  gap: 25px;
  background: #f5f9ff;
  transition: 0.3s ease-in-out;
  color: #0c1215;
  margin-left: -30px;
  padding-left: 30px;
  border-right: 5px;
  @media (max-width: 1016px) {
    margin-right: -30px;
  }
`;

export const FavoriteList = styled.li<{ selected: boolean }>`
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 16px 0 17px 0;
  cursor: pointer;
  opacity: ${({selected}) => (selected ? '1' : '0.4')};
`;

export const FavoriteButtonBlock = styled.div`
display: flex;
  justify-content: right;
  margin-right: 10px;
  padding: 30px 0;
`
export const FavoriteButton = styled.button`
  font-family: 'Gilroy', serif;
  padding: 13px 36px;
  background: #FFFFFF;
  opacity: 0.8;
  border: 1px solid #D2DAE3;
  border-radius: 200px 100px 100px 0;
  height: 100%;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.4em;
  color: #0c1215;
  min-width: 320px;

  @media (max-width: 425.98px) {
    padding: 13px 26px;
    text-align: center;
    font-size: 16px;

  }

`


export const FavoriteSliderWrap = styled.div<FavoriteSliderWrapProps>`
  ${({isArray}) =>
          isArray &&
          css`
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 40px 20px;
            place-content: stretch center;
            box-sizing: border-box;
            @media (max-width: 1436.98px) {
              //width: 100%;
            }
            @media (max-width: 682.98px) {
              grid-template-columns: repeat(1, 1fr);
            }
          `}
  ${({isArray}) =>
          !isArray &&
          css`
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px 20px;
            @media (max-width: 682.98px) {
              grid-template-columns: repeat(2, 1fr);
            }
            @media (max-width: 420.98px) {
              grid-template-columns: repeat(1, 1fr);
            }
          `}
`;


export const FavoriteSliderContainer = styled.div`
  display: flex;
  flex-direction: column;

`

export const SliderBlock = styled.div`
  width: 100%;
  max-width: 571px;
  transition: max-width 0.3s ease;

  @media (max-width: 1442.98px) {
    max-width: 540px;
  }

  @media (max-width: 1376.98px) {
    max-width: 500px;
  }
  @media (max-width: 1292.98px) {
    max-width: 450px;
  }
  @media (max-width: 1196.98px) {
    max-width: 400px;
  }
  @media (max-width: 1096.98px) {
    max-width: 360px;
  }
  @media (max-width: 1000.98px) {
    margin: 0 auto;
    max-width: 440px;
  }
  @media (max-width: 918.98px) {

    max-width: 400px;
  }
  @media (max-width: 842.98px) {

    max-width: 380px;
  }

  @media (max-width: 798.98px) {
    max-width: 350px;
  }
  @media (max-width: 738.98px) {
    max-width: 320px;
  }
  @media (max-width: 682.98px) {
    max-width: 565px;
  }
  @media (max-width: 564.98px) {
    max-width: 551px;
  }
  @media (max-width: 564.98px) {
    max-width: 500px;
  }
  @media (max-width: 514.98px) {
    max-width: 450px;
  }
  @media (max-width: 462.98px) {
    max-width: 400px;
  }
  @media (max-width: 412.98px) {
    max-width: 380px;
  }
  @media (max-width: 392.98px) {
    max-width: 350px;
  }

`

export const FavoriteSliderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`

export const FavoriteSliderIcon = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;
  line-height: 130%;

  span {
    color: #0C1215;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }
`


export const SliderTitle = styled.p`
  font-family: 'Cormorant Garamond', sans-serif;
  //font-weight: 600;
  font-size: 34px;
  line-height: 105%;
  letter-spacing: 0.04em;
  color: #0C1215;
`
export const SliderIcon = styled.span`

`

export const FavoriteSliderContentHotels = styled.div`
  margin-top: 11px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;


export const DressesCart = styled.div`

`
export const DressesBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
export const DressesImage = styled.div`
  margin-bottom: 12px;
  max-width: 374px;
`
export const DressWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const DressesTitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 10px;
  max-width: 368px;

  svg {
    width: 8px;
    height: 14px;
  }
`
export const DressesCarTitleBlock = styled.div`
  display: flex;
  align-items: baseline;
  gap: 20px;

  svg {
    width: 13px;
    height: 14px;
    stroke-width: 1.4px;
    stroke: #0C1215;
    opacity: 0.8;
  }
`


export const DressesTitle = styled.p`
  color: #0C1215;
  font-family: Gilroy, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  position: relative;
  max-width: 320px;
  width: 100%;
  cursor: pointer;

  &:hover::before {
    content: "";
    position: absolute;
    right: -24px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 10px;
    background: url(${right}) no-repeat;
    background-size: cover;
  }
`;

export const DressesPrice = styled.span`
  font-family: 'Cormorant Garamond', sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #0C1215;
  opacity: 0.5;
`


export const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const Image = styled.img`
  width: 100%;
  max-width: 100%;
  border-radius: 5px;
`;

export const DressesIcons = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  gap: 14px;

  svg {
    width: 18px;
    height: 18px;
  }
`;
