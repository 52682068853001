import React from 'react';
import {Outlet} from 'react-router-dom';
import {Header} from "./header/Header";
import {Navigate} from "./navigation/Navigate";
import {MainWrapper, NavigateMainWrap, StyleWrapper} from "./style-layout";
import {StyledContainer} from "./header/style-header";
import {GoToTop} from "common/goToTop/GoToTop";


export const Layout = () => {
    return (
        <StyleWrapper>
            <Header/>
            <GoToTop/>
            <MainWrapper>
                <StyledContainer>
                    <NavigateMainWrap>
                        <Navigate/>
                            <Outlet/>
                    </NavigateMainWrap>
                </StyledContainer>
            </MainWrapper>
        </StyleWrapper>
    );
};



