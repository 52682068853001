import React, {useEffect, useState} from 'react';
import {Skeleton, Upload} from "antd";

import {DeleteOutlined} from '@ant-design/icons';

import {Link} from "react-router-dom";
import {PhotoLabel} from "components/pages/wedding/usersForm/style-usersform";
import {WrapperPresentationFile} from "components/PresentationFile/style-presentationFile";
import {FileData} from "services/user/faq/faq.types";


interface PresentationFileProps {
    onImageChange?: (file: string | File | null) => void;
    photo: FileData | string;
    isError: boolean;
    error: any;
    isLoading: boolean;
    text: string;
}

export const PresentationFile: React.FC<PresentationFileProps> = (
    {
        onImageChange,
        photo,
        isError,
        error,
        isLoading,
        text,
    }
) => {
    const [imageUrl, setImageUrl] = useState<FileData | string>(photo);

    useEffect(() => {
        setImageUrl(photo);
    }, [photo]);

    const handleCustomRequest = async ({file, onSuccess, onError}: any) => {
        try {
            if (onImageChange) {
                onImageChange(file);
                setImageUrl({link: '', filename: file.name});
            }
            onSuccess();
        } catch (err) {
            onError(err);
        }
    };

    const handleDelete = (e: React.MouseEvent) => {
        e.stopPropagation();
        setImageUrl('');
        if (onImageChange) {
            onImageChange(null); // Отправляем null при удалении
        }
    };

    return (
        <Upload customRequest={handleCustomRequest} showUploadList={false}>
            {isLoading ? (
                <Skeleton.Input active size="small"/>
            ) : isError ? (
                <div>Ошибка загрузки данных: {error.message}</div>
            ) : !imageUrl ? (
                <PhotoLabel>{text}</PhotoLabel>
            ) : (
                <div style={{width: '100%'}}>
                    <WrapperPresentationFile>
                        {typeof imageUrl === 'object' && imageUrl.link ? (
                            <Link
                                to={imageUrl.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                            >
                                {imageUrl.filename}
                            </Link>
                        ) : (
                            <span>{typeof imageUrl === 'string' ? imageUrl : (imageUrl as FileData).filename}</span>
                        )}
                        <span onClick={handleDelete}>
                            <DeleteOutlined/>
                        </span>
                    </WrapperPresentationFile>
                </div>
            )}
        </Upload>
    );
};
