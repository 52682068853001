import styled from "styled-components";
import checkIcon from '../../../../../assets/main/form/array.svg';



export const SharingFormWrapper = styled.div`
  background: white;
  padding: 30px 62px 50px 62px;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
  overflow: auto;
  border-radius: 5px;
  @media (max-width: 890.98px) {
    padding: 0 20px 20px 20px;
  }

  form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap:  30px;
    grid-auto-flow: dense;
    justify-content: center;
    padding-top: 30px;
    position: relative;
  }
`;
export const FormDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
  grid-auto-flow: dense;
  justify-content: center;
  padding-top: 30px;
  position: relative;`;
export const SharingFormContainer = styled.div``;

export const SharingFormBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 55px;
  @media (max-width: 890.98px) {
    grid-column-gap: 20px;
  }
  @media (max-width: 720.98px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 504.98px) {

  }
`;

export const GuestBlock = styled.div`
  display: flex;
  gap: 20px;
  padding-top: 18px;
  align-items: flex-start;
`;

export const GuestBlockInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;

  p {
    font-family: 'Gilroy',sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #0C1215;
    opacity: 0.8;
  }

  span {
    font-family: 'Gilroy', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #0c1215;
    opacity: 0.6;
  }
`;

export const Checkbox = styled.input`
  position: relative;
  width: 16px;
  height: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  cursor: pointer;
  background: #FFFFFF;
  border: 1.5px solid rgba(12, 18, 21, 0.2);
  border-radius: 4px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    color: #1056b2;
    display: ${(props) => (props.checked ? 'block' : 'none')};
    background-image: url(${checkIcon});
    background-size: cover;
    width: 70%;
    height: 70%;
  }
};
`