import React, {useState} from 'react';
import {v1} from "uuid";
import Avatar2 from "../../../../../assets/main/weddingqa/avatar2.png";
import Avatar3 from "../../../../../assets/main/weddingqa/avatar2.png";
import Avatar4 from "../../../../../assets/main/weddingqa/avatar4.png";
import Avatar5 from "../../../../../assets/main/weddingqa/avatar5.png";
import {ReactComponent as ArrayUp} from '../../../../../assets/main/budget/arrayUp.svg';
import {ReactComponent as ArrayDown} from '../../../../../assets/main/budget/arrowDown.svg';
import {AnswerLatestTitle, AnswerLatestWrap, ShowMoreButton} from "./style-answer-latest";
import {ListContainer, ListDate, ListText, ListUser} from "../../questions/list-questions/style-list-questions";
import {AnswersQuestionsContent} from "../answers-questions/style-answers-questions";
import {ShowMore, ShowMoreBlock} from "../../../budget/butget-expense/style-budgetExpense";


const questions = [
    {
        id: v1(),
        text: 'Do większości będziemy jechać osobiście, ale mamy pare osób z zagranicy więc do nich wyślemy zaproszenia ale również obowiązkowo.',
        avatar: Avatar2,
        name: 'Magda',
        dateUser: '25.02.2022',
    },
    {
        id: v1(),
        text: 'Nikt ponieważ wiedzielismy że rzadko bedziemy tam siedzieć więc nie chcieliśmy żeby nasi świadkowie zostawali sami i żeby siedzieli ze swoimi znajomymi.',
        avatar: Avatar3,
        name: 'Justyna',
        dateUser: '28.02.2022',
    },
    {
        id: v1(),
        text: 'Nikt ponieważ wiedzielismy że rzadko bedziemy tam siedzieć więc nie chcieliśmy żeby nasi świadkowie zostawali sami i żeby siedzieli ze swoimi znajomymi.',
        avatar: Avatar4,
        name: 'Justyna',
        dateUser: '28.02.2022',
    },
    {
        id: v1(),
        text: 'Nikt ponieważ wiedzielismy że rzadko bedziemy tam siedzieć więc nie chcieliśmy żeby nasi świadkowie zostawali sami i żeby siedzieli ze swoimi znajomymi.',
        avatar: Avatar5,
        name: 'Piotr',
        dateUser: '10.02.2022',
    },
    {
        id: v1(),
        text: 'Nikt ponieważ wiedzielismy że rzadko bedziemy tam siedzieć więc nie chcieliśmy żeby nasi świadkowie zostawali sami i żeby siedzieli ze swoimi znajomymi.',
        avatar: Avatar5,
        name: 'Piotr',
        dateUser: '10.02.2022',
    },
]

export const AnswerLatest = () => {
    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
        setShowMore((prevState) => !prevState);
    };

    const visibleQuestions = showMore ? questions : questions.slice(0, 4);

    return (
        <AnswerLatestWrap>
            <AnswersQuestionsContent>
                <AnswerLatestTitle>Najnowsza odpowiedź</AnswerLatestTitle>
                {visibleQuestions.map(el => {
                    return (
                        <ListContainer key={el.id}>
                            <ListText>{el.text}</ListText>
                            <ListUser><img src={el.avatar} alt="avatar"/>
                                <ListDate>
                                    <p>{el.name}</p>
                                    <span></span>
                                    <p>{el.dateUser}</p>
                                </ListDate>
                            </ListUser>
                        </ListContainer>
                    )
                })}
                {questions.length > 4 && (
                    <ShowMoreButton>
                        <ShowMore onClick={toggleShowMore}> {showMore ?
                            <ShowMoreBlock><span>Zwiń</span><span> <ArrayDown/></span></ShowMoreBlock>
                            : <ShowMoreBlock><span>Zobacz wszystkie</span><span> <ArrayUp/></span></ShowMoreBlock>
                        }
                        </ShowMore>
                    </ShowMoreButton>
                )}
            </AnswersQuestionsContent>

        </AnswerLatestWrap>
    );
};

