import React from 'react';
import {Title} from "./style-title";

interface HeaderProps {
    title: string;
}

export const HeaderTitle: React.FC<HeaderProps> = ({ title }) => {
    return <Title>{title}</Title>;
};


