import React, {useEffect, useState} from 'react';
import {FullWrapper} from "../../../layout/style-layout";
import {WebsiteHeader} from "./website-header/WebsiteHeader";
import {WebsiteFull} from "./website-full/WebsiteFull";
import {WebsiteInformation} from "./website-information/WebsiteInformation";
import {WebsiteHoneymoon} from "./website-honeymoon/WebsiteHoneymoon";
import {WebsiteOurStory} from "./website-our-story/WebsiteOurStory";
import {WebsitePlan} from "./website-plan/WebsitePlan";
import {WebsiteGifts} from "./website-gifts/WebsiteGifts";
import {WebsiteSeatingPlan} from "./website-seating-plan/WebsiteSeatingPlan";
import {WebsiteGuestbook} from "./website-guestbook/WebsiteGuestbook";
import {WebsiteCollection} from "./website-collection/WebsiteCollection";
import {WebsiteAttendance} from "./website-attendance/WebsiteAttendance";
import {WebsitePreviewing} from "./website-previewing/WebsitePreviewing";
import {WebsiteWrapper} from "./style-website-pages";

export const WebsitePages = () => {
    // повыносить в хуки

    const [selectedColors, setSelectedColors] = useState(['#FFF8F8', '#805555']);
    const [backgroundColor, setBackgoundColor] = useState(selectedColors[0]);
    const [textColor, setTextColor] = useState(selectedColors[1]);

    useEffect(() => {
        const storedColors = localStorage.getItem('selectedColors');
        if (storedColors) {
            const colorsArray = storedColors.split(',');
            setSelectedColors(colorsArray);
            setBackgoundColor(colorsArray[0]);
            setTextColor(colorsArray[1]);
        }
    }, []);



    return (
        <WebsiteWrapper>
            <WebsiteHeader backgroundColor={backgroundColor} textColor={textColor}/>
            <WebsiteFull/>
            <WebsiteInformation backgroundColor={backgroundColor} textColor={textColor}/>
            <WebsiteHoneymoon backgroundColor={backgroundColor} textColor={textColor}/>
            <WebsiteOurStory/>
            <WebsitePlan/>
            <WebsiteGifts/>
            <WebsiteSeatingPlan backgroundColor={backgroundColor} textColor={textColor}/>
            <WebsiteGuestbook backgroundColor={backgroundColor} textColor={textColor}/>
            <WebsiteCollection backgroundColor={backgroundColor} textColor={textColor}/>
            <WebsiteAttendance backgroundColor={backgroundColor} textColor={textColor}/>
            <WebsitePreviewing  backgroundColor={backgroundColor} textColor={textColor}/>
        </WebsiteWrapper>
    );
};

