import styled from "styled-components";
import {ReactComponent as QuoteIcon} from '../../../../../assets/main/website/quote.svg';
interface IDownloadArrow {
    color: string
}

export const WebsiteGuestbookWrap = styled.section`
  background: #F5F9FF;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease; 

  &:hover {
    border: 1px dashed #D2DAE3;
    padding: 10px;

    .edit-button,
    .eye-button {
      display: block;
    }
  }
 `;



export const  GuestbookContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 47px;
  text-align: center;
  padding: 80px 30px;
  @media (max-width: 1268.98px) {
    gap: 25px;
    padding: 80px 0;
  }
  @media (max-width: 633.98px) {
    padding: 20px 0;
  }
`;


export const GuestbookBlock = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1268.98px) {
    flex-direction: column;
    gap: 20px;
    margin: 0 auto;
    align-items: center;
  }

`;

export const BlockFeedback = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-self: center;
`;


export const FeedbackTitle = styled.p`
  color: #0C1215;
  text-align: center;
  font-family: Gilroy,sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;





export const BlockSlider = styled.div`
  max-width: 581px;
  width: 100%;
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  border-radius: 40px;
  background: #FFF;
  box-shadow: 0px 2.68382px 20.12864px 0px rgba(11, 62, 138, 0.08);
  
  svg {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 607.98px) {
    max-width: 500px;
    height: auto;
    padding: 20px;
    align-items: center;
    gap: 10px;
  }
  
  @media (max-width: 524.98px) {
    max-width: 400px;

  }  
  @media (max-width: 425.98px) {
    max-width: 370px;

  }

  @media (max-width: 394.98px) {
    max-width: 320px;

  }
`;

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SliderBlock = styled.div`
  display: flex;
  gap: 20px;
`;


export const SliderText = styled.p`
  color: #0C1215;
  font-family: Gilroy,sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  text-align: start;
`;


export const FromWhom = styled.p`
  color: rgba(12, 18, 21, 0.60);
  text-align: right;
  font-family: Gilroy,sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
 
`;




export const QuoteIconIcon = styled(QuoteIcon)<IDownloadArrow>`
  flex-shrink: 0;
  margin-right: auto;
  path {
    fill: ${(props) => (props.color ? props.color : "")};
  }
    
`