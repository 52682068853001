import styled from "styled-components";


export const WebsiteAttendanceWrap = styled.section`
  position: relative;
`;

export const AttendanceBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media (max-width: 633.98px) {
    gap: 20px;
  }
`;

export const Container = styled.div`
  padding: 120px 0;
  @media (max-width: 768.98px) {
    padding: 40px 0;
  }
`;

export const AttendanceInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 47px;
  text-align: center;

  @media (max-width: 633.98px) {
    padding-top: 40px;
    gap: 15px;
  }

`;
export const AttendanceTitle = styled.p`
  color: #0C1215;
  text-align: center;
  font-family: Gilroy, serif;
  font-size: 46px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 768.98px) {
    font-size: 36px;
  }

  @media (max-width: 425.98px) {

    font-size: 28px;
  }
`;

export const AttendanceText = styled.p`
  max-width: 700px;
  margin: 0 auto;
  color: #0C1215;
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;

  @media (max-width: 768px) {
    /* Измените ширину для устройств с шириной экрана до 768px */
    max-width: 100%;
    padding: 0 20px; /* Добавьте отступы по бокам для лучшей читаемости */
  }

  @media (max-width: 425px) {
    /* Дополнительно измените стили для устройств с шириной экрана до 425px */
    font-size: 16px; /* Уменьшите размер шрифта для меньших экранов */
  }
`;

export const AttendanceConfirm = styled.div`
  width: 852px;
  margin: 0 auto;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1130.98px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const ConfirmBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 633.98px) {
    gap: 10px;
  }
`;

export const ConfirmTitle = styled.p`
  color: #0C1215;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  opacity: 0.5;
`;

export const RadioWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  padding-left: 20px;
  @media (max-width: 1000.98px) {
    padding-left: 0;
  }
`;

export const RadioInput = styled.input.attrs({type: 'radio'})`
  width: 16px;
  height: 16px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #1056B2; 
    box-sizing: border-box;
    opacity: 0.8;
  }

  &:checked:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #1056B2; /* Цвет для внутреннего круга в активном состоянии */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const RadioLabel = styled.label`
  color: #0C1215;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
  opacity: 0.8;
  cursor: pointer;
`;

export const ConfirmBlockInput = styled.div`

`;

export const AttendanceButton = styled.div`
  display: flex;
  justify-content: center;
`;

export const ConfirmInput = styled.input`
  font-size: 16px;
  line-height: 46px;
  color: rgba(12, 18, 21, 0.8);
  height: 48px;
  padding: 0 20px;
  background: #fff;
  border: 1px solid rgba(12, 18, 21, 0.2);
  border-radius: 5px;
  width: 416px;
  @media screen and (max-width: 438.98px) {
    width: 370px;
  }

  @media screen and (max-width: 383.98px) {
    width: 340px;
  }

  //&:hover {
  //  border-color: #007aff;
  //}
  //
  //&:focus {
  //  border-color: #007aff;
  //  outline: none;
  //  box-shadow: 0 0 3px #007aff;
  //}
`;
