import styled from 'styled-components';

export const WebsiteCreateWrap = styled.section`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 124px;
  padding-left: 30px;
  width: 100%;
  
  img {
    max-width: 692px;
    width: 100%;
  }
  @media (max-width: 1000.98px) {
    padding-top: 100px;
    padding-left: 0;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 80px 20px 0 20px; 
  }
`;


export const WebsiteCreateTitle = styled.p`
  color: #051f41;
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  max-width: 692px;
`;