import styled from "styled-components";

export const PlanWrap = styled.section`
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  @media (max-width: 682.98px){
    padding-top: 20px;
  }
`


export const PlanContainer = styled.div`
  @media (max-width: 1184.98px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    //grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
  }

  @media (max-width: 624.98px) {
    grid-template-columns: 1fr;
  }
  
`;

export const PlanBlock = styled.div`
  border-bottom: 1px solid #D2DAE3;
  padding: 15px 0 10px 0;
  transition: border-color 0.3s ease;

  @media (max-width: 1184.98px) {
    border-bottom: none;
    background: rgba(255, 255, 255, .8);
    box-shadow: 0 4px 30px rgba(11, 62, 138, .08);
    border-radius: 5px;
    padding: 15px;
    &:hover {
      border-color: #1056B2;
      cursor: pointer;
    }
  }
`;





export const PlanTitle = styled.p`
  font-family: 'Gilroy', serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.01em;
  color: #0C1215;
  margin-bottom: 15px;
`

export const PlanItems = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: flex-start;


  @media (max-width: 1184.98px) {
    position: relative;
    text-align: start;
    flex-direction: column;
    gap: 10px;
  }
`;

export const PlanTime = styled.div`
  font-family: 'Gilroy',serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #1056B2;
  transition: color 0.3s ease;

  p {
    cursor: auto;
  }

  @media (max-width:  1184.98px) {
    display: flex;
  }
  
`
export const PlanText = styled.div`
  font-family: 'Gilroy', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #0C1215;
  transition: color 0.3s ease;
  width: 100%;
  max-width: 280px;

  @media (min-width: 1184.98px) {
    width: 422px;
  }
  
`;
export const PlanName = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-decoration-line: underline;
  color: #1056B2;
  width: 183px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    opacity: 0.4;
  }
`

export const PlanFalseName = styled.div`
  color: #0C1215;
  font-family: Gilroy,sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; 
  width: 183px;
  opacity: 0.4;
  cursor: pointer;
`


export const PlanLocation = styled.div`
  display: flex;
  gap: 9px;
  align-items: baseline;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #0C1215;
  transition: color 0.3s ease;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
  span {
    width: 265px;
  }

`
export const PlanJust = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #0C1215;
  opacity: 0.5;
  transition: color 0.3s ease;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }

`
export const PlanEdit = styled.div`
  cursor: pointer;
  fill: #0C1215;
  transition: color 0.3s ease;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
  @media (max-width:  1184.98px) {
    display: none;
  }
`;

export const PlanEditNew = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  fill: #0C1215;
  transition: color 0.3s ease;
  display: none;

  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }

  @media (max-width: 1184.98px) {
    display: block;
  }
`;