import styled from "styled-components";

export const FormSlideBlock = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const BlockFormSlider = styled.div`
  max-width: 581px;
  width: 100%;
  //height: 239px;
  //display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  border-radius: 40px;
  background: #FFF;
  box-shadow: 0px 2.68382px 20.12864px 0px rgba(11, 62, 138, 0.08);
  
  svg {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 607.98px) {
    max-width: 500px;
    height: auto;
    padding: 20px;
    align-items: center;
    gap: 10px;
  }
  
  @media (max-width: 524.98px) {
    max-width: 400px;

  }  
  @media (max-width: 425.98px) {
    max-width: 370px;

  }

  @media (max-width: 394.98px) {
    max-width: 320px;

  }
`;