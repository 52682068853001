import React, {useEffect, useState} from "react";
import {
    Card, CardBlock,
    CardContent,
    CardEmail,
    CardImage,
    CardName,
    CardPhoneNumber,
    WebsiteHoneymoonWrap
} from "./style-website-honeymoon";
import jonathan from '../../../../../assets/main/website/jonathan.png'
import joel from '../../../../../assets/main/website/joel.png'
import {IWebsite} from "../types";


const cardData = [
    {
        name: "Iwona",
        phoneNumber: "+48 58 864 97 36",
        email: "alex@example.com",
        imageSrc: jonathan,
        alt: "Honeymoon Image 1"
    },
    {
        name: "Alex",
        phoneNumber: "987-654-321",
        email: "jane@example.com",
        imageSrc: joel,
        alt: "Honeymoon Image 2"
    },
];

export const WebsiteHoneymoon = ({backgroundColor,textColor}: IWebsite) => {


    return (
        <WebsiteHoneymoonWrap id="kontakty" style={{ background: backgroundColor }}>
            <CardBlock>
                {cardData.map((card, index) => (
                    <Card key={index}>
                        <CardImage src={card.imageSrc} alt={card.alt} />
                        <CardContent>
                            <CardName>{card.name}</CardName>
                            <CardPhoneNumber>{card.phoneNumber}</CardPhoneNumber>
                            <CardEmail>{card.email}</CardEmail>
                        </CardContent>
                    </Card>
                ))}
            </CardBlock>
        </WebsiteHoneymoonWrap>
    );
};