import styled from "styled-components";

export const PlanAddGuestWrap = styled.div`
  background: #F5F9FF;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0 10px;
  border-radius: 5px;
`;


export const PlanAddGuestButton = styled.button`
  font-family: 'Gilroy',sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: rgba(12, 18, 21, 0.4);
  padding: 8px 18px;

  svg {
    width: 13px;
    height: 13px;
`;
