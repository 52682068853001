import styled from "styled-components";

export const PlanButtonSubmit = styled.a`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  padding: 12px 30px;
  background: #1056B2;
  border-radius: 200px 100px 100px 0px;
  cursor: pointer;

  span {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #FFFFFF;

  }

  &:hover,
  &:focus {
    background-color: #1976d2;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;
