import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import {ReactComponent as ShowPassword} from '../../../assets/main/form/showPassword.svg';
import {ReactComponent as ShowPasswordError} from '../../../assets/main/form/showPasswordError.svg';
import {ReactComponent as VisibilityPassword} from '../../..//assets/main/form/visibilityPassword.svg';
import {ReactComponent as VisibilityPasswordError} from '../../..//assets/main/form/visibilityPasswordError.svg';
import {zodResolver} from "@hookform/resolvers/zod";
import {
    ContentButton, ErrorText, FormBlockPassword, Loader,
    NetworksBlockSocials,
    NetworksBlockText,
    SignSocialNetworksBlock,
    SingInBlock,
    SingInButton,
    SingInContainer,
    SingInContentBlock,
    SingInForgot,
    SingInForm,
    SingInFormContainer,
    SingInIcon,
    SingInImg,
    SingInImgContainer
} from "components/sign-in-page/sing-in/style-sing-in";
import singIcon from "../../../assets/sing/sing.png";
import singLogoIcon from "../../../assets/sing/sing-logo.svg";
import {ReactComponent as FacebookIcon} from '../../../assets/sing/facebook.svg';
import {ReactComponent as GoogleIcon} from '../../../assets/sing/google.svg';
import {FormBlock, StyledInput, StyledLabel} from "components/pages/wedding/usersForm/style-usersform";
import {ContentInput, ContentTitle} from "components/pages/tasks/style-tasks";
import {CalendarText} from "components/calendar-Info/styles";
import Cookies from 'universal-cookie';
import {FormValues, loginSchema} from "common/validation/loginSchema";
import {PasswordChangeResponseType} from "services/auth/auth.types";


interface SingInProps {
    onSubmit: (handlerLogin: FormValues) => void;
    showPassword: {
        password: boolean;
    };
    togglePasswordVisibility: (field: any) => void;
    data?: PasswordChangeResponseType;
    isLoading:boolean
}



export const SingIn = ({onSubmit, showPassword, togglePasswordVisibility, data,isLoading}: SingInProps) => {
    const [rememberMe, setRememberMe] = useState(false);
    const [isTitleDone, setIsTitleDone] = useState(false);
    const cookies = new Cookies();


    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<FormValues>({
        resolver: zodResolver(loginSchema),
        defaultValues: {
            email: cookies.get("rememberedEmail") || "",
            password: cookies.get("rememberedPassword") || "",
        },
    });

    const isPasswordError = data?.error || errors.password?.message;

    useEffect(() => {
        const rememberMeValue = cookies.get("rememberMe");
        setRememberMe(rememberMeValue === "true");
    }, []);


    const handleLoginSubmit = (data: FormValues) => {
        if (rememberMe) {
            cookies.set("rememberedEmail", data.email, {path: "/"});
            cookies.set("rememberedPassword", data.password, {path: "/"}); // Сохраняем пароль в куки
            cookies.set("rememberMe", "true", {path: "/"});
        } else {
            cookies.remove("rememberedEmail");
            cookies.remove("rememberedPassword");
            cookies.remove("rememberMe");
        }
        onSubmit(data);
    };


    const handleTitleClick = () => {
        setRememberMe(!rememberMe);
    };


    return (
        <SingInContainer>
            <SingInImgContainer>
                <SingInImg src={singIcon} alt="sing"/>
                <SingInIcon src={singLogoIcon} alt="icon"/>
            </SingInImgContainer>
            <SingInFormContainer>
                <SingInForm onSubmit={handleSubmit(handleLoginSubmit)}>
                    <FormBlock>
                        <StyledLabel
                            error={errors.email?.message || data?.error}
                            htmlFor="email">
                            E-mail
                        </StyledLabel>
                        <StyledInput
                            error={errors.email?.message || data?.error}
                            type="email"
                            id="email"
                            {...register("email")}
                        />
                        {(errors.email?.message || data?.error) && (
                            <ErrorText>{errors.email?.message || data?.error}</ErrorText>
                        )}

                    </FormBlock>

                    <FormBlock>
                        <StyledLabel
                            error={errors.password?.message || data?.error}
                            htmlFor="password">
                            Hasło
                        </StyledLabel>
                        <FormBlockPassword error={errors.password?.message || data?.error}>
                            <input
                                type={showPassword.password ? 'text' : 'password'}
                                id="password"
                                {...register("password")}
                            />
                            <button
                                type="button"
                                onClick={() => togglePasswordVisibility('password')}
                            >
                                {showPassword.password ? (
                                    isPasswordError ? <VisibilityPasswordError /> : <VisibilityPassword />
                                ) : (
                                    isPasswordError ? <ShowPasswordError /> : <ShowPassword />
                                )}
                            </button>
                        </FormBlockPassword>
                        {(errors.password?.message || data?.error) && (
                            <ErrorText>{errors.password?.message || data?.error}</ErrorText>
                        )}
                    </FormBlock>
                    <SingInContentBlock>
                        <SingInBlock>
                            <ContentInput
                                type="checkbox"
                                checked={rememberMe}
                                onChange={() => setRememberMe(!rememberMe)}
                            />
                            <ContentTitle
                                isDone={isTitleDone}
                                onClick={handleTitleClick}
                            >
                                Zapamiętaj mnie
                            </ContentTitle>
                        </SingInBlock>
                        <SingInForgot>
                            <Link to={"/forgot-password"}>Zapomniałeś hasła?</Link>
                        </SingInForgot>
                    </SingInContentBlock>


                    <SingInButton
                        disabled={Object.keys(errors).length > 0}
                        type="submit"
                    >
                        <ContentButton>
                            {isLoading && <Loader /> }
                            <CalendarText>
                                Dalej
                            </CalendarText>
                        </ContentButton>

                    </SingInButton>

                    <SignSocialNetworksBlock>
                        <NetworksBlockText>
                            lub zaloguj się przez:
                        </NetworksBlockText>
                        <NetworksBlockSocials>
                            <GoogleIcon/>
                            <FacebookIcon/>
                        </NetworksBlockSocials>
                    </SignSocialNetworksBlock>
                </SingInForm>
            </SingInFormContainer>
        </SingInContainer>
    );
};




