import styled from "styled-components";
import {ReactComponent as ArrayLeftIcon} from '../../../../../../assets/main/website/array-left.svg';
import {ReactComponent as QuoteIcon} from '../../../../../../assets/main/website/quote.svg';
interface IDownloadArrow {
    color: string
}

export const GuestbookItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: end;
  cursor: pointer;

`;

export const RightArrow = styled(ArrayLeftIcon)<IDownloadArrow>`
    path {
      stroke: ${(props) => (props.color ? props.color : "")};
    }
`
