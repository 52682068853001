import React, {useState} from 'react';
import usePopup from "../../../../../common/hook/use-popup";
import {InspirationsPopups} from "./Inspirations-popup/InspirationsPopups";
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';
import {imagesDate} from "../ImagesDate";
import {ReactComponent as MagnifierIcon} from '../../../../../assets/main/favorites/magnifier.svg';
import {GalleryWrap, ImageWrapper, MagnifierWrapper} from "./style-inspirations-gallery";
import {ReactComponent as LikeIcon} from '../../../../../assets/main/favorites/like.svg'
import {ReactComponent as NotesIcon} from '../../../../../assets/main/favorites/notes.svg'
import {ReactComponent as NotesWhiteIcon} from '../../../../../assets/main/favorites/notesWhite.svg'
import {DressesIcons} from "../../favorite/favorite-container/style-favorite-container";


export const InspirationsGallery = () => {
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();
    const [data, setData] = useState({image: '', i: 0});

    const viewImage = (image: string, i: number) => {
        setData({image, i});
        onClickHandler();
    };

    return (
        <>
            {data.image && (
                <InspirationsPopups
                    show={showPopup}
                    handleClosePopup={handleClosePopup}
                    onClickClosest={onClickClosest}
                    image={data.image}
                />
            )}

            <GalleryWrap>
                <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
                    <Masonry gutter="20px">
                        {imagesDate.map((imageData, i) => (
                            <ImageWrapper key={i} onClick={() => viewImage(imageData.image, i)}>
                                <img src={imageData.image} style={{width: "100%", display: "block"}} alt="image"/>
                                <DressesIcons>
                                    {imageData.noted ? <NotesIcon/> : <NotesWhiteIcon/>}
                                    <LikeIcon/>
                                </DressesIcons>
                                <MagnifierWrapper>
                                    <MagnifierIcon/>
                                </MagnifierWrapper>
                            </ImageWrapper>
                        ))}
                    </Masonry>
                </ResponsiveMasonry>
            </GalleryWrap>
        </>
    );
};

