import React, {useState} from 'react';
import {
    GiftText,
    ItemBlock,
    WebsiteGiftsBlock,
    WebsiteGiftsItem,
    WebsiteGiftsItems,
    WebsiteGiftsTitle,
    WebsiteGiftsWrap
} from "./style-website-gifts";
import {ImageContainer, ResponsiveImage} from "../website-full/style-website-full";
import imageSrc from '../../../../../assets/main/website/fullGift.png'
import {ReactComponent as GiftIcon} from '../../../../../assets/main/website/giftIcon.svg';
import usePopup from "../../../../../common/hook/use-popup";
import {
    Button,
    ButtonEye,
    ButtonVisibilityOff,
    EditBlocks,
    HiddenMessage,
    OurStoryButton
} from "../website-our-story/style-website-our-story";
import {ReactComponent as EyeIcon} from '../../../../../assets/main/website/eye.svg';
import {ReactComponent as VisibilityOffEyeIcon} from '../../../../../assets/main/website/visibility-off-eye.svg';
import {ReactComponent as EditIcon} from '../../../../../assets/main/website/edit.svg';
import {Popups} from "../../../../popups/Popups";
import {FormGift} from "./form-gift/FormGift";

export const WebsiteGifts = () => {
    const giftData = [
        {
            text: "Marzymy o tym, by mieć własną domową piwniczkę pełną wina, dlatego będzie nam miło, jeśli otrzymamy butelkę tego trunku."
        },
        {
            text: "Uwielbiamy książki i marzymy o obszernej domowej biblioteczce."
        },
        {
            text: "Jesteśmy słodyczoholikami, dlatego zamiast kwiatów prosimy o słodki podarunek."
        },
        {
            text: "Pragniemy, aby swoim szczęściem podzielić się z innymi, więc w ramach prezentu prosimy o wpłaty na konto Fundacji Małe Dzieci."
        }
    ];

    const [isHidden, setIsHidden] = useState<boolean>(true);
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();
    const toggleHidden = () => {
        setIsHidden(!isHidden);
    };


    return (
        <div id="prezenty">
            {isHidden ? (
                <WebsiteGiftsWrap>
                    <ImageContainer>
                        <ResponsiveImage src={imageSrc} alt={''}/>
                        <OurStoryButton>
                            <Button
                                onClick={onClickHandler}
                                className="edit-button">
                                <EditBlocks>
                                    <EditIcon/>
                                    <span>Edytuj</span>
                                </EditBlocks>
                            </Button>
                            <ButtonEye onClick={toggleHidden} className="eye-button"><EyeIcon/></ButtonEye>
                        </OurStoryButton>
                        <WebsiteGiftsBlock>
                            <WebsiteGiftsTitle>
                                Prezenty
                            </WebsiteGiftsTitle>
                            <WebsiteGiftsItems>
                                {giftData.map((gift, index) => (
                                    <WebsiteGiftsItem key={index}>
                                        <ItemBlock>
                                            <GiftIcon/>
                                            <GiftText>
                                                {gift.text}
                                            </GiftText>
                                        </ItemBlock>
                                    </WebsiteGiftsItem>
                                ))}
                            </WebsiteGiftsItems>
                        </WebsiteGiftsBlock>
                    </ImageContainer>
                </WebsiteGiftsWrap>
            ) : (
                <HiddenMessage>
                    <span>Blok jest ukryty. Kliknij przycisk Ukryj/Pokaż po prawej stronie, aby był widoczny.</span>
                    <ButtonVisibilityOff onClick={toggleHidden}
                                         className="eye-button"><VisibilityOffEyeIcon/></ButtonVisibilityOff>
                </HiddenMessage>
            )}
            <Popups
                show={showPopup}
                handleClosePopups={handleClosePopup}
                onClickClosest={onClickClosest}
                formComponent={<FormGift handleClosePopup={handleClosePopup}/>}
            />
        </div>

    );
};
