import React, {useEffect, useState} from 'react';
import {ReactComponent as СrossIcon} from '../../../../../../assets/main/wedding/close.svg'
import {StyledTitleAbout} from "../../../../wedding/popUp/style-popups";
import {
    ContentContainer,
    ImageContainer,
    InspirationsButtonCloses,
    InspirationsPopupContainer,
    InspirationsPopupTitle,
    InspirationsPopupWrapper,
    ThirdBlock
} from "./style-inspirations-popup";
import {ExpenseButton} from "../../../../budget/butget-expense/style-budgetExpense";
import {ReactComponent as LikeWhiteIcon} from '../../../../../../assets/main/favorites/likeWhite.svg'
import {Button} from "../../../../wedding/style-wedding";
import {ReactComponent as Edit} from '../../../../../../assets/main/wedding/edit.svg';
import {ReactComponent as Checkbox} from '../../../../../../assets/main/form/checkbox.svg';
import {ReactComponent as CloseIcon} from '../../../../../../assets/main/form/close.svg';

import {
    ButtonBlock,
    ButtonCloses,
    ButtonSubmit,
    ButtonWrap,
    FormBlock,
    StyledLabel,
    StyledTextArea
} from "../../../../wedding/usersForm/style-usersform";


interface InspirationsPopupProps {
    handleClosePopup: () => void;
    image: string;
}

export const InspirationsPopup: React.FC<InspirationsPopupProps> = ({handleClosePopup, image}) => {
    const [editedNote, setEditedNote] = useState(true);
    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    useEffect(() => {
        if (!handleClosePopup) {
            setEditedNote(false);
        }
    }, [handleClosePopup]);

    const onClickHandler = () => {
        setEditedNote(!editedNote);
    };


    return (
        <InspirationsPopupWrapper onClick={handleClick}>
            <InspirationsPopupContainer>
                <InspirationsButtonCloses type="button" onClick={handleClosePopup}>
                    <СrossIcon/>
                </InspirationsButtonCloses>
                <ImageContainer>
                    <LikeWhiteIcon/>
                    <img src={image} alt=""/>
                </ImageContainer>
                {editedNote ?
                    <ContentContainer>
                        <InspirationsPopupTitle>Notatka</InspirationsPopupTitle>
                        <StyledTitleAbout>Podobał mi się pomysł z welonem</StyledTitleAbout>
                        <ExpenseButton>
                            <Button onClick={onClickHandler}>
                                <Edit/>
                                <span>Edytuj</span>
                            </Button>
                        </ExpenseButton>
                    </ContentContainer>
                    :
                    <ContentContainer>
                        <InspirationsPopupTitle>Napisz notatkę</InspirationsPopupTitle>
                        <FormBlock>
                            <StyledLabel htmlFor="content">Opis</StyledLabel>
                            <StyledTextArea
                                id="content"
                                name="content"

                                autoSize={{minRows: 6, maxRows: 12}}
                            />
                        </FormBlock>
                        <ThirdBlock>
                            <ButtonWrap>
                                <ButtonBlock>
                                    <ButtonCloses>
                                        <CloseIcon/>
                                        <span>Anuluj</span>
                                    </ButtonCloses>
                                    <ButtonSubmit
                                        type="submit"
                                        // disabled={Object.keys(errors).length !== 0}
                                    >
                                        <Checkbox/>
                                        <span>Zapisz</span>
                                    </ButtonSubmit>
                                </ButtonBlock>
                            </ButtonWrap>
                        </ThirdBlock>
                    </ContentContainer>
                }
            </InspirationsPopupContainer>
        </InspirationsPopupWrapper>
    );
};
