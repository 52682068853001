import React, {FC} from 'react';
import {Form, Formik} from 'formik';
import {ReactComponent as СrossIcon} from '../../../../../../assets/main/wedding/close.svg';
import {ReactComponent as Checkbox} from '../../../../../../assets/main/form/checkbox.svg';
import {ReactComponent as Сlose} from '../../../../../../assets/main/form/close.svg';
import {useAppDispatch} from "../../../../../../common/hook";
import {
    ButtonBlock,
    ButtonCloses,
    ButtonSubmit,
    FormBlock,
    FormContainer,
    FormWrapper,
    StyledInput,
    StyledLabel,
    StyledTextArea, StyledUserFileInputContainer
} from "../../../../wedding/usersForm/style-usersform";
import {CloseButton, StyledTitle} from "../../../../../popups/style-popups";
import {
    AdsFormBlock,
    FileIconContainer,
    FileUploadIcon,
    FileUploadText
} from "../../../../ads/ads-place/style-ads-place";


interface MyFormValues {
    title: string,
    price: string,
}

type PropsType = {
    handleClosePopup: () => void;
};


export const FormInformation: FC<PropsType> = ({handleClosePopup}) => {
    const dispatch = useAppDispatch();

    const handleSubmit = (values: MyFormValues) => {

    };


    return (
        <Formik initialValues={
            {
                title: '',
                price: '',
            }
        }
                onSubmit={handleSubmit}
            // validationSchema={schema}
        >
            {({values, handleSubmit, handleChange, setFieldValue, errors}) => (
                <FormWrapper onClick={(e) => e.stopPropagation()}>
                    <Form onSubmit={handleSubmit}>
                        <CloseButton type="button" onClick={handleClosePopup}>
                            <СrossIcon/>
                        </CloseButton>
                        <StyledTitle>Edytuj</StyledTitle>
                        <FormContainer>
                            <FormBlock>
                                <StyledLabel htmlFor="additionalText">Dodaj tekst</StyledLabel>
                                <StyledTextArea
                                    id="additionalText"
                                    name="additionalText"
                                    onChange={handleChange}
                                    autoSize={{minRows: 4, maxRows: 8}}
                                />
                            </FormBlock>
                            <ButtonBlock>
                                <ButtonCloses><Сlose/><span>Anuluj</span></ButtonCloses>
                                <ButtonSubmit type="submit"
                                              disabled={Object.keys(errors).length !== 0}><Checkbox/><span>Zapisz</span></ButtonSubmit>
                            </ButtonBlock>
                        </FormContainer>
                    </Form>
                </FormWrapper>
            )}
        </Formik>
    );
};


