import React from 'react';

import {PreviewingText} from "../../website-previewing/style-website-previewing";
import {IWebsite} from "../../types";
import {FormGuestbook} from "../form-guestbook/FormGuestbook";
import {Popups} from "../../../../../popups/Popups";
import {FormPreviewMode} from "./form-preview-mode/FormPreviewMode";
import usePopup from "../../../../../../common/hook/use-popup";
import {GuestbookItem, RightArrow} from "./style-preview-mode";

export const PreviewMode = ({textColor,backgroundColor}: IWebsite) => {
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();
    return (
        <GuestbookItem onClick={onClickHandler}>
            <PreviewingText style={{color: textColor}}>
                Zobacz w trybie podglądu
            </PreviewingText>
            <RightArrow color={textColor}/>
            <Popups
                show={showPopup}
                handleClosePopups={handleClosePopup}
                onClickClosest={onClickClosest}
                formComponent={<FormPreviewMode textColor={textColor} handleClosePopup={handleClosePopup}/>}
            />
        </GuestbookItem>


    );
};

