import React from "react";
import {TasksEditPopup} from "../tasks-edit-popup/TasksEditPopup";
import usePopup from "../../../../../../../common/hook/use-popup";
import {PointsTitle} from "../../../../../budget/butget-expense/points-expense/style-points";


interface IExpensesId {
    expensesId: string;
    show:boolean
}

export const  TasksPopupEdit = ({expensesId,show}: IExpensesId) => {
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();

    return (
        <>
            <PointsTitle onClick={onClickHandler}>{!show && 'Zmień nazwę' }</PointsTitle>
            {showPopup && <TasksEditPopup expensesId={expensesId} show={showPopup} handleClosePopup={handleClosePopup}
                                          onClickClosest={onClickClosest}/>}
        </>
    );
};

