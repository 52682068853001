import styled from "styled-components";

export const AnswersQuestionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  //border-radius: 5px;
  @media (max-width: 1024.98px) {
    gap: 10px;
    margin-bottom: 40px;
  }
`
export const AnswersQuestionsContent = styled.div`
  
`
