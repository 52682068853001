import React, {FC} from 'react';
import {Form, Formik} from 'formik';
import {ReactComponent as СrossIcon} from '../../../../../../../assets/main/wedding/close.svg';
import {useAppDispatch} from "../../../../../../../common/hook";
import {FormContainer, FormWrapper} from "../../../../../wedding/usersForm/style-usersform";
import {CloseButton, StyledTitle} from "../../../../../../popups/style-popups";
import {FromWhom, QuoteIconIcon, SliderBlock, SliderContainer, SliderText} from "../../style-website-guestbook";
import {data} from "../../slider/Slider";
import {BlockFormSlider, FormSlideBlock} from "./style-form-preview-mode";


interface MyFormValues {
    title: string,

}

type PropsType = {
    handleClosePopup: () => void;
    textColor: string
};


export const FormPreviewMode: FC<PropsType> = ({handleClosePopup, textColor}) => {

    const dispatch = useAppDispatch();

    const handleSubmit = (values: MyFormValues) => {

    };


    return (
        <Formik initialValues={
            {
                title: '',
            }
        }
                onSubmit={handleSubmit}
        >
            {({values, handleChange, setFieldValue, errors}) => (
                <FormWrapper onClick={(e) => e.stopPropagation()}>
                    <Form >
                        <CloseButton type="button" onClick={handleClosePopup}>
                            <СrossIcon/>
                        </CloseButton>
                        <StyledTitle>Księga gości</StyledTitle>
                        <FormContainer>
                            <FormSlideBlock>
                                {data.map((item, index) => (
                                    <BlockFormSlider key={index}>
                                        <SliderContainer>
                                            <SliderBlock>
                                                <QuoteIconIcon color={textColor}/>
                                                <SliderText>{item.text}</SliderText>
                                            </SliderBlock>
                                            <FromWhom>{item.from}</FromWhom>
                                        </SliderContainer>
                                    </BlockFormSlider>
                                ))}
                            </FormSlideBlock>
                        </FormContainer>
                    </Form>
                </FormWrapper>
            )}
        </Formik>
    );
};


