import styled from "styled-components";

export const GuestCounterWrap = styled.div`
  background: #F5F9FF;
  border-radius: 5px;
  @media (max-width: 768.98px) {
    background: #F5F9FF;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
  }
`;

export const GuestCounterBlock = styled.div`
  padding: 25px 75px 15px 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  @media (max-width: 1183.98px) {
    padding: 25px 30px 15px 30px;
  }
  @media (max-width: 768.98px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding:10px;
  }
  @media (max-width: 492.98px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 492.98px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const CounterCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media (max-width: 768.98px) {
    background: white;
    border-radius: 8px;
    padding: 10px;
    flex: 1 1 calc(33.33% - 10px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: calc(50% - 10px);
    gap: 10px;
    text-align: center;
  }

`;

export const ContentNumber = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #0C1215;
  @media (max-width: 768.98px) {
    font-size: 20px;
  }
`;

export const ContentTitle = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(12, 18, 21, 0.5);
`;

