import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SpoilerState {
    isOpenSpoiler: boolean;
    isFullOpen: boolean;
    isOpenPopup: boolean;
}

const initialState: { [key: number]: SpoilerState } = {
    0: {
        isOpenSpoiler: false,
        isFullOpen: false,
        isOpenPopup: false,
    },
    1: {
        isOpenSpoiler: false,
        isFullOpen: false,
        isOpenPopup: false,
    },
    2: {
        isOpenSpoiler: false,
        isFullOpen: false,
        isOpenPopup: false,
    },
};

const spoilerSlice = createSlice({
    name: 'spoiler',
    initialState,
    reducers: {
        setIsOpen(state, action: PayloadAction<{ index: number }>) {
            const { index } = action.payload;
            state[index].isOpenSpoiler = !state[index].isOpenSpoiler;
        },
        setIsOpenPopup(state, action: PayloadAction<boolean>) {
            Object.values(state).forEach((spoiler) => (spoiler.isOpenPopup = action.payload));
        },
    },
});

export const { setIsOpen, setIsOpenPopup } = spoilerSlice.actions;
export const spoilerReducer = spoilerSlice.reducer;