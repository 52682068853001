import React from "react";
import {createBrowserRouter, Outlet, RouteObject, RouterProvider} from 'react-router-dom'
import {Ads, Budget, Favorites, Guest, Plan, Tasks, WebsitePages, Layout, Wedding, WeddingQA} from "components";
import {SignInPage} from "components/sign-in-page/sign-in-page";
import {SharingInformation} from "components/pages/guest/sharing-information/SharingInformation";
import {Questions} from "components/pages/weddingqa/questions/Questions";
import {Answers} from "components/pages/weddingqa/answers/Answers";
import {WebsiteCreate} from "components/pages/website/website-create/WebsiteCreate";
import {Website} from "components/pages/website/Website";
import {AdsPlace} from "components/pages/ads/ads-place/AdsPlace";
import {Favorite} from "components/pages/favorites/favorite/Favorite";
import {Inspirations} from "components/pages/favorites/insoirations/Inspirations";
import {ForgotPassword} from "components/forgot-password/forgot-password";
import {Navigate} from 'react-router-dom';
import {useMeQuery} from "services/auth/auth.service";
import {PasswordChange} from "components/password-change/password-change";



const publicRoutes: RouteObject[] = [
    {
        path: '/login',
        element: <SignInPage/>,
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword/>,
    },
    // {
    //     path: '/password-change',
    //     element: <PasswordChange/>,
    // },
]


const privateRoutes: RouteObject[] = [
    {
        path: '/',
        element: <Wedding/>,
    },
    {
        path: '/tasks',
        element: <Tasks/>,
    },
    {
        path: '/budget',
        element: <Budget/>,
    },
    {
        path: '/guest',
        element: <Guest/>,
    },
    {
        path: '/sharing',
        element: <SharingInformation/>,
    },
    {
        path: '/plan',
        element: <Plan/>,
    },
    {
        path: '/weddingqa',
        element: <WeddingQA/>,
        children: [
            {
                path: 'questions',
                element: <Questions/>,
            },
            {
                path: 'answers',
                element: <Answers/>,
            },
        ]
    },
    {
        path: '/website-create',
        element: <WebsiteCreate/>,
        children: [
            {
                path: 'website',
                element: <Website/>,
                children: [
                    {
                        path: 'website-pages',
                        element: <WebsitePages/>,
                    },
                ]
            },

        ]
    },
    {
        path: '/ads',
        element: <Ads/>,
    },
    {
        path: '/adsplace',
        element: <AdsPlace/>,
    },
    {
        path: '/favorites',
        element: <Favorites/>,
        children: [
            {
                path: 'favorite',
                element: <Favorite/>,
            },
            {
                path: 'inspirations',
                element: <Inspirations/>,
            },
        ]
    },

]


const router = createBrowserRouter([
    {
        element: <PrivateRoutes/>,
        children: privateRoutes,
    },
    ...publicRoutes,
])

export const Router = () => {
    return <RouterProvider router={router}/>
}


function PrivateRoutes() {
    const {isLoading, isError } = useMeQuery()

    if (isLoading) {
        return null
    }

    const isAuthenticated = !isError

    if (!isAuthenticated) {
        return <Navigate to="/login"/>;
    }

    return <Layout/>;
}
