import React, {FC, useState} from "react";
import {ReactComponent as ArrayUp} from '../../../../assets/main/budget/arrayUp.svg';

import {ReactComponent as ArrayDown} from '../../../../assets/main/budget/arrowDown.svg';
import {ReactComponent as CalendarIcon} from '../../../../assets/main/wedding/calendar_24px.svg';
import {ReactComponent as OutlinedIcon} from '../../../../assets/main/wedding/outlined.svg';
import {ReactComponent as LocationIcon} from '../../../../assets/main/wedding/location.svg';
import {checkedTask, TaskCalendar} from "../../../../app/tasks-wedding-reducer";
import {useAppDispatch, useAppSelector} from "../../../../common/hook";
import {spoilersSelector} from "../../../../app/selectors/spoilers";
import {setIsOpen} from "../../../../app/task-reduser";
import {
    ArrayBlock,
    ContainerIcons,
    ContentAddNote,
    ContentBlock,
    ContentBlueProvider,
    ContentContainer,
    ContentDate,
    ContentInput,
    ContentItem,
    ContentLocation,
    ContentProvider,
    ContentTitle,
    SpoilerBox,
    SpoilerButton,
    SpoilerContainer,
    SpoilerContent,
    SpoilerTitle,
    StyledArrayIcon,
    TaskItem
} from "../style-tasks";
import {PointsTask} from "../points-tasks/PointsTask";
import {ShowMore, ShowMoreBlock, ShowMoreWrapper} from "../../budget/butget-expense/style-budgetExpense";


interface Props {
    taskArray: TaskCalendar[];
    title: string;
    index: number;
    isExpanded: boolean;
    toggleExpand: () => void;
}


const fakeTaskArray: TaskCalendar[] = [
    {
        id: '1',
        title: 'Wybierz motyw ślubu',
        date: '15.03.2022',
        timeWith: '10:00',
        timeBefore: '12:00',
        location: 'Kraków, ul. Krakowskie Przedmieście 8',
        addNote: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        provider: 'Elena Fryzura',
        providerLink: '',
        done: true,
    },
    {
        id: '2',
        title: 'Wybierz motyw ślubu',
        date: '15.03.2022',
        timeWith: '10:00',
        timeBefore: '12:00',
        location: 'Kraków, ul. Krakowskie Przedmieście 8',
        addNote: '',
        provider: 'Elena Fryzura',
        providerLink: 'Ссылка на поставщика 1',
        done: false,
    },
    {
        id: '3',
        title: 'Wybierz motyw ślubu',
        date: '',
        timeWith: '',
        timeBefore: '',
        location: '',
        addNote: '',
        provider: '',
        providerLink: '',
        done: false,
    },
    {
        id: '4',
        title: 'Wybierz motyw ślubu',
        date: '15.03.2022',
        timeWith: '10:00',
        timeBefore: '12:00',
        location: 'Kraków, ul. Krakowskie Przedmieście 8',
        addNote: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        provider: 'Elena Fryzura',
        providerLink: '',
        done: true,
    },
    {
        id: '5',
        title: 'Wybierz motyw ślubu',
        date: '15.03.2022',
        timeWith: '10:00',
        timeBefore: '12:00',
        location: 'Kraków, ul. Krakowskie Przedmieście 8',
        addNote: '',
        provider: 'Elena Fryzura',
        providerLink: 'Ссылка на поставщика 1',
        done: false,
    },
    {
        id: '6',
        title: 'Wybierz motyw ślubu',
        date: '',
        timeWith: '',
        timeBefore: '',
        location: '',
        addNote: '',
        provider: '',
        providerLink: '',
        done: false,
    },
    {
        id: '7',
        title: 'Wybierz motyw ślubu',
        date: '',
        timeWith: '',
        timeBefore: '',
        location: '',
        addNote: '',
        provider: '',
        providerLink: '',
        done: false,
    },
];


export const TasksItem: FC<Props> = ({taskArray, title, index, isExpanded, toggleExpand}) => {
    const spoilers = useAppSelector(spoilersSelector);
    const dispatch = useAppDispatch();
    const [openPopupId, setOpenPopupId] = useState<string | null>(null);
    const [showAll, setShowAll] = useState(false);
    const visibleTasks = showAll ? fakeTaskArray : fakeTaskArray.slice(0, 6);
    const handleSpoilerToggle = (index: number) => {
        dispatch(setIsOpen({index}));
    };

    const handleShowMore = () => {
        setShowAll((prevShowAll) => !prevShowAll);
    };

    const handleTogglePopup = (id: string) => {
        setOpenPopupId((prevId) => (prevId === id ? null : id));
    };

    const onChangeHandler = (id: string, taskArray: TaskCalendar[]) => {
        dispatch(checkedTask({id, taskArray}));
    };

    return (
        <TaskItem>
            <SpoilerButton onClick={() => handleSpoilerToggle(index)}>
                <SpoilerTitle>{title}</SpoilerTitle>
                <ArrayBlock onClick={(e) => e.stopPropagation()}>
                    <StyledArrayIcon isOpenSpoiler={!spoilers[index].isOpenSpoiler}/>
                    <PointsTask expensesId={'0'} open={false} togglePopup={toggleExpand}/>
                </ArrayBlock>
            </SpoilerButton>
            <SpoilerBox isOpenSpoiler={!spoilers[index].isOpenSpoiler}>
                {visibleTasks.map((task) => (
                    <>
                        <SpoilerContainer key={task.id}>
                            <SpoilerContent isOpen={!spoilers[index].isOpenSpoiler}>
                                <ContentItem>
                                    <ContentBlock>
                                        <ContentInput
                                            type="checkbox"
                                            checked={task.done}
                                            onChange={() => onChangeHandler(task.id, taskArray)}
                                        />
                                        <ContentTitle isDone={task.done}>{task.title}</ContentTitle>
                                    </ContentBlock>
                                    <ContainerIcons>
                                        <OutlinedIcon/>
                                            <PointsTask
                                                expensesId={task.id}
                                                open={openPopupId === task.id}
                                                togglePopup={handleTogglePopup}
                                            />
                                    </ContainerIcons>
                                </ContentItem>
                                <ContentContainer>
                                    {task.date === '' ?
                                        <ContentDate></ContentDate>
                                        :
                                        <ContentDate><CalendarIcon/>{`${task.date} | ${task.timeWith}-${task.timeBefore}`}
                                        </ContentDate>
                                    }
                                    {task.location === '' ?
                                        <ContentLocation></ContentLocation>
                                        :
                                        <ContentLocation><LocationIcon/>{task.location}</ContentLocation>
                                    }
                                    {task.providerLink === '' ?
                                        <ContentProvider>{task.provider}</ContentProvider>
                                        :
                                        <ContentBlueProvider>{task.provider}</ContentBlueProvider>
                                    }
                                </ContentContainer>
                                <ContentAddNote>{task.addNote}</ContentAddNote>
                            </SpoilerContent>
                        </SpoilerContainer>
                    </>
                ))}
                {fakeTaskArray.length > 6 && (
                    <ShowMoreWrapper>
                        <ShowMore onClick={handleShowMore}>
                            {showAll ? (
                                <ShowMoreBlock>
                                    <span>Zwiń</span>
                                    <span><ArrayDown/></span>
                                </ShowMoreBlock>
                            ) : (
                                <ShowMoreBlock>
                                    <span>Zobacz wszystkie kategorie</span>
                                    <span><ArrayUp/></span>
                                </ShowMoreBlock>
                            )}
                        </ShowMore>
                    </ShowMoreWrapper>
                )}
            </SpoilerBox>
        </TaskItem>
    );
};