import styled from "styled-components";



export const WebsiteWrapper = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding-top: 70px;
`